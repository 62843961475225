export const classColors = [
  0xdddddd, 0xe6194b, 0x3cb44b, 0xffe119, 0x4363d8, 0xf58231, 0x911eb4, 0x42d4f4, 0xf032e6,
  0xbfef45, 0xfabebe, 0x469990, 0xe6beff, 0x9a6324, 0xfffac8, 0x800000, 0xaaffc3, 0x808000,
  0xffd8b1, 0x000075, 0xa9a9a9,
]

// export const baseColor = '#ecf0f1'
export const baseColor = '#dee2e6'

export function getColor(idx) {
  return classColors[idx % classColors.length]
}

export function getColorRGB(idx) {
  let color = getColor(idx)
  let r = (color >> 16) & 255
  let g = (color >> 8) & 255
  let b = color & 255
  return [r, g, b]
}

export function getColorForCSS(idx, alpha) {
  if (!alpha) {
    alpha = 0.35
  }
  let color = getColorRGB(idx)
  return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${alpha})`
  // return `#${color[0].toString(16).padStart(2, '0')}${color[1].toString(16).padStart(2, '0')}${color[2].toString(16).padStart(2, '0')}`
}

export function getClassStyle(idx, { alpha }) {
  if (idx === undefined || idx < 0) {
    return { 'background-color': '#ffffff', color: '#000000' }
  }

  // return {'background-color': `hsl(${10 * (idx % 36)}, 90%, 80%)`, 'color': `hsl(${10 * (idx % 36)}, 90%, 20%)`}
  return {
    'background-color': getColorForCSS(idx, alpha),
    color: getTextColorForCSS(idx, alpha > 0.8),
  }
}

export function getTextColorForCSS(idx, highContrast) {
  if (highContrast) {
    let color = getColorRGB(idx)
    let r = color[0]
    let g = color[1]
    let b = color[2]

    let y = Math.floor(0.299 * r + 0.587 * g + 0.114 * b)

    if (y < 150) {
      return '#ffffff'
    }
    return '#000000'
  } else {
    let color = getColorRGB(idx)
    let r = color[0]
    let g = color[1]
    let b = color[2]

    const targetLuminance = 30
    const luminance = (r + g + b) / 3
    let dl = luminance - targetLuminance

    r = Math.max(Math.min(r - dl, 255), 0)
    g = Math.max(Math.min(g - dl, 255), 0)
    b = Math.max(Math.min(b - dl, 255), 0)

    return `rgba(${r}, ${g}, ${b}, 1.0)`
  }
}

// export default classColors, getColor;
