<template>
  <table class="table table-sm table-striped">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Username</th>
        <th scope="col" class="text-center">License</th>
        <th scope="col" class="text-center">Role</th>
        <th scope="col" />
      </tr>
    </thead>
    <tbody>
      <tr v-for="user in userEntries" :key="`user-table-row-${user.username}`">
        <td>{{ getName(user) }}</td>
        <td>{{ user.username }}</td>
        <td class="text-center">
          <fai
            v-if="!organizationLicenseValid"
            id="exclamation-circle"
            v-b-popover.hover.top="'The organization license is invalid.'"
            icon="exclamation-circle"
            class="text-danger"
          />
          <template v-else>
            <fai v-if="user.license !== null" icon="check" class="text-success" />
            <fai v-else icon="ban" class="text-danger" />
          </template>
        </td>
        <td class="text-center">
          {{ getRole(user) | capitalize }}
        </td>
        <td class="text-right">
          <div>
            <b-dropdown variant="link" size="sm" no-caret>
              <template slot="button-content">
                <fai icon="ellipsis-v" class="text-primary" />
              </template>
              <b-dropdown-item href="#" @click="editUser(user)"> edit</b-dropdown-item>
              <b-dropdown-item href="#" @click="changeRole(user)"> change role</b-dropdown-item>
              <b-dropdown-item href="#" @click="changePassword(user)">
                change password
              </b-dropdown-item>
              <b-dropdown-item v-if="user.license !== null" href="#" @click="unassignLicense(user)">
                unassign license
              </b-dropdown-item>
              <b-dropdown-item
                v-if="user.license === null"
                :disabled="!organizationLicenseValid"
                href="#"
                @click="assignLicense(user)"
              >
                assign license
              </b-dropdown-item>
              <b-dropdown-item href="#" @click="deleteUser(user)"> delete</b-dropdown-item>
            </b-dropdown>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'UserTable',
  components: {},
  props: {
    organizationLicenseValid: Boolean,
    users: Array,
  },

  data() {
    return {}
  },

  computed: {
    userEntries() {
      return this.$_.orderBy(this.users, 'username', 'asc')
    },
  },

  created() {},

  mounted() {},

  methods: {
    getName(user) {
      let displayName = ''
      if (user) {
        if (user.first_name) {
          displayName += user.first_name + ' '
        }

        if (user.last_name) {
          displayName += user.last_name
        }
      }

      if (displayName === '') {
        displayName = '-'
      }

      return displayName
    },

    getRole(user) {
      if (user.organization_role) {
        return user.organization_role
      } else {
        return '-'
      }
    },

    editUser(user) {
      this.$emit('edit-user', user)
    },

    changeRole(user) {
      this.$emit('change-role', user)
    },

    changePassword(user) {
      this.$emit('change-password', user)
    },
    unassignLicense(user) {
      this.$emit('unassign-license', user)
    },
    assignLicense(user) {
      this.$emit('assign-license', user)
    },
    deleteUser(user) {
      this.$emit('delete-user', user)
    },
  },
}
</script>

<style scoped></style>
