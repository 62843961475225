<template>
  <div class="tile p-2" :class="{ selected: value, disabled: disabled }" @click="onClick">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SelectableCard',
  props: {
    value: Boolean,
    disabled: Boolean,
  },

  data() {
    return {}
  },

  methods: {
    onClick(evt) {
      if (!this.disabled) {
        this.$emit('input', !this.value)
      }
      this.$emit('click', evt)
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../custom';

.no-decoration {
  text-decoration: none;
  color: #000000;
}

.tile {
  @extend .shadow-md;

  /*display: flex;*/
  min-height: 5em;
  justify-content: center;
  align-items: center;
  border: $gray-200 solid 1px;
  border-radius: 5px;

  overflow-wrap: break-word;
  word-break: break-word;

  background: $white;

  &:not(.disabled):hover {
    background: $blue-light;
    cursor: pointer;
  }
}

.selected {
  background: $primary;
  color: $white;

  &:not(.disabled):hover {
    background: $blue-variation;
  }
}

.disabled {
  cursor: default !important;
  opacity: 0.6;
}
</style>
