import { render, staticRenderFns } from "./AutoAnnotationModal.vue?vue&type=template&id=69d02f47&scoped=true&"
import script from "./AutoAnnotationModal.vue?vue&type=script&lang=js&"
export * from "./AutoAnnotationModal.vue?vue&type=script&lang=js&"
import style0 from "./AutoAnnotationModal.vue?vue&type=style&index=0&id=69d02f47&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69d02f47",
  null
  
)

export default component.exports