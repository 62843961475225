<template>
  <div>
    <status-bar
      :total="total"
      :reviewed="reviewed"
      :annotated="annotated"
      :uploaded="uploaded"
      :ignored="ignored"
      class="mb-2"
    />

    <div class="d-flex flex-wrap font-size-small">
      <div class="w-100 font-weight-500 mb-1">
        total:
        {{ total }}
      </div>
      <div class="w-50">
        <fai icon="square" class="text-success mr-1" />
        reviewed: {{ reviewed }}
      </div>
      <div class="w-50">
        <fai icon="square" class="text-primary mr-1" />
        annotated: {{ annotated }}
      </div>
      <div class="w-50">
        <fai icon="square" class="text-light mr-1" />
        uploaded: {{ uploaded }}
      </div>
      <div class="w-50">
        <fai icon="square" class="text-dark mr-1" />
        ignored: {{ ignored }}
      </div>
      <div v-if="unknown > 0" class="w-100">undefined status: {{ unknown }}</div>
    </div>
  </div>
</template>

<script>
import StatusBar from '@/components/statistics/StatusBar'

export default {
  name: 'StatusCountInfoCard',
  components: { StatusBar },
  props: {
    itemsPerStatus: {
      type: Array,
    },
  },
  data() {
    return {
      total: 0,
      reviewed: 0,
      annotated: 0,
      uploaded: 0,
      ignored: 0,
      unknown: 0,
    }
  },
  watch: {
    itemsPerStatus: {
      deep: true,
      handler: function () {
        this.update()
      },
    },
  },
  methods: {
    update() {
      this.reviewed = 0
      this.annotated = 0
      this.uploaded = 0
      this.ignored = 0
      this.unknown = 0
      for (const entry of this.itemsPerStatus) {
        if (entry.id === 'reviewed') {
          this.reviewed = entry.count
        } else if (entry.id === 'annotated') {
          this.annotated = entry.count
        } else if (entry.id === 'uploaded') {
          this.uploaded = entry.count
        } else if (entry.id === 'ignored') {
          this.ignored = entry.count
        } else {
          this.unknown = entry.count
        }
      }
      this.total = this.reviewed + this.annotated + this.uploaded + this.ignored + this.unknown
    },
  },
}
</script>

<style scoped lang="scss"></style>
