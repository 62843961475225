import posthog from 'posthog-js'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import Vue from 'vue'
import router from '@/router'

export let analyticsInitialized = false

export function initializeAnalytics(user) {
  if (Vue.prototype.$analyticsEnabled && user && user.username && user.allow_analytics) {
    if (!analyticsInitialized) {
      posthog.init('phc_wu2avmeUPOCMhA6GMyDHo9Q48gGHIX11lRpv9sXqkAD', {
        api_host: 'https://app.posthog.com',
        loaded: function (posthog) {
          posthog.identify(user.username)
          posthog.people.set({ org: user.organization.name })
        },
      })
      Sentry.init({
        Vue,
        dsn: 'https://86fc36b2fcce456ebe4b1f2de416c26f@sentry.io/1525249',
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['vision.data-spree.com', 'api.vision.data-spree.com', /^\//],
          }),
          new posthog.SentryIntegration(posthog, 'data-spree', 'deep-learning-ds-vue'),
        ],
        tracesSampleRate: 0.1,
      })
      analyticsInitialized = true
    } else {
      posthog.identify(user.username)
      posthog.people.set({ org: user.organization.name })
    }

    Sentry.setUser({ username: user.username })
  }
}

export function resetAnalytics() {
  Sentry.configureScope((scope) => scope.setUser(null))
  try {
    posthog.reset()
  } catch (e) {
    if (!(e instanceof TypeError)) {
      // posthog throws a type error if it is not initialized before reset
      console.error(e)
    }
  }
}
