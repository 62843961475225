<template>
  <div class="overlay">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BasicOverlay',
}
</script>

<style>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
</style>
