<template>
  <div class="widget-wrapper p-3">
    <div
      v-if="showWidget"
      class="position-relative border-radius-md shadow-md border-gray-200 bg-white overflow-hidden"
    >
      <basic-overlay v-if="showConfirmation" class="bg-white p-3 text-center">
        <div class="text-center text-success mt-3 mb-3">
          <fai icon="check-circle" class="font-size-xx-large"></fai>
        </div>
        <div class="font-size-large font-weight-700">Your request was sent successfully</div>
      </basic-overlay>
      <!--      <div class="d-flex justify-content-between align-items-center pl-2 bg-primary">-->
      <!--        <div class="text-white font-weight-700">Support Request</div>-->
      <!--        <b-button variant="link" class="text-white" @click="showWidget = false">-->
      <!--          <fai icon="caret-down" class="text-white" />-->
      <!--        </b-button>-->
      <!--      </div>-->
      <div class="mw-250px p-2">
        <b-form @submit.prevent="onSubmitRequest">
          <b-form-group id="message" label="Support Request" label-for="messageInput">
            <b-form-textarea
              id="messageInput"
              v-model="form.message"
              placeholder="Enter your support request"
              rows="4"
              max-rows="8"
              required
            ></b-form-textarea>
          </b-form-group>
          <div class="d-flex justify-content-between align-items-center mt-2 form-footer">
            <div class="text-danger font-weight-700 font-size-small mr-1">
              {{ errorMessage }}
            </div>
            <div class="flex-shrink-0">
              <b-button type="submit" variant="primary" tabindex="0">
                <b-spinner v-if="loading" small class="mr-1" />
                Submit
              </b-button>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <div class="mt-3 d-flex justify-content-end">
      <b-button variant="primary" size="lg" @click="onToggle" class="font-weight-700" pill>
        <div class="button-content">
          <transition name="fade" mode="out-in">
            <fai v-if="showWidget" icon="caret-down" />
            <span v-else>?</span>
          </transition>
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import BasicOverlay from '@/components/BasicOverlay'

export default {
  name: 'SupportWidget',
  components: { BasicOverlay },
  props: {},
  data() {
    return {
      errorMessage: '',
      showConfirmation: true,
      loading: false,
      showWidget: false,
      form: {
        message: '',
      },
    }
  },
  computed: {},
  methods: {
    onToggle() {
      this.showWidget = !this.showWidget
      this.showConfirmation = false
      this.errorMessage = ''
      this.form.message = ''
    },
    onSubmitRequest() {
      this.loading = true
      this.errorMessage = ''
      const requestData = {
        message: this.form.message,
      }
      this.$axios({
        method: 'post',
        url: '/api/support/',
        data: requestData,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
        .then(() => {
          this.loading = false
          this.showConfirmation = true
        })
        .catch((e) => {
          if (e.response) {
            this.errorMessage = 'Could not send support request'
          } else {
            this.errorMessage = 'No connection to server'
          }
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../custom';

.widget-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 15;
}

.button-content {
  min-width: 15px;
}
</style>
