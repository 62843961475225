<template>
  <div class="pl-3 pr-3 pb-2 mh-100 flex-grow-1 position-relative scroll-box">
    <!--    <loading-overlay v-if="modelsLoading" />-->
    <div class="mb-2 border-radius-md bg-white shadow-md border-gray-100">
      <card-collapse
        class="h-100 mh-100"
        initially-open
        inner-class="h-100 mh-100 scroll-box"
        no-animation
        small
        title="Group by"
      >
        <div class="pl-3 pr-3 pb-0 pt-1 position-relative">
          <div>
            <b-form-group>
              <b-form-radio v-model="selectedGroupBy" name="group-by-radio" value="dataset">
                Dataset
              </b-form-radio>
              <b-form-radio v-model="selectedGroupBy" name="group-by-radio" value="architecture">
                Architecture
              </b-form-radio>
              <b-form-radio v-model="selectedGroupBy" name="group-by-radio" value="type">
                Model type
              </b-form-radio>
              <b-form-radio v-model="selectedGroupBy" name="group-by-radio" value="date">
                Creation date
              </b-form-radio>
              <b-form-radio v-model="selectedGroupBy" name="group-by-radio" value="creator">
                Creator
              </b-form-radio>
              <b-form-radio v-model="selectedGroupBy" name="group-by-radio" value="none">
                No grouping
              </b-form-radio>
            </b-form-group>
          </div>
        </div>
      </card-collapse>
    </div>

    <div
      class="flex-grow-0 position-relative overflow-hidden mb-2 bg-white border-radius-md shadow-md border-gray-100"
    >
      <card-collapse
        title="Model Type"
        class="h-100 mh-100"
        inner-class="h-100 mh-100 pl-3 pr-3 pb-3 pt-1"
        initially-open
        no-animation
        small
      >
        <b-form-radio-group
          id="radio-group-model-type"
          v-model="selectedModelTypes"
          :options="[
            { text: 'Select All', value: ['classification', 'detection'] },
            { text: 'classification', value: ['classification'] },
            { text: 'detection', value: ['detection'] },
          ]"
          name="radio-options"
          stacked
        />
      </card-collapse>
    </div>

    <div
      class="flex-grow-0 position-relative overflow-hidden mb-2 bg-white border-radius-md shadow-md border-gray-100"
    >
      <card-collapse
        title="Dataset"
        class="h-100 mh-100"
        inner-class="h-100 mh-100 pl-3 pr-3 pb-0 pt-1"
        initially-open
        no-animation
        small
      >
        <checkbox-all-select
          v-model="selectedDatasets"
          :options="datasetFilterOptions"
          select-all-initially
        />
      </card-collapse>
    </div>

    <div
      class="flex-grow-0 position-relative overflow-hidden mb-2 bg-white border-radius-md shadow-md border-gray-100"
    >
      <card-collapse
        title="Architecture"
        class="h-100 mh-100"
        inner-class="h-100 mh-100 pl-3 pr-3 pb-0 pt-1"
        no-animation
        small
      >
        <checkbox-all-select
          v-model="selectedArchitectures"
          :options="architectureFilterOptions"
          select-all-initially
        />
      </card-collapse>
    </div>

    <div
      class="flex-grow-0 position-relative overflow-hidden mb-2 bg-white border-radius-md shadow-md border-gray-100"
    >
      <card-collapse
        title="Creator"
        class="h-100 mh-100"
        inner-class="h-100 mh-100 pl-3 pr-3 pb-0 pt-1"
        no-animation
        small
      >
        <checkbox-all-select
          v-model="selectedCreators"
          :options="creatorFilterOptions"
          select-all-initially
        />
      </card-collapse>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import CardCollapse from '@/components/CardCollapse'
import CheckboxAllSelect from '@/components/CheckboxAllSelect'

export default {
  name: 'ModelFilters',
  components: {
    CheckboxAllSelect,
    CardCollapse,
  },
  props: {
    models: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedModelTypes: ['classification', 'detection'],
      selectedDatasets: [],
      selectedArchitectures: [],
      selectedCreators: [],
      selectedGroupBy: 'none',
    }
  },
  mounted() {
    this.updateInternalFilters(this.value)
  },
  watch: {
    selectedModelTypes() {
      this.emitUpdate()
    },
    selectedDatasets() {
      this.emitUpdate()
    },
    selectedArchitectures() {
      this.emitUpdate()
    },
    selectedCreators() {
      this.emitUpdate()
    },
    selectedGroupBy() {
      this.emitUpdate()
    },
    value: {
      deep: true,
      handler: function () {
        this.updateInternalFilters(this.value)
      },
    },
  },
  computed: {
    ...mapState(['modelsLoading', 'user']),
    datasetFilterOptions() {
      const resultSet = new Set()
      for (const model of this.models) {
        for (const modelSubset of model.data_subsets) {
          resultSet.add(modelSubset.data_subset.dataset_name)
        }
      }
      return [...resultSet].sort()
    },
    architectureFilterOptions() {
      const resultSet = new Set()
      for (const model of this.models) {
        resultSet.add(model.network_config_option_name)
      }
      return [...resultSet].sort()
    },
    creatorFilterOptions() {
      const resultSet = new Set()
      for (const model of this.models) {
        resultSet.add(model.created_by.username)
      }
      return [...resultSet].sort()
    },
  },
  methods: {
    updateInternalFilters(filters) {
      if (filters) {
        this.selectedModelTypes = filters.selectedModelTypes
          ? filters.selectedModelTypes
          : this.selectedModelTypes
        this.selectedDatasets = filters.selectedDatasets
          ? filters.selectedDatasets
          : this.selectedDatasets
        this.selectedArchitectures = filters.selectedArchitectures
          ? filters.selectedArchitectures
          : this.selectedArchitectures
        this.selectedCreators = filters.selectedCreators
          ? filters.selectedCreators
          : this.selectedCreators
        this.selectedGroupBy = filters.selectedGroupBy
          ? filters.selectedGroupBy
          : this.selectedGroupBy
      } else {
        this.emitUpdate()
      }
    },
    emitUpdate() {
      const modelFilters = {
        selectedModelTypes: this.selectedModelTypes,
        selectedDatasets: this.selectedDatasets,
        selectedArchitectures: this.selectedArchitectures,
        selectedCreators: this.selectedCreators,
        selectedGroupBy: this.selectedGroupBy,
      }
      this.$emit('input', modelFilters)
    },
  },
}
</script>

<style lang="scss" scoped></style>
