<template>
  <b-modal id="modal-item-preview" v-model="showModal" hide-footer size="xl" @shown="draw">
    <template slot="modal-title">
      <template v-if="datasetItem && datasetItem.name">
        <div class="d-flex justify-content-between">
          <div class="pr-2">Item {{ datasetItem.name | getFileName }}</div>
          <div class="pl-2">
            <b-button
              :to="`/dataset-explorer?dataset=${datasetItem.dataset}&item=${datasetItem.id}`"
              target="_blank"
              size="sm"
              variant="outline-secondary"
            >
              open dataset item
            </b-button>
          </div>
        </div>
      </template>
    </template>
    <b-container fluid>
      <div class="row mh-300px">
        <transition name="slow-fade">
          <loading-overlay v-if="loading" />
          <!--          <loading-spinner  class="loading-spinner" />-->
          <!--          <div v-if="loading" class="image-loading-background" />-->
        </transition>
        <div style="display: none">
          <img
            v-if="datasetItem && datasetItem.data"
            ref="itemPreviewModalImage"
            :src="datasetItem.data"
            alt="Item Preview"
            @load="onImageLoad"
          />
        </div>
        <canvas ref="itemPreviewModalCanvas" class="w-100" height="1080" width="1920" />
        <b-button
          v-if="!isFirstItem"
          class="image-hover-button image-hover-button-left no-btn-outline"
          size="lg"
          variant="link"
          @click="previous"
        >
          <fai icon="arrow-circle-left" />
        </b-button>
        <b-button
          v-if="!isLastItem"
          class="image-hover-button image-hover-button-right no-btn-outline"
          size="lg"
          variant="link"
          @click="next"
        >
          <fai icon="arrow-circle-right" />
        </b-button>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import { drawItem } from '@/draw'
import LoadingOverlay from '@/components/LoadingOverlay'

export default {
  name: 'ItemPreviewModal',
  components: { LoadingOverlay },
  props: {
    show: Boolean,
    item: {
      type: Object,
      default: () => {},
    },

    showImageLabels: {
      type: Boolean,
      default: true,
    },
    showPredictedLabels: {
      type: Boolean,
      default: true,
    },
    showObjects: {
      type: Boolean,
      default: true,
    },
    drawGroundTruthDashed: {
      type: Boolean,
      default: true,
    },
    showPredictedObjects: {
      type: Boolean,
      default: true,
    },
    predictedObjectClassIds: {
      type: Array,
      default: () => [],
    },
    showConfidence: {
      type: Boolean,
      default: true,
    },
    objectSelectivity: {
      type: Number,
      default: 0.3,
    },
    boxLineWidth: {
      type: Number,
      default: 5,
    },
    textSize: {
      type: Number,
      default: 24,
    },

    isFirstItem: {
      type: Boolean,
      default: false,
    },
    isLastItem: {
      type: Boolean,

      default: false,
    },
  },

  data() {
    return {
      showModal: false,
      loading: false,
    }
  },

  computed: {
    datasetItem() {
      if ('dataset_item' in this.item) {
        return this.item['dataset_item']
      } else {
        return this.item
      }
    },
    predictions() {
      if ('predictions' in this.item) {
        return this.item['predictions']
      } else {
        return undefined
      }
    },
  },

  watch: {
    show: function (newValue) {
      this.showModal = newValue
    },

    showModal: function (newValue) {
      this.$emit('show', newValue)
    },
    item: {
      deep: true,
      handler: function () {
        this.loading = true
      },
    },
  },

  created: function () {},

  mounted() {
    this.showModal = this.show
    this.draw()
  },

  methods: {
    previous() {
      this.$emit('open-previous')
    },
    next() {
      this.$emit('open-next')
    },
    onImageLoad() {
      this.loading = false
      this.draw()
    },
    draw() {
      let canvas = this.$refs.itemPreviewModalCanvas
      if (typeof canvas === 'undefined') {
        return
      }

      let image = this.$refs.itemPreviewModalImage

      if (this.datasetItem && image) {
        drawItem(canvas, this.datasetItem, this.predictions, image, {
          drawGroundTruthObjects: this.showObjects,
          drawGroundTruthDashed: this.drawGroundTruthDashed,
          predictedObjectClassIds: this.predictedObjectClassIds,
          confidenceThreshold: this.objectSelectivity,
          showConfidence: this.showConfidence,
          boxLineWidth: this.boxLineWidth,
          applyPadding: false,
          textSize: this.textSize,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.image-hover-button {
  font-size: 5em;
  color: #ffffff;
  position: absolute;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: visibility 0s, opacity 0.25s linear;
}

.image-hover-button-left {
  padding-left: 0.5em;
  left: 0;
}

.image-hover-button-right {
  padding-right: 0.5em;
  right: 0;
}

.image-hover-button:hover {
  opacity: 0.8;
}

.image-loading-background {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 9;
}
</style>
