<template>
  <div ref="canvasWrapper" class="h-100 mh-100">
    <canvas id="canvas" ref="canvas" class="canvas" />
    <canvas id="textureCanvas" ref="textureCanvas" class="canvas" style="display: none" />
  </div>
</template>

<script>
import _ from 'lodash'
import * as THREE from 'three'
import { MeshLine, MeshLineMaterial } from 'meshline'

import CustomOrbitControls from './CustomOrbitControls'
import { ctrlOrCmdPressed } from '@/keyboardShortcuts'
import { getColor } from '@/colors'
import { PCDLoader } from '@/components/annotation/PCDLoader'

export default {
  name: 'AnnotationToolCanvas',
  props: [
    'classLabels',
    'selectedLabel',
    'datasetItem',
    'orientedBoxes',
    'showBoxOrientation',
    'viewOnly',
    'showCrossHair',
    'active',
    'rangeRgbImageBlending',
    'brightness',
    'contrast',
    'gamma',
  ],
  data() {
    return {
      item: {
        annotations: {
          objects: [],
        },
      },

      selectedObject: null,
      objects: [],
      objectHistory: [],
      updateAllObjects: false,
      updateRenderer: true,
      currentState: undefined,
    }
  },

  computed: {
    selectedClassLabel: function () {
      return this.selectedLabel
    },
  },

  watch: {
    selectedLabel: function (newLabel, oldLabel) {
      if (newLabel !== oldLabel) {
        this.changeClassOfSelectedObject(newLabel)
      }
    },
    classLabels: {
      handler: function () {
        this.updateAllObjects = true
      },
      deep: true,
    },

    orientedBoxes: function (newValue) {
      if (newValue) {
        this.updateSelectedObjectTools()
      } else {
        this.removeRotationTool()
      }
    },

    showBoxOrientation: function () {
      this.updateAllObjects = true
    },

    showCrossHair(newValue) {
      if (!newValue) {
        this.hideCrossHair()
      }
    },

    datasetItem: {
      deep: true,
      handler: function (newItem) {
        if (newItem) {
          this.item = _.cloneDeep(newItem)
          this.loadItem(this.item)
        }
      },
    },

    rangeRgbImageBlending() {
      this.debounceddrawTexture()
    },
    brightness() {
      this.debounceddrawTexture()
    },
    contrast() {
      this.debounceddrawTexture()
    },
    gamma() {
      this.debounceddrawTexture()
    },
  },

  created() {
    this.debounceddrawTexture = _.debounce(this.drawTexture, 50)

    this.EDITOR_PLANE_NAME = 'plane_editor'
    this.IMAGE_PLANE_NAME = 'plane_image'

    this.STATE_DEFAULT = 'default'
    this.STATE_NEW_OBJECT = 'new_object'
    this.STATE_INSERT_OBJECT = 'insert_object'
    this.STATE_MOVE_OBJECT = 'move_object'
    this.STATE_ROTATE_OBJECT = 'rotate_object'
    this.STATE_RESIZE_OBJECT_TOP_RIGHT = 'resize_object_top_right'
    this.STATE_RESIZE_OBJECT_TOP = 'resize_object_top'
    this.STATE_RESIZE_OBJECT_TOP_LEFT = 'resize_object_top_left'
    this.STATE_RESIZE_OBJECT_LEFT = 'resize_object_left'
    this.STATE_RESIZE_OBJECT_BOTTOM_LEFT = 'resize_object_bottom_left'
    this.STATE_RESIZE_OBJECT_BOTTOM = 'resize_object_bottom'
    this.STATE_RESIZE_OBJECT_BOTTOM_RIGHT = 'resize_object_bottom_right'
    this.STATE_RESIZE_OBJECT_RIGHT = 'resize_object_right'

    this.currentState = this.STATE_DEFAULT
  },

  beforeDestroy() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame)
    }

    if (this.renderer) {
      let loseContextExtension = this.renderer.context.getExtension('WEBGL_lose_context')
      if (loseContextExtension) {
        loseContextExtension.loseContext()
      }
    }

    window.removeEventListener('keydown', this.onDocumentKeyDown)
    if (this.canvas) {
      this.canvas.removeEventListener('mousedown', this.onMouseDown)
      this.canvas.removeEventListener('mousemove', this.onMouseMove)
      this.canvas.removeEventListener('mouseup', this.onMouseUp)
    }
  },

  mounted() {
    this.baseURL = this.$axios.defaults.baseURL

    this.raycastObjects = []

    this.initializeCanvas()

    // Image
    this.imageWidth = 0.0
    this.imageHeight = 0.0
    this.imageScale = 0.0

    this.raycaster = new THREE.Raycaster()

    window.addEventListener('keydown', this.onDocumentKeyDown, false)
    this.newObject = null
    this.mouseDownImagePosition = [0, 0]
    this.canvas.addEventListener('mousedown', this.onMouseDown, false)

    this.mouseMoveImagePosition = [0, 0]
    this.canvas.addEventListener('mousemove', this.onMouseMove, false)

    this.mouseUpImagePosition = [0, 0]
    this.canvas.addEventListener('mouseup', this.onMouseUp, false)

    this.lastZoom = this.controls.object.zoom
    this.objectGroup = new THREE.Group()
    this.scene.add(this.objectGroup)
    this.raycastObjects.push(this.objectGroup)

    this.createCrossHair()
    if (!this.showCrossHair) {
      this.hideCrossHair()
    }

    this.objectIdCounter = 0

    this.item = _.cloneDeep(this.datasetItem)
    this.loadItem(this.item)

    this.animate()

    this.$nextTick(() => {
      this.$refs.canvas.focus()
    })
  },

  methods: {
    initializeCanvas() {
      try {
        // initialize scene and container
        this.scene = new THREE.Scene()

        this.canvas = this.$refs.canvas
        this.width = this.canvas.clientWidth
        this.height = this.canvas.clientHeight

        if (this.width === 0) this.width = 200
        if (this.height === 0) this.height = 200

        // initialize camera
        this.camera = new THREE.OrthographicCamera(
          this.width / -2,
          this.width / 2,
          this.height / 2,
          this.height / -2,
          1,
          10000
        )

        this.camera.position.set(0, 1000, 0)
        this.camera.updateProjectionMatrix()
        this.scene.add(this.camera)

        // initialize mouse controls
        this.controls = new CustomOrbitControls(this.camera, this.canvas)
        this.controls.enableRotate = false
        this.controls.minDistance = 1

        // initialize renderer
        // Not used at the moment: we need to use the WEbGL1Renderer instead of WebGLRenderer due to an issue with the text shader
        // see https://github.com/Jam3/three-bmfont-text/issues/38 for details
        // this.renderer = new THREE.WebGL1Renderer({canvas: this.canvas, alpha: true, antialias: true})

        this.renderer = new THREE.WebGLRenderer({
          canvas: this.canvas,
          alpha: true,
          antialias: true,
        })
        this.renderer.setPixelRatio(window.devicePixelRatio)
        this.renderer.setClearColor(0xffffff)

        // add group for the objects that will be rendered
        this.objectGroup = new THREE.Group()
        this.scene.add(this.objectGroup)

        this.selectedObjectToolsNewlyCreated = false
        this.selectedObjectTools = null
        this.sizeSelectorPlaneTopRight = null
        this.sizeSelectorPlaneTop = null
        this.sizeSelectorPlaneTopLeft = null
        this.sizeSelectorPlaneLeft = null
        this.sizeSelectorPlaneBottomLeft = null
        this.sizeSelectorPlaneBottom = null
        this.sizeSelectorPlaneBottomRight = null
        this.sizeSelectorPlaneRight = null
        this.rotationToolCircle = null

        this.selectedObject = null
        this.selectedObjectOriginal = null

        // Image
        this.imageWidth = 0.0
        this.imageHeight = 0.0
        this.imageScale = 0.0

        this.raycaster = new THREE.Raycaster()
      } catch (e) {
        this.errorMessage = 'Could not create WebGL canvas.'
      }
    },

    loadItem(item) {
      // this.updateAllObjects = true
      this.selectedObject = null
      this.selectedObjectOriginal = null
      this.updateSelectedObjectTools()
      this.objectHistory = []
      if (item) {
        this.loadImage(item, () => {
          this.setAnnotations(item.annotations)
        })
        // TODO reset everything (probably there is nothing else to reset)
      }
    },

    clone(obj) {
      if (null == obj || 'object' != typeof obj) return obj
      let copy = obj.constructor()
      for (let attr in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, attr)) copy[attr] = obj[attr]
      }
      return copy
    },

    cloneArray(objArray) {
      let newArray = []
      for (let i = 0; i < objArray.length; i++) {
        newArray.push(this.clone(objArray[i]))
      }

      return newArray
    },

    onUpdateAnnotations() {
      this.$emit('annotations-update', this.objects)
    },

    setAnnotations(annotations) {
      let newObjects = []
      let newObjectIdCounter = 0

      if (annotations) {
        if (annotations.objects !== undefined) {
          for (let i = 0; i < annotations.objects.length; i++) {
            let o = annotations.objects[i]
            newObjects.push({
              id: o.id,
              label: o.label,
              x: o.x,
              y: o.y,
              width: o.width,
              height: o.height,
              orientation: o.orientation,
              selected: false,
              updated: true,
            })

            if (newObjectIdCounter < o.id) {
              newObjectIdCounter = o.id
            }
          }
          newObjectIdCounter++
        }
      }

      this.objects = newObjects
      this.objectIdCounter = newObjectIdCounter

      this.updateAllObjects = true
      this.selectedObject = null
      this.updateSelectedObjectTools()
    },

    getMousePosition(x, y) {
      let rect = this.canvas.getBoundingClientRect()
      return [(x - rect.left) / rect.width, (y - rect.top) / rect.height]
    },

    selectClassLabel(id) {
      this.$emit('class-label-changed', id)
    },

    createRotationTool() {
      if (this.rotationToolCircle === null) {
        let textureLoader = new THREE.TextureLoader()
        textureLoader.setCrossOrigin(true)
        let textureRotationCircle = textureLoader.load('/rotation-arrow.png')
        textureRotationCircle.anisotropy = this.renderer.capabilities.getMaxAnisotropy()

        let rotationToolMaterial = new THREE.MeshBasicMaterial({
          map: textureRotationCircle,
          transparent: true,
          color: 0xffffff,
        })

        let rotationToolGeometry = new THREE.CircleGeometry(16, 32)
        this.rotationToolCircle = new THREE.Mesh(rotationToolGeometry, rotationToolMaterial)
        this.rotationToolCircle.name = 'selected_object_tools_rotation'
        this.selectedObjectTools.add(this.rotationToolCircle)
      }
    },

    removeRotationTool() {
      if (this.rotationToolCircle !== null) {
        for (let i = this.selectedObjectTools.children.length - 1; i >= 0; i--) {
          if (this.selectedObjectTools.children[i].name === 'selected_object_tools_rotation') {
            this.selectedObjectTools.remove(this.selectedObjectTools.children[i])
            this.rotationToolCircle = null
          }
        }
      }
    },

    createCrossHair() {
      this.crossHair = new THREE.Group()
      let material = new THREE.LineBasicMaterial({ color: 0xaaaaaa })

      let points1 = []
      points1.push(new THREE.Vector3(-100000, 100, 0))
      points1.push(new THREE.Vector3(100000, 100, 0))
      let geometry1 = new THREE.BufferGeometry().setFromPoints(points1)
      let line1 = new THREE.Line(geometry1, material)
      this.crossHair.add(line1)

      let points2 = []
      points2.push(new THREE.Vector3(0, 100, -100000))
      points2.push(new THREE.Vector3(0, 100, 100000))
      let geometry2 = new THREE.BufferGeometry().setFromPoints(points2)
      let line2 = new THREE.Line(geometry2, material)
      this.crossHair.add(line2)

      this.scene.add(this.crossHair)
    },

    hideCrossHair() {
      this.crossHair.position.x = -1000000
      this.crossHair.position.z = -1000000
    },

    updateSelectedObjectTools() {
      if (!this.selectedObject) {
        if (this.selectedObjectTools) {
          this.selectedObjectTools.position.x = -10000.0
          this.selectedObjectTools.position.z = -10000.0
        }
        return
      }

      // Tools for selected box
      if (!this.selectedObjectTools) {
        this.selectedObjectToolsNewlyCreated = true
        let SELECTED_OBJECT_TOOLS_NAME = 'selected_object_tools'
        this.selectedObjectTools = new THREE.Group()
        this.selectedObjectTools.name = SELECTED_OBJECT_TOOLS_NAME
        this.selectedObjectTools.rotation.x = -0.5 * Math.PI
        this.selectedObjectTools.position.y = 910

        let textureLoader = new THREE.TextureLoader()
        textureLoader.setCrossOrigin(true)

        if (this.orientedBoxes) {
          this.createRotationTool()
        }

        let textureSizeSelector = textureLoader.load('/size-selector.png')
        let sizeSelectorMaterial = new THREE.MeshBasicMaterial({
          map: textureSizeSelector,
          transparent: true,
          color: 0xffffff,
        })

        let sizeSelectorGeometry = new THREE.PlaneGeometry(20, 20, 1, 1)
        this.sizeSelectorPlaneTopRight = new THREE.Mesh(sizeSelectorGeometry, sizeSelectorMaterial)

        this.sizeSelectorPlaneTopRight.name = 'selected_object_tools_size_top_right'
        this.sizeSelectorPlaneTop = new THREE.Mesh(sizeSelectorGeometry, sizeSelectorMaterial)
        this.sizeSelectorPlaneTop.name = 'selected_object_tools_size_top'
        this.sizeSelectorPlaneTopLeft = new THREE.Mesh(sizeSelectorGeometry, sizeSelectorMaterial)
        this.sizeSelectorPlaneTopLeft.name = 'selected_object_tools_size_top_left'
        this.sizeSelectorPlaneLeft = new THREE.Mesh(sizeSelectorGeometry, sizeSelectorMaterial)
        this.sizeSelectorPlaneLeft.name = 'selected_object_tools_size_left'
        this.sizeSelectorPlaneBottomLeft = new THREE.Mesh(
          sizeSelectorGeometry,
          sizeSelectorMaterial
        )
        this.sizeSelectorPlaneBottomLeft.name = 'selected_object_tools_size_bottom_left'
        this.sizeSelectorPlaneBottom = new THREE.Mesh(sizeSelectorGeometry, sizeSelectorMaterial)
        this.sizeSelectorPlaneBottom.name = 'selected_object_tools_size_bottom'
        this.sizeSelectorPlaneBottomRight = new THREE.Mesh(
          sizeSelectorGeometry,
          sizeSelectorMaterial
        )
        this.sizeSelectorPlaneBottomRight.name = 'selected_object_tools_size_bottom_right'
        this.sizeSelectorPlaneRight = new THREE.Mesh(sizeSelectorGeometry, sizeSelectorMaterial)
        this.sizeSelectorPlaneRight.name = 'selected_object_tools_size_right'

        this.selectedObjectTools.add(this.sizeSelectorPlaneTopRight)
        this.selectedObjectTools.add(this.sizeSelectorPlaneTop)
        this.selectedObjectTools.add(this.sizeSelectorPlaneTopLeft)
        this.selectedObjectTools.add(this.sizeSelectorPlaneLeft)
        this.selectedObjectTools.add(this.sizeSelectorPlaneBottomLeft)
        this.selectedObjectTools.add(this.sizeSelectorPlaneBottom)
        this.selectedObjectTools.add(this.sizeSelectorPlaneBottomRight)
        this.selectedObjectTools.add(this.sizeSelectorPlaneRight)
        this.scene.add(this.selectedObjectTools)
        this.raycastObjects.push(this.selectedObjectTools)
      }

      this.sizeSelectorPlaneTopRight.position.x =
        0.5 * this.selectedObject.width * (this.imageScale / this.imageWidth) * this.imageWidth
      this.sizeSelectorPlaneTopRight.position.y =
        0.5 * this.selectedObject.height * (this.imageScale / this.imageHeight) * this.imageHeight

      this.sizeSelectorPlaneTop.position.x = 0.0
      this.sizeSelectorPlaneTop.position.y =
        0.5 * this.selectedObject.height * (this.imageScale / this.imageHeight) * this.imageHeight

      this.sizeSelectorPlaneTopLeft.position.x =
        -0.5 * this.selectedObject.width * (this.imageScale / this.imageWidth) * this.imageWidth
      this.sizeSelectorPlaneTopLeft.position.y =
        0.5 * this.selectedObject.height * (this.imageScale / this.imageHeight) * this.imageHeight

      this.sizeSelectorPlaneLeft.position.x =
        -0.5 * this.selectedObject.width * (this.imageScale / this.imageWidth) * this.imageWidth
      this.sizeSelectorPlaneLeft.position.y = 0.0

      this.sizeSelectorPlaneBottomLeft.position.x =
        -0.5 * this.selectedObject.width * (this.imageScale / this.imageWidth) * this.imageWidth
      this.sizeSelectorPlaneBottomLeft.position.y =
        -0.5 * this.selectedObject.height * (this.imageScale / this.imageHeight) * this.imageHeight

      this.sizeSelectorPlaneBottom.position.x = 0.0
      this.sizeSelectorPlaneBottom.position.y =
        -0.5 * this.selectedObject.height * (this.imageScale / this.imageHeight) * this.imageHeight

      this.sizeSelectorPlaneBottomRight.position.x =
        0.5 * this.selectedObject.width * (this.imageScale / this.imageWidth) * this.imageWidth
      this.sizeSelectorPlaneBottomRight.position.y =
        -0.5 * this.selectedObject.height * (this.imageScale / this.imageHeight) * this.imageHeight

      this.sizeSelectorPlaneRight.position.x =
        0.5 * this.selectedObject.width * (this.imageScale / this.imageWidth) * this.imageWidth
      this.sizeSelectorPlaneRight.position.y = 0.0

      if (this.rotationToolCircle !== null) {
        this.rotationToolCircle.position.x = 0.0
        this.rotationToolCircle.position.y =
          0.5 *
            this.selectedObject.height *
            (this.imageScale / this.imageHeight) *
            this.imageHeight +
          25.0 / this.controls.object.zoom
      }

      this.selectedObjectTools.position.x =
        (this.selectedObject.x * (this.imageScale / this.imageWidth) - 0.5) * this.imageWidth
      this.selectedObjectTools.position.z =
        (this.selectedObject.y * (this.imageScale / this.imageHeight) - 0.5) * this.imageHeight
      this.selectedObjectTools.rotation.z = this.selectedObject.orientation
    },

    getResizeVectors(anchor) {
      anchor.rotateAround(new THREE.Vector2(0.0, 0.0), -this.selectedObjectOriginal.orientation)
      anchor.x += this.selectedObjectOriginal.x
      anchor.y += this.selectedObjectOriginal.y

      let e = new THREE.Vector2(
        Math.cos(-this.selectedObjectOriginal.orientation),
        Math.sin(-this.selectedObjectOriginal.orientation)
      )
      let a = new THREE.Vector2(
        this.mouseMoveImagePosition[0] - anchor.x,
        this.mouseMoveImagePosition[1] - anchor.y
      )

      return [e, a]
    },

    selectObject(object) {
      if (this.selectedObject) {
        this.selectedObject.selected = false
        this.selectedObject.updated = true
      }

      object.selected = true
      object.updated = true

      this.updateAllObjects = true

      this.selectedObject = object
      this.selectedObjectOriginal = this.clone(this.selectedObject)
      this.updateSelectedObjectTools()

      this.selectClassLabel(this.selectedObject['label'])

      this.$emit('select-object', this.objects)
    },

    changeClassOfSelectedObject(classLabel) {
      if (this.selectedObject != null) {
        if (this.selectedObject.label !== classLabel) {
          this.selectedObject.label = classLabel
          this.selectedObject.updated = true
          this.onUpdateAnnotations()
        }
      }
    },

    selectObjectById(id) {
      for (let i = 0; i < this.objects.length; i++) {
        let o = this.objects[i]
        if (o.id === id) {
          // TODO == or === ?
          this.selectObject(o)
          break
        }
      }
    },

    loadImage(item, callback) {
      this.$emit('load-image-start')

      const textureLoadCallback = function (t) {
        self.texture = t
        self.imageWidth = t.image.width
        self.imageHeight = t.image.height
        self.imageScale = Math.max(self.imageWidth, self.imageHeight)
        t.minFilter = THREE.LinearFilter
        t.magFilter = THREE.NearestFilter
        t.wrapS = THREE.ClampToEdgeWrapping
        t.wrapT = THREE.ClampToEdgeWrapping

        let planeMaterial2 = new THREE.MeshBasicMaterial({
          color: 0x000000,
          // color: 0xf7fafc,
          opacity: 0.0,
        })
        let planeGeometry2 = new THREE.PlaneGeometry(
          2 * self.imageWidth,
          2 * self.imageHeight,
          1,
          1
        )

        if (self.plane2) {
          self.scene.remove(self.plane2)
          const idx = self.raycastObjects.indexOf(self.plane2)
          if (idx >= 0) {
            self.raycastObjects.splice(idx, 1)
          }
          self.plane2 = undefined
        }

        self.plane2 = new THREE.Mesh(planeGeometry2, planeMaterial2)
        self.plane2.position.y = -2000
        self.plane2.rotation.x = -0.5 * Math.PI
        self.plane2.name = self.EDITOR_PLANE_NAME
        self.scene.add(self.plane2)
        self.raycastObjects.push(self.plane2)

        let planeMaterial = new THREE.MeshBasicMaterial({ map: t })
        let planeGeometry = new THREE.PlaneGeometry(self.imageWidth, self.imageHeight, 1, 1)

        if (self.imagePlane) {
          self.scene.remove(self.imagePlane)

          const idx = self.raycastObjects.indexOf(self.imagePlane)
          if (idx >= 0) {
            self.raycastObjects.splice(idx, 1)
          }
          self.imagePlane = undefined
        }

        self.imagePlane = new THREE.Mesh(planeGeometry, planeMaterial)
        self.imagePlane.rotation.x = -0.5 * Math.PI
        self.imagePlane.name = self.IMAGE_PLANE_NAME
        self.scene.add(self.imagePlane)
        self.raycastObjects.push(self.imagePlane)

        self.updateRenderer = true

        let margin = 0.05
        self.controls.object.zoom = Math.min(
          self.width / t.image.width - margin,
          self.height / t.image.height - margin
        )
        self.controls.object.updateProjectionMatrix()
        self.controls.zoomChanged = true

        if (callback) {
          callback()
        }

        self.$emit('image-update', {
          width: self.imageWidth,
          height: self.imageHeight,
          scale: self.imageScale,
        })
      }

      const self = this
      if (item.data_type === 'point_cloud') {
        const pcdLoader = new PCDLoader()
        pcdLoader.crossOrigin = true
        pcdLoader.load(item.data, (data) => {
          self.pointCloudData = data
          self.drawTexture()

          const canvas = self.$refs.textureCanvas
          const texture = new THREE.CanvasTexture(canvas)
          textureLoadCallback(texture)
        })
      } else if (item.data_type === 'image') {
        let img = new Image()
        img.crossOrigin = 'anonymous'
        img.addEventListener('load', () => {
          self.image = img
          self.drawTexture()
          const canvas = self.$refs.textureCanvas
          const texture = new THREE.CanvasTexture(canvas)
          textureLoadCallback(texture)
        })
        img.addEventListener('error', () => {
          throw new Error(`Error while loading image.`)
        })
        img.src = item.data
        // this.$nextTick(() => {
        // })

        // const textureLoader = new THREE.TextureLoader()
        // textureLoader.setCrossOrigin(true)
        // textureLoader.load(item.data, textureLoadCallback, textureProgressCallback, textureErrorCallback)
      } else {
        throw new Error(`Unsupported item data type "${item.data_type}"`)
      }
    },
    drawTexture() {
      if (!this.item) return

      const brightness = parseFloat(this.brightness)
      const gamma = parseFloat(this.gamma)
      const contrast = parseFloat(this.contrast)
      const adjustColor = (v) => {
        return 255 * Math.min(Math.max(0.0, contrast * Math.pow(v / 255, gamma) + brightness), 1.0)
      }

      if (this.item.data_type === 'image') {
        if (!this.image) return

        const canvas = this.$refs.textureCanvas
        const width = this.image.width
        const height = this.image.height
        canvas.width = width
        canvas.height = height

        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, width, height)
        ctx.drawImage(this.image, 0, 0, width, height)
        const imageData = ctx.getImageData(0, 0, width, height)
        const img = imageData.data
        for (let v = 0; v < height; v++) {
          for (let u = 0; u < width; u++) {
            const imgIdx = (v * width + u) * 4
            img[imgIdx + 0] = adjustColor(img[imgIdx + 0])
            img[imgIdx + 1] = adjustColor(img[imgIdx + 1])
            img[imgIdx + 2] = adjustColor(img[imgIdx + 2])
            img[imgIdx + 3] = 255
          }
        }
        ctx.putImageData(imageData, 0, 0)
      } else if (this.item.data_type === 'point_cloud') {
        if (!this.pointCloudData) return

        const self = this

        const xyz = self.pointCloudData.xyz
        const rgb = self.pointCloudData.rgb
        const width = self.pointCloudData.width
        const height = self.pointCloudData.height

        const canvas = self.$refs.textureCanvas
        canvas.width = width
        canvas.height = height

        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, width, height)
        const imageData = ctx.getImageData(0, 0, width, height)
        const img = imageData.data

        let maxDistance = 0
        let minDistance = 1e9

        for (let i = 0; i < width * height; i++) {
          const idx = i * 3
          const x = xyz[idx + 0]
          const y = xyz[idx + 1]
          const z = xyz[idx + 2]
          const d = Math.sqrt(x * x + y * y + z * z)

          maxDistance = Math.max(d, maxDistance)
          minDistance = Math.min(d, minDistance)
        }

        for (let v = 0; v < height; v++) {
          for (let u = 0; u < width; u++) {
            const imgIdx = (v * width + u) * 4
            const pcIdx = (v * width + u) * 3
            const rgbIdx = (v * width + u) * 3

            const x = xyz[pcIdx + 0]
            const y = xyz[pcIdx + 1]
            const z = xyz[pcIdx + 2]
            let d = Math.sqrt(x * x + y * y + z * z)
            d = (d - minDistance) / (maxDistance - minDistance)

            let r = rgb[rgbIdx + 0]
            let g = rgb[rgbIdx + 1]
            let b = rgb[rgbIdx + 2]

            r = adjustColor(255 * r)
            g = adjustColor(255 * g)
            b = adjustColor(255 * b)
            d = adjustColor(255 * d)

            const alpha = self.rangeRgbImageBlending

            img[imgIdx + 0] = alpha * d + (1 - alpha) * r
            img[imgIdx + 1] = alpha * d + (1 - alpha) * g
            img[imgIdx + 2] = alpha * d + (1 - alpha) * b

            img[imgIdx + 3] = 255
          }
        }

        ctx.putImageData(imageData, 0, 0)
      }
      if (this.texture) {
        this.texture.needsUpdate = true
      }
    },
    copy() {
      this.clipboard = this.clone(this.selectedObject)
    },
    paste() {
      if (this.clipboard) {
        let newObject = this.clone(this.clipboard)
        // TODO add on mouse position
        newObject.x += 0.02
        newObject.y += 0.02
        this.addObject(newObject)
        this.currentState = this.STATE_INSERT_OBJECT
      }
    },
    duplicate() {
      if (this.selectedObject !== null && !this.$_.isEmpty(this.selectedObject)) {
        let newObject = this.clone(this.selectedObject)
        newObject.x += 0.02
        newObject.y += 0.02
        this.addObject(newObject)
        this.currentState = this.STATE_INSERT_OBJECT
      }
    },
    onDocumentKeyDown(event) {
      if (!this.active) return

      let keyCode = event.which
      if (keyCode == 90 && ctrlOrCmdPressed(event)) {
        // CTRL+Z (undo)
        this.selectedObject = null
        this.selectedObjectOriginal = null
        this.updateSelectedObjectTools()
        if (this.objectHistory.length > 0) {
          this.objects = this.objectHistory.pop()
          for (let i = 0; i < this.objects.length; i++) {
            if (this.objects[i].selected) {
              this.selectedObject = this.objects[i]
              this.selectedObjectOriginal = this.clone(this.selectedObject)
              this.updateSelectedObjectTools()
              break
            }
          }
          this.updateAllObjects = true

          this.onUpdateAnnotations()
        }
      } else if (keyCode === 8 || keyCode === 46) {
        // backspace or delete
        this.objectHistory.push(this.cloneArray(this.objects))
        if (this.selectedObject) {
          this.selectedObject = null
          this.selectedObjectOriginal = null
          this.updateSelectedObjectTools()

          for (let i = 0; i < this.objects.length; i++) {
            if (this.objects[i].selected) {
              this.objects.splice(i, 1)
              break
            }
          }
          this.updateAllObjects = true

          this.onUpdateAnnotations()
        }
      } else if (keyCode == 67 && ctrlOrCmdPressed(event)) {
        // CTRL+C (copy)
        event.preventDefault()
        this.copy()
      } else if (keyCode == 86 && ctrlOrCmdPressed(event)) {
        // CTRL+V (paste)
        event.preventDefault()
        this.paste()
      } else if (keyCode == 68 && ctrlOrCmdPressed(event)) {
        // CTRL+D (duplicate)
        event.preventDefault()
        this.duplicate()
      } else if (this.orientedBoxes && keyCode == 69 && event.altKey) {
        // CTRL+E (rotate object -90 degrees)
        if (this.selectedObject) {
          this.selectedObject.orientation -= 0.5 * Math.PI
          this.selectedObject.updated = true
          this.updateSelectedObjectTools()
        }
      } else if (this.orientedBoxes && keyCode == 81 && event.altKey) {
        // CTRL+Q (rotate object +90 degrees)
        if (this.selectedObject) {
          this.selectedObject.orientation += 0.5 * Math.PI
          this.selectedObject.updated = true
          this.updateSelectedObjectTools()
        }
      }
    },

    addObjectsBulk(newObjects) {
      this.objectHistory.push(this.cloneArray(this.objects))
      for (let i = 0; i < newObjects.length; i++) {
        let newObject = newObjects[i]
        newObject.id = this.objectIdCounter++
        this.objects.push(newObject)
      }

      this.onUpdateAnnotations()
    },

    addObjectNoSelection(newObject) {
      this.objectHistory.push(this.cloneArray(this.objects))
      newObject.id = this.objectIdCounter++
      this.objects.push(newObject)
    },

    addObject(newObject) {
      this.addObjectNoSelection(newObject)
      this.selectObject(newObject)
      this.currentState = this.STATE_NEW_OBJECT
      this.newObject = newObject
    },

    onMouseDown(evt) {
      if (this.viewOnly) return

      let mousePos = this.getMousePosition(evt.clientX, evt.clientY)
      if (mousePos[0] < 0 || mousePos[0] > 1 || mousePos[1] < 0 || mousePos[1] > 1) {
        evt.preventDefault()
        return
      }

      let keyCode = evt.which
      if (keyCode === 1) {
        // mouse left
        this.objectHistory.push(this.cloneArray(this.objects))

        let mouseDownPosition = new THREE.Vector2(
          mousePos[0] * 2.0 - 1.0,
          -(mousePos[1] * 2.0) + 1.0
        )
        this.raycaster.setFromCamera(mouseDownPosition, this.camera)
        let intersects = this.raycaster.intersectObjects(this.raycastObjects, true)

        for (let i = 0; i < intersects.length; i++) {
          let uv = intersects[i].uv
          if (intersects[i].object.name === this.EDITOR_PLANE_NAME) {
            let uvx = uv.x * 2.0 - 0.5
            let uvy = (1.0 - uv.y) * 2.0 - 0.5
            this.mouseDownImagePosition[0] = (uvx / this.imageScale) * this.imageWidth
            this.mouseDownImagePosition[1] = (uvy / this.imageScale) * this.imageHeight
          }
        }

        if (intersects.length > 0) {
          if (intersects.length > 1) {
            if (intersects[0].object.name === this.IMAGE_PLANE_NAME) {
              let newObject = {
                label: this.selectedClassLabel,
                x: -1000.0,
                y: -1000.0,
                width: 0.0001,
                height: 0.0001,
                orientation: 0.0,
                selected: false,
                updated: true,
              }
              this.addObject(newObject)
            }
          }

          if (this.currentState !== this.STATE_NEW_OBJECT && this.selectedObject) {
            let anyUpdate = true
            if (intersects[0].object.name === `object_${this.selectedObject.id}`) {
              this.currentState = this.STATE_MOVE_OBJECT
            } else if (intersects[0].object.name === 'selected_object_tools_rotation') {
              this.currentState = this.STATE_ROTATE_OBJECT
            } else if (intersects[0].object.name === 'selected_object_tools_size_top_right') {
              this.currentState = this.STATE_RESIZE_OBJECT_TOP_RIGHT
            } else if (intersects[0].object.name === 'selected_object_tools_size_top') {
              this.currentState = this.STATE_RESIZE_OBJECT_TOP
            } else if (intersects[0].object.name === 'selected_object_tools_size_top_left') {
              this.currentState = this.STATE_RESIZE_OBJECT_TOP_LEFT
            } else if (intersects[0].object.name === 'selected_object_tools_size_left') {
              this.currentState = this.STATE_RESIZE_OBJECT_LEFT
            } else if (intersects[0].object.name === 'selected_object_tools_size_bottom_left') {
              this.currentState = this.STATE_RESIZE_OBJECT_BOTTOM_LEFT
            } else if (intersects[0].object.name === 'selected_object_tools_size_bottom') {
              this.currentState = this.STATE_RESIZE_OBJECT_BOTTOM
            } else if (intersects[0].object.name === 'selected_object_tools_size_bottom_right') {
              this.currentState = this.STATE_RESIZE_OBJECT_BOTTOM_RIGHT
            } else if (intersects[0].object.name === 'selected_object_tools_size_right') {
              this.currentState = this.STATE_RESIZE_OBJECT_RIGHT
            } else {
              anyUpdate = false
            }

            if (anyUpdate) {
              this.onUpdateAnnotations()
              this.selectedObjectOriginal = this.clone(this.selectedObject)
            }
          }
        }
      }
    },

    onMouseMove(evt) {
      let mousePos = this.getMousePosition(evt.clientX, evt.clientY)
      if (mousePos[0] < 0 || mousePos[0] > 1 || mousePos[1] < 0 || mousePos[1] > 1) {
        evt.preventDefault()
        return
      }

      let mouseMovePosition = new THREE.Vector2(mousePos[0] * 2.0 - 1.0, -(mousePos[1] * 2.0) + 1.0)

      this.raycaster.setFromCamera(mouseMovePosition, this.camera)
      let intersects = this.raycaster.intersectObjects(this.raycastObjects, true)

      for (let i = 0; i < intersects.length; i++) {
        let uv = intersects[i].uv
        if (intersects[i].object.name === this.EDITOR_PLANE_NAME) {
          let uvx = uv.x * 2.0 - 0.5
          let uvy = (1.0 - uv.y) * 2.0 - 0.5
          this.mouseMoveImagePosition[0] = (uvx / this.imageScale) * this.imageWidth
          this.mouseMoveImagePosition[1] = (uvy / this.imageScale) * this.imageHeight
        }
      }

      if (intersects.length > 0) {
        if (this.showCrossHair) {
          this.crossHair.position.x =
            (this.mouseMoveImagePosition[0] * (this.imageScale / this.imageWidth) - 0.5) *
            this.imageWidth
          this.crossHair.position.z =
            (this.mouseMoveImagePosition[1] * (this.imageScale / this.imageHeight) - 0.5) *
            this.imageHeight
        } else {
          this.hideCrossHair()
        }

        if (this.currentState === this.STATE_NEW_OBJECT && this.newObject) {
          let dx = this.mouseMoveImagePosition[0] - this.mouseDownImagePosition[0]
          let dy = this.mouseMoveImagePosition[1] - this.mouseDownImagePosition[1]
          let newWidth = Math.abs(dx)
          let newHeight = Math.abs(dy)

          if (!evt.shiftKey) {
            if (newHeight !== 0.0) {
              this.newObject.height = newHeight
            }

            if (newWidth !== 0.0) {
              this.newObject.width = newWidth
            }

            this.newObject.x =
              0.5 * (this.mouseMoveImagePosition[0] + this.mouseDownImagePosition[0])
            this.newObject.y =
              0.5 * (this.mouseMoveImagePosition[1] + this.mouseDownImagePosition[1])
          } else {
            if (newHeight !== 0.0 && newWidth !== 0.0) {
              if (newWidth < newHeight) {
                this.newObject.height = newWidth
                this.newObject.width = newWidth
                this.newObject.x = this.mouseDownImagePosition[0] + 0.5 * dx
                this.newObject.y = this.mouseDownImagePosition[1] + 0.5 * Math.sign(dy) * newWidth
              } else {
                this.newObject.height = newHeight
                this.newObject.width = newHeight
                this.newObject.x = this.mouseDownImagePosition[0] + 0.5 * Math.sign(dx) * newHeight
                this.newObject.y = this.mouseDownImagePosition[1] + 0.5 * dy
              }
            }
          }

          this.newObject.updated = true
        }

        if (this.currentState === this.STATE_MOVE_OBJECT && this.selectedObject) {
          this.selectedObject.x =
            this.selectedObjectOriginal.x +
            (this.mouseMoveImagePosition[0] - this.mouseDownImagePosition[0])
          this.selectedObject.y =
            this.selectedObjectOriginal.y +
            (this.mouseMoveImagePosition[1] - this.mouseDownImagePosition[1])
          this.selectedObject.updated = true
        }

        if (this.currentState === this.STATE_ROTATE_OBJECT && this.selectedObject) {
          let a = new THREE.Vector2(
            this.mouseMoveImagePosition[0] - this.selectedObjectOriginal.x,
            this.mouseMoveImagePosition[1] - this.selectedObjectOriginal.y
          )
          this.selectedObject.orientation = -a.angle() - 0.5 * Math.PI
          this.selectedObject.updated = true
        }

        if (this.currentState === this.STATE_RESIZE_OBJECT_TOP_RIGHT && this.selectedObject) {
          let anchor = new THREE.Vector2(
            0.5 * this.selectedObjectOriginal.width,
            -0.5 * this.selectedObjectOriginal.height
          )
          let [e, a] = this.getResizeVectors(anchor)

          let newWidth = this.selectedObjectOriginal.width + (a.x * e.x + a.y * e.y)
          let newHeight = this.selectedObjectOriginal.height + (a.x * e.y - a.y * e.x)
          if (newWidth > 0 && newHeight > 0) {
            this.selectedObject.width = newWidth
            this.selectedObject.height = newHeight
            this.selectedObject.x = this.selectedObjectOriginal.x + 0.5 * a.x
            this.selectedObject.y = this.selectedObjectOriginal.y + 0.5 * a.y
            this.selectedObject.updated = true
          }
        }

        if (this.currentState === this.STATE_RESIZE_OBJECT_TOP && this.selectedObject) {
          let anchor = new THREE.Vector2(0.0, -0.5 * this.selectedObjectOriginal.height)
          let [e, a] = this.getResizeVectors(anchor)

          let newHeight = this.selectedObjectOriginal.height + (a.x * e.y - a.y * e.x)
          if (newHeight > 0) {
            this.selectedObject.height = newHeight
            this.selectedObject.x =
              this.selectedObjectOriginal.x + 0.5 * (a.x * e.y - a.y * e.x) * e.y
            this.selectedObject.y =
              this.selectedObjectOriginal.y - 0.5 * (a.x * e.y - a.y * e.x) * e.x
            this.selectedObject.updated = true
          }
        }

        if (this.currentState === this.STATE_RESIZE_OBJECT_TOP_LEFT && this.selectedObject) {
          let anchor = new THREE.Vector2(
            -0.5 * this.selectedObjectOriginal.width,
            -0.5 * this.selectedObjectOriginal.height
          )
          let [e, a] = this.getResizeVectors(anchor)

          let newWidth = this.selectedObjectOriginal.width - (a.x * e.x + a.y * e.y)
          let newHeight = this.selectedObjectOriginal.height + (a.x * e.y - a.y * e.x)
          if (newWidth > 0 && newHeight > 0) {
            this.selectedObject.width = newWidth
            this.selectedObject.height = newHeight
            this.selectedObject.x = this.selectedObjectOriginal.x + 0.5 * a.x
            this.selectedObject.y = this.selectedObjectOriginal.y + 0.5 * a.y
            this.selectedObject.updated = true
          }
        }

        if (this.currentState === this.STATE_RESIZE_OBJECT_LEFT && this.selectedObject) {
          let anchor = new THREE.Vector2(-0.5 * this.selectedObjectOriginal.width, 0.0)
          let [e, a] = this.getResizeVectors(anchor)

          let newWidth = this.selectedObjectOriginal.width - (a.x * e.x + a.y * e.y)
          if (newWidth > 0) {
            this.selectedObject.width = newWidth
            this.selectedObject.x =
              this.selectedObjectOriginal.x + 0.5 * (a.x * e.x + a.y * e.y) * e.x
            this.selectedObject.y =
              this.selectedObjectOriginal.y + 0.5 * (a.x * e.x + a.y * e.y) * e.y
            this.selectedObject.updated = true
          }
        }

        if (this.currentState === this.STATE_RESIZE_OBJECT_BOTTOM_LEFT && this.selectedObject) {
          let anchor = new THREE.Vector2(
            -0.5 * this.selectedObjectOriginal.width,
            0.5 * this.selectedObjectOriginal.height
          )
          let [e, a] = this.getResizeVectors(anchor)

          let newWidth = this.selectedObjectOriginal.width - (a.x * e.x + a.y * e.y)
          let newHeight = this.selectedObjectOriginal.height - (a.x * e.y - a.y * e.x)
          if (newWidth > 0 && newHeight > 0) {
            this.selectedObject.width = newWidth
            this.selectedObject.height = newHeight
            this.selectedObject.x = this.selectedObjectOriginal.x + 0.5 * a.x
            this.selectedObject.y = this.selectedObjectOriginal.y + 0.5 * a.y
            this.selectedObject.updated = true
          }
        }

        if (this.currentState === this.STATE_RESIZE_OBJECT_BOTTOM && this.selectedObject) {
          let anchor = new THREE.Vector2(0.0, 0.5 * this.selectedObjectOriginal.height)
          let [e, a] = this.getResizeVectors(anchor)

          let newHeight = this.selectedObjectOriginal.height + (-a.x * e.y + a.y * e.x)
          if (newHeight > 0) {
            this.selectedObject.height = newHeight
            this.selectedObject.x =
              this.selectedObjectOriginal.x - 0.5 * (-a.x * e.y + a.y * e.x) * e.y
            this.selectedObject.y =
              this.selectedObjectOriginal.y + 0.5 * (-a.x * e.y + a.y * e.x) * e.x
            this.selectedObject.updated = true
          }
        }

        if (this.currentState === this.STATE_RESIZE_OBJECT_BOTTOM_RIGHT && this.selectedObject) {
          let anchor = new THREE.Vector2(
            0.5 * this.selectedObjectOriginal.width,
            0.5 * this.selectedObjectOriginal.height
          )
          let [e, a] = this.getResizeVectors(anchor)

          let newWidth = this.selectedObjectOriginal.width + (a.x * e.x + a.y * e.y)
          let newHeight = this.selectedObjectOriginal.height - (a.x * e.y - a.y * e.x)
          if (newWidth > 0 && newHeight > 0) {
            this.selectedObject.width = newWidth
            this.selectedObject.height = newHeight
            this.selectedObject.x = this.selectedObjectOriginal.x + 0.5 * a.x
            this.selectedObject.y = this.selectedObjectOriginal.y + 0.5 * a.y
            this.selectedObject.updated = true
          }
        }

        if (this.currentState === this.STATE_RESIZE_OBJECT_RIGHT && this.selectedObject) {
          let anchor = new THREE.Vector2(0.5 * this.selectedObjectOriginal.width, 0.0)
          let [e, a] = this.getResizeVectors(anchor)

          let newWidth = this.selectedObjectOriginal.width + (a.x * e.x + a.y * e.y)
          if (newWidth > 0) {
            this.selectedObject.width = newWidth
            this.selectedObject.x =
              this.selectedObjectOriginal.x + 0.5 * (a.x * e.x + a.y * e.y) * e.x
            this.selectedObject.y =
              this.selectedObjectOriginal.y + 0.5 * (a.x * e.x + a.y * e.y) * e.y
            this.selectedObject.updated = true
          }
        }

        this.updateSelectedObjectTools()
      }
    },

    onMouseUp(evt) {
      if (evt.which === 1) {
        let mousePos = this.getMousePosition(evt.clientX, evt.clientY)
        if (mousePos[0] < 0 || mousePos[0] > 1 || mousePos[1] < 0 || mousePos[1] > 1) {
          evt.preventDefault()
          return
        }

        let mouseUpPosition = new THREE.Vector2(mousePos[0] * 2.0 - 1.0, -(mousePos[1] * 2.0) + 1.0)
        this.raycaster.setFromCamera(mouseUpPosition, this.camera)
        let intersects = this.raycaster.intersectObjects(this.raycastObjects, true)

        for (let i = 0; i < intersects.length; i++) {
          let uv = intersects[i].uv
          if (intersects[i].object.name === this.EDITOR_PLANE_NAME) {
            // intersects[i].object.material.map.transformUv(uv);
            let uvx = uv.x * 2.0 - 0.5
            let uvy = (1.0 - uv.y) * 2.0 - 0.5
            this.mouseUpImagePosition[0] = (uvx / this.imageScale) * this.imageWidth
            this.mouseUpImagePosition[1] = (uvy / this.imageScale) * this.imageHeight
          }
        }

        if (this.currentState === this.STATE_NEW_OBJECT) {
          // remove last added object if it is too small
          if (this.newObject.width < 0.001 && this.newObject.height < 0.001) {
            this.objects.pop()
            this.selectedObject = null
            this.updateSelectedObjectTools()
          } else {
            this.onUpdateAnnotations()
          }

          this.newObject = null
        }

        if (this.currentState === this.STATE_DEFAULT) {
          let found = false
          for (let i = 0; i < intersects.length; i++) {
            let objectName = intersects[i].object.name
            if (objectName.includes('object_')) {
              let objectId = parseInt(objectName.split('_')[1])
              if (this.selectedObject && this.selectedObject.id === objectId) {
                continue
              }
              for (let j = 0; j < this.objects.length; j++) {
                if (this.objects[j].id === objectId) {
                  this.selectObject(this.objects[j])
                  found = true
                  break
                }
              }
              if (found) {
                break
              }
            }
            if (found) {
              break
            }
          }
        }

        this.currentState = this.STATE_DEFAULT
      }
    },

    animate() {
      this.animationFrame = requestAnimationFrame(this.animate)
      this.render()
    },

    render() {
      if (!this.renderer) {
        console.warn('renderer is undefined')
        return
      }

      this.width = this.canvas.clientWidth
      this.height = this.canvas.clientHeight

      if (
        Math.abs(this.canvas.width - this.width * window.devicePixelRatio) > 1 ||
        Math.abs(this.canvas.height - this.height * window.devicePixelRatio) > 1
      ) {
        this.updateAllObjects = true

        this.renderer.setSize(this.width, this.height, false)
        this.renderer.setPixelRatio(window.devicePixelRatio)
        this.camera.left = this.width / -2
        this.camera.right = this.width / 2
        this.camera.top = this.height / 2
        this.camera.bottom = this.height / -2
        this.camera.updateProjectionMatrix()
      }

      let updateScale = this.controls.object.zoom !== this.lastZoom
      this.lastZoom = this.controls.object.zoom
      let zoomScaling = 1 / this.lastZoom
      if ((updateScale && this.selectedObjectTools) || this.selectedObjectToolsNewlyCreated) {
        for (let i = 0; i < this.selectedObjectTools.children.length; i++) {
          let o = this.selectedObjectTools.children[i]
          o.scale.x = zoomScaling
          o.scale.y = zoomScaling
        }

        if (this.selectedObject) {
          if (this.rotationToolCircle !== null) {
            this.rotationToolCircle.position.y =
              0.5 *
                this.selectedObject.height *
                (this.imageScale / this.imageHeight) *
                this.imageHeight +
              25.0 * zoomScaling
          }
        }

        this.selectedObjectToolsNewlyCreated = false
      }

      if (this.updateAllObjects) {
        // remove all objects from the scene
        while (this.objectGroup.children.length) {
          this.objectGroup.children.pop()
        }
      }

      // console.log(this.objects.length)
      // let anyObjectUpdated = false
      for (let i = 0; i < this.objects.length; i++) {
        let o = this.objects[i]
        if (o.updated || this.updateAllObjects || this.updateRenderer) {
          if (o.updated || this.updateAllObjects) {
            // anyObjectUpdated = true
          }

          let color = getColor(o.label)

          const classLabel = this.classLabels[o.label]
          if (!o.selected && (classLabel === undefined || !classLabel.show)) {
            continue
          }

          let opacity = 0.0

          // special class "ignore"
          let ignoreClass = 177
          if (o.label === ignoreClass) {
            color = 0xcccccc
            opacity = 0.5
          }

          let object = this.objectGroup.getObjectByName('object_' + o.id)
          if (!object) {
            let objectMaterial = new THREE.MeshBasicMaterial({
              color: color,
              transparent: true,
              opacity: opacity,
            })
            let objectGeometry = new THREE.PlaneGeometry(1, 1, 1, 1)

            object = new THREE.Mesh(objectGeometry, objectMaterial)
            object.name = 'object_' + o.id
            object.position.y = 1.0 + i
            object.rotation.x = -0.5 * Math.PI
            object.scale.set(
              o.width * (this.imageScale / this.imageWidth) * this.imageWidth,
              o.height * (this.imageScale / this.imageHeight) * this.imageHeight,
              1
            )
            this.objectGroup.add(object)

            // add border
            const points = []
            points.push(-0.5, -0.5, 100)
            points.push(-0.5, 0.5, 100)
            if (this.showBoxOrientation) {
              points.push(-0.05, 0.5, 100)
              points.push(0.0, 0.55, 100)
              points.push(0.05, 0.5, 100)
            }
            points.push(0.5, 0.5, 100)
            points.push(0.5, -0.5, 100)
            points.push(-0.5, -0.5, 100)

            let line = new MeshLine()
            line.setPoints(points)

            let mat = new MeshLineMaterial({
              resolution: new THREE.Vector2(this.width, this.height),
              color: color,
              lineWidth: 8,
              sizeAttenuation: false,
            })
            let mesh = new THREE.Mesh(line.geometry, mat)

            object.add(mesh)
          }

          object.position.x = (o.x * (this.imageScale / this.imageWidth) - 0.5) * this.imageWidth
          object.position.z = (o.y * (this.imageScale / this.imageHeight) - 0.5) * this.imageHeight

          object.rotation.z = o.orientation
          object.scale.set(
            o.width * (this.imageScale / this.imageWidth) * this.imageWidth,
            o.height * (this.imageScale / this.imageHeight) * this.imageHeight,
            1
          )
          object.geometry.needsUpdate = true

          object.material.color.setHex(color)
          if (o.label !== ignoreClass) {
            object.material.opacity = 0.0
          } else {
            object.material.opacity = 0.5
          }

          for (let j = 0; j < object.children.length; j++) {
            object.children[j].material.color.setHex(color)
          }

          o.updated = false
        }
      }

      this.renderer.render(this.scene, this.camera)
      this.updateAllObjects = false
      this.updateRenderer = false
    },
  },
}
</script>

<style scoped lang="scss">
.canvas {
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 100px);
}
</style>
