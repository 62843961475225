import { render, staticRenderFns } from "./StartTrainingModal.vue?vue&type=template&id=23039c84&scoped=true&"
import script from "./StartTrainingModal.vue?vue&type=script&lang=js&"
export * from "./StartTrainingModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23039c84",
  null
  
)

export default component.exports