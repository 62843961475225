<template>
  <div class="full-height-wrapper">
    <transition name="fade">
      <annotation-overlay
        v-show="showAnnotationOverlay"
        :key="`annotation-overlay-${selectedDataset.id}`"
        :active="showAnnotationOverlay"
        :data-subsets="subsets"
        :dataset="selectedDataset"
        :dataset-items="datasetItems"
        :items-loading="itemsLoading > 0"
        :selected-item="selectedItem"
        @close="onCloseAnnotationOverlay"
        @scroll-item-viewer="onScrollItemViewer"
        @click-item="openItem"
        @selected-item-changed="onSelectedItemChange"
        @previous-item="onPreviousItem"
        @next-item="onNextItem"
      />
    </transition>

    <div
      v-if="showOverviewFirstTime"
      v-show="showOverview"
      class="container-fluid mh-100 h-100 scroll-box"
    >
      <dataset-overview
        key="dataset-overview"
        :datasets="datasets"
        :loading="datasetsLoading"
        @create-dataset="openCreateDatasetModal"
        @open-dataset="selectDataset"
      />
    </div>

    <div v-show="!showOverview" class="h-100 mh-100">
      <div class="h-100 mh-100 d-flex">
        <div class="mh-100 mw-50px flex-shrink-0 d-flex flex-column align-content-center">
          <b-button class="mh-50px text-dark" variant="link" @click="openOverview">
            <fai icon="arrow-left" />
          </b-button>
          <div class="p-1 d-flex flex-column align-content-center">
            <b-button
              id="button-upload"
              v-b-tooltip.right.nofade.hover.d0.o0
              class="icon-button mh-50px font-size-large"
              title="upload"
              variant="link"
              @click="openDataUploadModal"
            >
              <fai icon="upload" />
            </b-button>

            <b-button
              id="button-ai-models"
              v-b-tooltip.right.nofade.hover.d0.o0
              class="icon-button mh-50px font-size-large"
              title="AI models"
              variant="link"
              @click="openAiModelsModal"
            >
              <fai icon="brain" />
            </b-button>

            <b-button
              id="button-timeline"
              v-b-tooltip.right.nofade.hover.d0.o0
              class="icon-button mh-50px font-size-large"
              title="timeline"
              variant="link"
              @click="bottomAreaOpen = !bottomAreaOpen"
            >
              <fai icon="chart-bar" />
            </b-button>

            <b-button
              id="button-settings"
              v-b-tooltip.right.nofade.hover.d0.o0
              class="icon-button mh-50px font-size-large"
              title="settings"
              variant="link"
              @click="openDatasetModal"
            >
              <fai icon="cogs" />
            </b-button>
          </div>
        </div>
        <div class="h-100 mh-100 flex-grow-1 position-relative overflow-hidden d-flex flex-column">
          <div class="row">
            <div
              class="col-12 col-xl-10 pl-2 pr-3 m-0 image-toolbar align-items-center d-flex justify-content-between w-100"
            >
              <div class="d-flex align-items-center h-100">
                <span class="font-size-large font-weight-700 ml-3">{{ selectedDataset.name }}</span>
                <b-spinner
                  v-if="itemsLoading > 0"
                  class="align-self-center m-2"
                  label="items loading"
                  small
                  variant="primary"
                />
                <!--              <b-dropdown variant="link" size="sm" no-caret>-->
                <!--                <template slot="button-content">-->
                <!--                  <fai icon="ellipsis-v" class="text-primary"></fai>-->
                <!--                </template>-->
                <!--                <b-dropdown-item @click="" href="#">edit</b-dropdown-item>-->
                <!--              </b-dropdown>-->
              </div>
              <div
                v-if="selectedDataset"
                class="flex-wrap flex-grow-1 d-flex justify-content-end align-items-center"
              >
                <div class="ml-2 mr-2">
                  <b-input-group class="flex-nowrap" size="sm">
                    <b-button-group>
                      <b-form-checkbox
                        v-model="multiSelection"
                        class="no-select"
                        size="sm"
                        switch
                        @change="resetItemSelection"
                      >
                        multi-selection
                      </b-form-checkbox>
                    </b-button-group>
                  </b-input-group>
                </div>
                <div class="ml-2">
                  <b-input-group size="sm">
                    <b-button-group>
                      <b-dropdown
                        id="select-image-actions"
                        :disabled="
                          !this.multiSelection ||
                          $_.isEmpty(selectedDataset) ||
                          selectedItems.length === 0
                        "
                        size="sm"
                        text="actions"
                        variant="outline-primary"
                      >
                        <template v-if="!$_.isEmpty(selectedDataset)">
                          <template
                            v-if="!$_.isEmpty(selectedDataset) && selectedDataset.classification"
                          >
                            <b-dropdown-item-button @click="addClassLabelsToSelectedItems">
                              Add class labels
                            </b-dropdown-item-button>
                            <b-dropdown-item-button @click="removeClassLabelsFromSelectedItems">
                              Remove class labels
                            </b-dropdown-item-button>

                            <b-dropdown-divider />
                          </template>

                          <b-dropdown-group
                            id="image-action-dropdown-change-status-group"
                            header="change status to"
                          >
                            <b-dropdown-item-button
                              v-for="statusOption in statusOptions"
                              :key="`image-action-dropdown-change-status-button-${statusOption.value}`"
                              @click="onClickChangeStatus(statusOption.value)"
                            >
                              {{ statusOption.text }}
                            </b-dropdown-item-button>
                          </b-dropdown-group>

                          <b-dropdown-divider />

                          <b-dropdown-item-button @click="openDeleteItemModal">
                            Delete items
                          </b-dropdown-item-button>
                        </template>
                      </b-dropdown>
                    </b-button-group>
                  </b-input-group>
                </div>
                <div class="ml-2">
                  <b-input-group class="flex-nowrap" size="sm">
                    <b-button-group>
                      <b-button size="sm" variant="outline-primary" @click="openFilterSettings">
                        <fai class="mb-1 mr-1" icon="filter" />
                        filters
                      </b-button>
                    </b-button-group>
                  </b-input-group>
                </div>
                <div class="ml-2">
                  <b-button
                    id="timezone-button"
                    size="sm"
                    variant="outline-primary"
                    @click="openTimezoneSettings"
                  >
                    <fal class="mr-2">
                      <fai class="text-secondary" icon="globe" transform="grow-4" />
                      <fai class="text-primary" icon="clock" transform="down-7 right-7 shrink-3" />
                    </fal>
                    timezone
                  </b-button>
                  <b-popover
                    id="timezone-popover"
                    ref="timezone-popover"
                    :delay="0"
                    no-fade
                    placement="auto"
                    target="timezone-button"
                    triggers="click blur"
                  >
                    <div>
                      <b-form-group
                        class="mb-1"
                        label="Timezone"
                        label-for="popover-timezone-input"
                      >
                        <b-form-select
                          id="popover-timezone-input"
                          v-model="timezone"
                          :options="availableTimezones"
                          size="sm"
                          @change="changeTimezoneSettings"
                        />
                      </b-form-group>
                    </div>
                  </b-popover>
                </div>
                <!--                          <div class="ml-2 mb-2">-->
                <!--                            <b-input-group size="sm" class="flex-nowrap">-->
                <!--                              <b-input-group-prepend is-text>Items</b-input-group-prepend>-->
                <!--                              <b-form-select-->
                <!--                                :disabled="centerLoading"-->
                <!--                                v-model="datasetItemsPage.size"-->
                <!--                                :options="pageSizeOptions"-->
                <!--                                @input="loadItems()"-->
                <!--                              ></b-form-select>-->
                <!--                            </b-input-group>-->
                <!--                          </div>-->
                <div class="ml-2">
                  <b-input-group class="flex-nowrap" prepend="Subsets" size="sm">
                    <b-input-group-append is-text>
                      <input
                        v-model="showSubsets"
                        :disabled="
                          !selectedDataset.data_subsets || selectedDataset.data_subsets.length === 0
                        "
                        type="checkbox"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div v-if="selectedDataset.classification" class="ml-2">
                  <b-input-group class="flex-nowrap" prepend="Image Labels" size="sm">
                    <b-input-group-append is-text>
                      <input v-model="showTrueLabels" type="checkbox" />
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div v-if="selectedDataset.object_detection" class="ml-2">
                  <b-input-group class="flex-nowrap" prepend="Objects" size="sm">
                    <b-input-group-append is-text>
                      <input v-model="showObjects" type="checkbox" />
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="ml-2">
                  <b-input-group class="flex-nowrap" prepend="Status" size="sm">
                    <b-input-group-append is-text>
                      <input v-model="showStatus" type="checkbox" />
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="ml-2">
                  <b-input-group class="flex-nowrap" prepend="Filename" size="sm">
                    <b-input-group-append is-text>
                      <input v-model="showFilename" type="checkbox" />
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="ml-2">
                  <b-input-group class="flex-nowrap" prepend="Timestamp" size="sm">
                    <b-input-group-append is-text>
                      <input v-model="showTimestamp" type="checkbox" />
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="ml-2">
                  <b-input-group class="flex-nowrap" size="sm">
                    <b-input-group-prepend is-text> Size</b-input-group-prepend>
                    <b-form-input
                      v-model="itemsPerRowReversed"
                      :max="maxItemsPerRow - 2"
                      min="0"
                      size="sm"
                      step="1"
                      type="range"
                    />
                  </b-input-group>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-grow-1 position-relative overflow-hidden container-fluid">
            <div class="row h-100 mh-100">
              <div class="col-lg-9 col-xl-10 h-100 mh-100 m-0 p-0">
                <div v-if="!$_.isEmpty(selectedDataset)" class="item-area">
                  <loading-spinner v-if="centerLoading" />
                  <transition name="fade">
                    <div v-if="centerLoading" class="center-loading-background" />
                  </transition>

                  <div class="item-viewer-wrapper-background h-100 mh-100 overflow-hidden">
                    <div
                      v-if="viewMode === 'gallery'"
                      ref="itemViewerWrapper"
                      class="item-viewer-wrapper"
                      @scroll="onScrollItemViewer"
                    >
                      <!--                      <div class="col-12 p-0">-->
                      <template
                        v-if="itemsLoading === 0 && !centerLoading && datasetItems.length === 0"
                      >
                        <!--                        <div-->
                        <!--                          class="p-4 text-dark no-select">-->
                        <!--                          <fai icon="arrow-left"></fai>-->
                        <!--                          upload data-->
                        <!--                        </div>-->
                        <div class="upload-first-data-message text-dark no-select">
                          <div class="d-flex justify-content-start">
                            <fai icon="arrow-left" class="mt-1 mr-2" />
                            <div class="text-left">Upload your first images</div>
                          </div>
                        </div>
                        <div class="mt-5 p-5 w-100 text-center text-dark font-size-large no-select">
                          uploaded data appears here
                        </div>
                      </template>

                      <item-viewer
                        v-else
                        :container-class="'p-3'"
                        :data-subsets="subsets"
                        :dataset-items="datasetItems"
                        :item-size="itemSize"
                        :loading="itemsLoading > 0 && !centerLoading"
                        :selection-rectangle-enabled="multiSelection"
                        :show-filename="showFilename"
                        :show-objects="showObjects"
                        :show-status="showStatus"
                        :show-subsets="showSubsets"
                        :show-timestamp="showTimestamp"
                        :show-true-labels="showTrueLabels"
                        :timezone="timezone"
                        @click="onClickItem"
                        @double-click="onDoubleClickItem"
                        @click-middle="onClickItem"
                        @multi-selection="onMultiSelection"
                        @scroll-adjustment-required="onScrollAdjustmentRequired"
                        @click-background="resetItemSelection"
                      />
                    </div>
                  </div>
                </div>

                <!--          <dataset-statistics-->
                <!--            v-if="selectedDataset.id"-->
                <!--            :key="`statistics-${selectedDataset.id}`"-->
                <!--            :dataset="selectedDataset"-->
                <!--          />-->

                <!--          <data-subsets-->
                <!--            v-if="selectedDataset.id"-->
                <!--            :key="`subsets-${selectedDataset.id}`"-->
                <!--            @subset-created="subsetsUpdated"-->
                <!--            @subset-deleted="subsetsUpdated"-->
                <!--            :dataset="selectedDataset"-->
                <!--          />-->
              </div>

              <div
                v-if="multiSelection"
                class="h-100 mh-100 col-lg-3 col-xl-2 pl-3 pr-3 d-flex flex-column"
              >
                <div class="mb-2 border-radius-md shadow-md border-gray-100">
                  <card-collapse
                    class="h-100 mh-100 bg-white"
                    initially-open
                    inner-class="h-100 mh-100"
                    no-animation
                    small
                    title="Change Status"
                  >
                    <div class="pl-3 pr-3 pb-3 pt-2 position-relative">
                      <basic-overlay
                        v-if="selectedItems.length === 0"
                        class="bg-white-overlay"
                      ></basic-overlay>
                      <div class="d-flex flex-wrap">
                        <div
                          v-for="statusOption in statusOptions"
                          :key="`image-action-change-status-button-${statusOption.value}`"
                          class="w-50 p-1"
                        >
                          <b-button
                            variant="light"
                            size="sm"
                            block
                            @click="onClickChangeStatus(statusOption.value)"
                          >
                            <fai :icon="statusOption.icon" class="mr-2" />
                            {{ statusOption.text }}
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </card-collapse>
                </div>

                <div class="mb-2 border-radius-md shadow-md border-gray-100">
                  <card-collapse
                    class="h-100 mh-100 bg-white"
                    initially-open
                    inner-class="h-100 mh-100"
                    no-animation
                    small
                    title="Add to Subset"
                  >
                    <div class="pl-3 pr-3 pb-3 pt-2 position-relative">
                      <basic-overlay
                        v-if="selectedItems.length === 0"
                        class="bg-white-overlay"
                      ></basic-overlay>
                      <subset-badge
                        v-for="subset in selectedDataset.data_subsets"
                        @click="addSelectedItemsToSubset(subset)"
                        :key="`add-subset-badge-${subset.id}`"
                        :data-subsets="subsets"
                        :subset-id="subset.id"
                        button
                      />
                    </div>
                  </card-collapse>
                </div>

                <div class="mb-2 border-radius-md shadow-md border-gray-100">
                  <card-collapse
                    class="h-100 mh-100 bg-white"
                    initially-open
                    inner-class="h-100 mh-100"
                    no-animation
                    small
                    title="Remove from Subset"
                  >
                    <div class="pl-3 pr-3 pb-3 pt-2 position-relative">
                      <basic-overlay
                        v-if="selectedItems.length === 0"
                        class="bg-white-overlay"
                      ></basic-overlay>
                      <subset-badge
                        v-for="subset in selectedDataset.data_subsets"
                        @click="removeSelectedItemsFromSubset(subset)"
                        :key="`remove-subset-badge-${subset.id}`"
                        :data-subsets="subsets"
                        :subset-id="subset.id"
                        button
                      />
                    </div>
                  </card-collapse>
                </div>

                <div
                  v-if="selectedDataset.classification"
                  class="mb-2 border-radius-md shadow-md border-gray-100"
                >
                  <card-collapse
                    class="h-100 mh-100 bg-white"
                    initially-open
                    inner-class="h-100 mh-100"
                    no-animation
                    small
                    title="Add Image Class"
                  >
                    <div class="pl-3 pr-3 pb-3 pt-2 position-relative">
                      <basic-overlay
                        v-if="selectedItems.length === 0"
                        class="bg-white-overlay"
                      ></basic-overlay>
                      <class-label-selection
                        :class-labels="classificationClassLabels"
                        class=""
                        full-width
                        @click-class-label="onClickAddImageClassLabel($event.id)"
                      />
                    </div>
                  </card-collapse>
                </div>
                <div
                  v-if="selectedDataset.classification"
                  class="mb-2 border-radius-md shadow-md border-gray-100"
                >
                  <card-collapse
                    class="h-100 mh-100 bg-white"
                    initially-open
                    inner-class="h-100 mh-100"
                    no-animation
                    small
                    title="Remove Image Class"
                  >
                    <div class="pl-3 pr-3 pb-3 pt-2 position-relative">
                      <basic-overlay
                        v-if="selectedItems.length === 0"
                        class="bg-white-overlay"
                      ></basic-overlay>
                      <class-label-selection
                        :class-labels="classificationClassLabels"
                        class=""
                        full-width
                        @click-class-label="onClickRemoveImageClassLabel($event.id)"
                      />
                    </div>
                  </card-collapse>
                </div>
              </div>

              <div
                v-if="selectedDataset"
                v-show="!multiSelection"
                class="h-100 mh-100 col-lg-3 col-xl-2 pl-3 pr-3 d-flex flex-column"
              >
                <!--        <p>Annotated: {{ selectedDataset.annotated_items }}</p>-->
                <!--        <p>Reviewed: {{ selectedDataset.reviewed_items }}</p>-->
                <!--        <p>Created: {{ selectedDataset.created_date | formatDate }}</p>-->

                <div class="mb-2 border-radius-md shadow-md border-gray-100">
                  <card-collapse
                    class="h-100 mh-100 bg-white"
                    initially-open
                    inner-class="h-100 mh-100 scroll-box"
                    no-animation
                    small
                    title="Items per Status"
                  >
                    <div class="pl-3 pr-3 pb-3 pt-2 position-relative">
                      <loading-overlay v-if="statistics.itemsPerStatusLoading" light />
                      <status-count-info-card :items-per-status="statistics.itemsPerStatus" />
                    </div>
                  </card-collapse>
                </div>

                <div
                  :style="
                    statistics.itemsPerSubsetView
                      ? `min-height: ${
                          68 + 20 * Math.min(3, statistics.itemsPerSubset.length)
                        }px !important;`
                      : ''
                  "
                  class="dynamic-collapse-wrapper flex-grow-0 position-relative overflow-hidden mb-2 border-radius-md shadow-md border-gray-100"
                >
                  <card-collapse
                    class="h-100 mh-100 bg-white"
                    initially-open
                    inner-class="h-100 mh-100 scroll-box"
                    no-animation
                    small
                    title="Items per Subset"
                    @view="statistics.itemsPerSubsetView = $event"
                  >
                    <div class="pl-3 pr-3 pb-3 pt-2 position-relative">
                      <template v-if="statistics.itemsPerSubsetLoading">
                        <loading-overlay light />
                        <div class="mh-50px" />
                      </template>
                      <bar-chart
                        v-if="statistics.itemsPerSubset.length > 0"
                        :element-map="subsets"
                        :stats="statistics.itemsPerSubset"
                        border
                        color-from-name
                        not-found-name="no subset"
                      />
                      <div
                        v-else-if="!statistics.itemsPerSubsetLoading"
                        class="text-secondary text-center no-select"
                      >
                        nothing to show
                      </div>
                    </div>
                  </card-collapse>
                </div>

                <div
                  v-if="selectedDataset.classification"
                  :style="
                    statistics.itemsPerClassView
                      ? `min-height: ${
                          68 + 20 * Math.min(3, statistics.itemsPerSubset.length)
                        }px !important;`
                      : ''
                  "
                  class="dynamic-collapse-wrapper position-relative overflow-hidden mb-2 border-radius-md shadow-md border-gray-100"
                >
                  <card-collapse
                    class="h-100 mh-100 bg-white"
                    initially-open
                    inner-class="h-100 mh-100 scroll-box"
                    no-animation
                    small
                    title="Items per Class"
                    @view="statistics.itemsPerClassView = $event"
                  >
                    <div class="pl-3 pr-3 pb-3 pt-2 position-relative">
                      <template v-if="statistics.itemsPerClassLoading">
                        <loading-overlay light />
                        <div class="mh-50px" />
                      </template>
                      <bar-chart
                        v-if="statistics.itemsPerClass.length > 0"
                        :element-map="classLabelMap"
                        :stats="statistics.itemsPerClass"
                      />
                      <div
                        v-else-if="!statistics.itemsPerClassLoading"
                        class="text-secondary text-center no-select"
                      >
                        nothing to show
                      </div>
                    </div>
                  </card-collapse>
                </div>

                <div
                  v-if="selectedDataset.object_detection"
                  :style="
                    statistics.objectsPerClassView
                      ? `min-height: ${
                          68 + 20 * Math.min(3, statistics.itemsPerSubset.length)
                        }px !important;`
                      : ''
                  "
                  class="dynamic-collapse-wrapper flex-grow-0 position-relative overflow-hidden mb-2 border-radius-md shadow-md border-gray-100"
                >
                  <card-collapse
                    class="h-100 mh-100 bg-white"
                    initially-open
                    inner-class="h-100 mh-100 scroll-box"
                    no-animation
                    small
                    title="Objects per Class"
                    @view="statistics.objectsPerClassView = $event"
                  >
                    <div class="pl-3 pr-3 pb-3 pt-2 position-relative">
                      <template v-if="statistics.objectsPerClassLoading">
                        <loading-overlay light />
                        <div class="mh-50px" />
                      </template>
                      <bar-chart
                        v-if="statistics.objectsPerClass.length > 0"
                        :element-map="classLabelMap"
                        :stats="statistics.objectsPerClass"
                      />
                      <div
                        v-else-if="!statistics.objectsPerClassLoading"
                        class="text-secondary text-center no-select"
                      >
                        nothing to show
                      </div>
                    </div>
                  </card-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!showOverview && bottomAreaOpen" class="d-flex flex-column bottom-area">
      <div class="d-flex justify-content-between pl-3 pr-3 pt-2 pb-2">
        <span><b>Timeline</b></span>
        <fai class="cursor-pointer" icon="angle-down" size="lg" @click="bottomAreaOpen = false" />
      </div>
      <div class="pl-3 pr-3 pb-3">
        <item-timeline
          :dataset="selectedDataset"
          :filter-settings="filterSettings"
          :timezone="timezone"
          @date-time-range-change="onDateTimeRangeChange"
        />
      </div>
    </div>

    <!--    <div v-if="!showOverview && !bottomAreaOpen" class="bottom-buttons">-->
    <!--      <b-button-group>-->
    <!--        <b-button @click="bottomAreaOpen = true" variant="light">Timeline</b-button>-->
    <!--      </b-button-group>-->
    <!--    </div>-->

    <div>
      <dataset-modal
        :dataset="settingsDataset"
        :show="showDatasetModal"
        @show="showDatasetModal = $event"
        @dataset-updated="onDatasetUpdated"
        @dataset-created="onDatasetCreated"
        @dataset-deleted="onDatasetDeleted"
        @subset-updated="subsetsUpdated"
      />

      <b-modal
        id="modal-data-upload"
        ref="modalDataUpload"
        :ok-title="uploading ? 'Cancel' : 'Close'"
        :ok-variant="uploading ? 'danger' : 'primary'"
        hide-header-close
        :no-close-on-backdrop="uploading"
        no-close-on-esc
        ok-only
        size="lg"
        title="Data Upload"
        @ok="modalUploadCloseOrCancel"
      >
        <b-modal
          ref="cancelUploadModal"
          cancel-title="Confirm Cancel"
          cancel-variant="danger"
          ok-title="Continue Uploading"
          title="Cancel Upload"
          @cancel="closeFileUploadModal"
        >
          Are you sure you want to cancel the upload? Already uploaded items won't be deleted.
        </b-modal>
        <div class="d-flex flex-column p-3">
          <div v-if="selectedDataset.classification">
            <p>
              Specify one or more class labels for the uploaded images. Leave blank if you want to
              assign the labels later.
            </p>
            <div class="content-with-overlay">
              <class-input
                :available-class-labels="classificationClassLabels"
                :block="true"
                :selected-class-labels="selectedImageClassLabels"
                @change="onImageClassesChanged"
              />
              <div v-if="uploading" class="gray-overlay" />
            </div>
          </div>

          <div v-if="subsetOptions.length > 0" class="mt-3">
            <p>
              You can select one or more subsets for the uploaded images. This can be useful to
              distinguish between data for training and testing directly.
            </p>

            <b-form-select
              v-model="selectedSubsets"
              :disabled="uploading"
              :options="subsetOptions"
              :select-size="Math.min(subsetOptions.length, 5)"
              multiple
            />
          </div>

          <div class="position-relative">
            <transition name="fade">
              <basic-overlay v-if="uploadErrorMessage" class="bg-white p-5 text-center">
                <div class="text-danger mt-2 mb-3">
                  <fai icon="times-circle" class="font-size-xxx-large"></fai>
                </div>
                <div class="font-size-large font-weight-700 mb-1">Error during upload</div>
                <div class="text-dark mb-3">{{ uploadErrorMessage }}</div>
                <b-button variant="light" @click="uploadErrorMessage = undefined">back</b-button>
              </basic-overlay>
            </transition>
            <vue-dropzone
              v-show="!uploading"
              id="dropzone"
              ref="itemUploadDropzone"
              :options="dropzoneOptions"
              :use-custom-slot="true"
              class="mt-3 item-upload-dropzone"
              @vdropzone-file-added="uploading = true"
              @vdropzone-queue-complete="onUploadComplete"
              @vdropzone-sending="fileSendingEvent"
              @vdropzone-success="fileSuccessEvent"
              @vdropzone-error="fileErrorEvent"
              @vdropzone-complete="fileCompleteEvent"
              @vdropzone-upload-progress="uploadProgressEvent"
              @vdropzone-files-added="filesAdded"
            >
              <div>
                <h4>Drag and drop to upload files</h4>
                <div>...or click to select files from your computer</div>
              </div>
            </vue-dropzone>
            <div v-show="uploading" class="mt-3">
              <h5>
                Total Progress
                {{
                  `${(totalUploadProgress * 100).toFixed(
                    0
                  )}% (${finishedFiles} / ${totalUploadFiles})`
                }}
              </h5>
              <p class="text-secondary small">
                {{
                  `${(totalBytesSent / 1048576).toFixed(2)} MB sent of ${(
                    totalUploadSize / 1048576
                  ).toFixed(2)} MB. ${estimatedUploadTimeInfo}`
                }}
              </p>
              <b-progress
                :value="totalUploadProgress * 100"
                class="mb-3"
                height="18px"
                variant="success"
              />
              <div v-for="file in shownUploadFiles" :key="file.name" class="row">
                <div class="col-sm-4 w-100 text-secondary text-ellipsis small">
                  {{ file.name }}
                </div>
                <div class="col-sm-8 pt-1">
                  <b-progress :value="file.upload.progress" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="modal-select-class-labels"
        ref="modalSelectClassLabels"
        :ok-disabled="selectedImageClassLabels.length === 0"
        size="lg"
        title="Select Class Labels"
        @ok="selectClassLabelOk"
      >
        <div class="container-fluid">
          <div v-if="selectedDataset.classification" class="row">
            <div class="col-12">
              <class-input
                :available-class-labels="classificationClassLabels"
                :block="true"
                :selected-class-labels="selectedImageClassLabels"
                @change="onImageClassesChanged"
              />
            </div>
          </div>
          <div v-else>
            <p>
              You cannot assign image class labels to this dataset because image classification is
              not activated for this dataset.
            </p>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="modal-dataset-models"
        ref="modalDatasetModels"
        ok-only
        size="lg"
        title="AI Models"
        @show="loadDatasetModels"
      >
        <div>
          <div v-if="modalDatasetModelsLoading" class="mt-5 mb-5">
            <loading-spinner-light />
          </div>
          <div v-else>
            <div v-if="networkModels.length > 0" class="table-responsive-sm">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="model in networkModels" :key="`dataset-models-row-${model.id}`">
                    <td>{{ model.id }}</td>
                    <td>{{ model.name }}</td>
                    <td class="text-right">
                      <b-button
                        class="mr-1"
                        size="sm"
                        variant="outline-primary"
                        @click="onClickOpenModel(model)"
                        :disabled="modelsLoading"
                      >
                        <b-spinner v-if="modelsLoading" small class="mr-1" />
                        open model
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              There are no AI models trained using this dataset yet. Go to
              <b-link to="/model-explorer"> AI models</b-link>
              to create one.
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="modal-delete-items"
        ref="modalDeleteItems"
        ok-title="Delete"
        ok-variant="danger"
        size="md"
        title="Delete items"
        @ok="deleteItems"
      >
        <div>
          <p>
            Please confirm that you want to delete {{ this.selectedItems.length }} items. These
            items cannot be restored.
          </p>
        </div>
      </b-modal>

      <filter-settings-modal
        :attributes="selectedDataset ? selectedDataset.attributes : undefined"
        :filter-settings="filterSettings"
        :image-class-labels="classificationClassLabels"
        :object-class-labels="objectClassLabels"
        :show="showFilterSettings"
        :subsets="subsets"
        @show="showFilterSettings = $event"
        @new-settings="applyFilterSettings"
      />

      <progress-modal
        :max-progress="progress.maxProgress"
        :progress="progress.progress"
        :show="progress.show"
        :show-absolute="progress.showAbsolute"
        :title="progress.title"
        @show="progress.show = $event"
      />
    </div>

    <b-toast id="image-exists-toast" auto-hide="4000" title="Image Exists" variant="warning">
      Image already exists in this dataset.
    </b-toast>

    <b-toast id="upload-error-toast" auto-hide="4000" title="Error" variant="danger">
      Could not upload image.
    </b-toast>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import _ from 'lodash'
import axios from 'axios'
import vue2Dropzone from 'vue2-dropzone'
import { mapActions, mapState } from 'vuex'

import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import statusOptions from '@/options'
import { binarySearch } from '@/utils'
import { ctrlOrCmdPressed } from '@/keyboardShortcuts'
import { formatDatetimeCursorPosition } from '@/datetime'
import { getFilterQuery } from '@/filter'

import AnnotationOverlay from '@/components/annotation/AnnotationOverlay'
import BarChart from '@/components/statistics/BarChart'
import BasicOverlay from '@/components/BasicOverlay'
import CardCollapse from '@/components/CardCollapse'
import ClassInput from '@/components/ClassInput'
import ClassLabelSelection from '@/components/class-labels/ClassLabelSelection'
import DatasetModal from '@/components/modals/DatasetModal'
import DatasetOverview from '../components/DatasetOverview'
import FilterSettingsModal from '@/components/modals/FilterSettingsModal'
import ItemTimeline from '@/components/timeline/ItemTimeline'
import ItemViewer from '@/components/item-viewer/ItemViewer'
import LoadingOverlay from '@/components/LoadingOverlay'
import LoadingSpinner from '../components/LoadingSpinner'
import LoadingSpinnerLight from '../components/LoadingSpinnerLight'
import ProgressModal from '@/components/modals/ProgressModal'
import StatusCountInfoCard from '@/components/statistics/StatusCountInfoCard'
import SubsetBadge from '@/components/item-viewer/SubsetBadge'

export default {
  name: 'DatasetExplorer',
  components: {
    AnnotationOverlay,
    BarChart,
    BasicOverlay,
    CardCollapse,
    ClassInput,
    ClassLabelSelection,
    DatasetModal,
    DatasetOverview,
    FilterSettingsModal,
    ItemTimeline,
    ItemViewer,
    LoadingOverlay,
    LoadingSpinner,
    LoadingSpinnerLight,
    ProgressModal,
    StatusCountInfoCard,
    SubsetBadge,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      queryParams: {},
      centerLoading: false,
      datasetsLoading: false,
      itemsLoading: 0,
      countsLoading: false,
      modalDatasetModelsLoading: false,

      showAnnotationOverlay: false,

      showOverview: true,
      showOverviewFirstTime: false,

      timezone: 'UTC',

      multiSelection: false,

      viewMode: 'gallery',

      showSubsets: true,
      showTrueLabels: true,
      showObjects: true,
      showStatus: true,
      showFilename: true,
      showTimestamp: true,
      showItemPreview: false,
      previewItem: undefined,

      showDatasetModal: false,

      statistics: {
        itemsPerClass: [],
        itemsPerClassLoading: false,
        itemsPerClassView: false,
        objectsPerClass: [],
        objectsPerClassLoading: false,
        objectsPerClassView: false,
        itemsPerSubset: [],
        itemsPerSubsetLoading: false,
        itemsPerSubsetView: false,
        itemsPerStatus: [],
        itemsPerStatusLoading: false,
      },

      progress: {
        title: 'Progress',
        show: false,
        progress: 0.0,
        maxProgress: 1.0,
        showAbsolute: false,
      },

      // datasets: [],

      viewNetworkExplorerPermission: false,
      deleteDatasetPermission: false,
      lastDatasetSearchString: '',
      datasetSearchString: '',
      filteredDatasetLength: 0,
      selectedDataset: {},
      settingsDataset: undefined,
      totalDatasetItems: 0,
      datasetItems: [],
      maxDatasetItems: 10000000,
      // maxDatasetItems: 200,
      earliestPreviousLink: undefined,
      latestNextLink: undefined,
      // datasetItemsPage: {
      //   current: 1,
      //   last: 1,
      //   size: 24
      // },

      selectedItemsSet: new Set([]),
      selectedItems: [],
      selectedItem: undefined,
      allItemsSelected: false,
      itemCursor: {
        position: undefined,
        reversed: false,
      },

      newDatasetItems: [],

      networkModels: [],
      deleteDatasetName: '',
      newClassLabel: '',

      classificationTableItems: [],
      classificationTableItemsPerPage: 10,
      classificationTablePage: 1,

      objectDetectionTablePage: 1,

      uploadErrorMessage: undefined,
      uploading: false,
      dropzoneOptions: {
        paramName: 'data',
        url: this.$axios.defaults.baseURL + '/api/dataset-items/',
        createImageThumbnails: false,
        headers: { 'X-CSRFToken': Cookie.get('csrftoken') },
        withCredentials: true,
      },
      selectedImageClassLabels: [],
      selectedSubsets: [],
      selectedStatus: '',
      statusOptions: statusOptions,

      showFilterSettings: false,
      filterSettings: {
        status: [],
        imageClass: null,
        objectClass: null,
        dataSubset: null,
        attributes: [],
        dateTimeFrom: null,
        dateTimeTo: null,
      },
      itemsPerRowReversed: 3,
      maxItemsPerRow: 12,

      totalUploadProgress: 0,
      uploadFiles: [],
      totalUploadFiles: 0,
      totalUploadSize: 0,
      totalBytesSent: 0,
      finishedFiles: 0,
      bytesSentBefore: 0,
      timeBefore: 0,
      estimatedUploadTimeInfo: '',
      ignoreUploadErrors: false,
      bottomAreaOpen: false,
    }
  },

  computed: {
    ...mapState({
      classLabels: (state) => state.classLabels,
      classLabelMap: (state) => state.classLabelMap,
      availableTimezones: (state) => state.server.timezones,
      datasets: (state) => state.datasets,
      models: (state) => state.models,
      modelsLoading: (state) => state.modelsLoading,
    }),
    objectClassLabels() {
      if (
        this.selectedDataset &&
        this.selectedDataset.object_detection &&
        this.selectedDataset.object_detection_class_labels
      ) {
        return this.selectedDataset.object_detection_class_labels
      }
      return []
    },
    classificationClassLabels() {
      if (
        this.selectedDataset &&
        this.selectedDataset.classification &&
        this.selectedDataset.classification_class_labels
      ) {
        return this.selectedDataset.classification_class_labels
      }
      return []
    },
    subsets() {
      let subsets = {}
      if (this.selectedDataset && this.selectedDataset.data_subsets) {
        for (let i = 0; i < this.selectedDataset.data_subsets.length; i++) {
          let subset = this.$_.cloneDeep(this.selectedDataset.data_subsets[i])
          subsets[subset.id] = subset
        }
      }

      return subsets
    },

    // loadSubsets() {
    //   if (this.showSubsets && this.$_.isEmpty(this.subsets)) {
    //     this.getSubsets()
    //   }
    // },
    shownUploadFiles() {
      const result = []
      for (const file of this.uploadFiles) {
        if (file.show) {
          result.push(file)
        }
      }
      return result
    },
    availableClassLabels() {
      let classLabels = []
      for (const c of this.classLabels) {
        classLabels.push({ value: c.id, text: c.name })
      }
      return classLabels
    },
    filteredDatasets() {
      const filteredDatasets = this.datasets.filter((d) => {
        return d.name.toLowerCase().includes(this.datasetSearchString.toLowerCase())
      })

      return filteredDatasets
    },
    itemsPerRow() {
      return this.maxItemsPerRow - this.itemsPerRowReversed
    },
    itemSize() {
      // if (this.itemsPerRowReversed < 6) return 1 / (this.maxItemsPerRow - this.itemsPerRowReversed) - 1e-4
      return 1 / this.itemsPerRow - 1e-4
    },
    subsetOptions() {
      let options = []
      for (let subsetId in this.subsets) {
        const subset = this.subsets[subsetId]
        options.push({ value: subset.id, text: subset.name })
      }
      return options
    },
  },
  watch: {
    $route() {
      // if (from.name == to.name) {
      //   this.$router.replace({
      //     query: {
      //       ...from.query,
      //       ...to.query
      //     }
      //   })
      // }
      // this.queryParams = to.query
    },
    '$route.query.dataset'(datasetId) {
      this.showOverview = datasetId === undefined
      if (this.showOverview) {
        this.showOverviewFirstTime = true
      }
      if (datasetId !== undefined) {
        this.loadDataset(datasetId)
      }
    },
    '$route.query.item'(itemId) {
      if (itemId) {
        let idx = -1
        for (let i = 0; i < this.datasetItems.length - 1; i++) {
          const item = this.datasetItems[i]
          if (item.id === itemId) {
            idx = i
            break
          }
        }

        if (idx !== -1) {
          let item = this.datasetItems[idx]
          this.openItem({ item })
        }
      } else {
        this.onCloseAnnotationOverlay()
      }
    },
    showOverview() {
      // this.$nextTick(this.checkLoadItems)
    },
    viewMode() {
      this.$nextTick(this.checkLoadItems)
    },
    itemsPerRowReversed() {
      this.$nextTick(this.checkLoadItems)
    },
  },

  created() {
    this.$store.dispatch('getClassLabels')

    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    let q = this.$route.query
    this.queryParams = _.cloneDeep(q)

    let datasetId = q['dataset']

    if (datasetId === undefined) {
      this.showOverview = true
      this.showOverviewFirstTime = true
    } else {
      this.showOverview = false
    }

    this.load = function (datasetId, callback, loadNext, loadPrevious) {
      if (loadPrevious && this.earliestPreviousLink === null) {
        return
      }

      if (loadNext && this.latestNextLink === null) {
        return
      }

      if (datasetId === undefined) {
        datasetId = this.selectedDataset.id
        this.centerLoading = false
      }

      if (datasetId === undefined) {
        return
      }

      if (this.loadItemCancelTokenSource) {
        // cancel previous request
        this.loadItemCancelTokenSource.cancel()
        this.loadItemCanceled = true
      }
      this.loadItemCancelTokenSource = axios.CancelToken.source()

      this.itemsLoading++

      let url
      if (loadPrevious && this.earliestPreviousLink) {
        url = this.earliestPreviousLink
      } else if (loadNext && this.latestNextLink) {
        url = this.latestNextLink
      } else {
        this.centerLoading = true

        let cursor = ''
        if (!loadPrevious && !loadNext && this.itemCursor.position) {
          cursor = `&cursor_position=${this.itemCursor.position}`
        }

        const itemPageSize = 12 * this.itemsPerRow
        let filterQuery = getFilterQuery(this.filterSettings, false, this.timezone)
        url = `/api/dataset-items/?page_size=${itemPageSize}&dataset__id=${datasetId}${filterQuery}${cursor}`
      }

      // convert absolute url to relative url
      if (this.$axios.defaults.baseURL !== '') {
        const urlObj = new URL(url, this.$axios.defaults.baseURL)
        url = urlObj.toString().substring(urlObj.origin.length)
      }

      let self = this
      this.$axios({
        method: 'get',
        url: url,
        cancelToken: this.loadItemCancelTokenSource.token,
      })
        .then((response) => {
          if (datasetId !== self.selectedDataset.id) {
            return
          }

          if (self.earliestPreviousLink === undefined || loadPrevious) {
            self.earliestPreviousLink = response.data.previous
          }

          if (self.latestNextLink === undefined || loadNext) {
            self.latestNextLink = response.data.next
          }

          let datasetItems = response.data.results
          self.allItemsSelected = false
          for (let i = 0; i < datasetItems.length; i++) {
            let item = datasetItems[i]
            if (self.selectedItem && item.id === self.selectedItem.id) {
              datasetItems[i] = self.selectedItem
            } else {
              item.selected = self.selectedItemsSet.has(item.id)
            }
          }

          let maxDatasetItems =
            this.itemsPerRow * Math.ceil(self.maxDatasetItems / this.itemsPerRow)
          let updatedDatasetItems = []
          if (loadPrevious) {
            updatedDatasetItems = datasetItems.concat(self.datasetItems)
            if (updatedDatasetItems.length > maxDatasetItems) {
              updatedDatasetItems = updatedDatasetItems.slice(maxDatasetItems)
              // TODO update next link accordingly (parse url and add or replace cursor)
            }
          } else if (loadNext) {
            updatedDatasetItems = self.datasetItems.concat(datasetItems)
            if (updatedDatasetItems.length > maxDatasetItems) {
              // TODO slice only full rows
              // TODO update previous link accordingly (parse url and add or replace cursor)
              const nRows = Math.floor(self.datasetItems.length / this.itemsPerRow)
              let amountLastRow = self.datasetItems.length - this.itemsPerRow * nRows
              let amountAfterSlice = maxDatasetItems + amountLastRow
              updatedDatasetItems = updatedDatasetItems.slice(-amountAfterSlice)
              console.log('slice', nRows, this.itemsPerRow, amountLastRow, amountAfterSlice)
            }
          } else {
            updatedDatasetItems = datasetItems
          }

          self.datasetItems = updatedDatasetItems

          self.itemsLoading--
          if (!loadPrevious && !loadNext) {
            // on the initial loading, load one previous chunk
            this.load(datasetId, undefined, false, true)
          } else {
            self.$nextTick(() => {
              self.checkLoadItems()
            })
          }
        })
        .catch(() => {
          self.itemsLoading--
          if (!self.loadItemCanceled) {
            self.$bvToast.toast('Could not load dataset items.', {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 4000,
              solid: true,
            })
          }
        })
        .finally(() => {
          self.centerLoading = false
          if (callback) {
            callback()
          }
        })
    }

    this.loadThrottled = _.throttle(
      (datasetId, callback, loadNext, loadPrevious) => {
        if (this.selectedDataset && this.selectedDataset.id === datasetId) {
          this.load(datasetId, callback, loadNext, loadPrevious)
        }
      },
      200,
      { leading: true }
    )
    this.loadItems = _.debounce(
      (datasetId, callback, loadNext, loadPrevious) => {
        this.load(datasetId, callback, loadNext, loadPrevious)
      },
      500,
      { leading: true, maxWait: 2000 }
    )
    this.loadItemsRare = _.debounce(this.load, 5000, { leading: true, maxWait: 1000 })
  },
  mounted() {
    let user = this.$store.getters.user

    if (user !== undefined && user.permissions !== undefined) {
      this.deleteDatasetPermission = user.permissions.includes('platform_api.delete_dataset')
      this.viewNetworkExplorerPermission = user.permissions.includes(
        'platform_api.view_networkmodel'
      )
    }

    let itemId = this.queryParams['item']
    let datasetId = this.queryParams['dataset']

    if (datasetId !== undefined && itemId !== undefined) {
      this.loadItem(itemId, () => {
        this.loadDataset(datasetId)
        this.openItem({ item: this.selectedItem })
      })
    } else if (datasetId !== undefined) {
      this.loadDataset(datasetId)
    }

    this.datasetsLoading = true
    this.loadDatasets()
      .then(() => {})
      .catch(() => {
        this.$bvToast.toast('Could not load list of datasets.', {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 4000,
          solid: true,
        })
      })
      .finally(() => {
        this.datasetsLoading = false
      })
  },

  methods: {
    ...mapActions({
      loadDatasets: 'getDatasets',
      getModels: 'getModels',
      openAiModal: 'openAiModal',
    }),
    loadItem(itemId, callback) {
      const self = this
      this.$axios
        .get(`/api/dataset-items/${itemId}/`)
        .then((response) => {
          let item = response.data
          item.selected = true
          self.selectedItem = item
          self.itemCursor.position = formatDatetimeCursorPosition(item.created_date)
        })
        .catch(() => {
          this.$bvToast.toast(`Could selected item #${itemId}.`, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
        })
        .finally(() => {
          if (callback) {
            callback()
          }
        })
    },
    checkLoadItems() {
      if (this.showOverview) {
        return
      }
      const itemsViewerWrapper = this.$refs.itemViewerWrapper
      if (
        this.selectedDataset &&
        itemsViewerWrapper &&
        itemsViewerWrapper.scrollHeight <= itemsViewerWrapper.clientHeight
      ) {
        this.load(this.selectedDataset.id, undefined, true, false)
      }
    },
    openTimezoneSettings() {},
    changeTimezoneSettings() {
      this.$root.$emit('bv::hide::popover', 'timezone-popover')
    },
    onDateTimeRangeChange(event) {
      if (
        this.filterSettings.dateTimeFrom !== event.dateTimeFrom ||
        this.filterSettings.dateTimeTo !== event.dateTimeTo
      ) {
        this.filterSettings.dateTimeFrom = event.dateTimeFrom
        this.filterSettings.dateTimeTo = event.dateTimeTo
        this.applyFilterSettings(this.filterSettings)
      }
    },
    getClassificationClassName(classId) {
      for (const classLabel of this.selectedDataset.classification_class_labels) {
        if (classLabel.id === classId) {
          return classLabel.name
        }
      }

      return classId
    },

    onClickOpenModel(model) {
      this.getModels({ forceReload: false }).then(() => {
        for (const m of this.models) {
          if (m.id === model.id) {
            this.openAiModal(m)
          }
        }
      })
    },

    openOverview() {
      // this.showOverview = true;
      this.showOverviewFirstTime = true
      this.$router.push({
        path: 'dataset-explorer',
        query: {},
      })
    },

    openCreateDatasetModal() {
      this.settingsDataset = undefined
      this.showDatasetModal = true
    },

    openDatasetModal() {
      this.settingsDataset = this.selectedDataset
      this.showDatasetModal = true
    },

    onDatasetCreated(dataset) {
      this.selectDataset(dataset.id)
    },

    onDatasetUpdated(dataset) {
      this.selectedDataset = dataset
    },

    onDatasetDeleted() {
      this.$bvToast.toast('Dataset was successfully deleted.', {
        title: 'Success',
        variant: 'success',
        autoHideDelay: 4000,
        solid: true,
      })
      this.selectDataset(undefined)
    },

    openDataUploadModal() {
      this.selectedSubsets = []
      this.selectedImageClassLabels = []
      this.newDatasetItems = []
      // this.getSubsets()
      this.$bvModal.show('modal-data-upload')
    },

    openAiModelsModal() {
      this.$bvModal.show('modal-dataset-models')
    },

    resetItemSelection() {
      for (const item of this.datasetItems) {
        this.$set(item, 'selected', false)
      }
      this.selectedItemsSet = new Set([])
      this.selectedItems = []
    },

    openFilterSettings() {
      this.showFilterSettings = true
    },

    applyFilterSettings(event) {
      this.filterSettings = event
      // this.datasetItemsPage.current = 1
      this.reloadItems()
    },

    reloadItems() {
      this.itemCursor.position = undefined
      this.itemCursor.reversed = false
      this.resetItems()
      this.loadItems(this.selectedDataset.id)
      this.loadStatistics()
    },

    resetFilterSettings() {
      this.filterSettings = {
        status: [],
        imageClass: null,
        objectClass: null,
        dataSubset: null,
        attributes: [],
        dateTimeFrom: null,
        dateTimeTo: null,
      }
    },

    onCloseAnnotationOverlay() {
      if (this.selectedItem) {
        this.$set(this.selectedItem, 'selected', false)
        this.selectedItem = undefined
      }
      this.selectedItemsSet = new Set([])
      this.selectedItems = []
      // this.$store.dispatch('showNavbar')
      this.showAnnotationOverlay = false
      this.queryParams.item = undefined
      this.updateRouterQuery(true)
    },

    openItem({ item }) {
      let isFirstSelectedItem = true
      if (this.selectedItem) {
        isFirstSelectedItem = false
        this.$set(this.selectedItem, 'selected', false)
      }
      this.selectedItem = item
      this.selectedItemsSet = new Set([item.id])
      this.selectedItems = Array.from(this.selectedItemsSet)
      this.$set(item, 'selected', true)
      this.showAnnotationOverlay = true
      this.queryParams.item = `${item.id}`
      this.updateRouterQuery(isFirstSelectedItem)
      // this.$store.dispatch('hideNavbar')
    },

    updateRouterQuery(push) {
      if (push) {
        this.$router
          .push({
            query: this.queryParams,
          })
          .catch(() => {})
      } else {
        this.$router
          .replace({
            query: this.queryParams,
          })
          .catch(() => {})
      }
    },

    onClickItem({ item, evt }) {
      if (!this.multiSelection) {
        this.openItem({ item, evt })
      } else {
        if (this.selectedItemsSet.has(item.id)) {
          this.selectedItemsSet.delete(item.id)
          this.$set(item, 'selected', false)
        } else {
          this.selectedItemsSet.add(item.id)
          this.$set(item, 'selected', true)
        }
        this.selectedItems = Array.from(this.selectedItemsSet)
      }
    },

    onDoubleClickItem({ item, evt }) {
      this.resetItemSelection()
      this.openItem({ item, evt })
    },

    onMultiSelection({ itemIds, evt }) {
      if (this.multiSelection) {
        let itemMap = {}
        for (const item of this.datasetItems) {
          itemMap[item.id] = item
        }

        for (const itemId of itemIds) {
          let item = itemMap[itemId]

          if (ctrlOrCmdPressed(evt)) {
            // toggle selected
            if (this.selectedItemsSet.has(item.id)) {
              this.selectedItemsSet.delete(item.id)
              this.$set(item, 'selected', false)
            } else {
              this.selectedItemsSet.add(item.id)
              this.$set(item, 'selected', true)
            }
          } else {
            if (evt.shiftKey) {
              // unselect
              this.selectedItemsSet.delete(item.id)
              this.$set(item, 'selected', false)
            } else {
              // select
              this.selectedItemsSet.add(item.id)
              this.$set(item, 'selected', true)
            }
          }
        }
        this.selectedItems = Array.from(this.selectedItemsSet)
      }
    },

    onScrollAdjustmentRequired({ scrollDifference }) {
      this.$refs.itemViewerWrapper.scrollBy(0, scrollDifference)
    },

    toggleAllItems(event) {
      this.allItemsSelected = event
      if (event) {
        for (const item of this.datasetItems) {
          this.selectedItemsSet.add(item.id)
          this.$set(item, 'selected', true)
        }
      } else {
        for (const item of this.datasetItems) {
          this.selectedItemsSet.delete(item.id)
          this.$set(item, 'selected', false)
        }
      }
      this.selectedItems = Array.from(this.selectedItemsSet)
    },

    addClassLabelsToSelectedItems() {
      this.classLabelEditMode = 'add'
      this.selectedImageClassLabels = []
      this.$bvModal.show('modal-select-class-labels')
    },

    removeClassLabelsFromSelectedItems() {
      this.classLabelEditMode = 'remove'
      this.selectedImageClassLabels = []
      this.$bvModal.show('modal-select-class-labels')
    },

    openDeleteItemModal() {
      this.$bvModal.show('modal-delete-items')
    },

    deleteItems() {
      this.centerLoading = true
      const selectedItems = _.cloneDeep(this.selectedItems)
      const requestData = {
        items: selectedItems,
        dataset: this.selectedDataset.id,
      }

      const self = this
      this.$axios({
        method: 'post',
        url: '/api/dataset-items/bulk_delete/',
        data: requestData,
        withCredentials: true,
        timeout: 5 * 60 * 1000,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
        .then(() => {
          // remove items
          for (let j = self.datasetItems.length - 1; j >= 0; j--) {
            const datasetItem = self.datasetItems[j]
            if (selectedItems.includes(datasetItem.id)) {
              self.datasetItems.splice(j, 1)
            }
          }
          self.centerLoading = false
          self.loadStatistics()
        })
        .catch(() => {
          self.$bvToast.toast(`Error while deleting class labels.`, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
          self.centerLoading = false
        })
    },

    onClickAddImageClassLabel(classLabelId) {
      this.editClassLabels([classLabelId], 'add', _.cloneDeep(this.selectedItems))
    },
    onClickRemoveImageClassLabel(classLabelId) {
      this.editClassLabels([classLabelId], 'remove', _.cloneDeep(this.selectedItems))
    },
    editClassLabels(classLabelIds, classLabelEditMode, itemsIds) {
      const requestData = {
        items: itemsIds,
        dataset: this.selectedDataset.id,
        class_labels: classLabelIds,
        mode: classLabelEditMode,
      }

      const self = this
      this.$axios({
        method: 'post',
        url: '/api/dataset-items/edit_class_labels/',
        data: requestData,
        withCredentials: true,
        timeout: 5 * 60 * 1000,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
        .then(() => {
          // update annotations of the changed items
          for (const itemId of itemsIds) {
            for (const datasetItem of self.datasetItems) {
              if (itemId === datasetItem.id) {
                let annotations = datasetItem.annotations
                if (!annotations.classes) {
                  annotations.classes = []
                }
                if (classLabelEditMode === 'add') {
                  for (const classLabelId of classLabelIds) {
                    if (!annotations.classes.includes(classLabelId)) {
                      annotations.classes.push(classLabelId)
                    }
                  }
                } else if (classLabelEditMode === 'remove') {
                  for (const classLabelId of classLabelIds) {
                    // eslint-disable-next-line no-constant-condition
                    while (true) {
                      let j = annotations.classes.indexOf(classLabelId)
                      if (j >= 0) {
                        annotations.classes.splice(j, 1)
                      } else {
                        break
                      }
                    }
                  }
                }
                self.$set(datasetItem, 'annotations', annotations)
                break
              }
            }
          }
          self.centerLoading = false
          self.loadStatistics()
        })
        .catch(() => {
          this.$bvToast.toast(`Error while editing class labels.`, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
          this.centerLoading = false
        })
    },

    selectClassLabelOk() {
      this.centerLoading = true
      const itemsIds = _.cloneDeep(this.selectedItems)
      const classLabelEditMode = this.classLabelEditMode
      const classLabelIds = []
      for (let classLabel of this.selectedImageClassLabels) {
        classLabelIds.push(classLabel.id)
      }
      this.editClassLabels(classLabelIds, classLabelEditMode, itemsIds)
    },

    onClickChangeStatus(newStatus) {
      this.centerLoading = true
      const selectedItems = _.cloneDeep(this.selectedItems)
      const requestData = {
        items: selectedItems,
        dataset: this.selectedDataset.id,
        status: newStatus,
      }

      const self = this
      this.$axios({
        method: 'post',
        url: '/api/dataset-items/edit_status/',
        data: requestData,
        withCredentials: true,
        timeout: 5 * 60 * 1000,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
        .then(() => {
          // update annotations of the changed items
          for (const itemId of selectedItems) {
            for (const datasetItem of self.datasetItems) {
              if (itemId === datasetItem.id) {
                self.$set(datasetItem, 'status', newStatus)
                break
              }
            }
          }
          self.centerLoading = false
          self.loadStatistics()
        })
        .catch(() => {
          this.$bvToast.toast(`Error while editing class labels.`, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
          this.centerLoading = false
        })
    },

    addSelectedItemsToSubset(subset) {
      this.centerLoading = true
      const itemIds = this.$_.cloneDeep(this.selectedItems)
      const subsetId = subset.id
      // const subsetName = subset.name

      let requestData = {
        items: itemIds,
        subsets: [subsetId],
        mode: 'add',
      }

      let self = this
      self
        .$axios({
          method: 'post',
          url: `/api/dataset-items/edit_subsets/`,
          data: requestData,
          withCredentials: true,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': Cookie.get('csrftoken'),
          },
        })
        .then(() => {
          // app.$bvToast.toast(`Added ${itemIds.length} items to subset "${subset.name}".`, {
          // self.$bvToast.toast(`Added items to subset "${subsetName}".`, {
          //   title: 'Success',
          //   variant: 'success',
          //   autoHideDelay: 8000,
          //   solid: true,
          // })
          self.loadStatistics()

          for (const datasetItem of self.datasetItems) {
            if (itemIds.includes(datasetItem.id)) {
              if (!datasetItem.data_subsets.includes(subsetId)) {
                datasetItem.data_subsets.push(subsetId)
              }
            }
          }
          // this.setInteractionMode('openAnnotationTool')
          self.centerLoading = false
        })
        .catch(() => {
          self.$bvToast.toast(`Could not add items to subset "${subset.name}".`, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
          self.centerLoading = false
        })
    },

    removeSelectedItemsFromSubset(subset) {
      this.centerLoading = true
      const itemIds = this.$_.cloneDeep(this.selectedItems)
      const subsetId = subset.id
      // const subsetName = subset.name
      let requestData = {
        items: itemIds,
        subsets: [subsetId],
        mode: 'remove',
      }
      let self = this
      self
        .$axios({
          method: 'post',
          url: `/api/dataset-items/edit_subsets/`,
          data: requestData,
          withCredentials: true,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': Cookie.get('csrftoken'),
          },
        })
        .then(() => {
          // self.$bvToast.toast(`Removed items from subset "${subsetName}".`, {
          //   title: 'Success',
          //   variant: 'success',
          //   autoHideDelay: 8000,
          //   solid: true,
          // })
          for (const datasetItem of self.datasetItems) {
            if (itemIds.includes(datasetItem.id)) {
              let idx = datasetItem.data_subsets.indexOf(subsetId)
              if (idx >= 0) {
                datasetItem.data_subsets.splice(idx, 1)
              }
            }
          }
          self.loadStatistics()
          // this.setInteractionMode('openAnnotationTool')
          self.centerLoading = false
        })
        .catch(() => {
          self.$bvToast.toast(`Could not remove items from subset "${subset.name}".`, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
          self.centerLoading = false
        })
    },

    subsetsUpdated() {
      this.loadDataset(this.selectedDataset.id, () => {
        this.$set(this.settingsDataset, 'data_subsets', this.selectedDataset.data_subsets)
      })
      // if (this.selectedDataset !== -1) {
      //   this.centerLoading = true
      //   this.$axios
      //     .get(`/api/datasets/${this.selectedDataset.id}`)
      //     .then(response => {
      //       if (this.selectedDataset !== -1) {
      //         this.selectedDataset.data_subsets = response.data.data_subsets
      //         // this.getSubsets()
      //         this.reloadItems()
      //         this.loadStatistics()
      //         this.centerLoading = false
      //       }
      //     })
      //     .catch(error => {
      //       this.centerLoading = false
      //     })
      // }
    },

    selectDataset(datasetId) {
      let q = this.$route.query
      let oldDatasetId = q['dataset']
      if (datasetId === oldDatasetId) return

      if (datasetId === -1) {
        datasetId = undefined
      }

      this.queryParams.dataset = datasetId

      this.$router.push({
        path: 'dataset-explorer',
        query: { dataset: datasetId },
      })
      this.itemCursor.position = undefined
      this.itemCursor.reversed = false
    },

    resetItems() {
      this.earliestPreviousLink = undefined
      this.latestNextLink = undefined

      if (this.$refs.itemViewerWrapper) {
        // scroll to top
        this.$refs.itemViewerWrapper.scrollBy(0, -this.$refs.itemViewerWrapper.scrollTop)
      }
      this.datasetItems = []
    },

    // getDatasetCounts(datasetId) {
    //
    //   if (this.loadDatasetCountCancelToken) {
    //     // cancel previous request
    //     this.loadDatasetCountCancelToken.cancel()
    //     this.loadDatasetCountCancelToken = undefined
    //   }
    //   this.loadDatasetCountCancelToken = axios.CancelToken.source()
    //
    //   // let filterQuery = getFilterQuery(this.filterSettings, false, this.timezone)
    //   // let url = `/api/dataset-items/count/?mode=count_items_by_class&dataset__id=${datasetId}${filterQuery}`
    //
    //   this.countsLoading = true
    //   const self = this
    //   this.$axios({
    //     method: 'get',
    //     url: `/api/datasets/${datasetId}/counts`,
    //     cancelToken: this.loadDatasetCountCancelToken.token
    //   }).then(response => {
    //     if (self.selectedDataset && self.selectedDataset.id == datasetId) {
    //       const counts = response.data
    //
    //       self.$set(self.selectedDataset, 'total_items', counts.total_items)
    //       self.$set(self.selectedDataset, 'uploaded_items', counts.uploaded_items)
    //       self.$set(self.selectedDataset, 'annotated_items', counts.annotated_items)
    //       self.$set(self.selectedDataset, 'reviewed_items', counts.reviewed_items)
    //       self.$set(self.selectedDataset, 'ignored_items', counts.ignored_items)
    //       let unknown =
    //         this.selectedDataset.total_items -
    //         this.selectedDataset.uploaded_items -
    //         this.selectedDataset.annotated_items -
    //         this.selectedDataset.reviewed_items -
    //         this.selectedDataset.ignored_items
    //       self.$set(self.selectedDataset, 'unknown_items', unknown)
    //       self.loadDatasetCountCancelToken = undefined
    //     }
    //   }).catch(error => {
    //     console.warn(error)
    //   }).finally(() => {
    //     self.countsLoading = false
    //   })
    // },

    loadDataset(datasetId, callback) {
      if (datasetId !== -1) {
        window.scrollTo(0, 0)
        this.selectedDataset = {}
        this.showOverview = false
        this.centerLoading = true
        const self = this
        self.resetItems()
        self.resetItemSelection()
        self.selectedImageClassLabels = []
        self.statistics.itemsPerClass = []
        self.statistics.objectsPerClass = []
        self.statistics.itemsPerSubset = []
        self.statistics.itemsPerStatus = []
        this.$axios
          .get(`/api/datasets/${datasetId}`)
          .then((response) => {
            self.selectedDataset = response.data
            self.resetFilterSettings()
            self.loadStatistics()
            self.selectionUpdated = true
            self.loadItems(self.selectedDataset.id)
            if (callback) {
              callback()
            }
          })
          .catch(() => {
            this.centerLoading = false
            this.$bvToast.toast('Could not load dataset details.', {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 4000,
              solid: true,
            })
          })
      }
    },

    loadDatasetModels() {
      this.modalDatasetModelsLoading = true
      this.networkModels = []
      this.$axios({
        method: 'get',
        url: `/api/datasets/${this.selectedDataset.id}/network_models/`,
        withCredentials: true,
        crossDomain: true,
      })
        .then((response) => {
          this.networkModels = response.data
        })
        .catch(() => {
          this.$bvToast.toast('Could not load corresponding network models.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
        })
        .finally(() => {
          this.modalDatasetModelsLoading = false
        })
    },

    async loadStatistics() {
      if (this.selectedDataset && this.selectedDataset.id) {
        const load = async (url, key, total) => {
          let result = []
          try {
            const response = await this.$axios.get(url)
            let sum = 0
            let max = 0
            for (let i = 0; i < response.data[key].length; i++) {
              const id = response.data[key][i]
              const count = response.data.count[i]
              max = Math.max(count, max)
              sum += count
              const percentage = 0
              const displayWidth = 0
              const d = { id, count, percentage, displayWidth }
              result.push(d)
            }

            for (const stat of result) {
              stat.percentage = 0
              if (total > 0) {
                stat.percentage = stat.count / total
              } else if (sum > 0) {
                stat.percentage = stat.count / sum
              }

              stat.displayWidth = 0
              if (max > 0) {
                stat.displayWidth = stat.count / max
              }
            }
          } catch (error) {
            console.log(error)
            result = []
          }
          return result
        }

        let url, filterQuery
        this.statistics.itemsPerClassLoading = true
        this.statistics.objectsPerClassLoading = true
        this.statistics.itemsPerSubsetLoading = true
        this.statistics.itemsPerStatusLoading = true

        if (this.selectedDataset.id && this.selectedDataset.classification) {
          filterQuery = getFilterQuery(this.filterSettings, false, this.timezone)
          url = `/api/dataset-items/count/?mode=count_items_by_class&dataset__id=${this.selectedDataset.id}${filterQuery}`
          this.statistics.itemsPerClass = await load(url, 'class_id')
        }
        this.statistics.itemsPerClassLoading = false

        if (this.selectedDataset.id && this.selectedDataset.object_detection) {
          filterQuery = getFilterQuery(this.filterSettings, false, this.timezone)
          url = `/api/dataset-items/count/?mode=count_objects_by_class&dataset__id=${this.selectedDataset.id}${filterQuery}`
          this.statistics.objectsPerClass = await load(url, 'class_id')
        }
        this.statistics.objectsPerClassLoading = false

        if (this.selectedDataset.id) {
          filterQuery = getFilterQuery(this.filterSettings, false, this.timezone)
          url = `/api/dataset-items/count/?mode=count_items_by_status&dataset__id=${this.selectedDataset.id}${filterQuery}`
          this.statistics.itemsPerStatus = await load(url, 'status')
          this.statistics.itemsPerStatusLoading = false
        }

        if (this.selectedDataset.id) {
          filterQuery = getFilterQuery(this.filterSettings, false, this.timezone)
          url = `/api/dataset-items/count/?mode=count_items_by_subset&dataset__id=${this.selectedDataset.id}${filterQuery}`
          let total = 0
          for (const entry of this.statistics.itemsPerStatus) {
            total += entry.count
          }
          this.statistics.itemsPerSubset = await load(url, 'subset_id', total)
          this.statistics.itemsPerSubsetLoading = false
        }
      } else {
        this.statistics.itemsPerClass = []
        this.statistics.objectsPerClass = []
        this.statistics.itemsPerSubset = []
        this.statistics.itemsPerStatus = []
      }
    },

    fileSendingEvent(file, xhr, formData) {
      this.cookie = 'COOKIE: ' + Cookie.get('csrftoken')

      let status = 'uploaded'
      let annotations = {
        classes: [],
      }
      for (let i = 0; i < this.selectedImageClassLabels.length; i++) {
        annotations.classes.push(this.selectedImageClassLabels[i].id)
      }

      const isOnlyClassification =
        this.selectedDataset.classification &&
        !this.selectedDataset.object_detection &&
        !this.selectedDataset.object_detection_key_points &&
        !this.selectedDataset.object_detection_mask &&
        !this.selectedDataset.roi_classification &&
        !this.selectedDataset.semantic_segmentation

      if (this.selectedImageClassLabels.length > 0 && isOnlyClassification) {
        status = 'annotated'
      }

      formData.append('dataset_id', this.selectedDataset.id)
      formData.append('status', status)
      formData.append('annotations', JSON.stringify(annotations))
    },

    fileSuccessEvent(file, response) {
      this.newDatasetItems.push(response.id)
    },

    onUploadComplete() {
      this.estimatedUploadTimeInfo = ''

      this.uploading = false

      this.$refs.cancelUploadModal.hide()

      const itemIds = this.$_.cloneDeep(this.newDatasetItems)
      this.newDatasetItems = []

      if (this.selectedSubsets.length > 0 && itemIds.length > 0) {
        for (const subsetId of this.selectedSubsets) {
          let requestData = {
            items: itemIds,
          }
          let self = this
          self
            .$axios({
              method: 'post',
              url: `/api/data-subsets/${subsetId}/items/`,
              data: requestData,
              withCredentials: true,
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': Cookie.get('csrftoken'),
              },
            })
            .then(() => {
              self.$bvToast.toast(`Added ${itemIds.length} items to subset with ID ${subsetId}.`, {
                title: 'Success',
                variant: 'success',
                autoHideDelay: 8000,
                solid: true,
              })
            })
            .catch(() => {
              self.$bvToast.toast(`Could not add items to subset "${subsetId}".`, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 4000,
                solid: true,
              })
            })
            .finally(() => {
              this.reloadItems()
            })
        }
      } else {
        this.reloadItems()
      }
    },

    fileErrorEvent(file, message, xhr) {
      if (this.ignoreUploadErrors) {
        // pass error handling for cancel upload
      } else if (xhr) {
        if (xhr.status === 409) {
          this.$bvToast.show('image-exists-toast')
        } else if (xhr.status === 403) {
          // cancel all pending requests in case of 403 ("bad request")
          this.ignoreUploadErrors = true
          this.$refs.itemUploadDropzone.removeAllFiles(true)
          this.ignoreUploadErrors = false
          if (message.error) {
            this.uploadErrorMessage = message.error
          } else {
            this.uploadErrorMessage = 'Bad request'
          }
        } else {
          console.log('error message', message)
          this.$bvToast.show('upload-error-toast')
        }
      } else {
        console.log('error message', message)
        this.$bvToast.show('upload-error-toast')
      }
    },

    fileCompleteEvent(file) {
      this.$refs.itemUploadDropzone.removeFile(file)
    },

    onImageClassesChanged(newClasses) {
      this.selectedImageClassLabels = newClasses
    },

    modalUploadCloseOrCancel(bvModalEvt) {
      if (this.uploading) {
        bvModalEvt.preventDefault()
        this.$refs.cancelUploadModal.show()
      } else {
        this.loadItemsRare.flush()
      }
    },

    closeFileUploadModal() {
      this.ignoreUploadErrors = true
      this.$refs.itemUploadDropzone.removeAllFiles(true)
      this.uploading = false
      this.$refs.modalDataUpload.hide()
      this.ignoreUploadErrors = false
    },

    uploadProgressEvent(file) {
      let sent = 0
      for (let i = 0; i < this.uploadFiles.length; i++) {
        let progress = this.uploadFiles[i].upload.progress / 100
        sent += Math.ceil(this.uploadFiles[i].originalSize * progress)

        if (this.uploadFiles[i].name === file.name) {
          this.uploadFiles[i].show = true

          if (this.uploadFiles[i].upload.progress >= 100) {
            this.uploadFiles[i].show = false
            this.finishedFiles += 1
          }
        }
      }
      this.totalBytesSent = sent
      this.totalUploadProgress = this.totalBytesSent / this.totalUploadSize
    },

    filesAdded(files) {
      this.bytesSentBefore = 0
      this.totalUploadProgress = 0
      this.finishedFiles = 0
      this.estimatedUploadTimeInfo = ''

      this.uploadFiles = files
      this.totalUploadFiles = files.length
      this.uploadFiles = files

      let totalUploadSize = 0
      for (let i = 0; i < this.uploadFiles.length; i++) {
        this.uploadFiles[i].originalSize = this.uploadFiles[i].size
        totalUploadSize += this.uploadFiles[i].size
        this.uploadFiles.show = false
      }
      this.totalUploadSize = totalUploadSize
      this.timeBefore = Date.now() - 1000
      this.startDate = Date.now()

      let interval = setInterval(() => {
        if (!this.uploading) {
          clearInterval(interval)
        }

        let timeNow = Date.now()
        let s = new Date(timeNow - this.startDate)
        let bytesPerSecond =
          (this.totalBytesSent - this.bytesSentBefore) / ((timeNow - this.timeBefore) * 1e-3)
        let bytesPerSecondString = ''
        if (bytesPerSecond < 1024) {
          bytesPerSecondString = `${bytesPerSecond.toFixed(0)} B/s`
        } else if (bytesPerSecond < 1024 * 1024) {
          bytesPerSecondString = `${(bytesPerSecond / 1024).toFixed(2)} KB/s`
        } else {
          bytesPerSecondString = `${(bytesPerSecond / (1024 * 1024)).toFixed(2)} MB/s`
        }

        if (bytesPerSecond > 0) {
          let restToLoad = this.totalUploadSize - this.totalBytesSent
          let d = new Date(Math.round((restToLoad / bytesPerSecond) * 1000))
          this.estimatedUploadTimeInfo = `${bytesPerSecondString}, ${s.getMinutes()} min ${s.getSeconds()} s passed, ${d.getMinutes()} min ${d.getSeconds()} s remaining`
          this.bytesSentBefore = this.totalBytesSent
          this.timeBefore = timeNow
        } else if (timeNow - this.timeBefore > 4000) {
          this.estimatedUploadTimeInfo = `${bytesPerSecondString}, ${s.getMinutes()} min ${s.getSeconds()} s passed, ∞ remaining time`
        }
      }, 2000)
    },
    onScrollItemViewer(event) {
      let scrollTopMax = event.target.scrollTopMax
      if (scrollTopMax === undefined) {
        scrollTopMax = event.target.scrollHeight - event.target.clientHeight
      }
      let scrollTop = Math.ceil(event.target.scrollTop)

      if (
        this.itemsLoading === 0 &&
        scrollTop >= Math.max(scrollTopMax * 0.7, scrollTopMax - 600)
      ) {
        // load next items
        this.loadThrottled(this.selectedDataset.id, undefined, true, false)
      }

      if (this.itemsLoading === 0 && scrollTop <= Math.max(600)) {
        // load previous items
        this.loadThrottled(this.selectedDataset.id, undefined, false, true)
      }
    },
    onSelectedItemChange(item) {
      if (this.selectedItem && this.selectedItem.id === item.id) {
        // this.selectedItem = Object.assign(this.selectedItem, item)
        Object.assign(this.selectedItem, item)
      } else {
        this.selectedItem = item // Object.assign({}, item)
      }

      // try binary search to find the item (the array is sorted in most cases, but not in every case)
      let idx = binarySearch(this.datasetItems, this.selectedItem.id, (elem) => elem.id)

      // try linear search in case the item was not found by binary search
      if (idx === -1) {
        for (let i = 0; i < this.datasetItems.length; i++) {
          if (this.datasetItems[i].id === this.selectedItem.id) {
            idx = i
          }
        }
      }

      if (idx !== -1) {
        this.$set(this.datasetItems, idx, this.selectedItem)
      } else {
        console.warn('Could not find updated item.')
      }
    },
    onPreviousItem() {
      // find currently selected item and choose the previous one
      if (!this.selectedItem) return
      let idx = -1
      for (let i = 1; i < this.datasetItems.length; i++) {
        const item = this.datasetItems[i]
        if (item.id === this.selectedItem.id) {
          idx = i - 1
          break
        }
      }

      if (idx !== -1) {
        let item = this.datasetItems[idx]
        this.openItem({ item })
      }
    },

    onNextItem() {
      // find currently selected item and choose the next one
      if (!this.selectedItem) return
      let idx = -1
      for (let i = 0; i < this.datasetItems.length - 1; i++) {
        const item = this.datasetItems[i]
        if (item.id === this.selectedItem.id) {
          idx = i + 1
          break
        }
      }

      if (idx !== -1) {
        let item = this.datasetItems[idx]
        this.openItem({ item })
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../custom';

.item-area {
  min-height: 300px;
  max-height: 100%;
  height: 100%;
}

.item-viewer-wrapper {
  scrollbar-width: thin;
  overflow-y: scroll;
  //height: calc(100vh - 184px);
  height: 100%;
}

.item-viewer-wrapper-background {
  background-color: $gray-200;
  //background-color: $white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.img-selected {
  background-color: $info;
}

.img-selected:hover {
  background-color: $primary;
}

.image-toolbar {
  //border-bottom: 1px solid $gray-200;
  background: $white;
  //color: $white;
  //background: $primary;
  min-height: 50px;
  //height: 50px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.item-upload-dropzone {
  @extend .border-radius-md;
  border-style: dashed;
  min-height: 250px;
}

.bottom-buttons {
  position: fixed;
  padding: 0em;
  margin: 1em;
  bottom: 0;
  left: 0;
  z-index: 100;

  border: #dfdfdf 1px solid;
  border-radius: 0.5rem;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.1);
}

.bottom-area {
  @extend .shadow-xl;

  position: fixed;
  padding: 0em;
  margin: 0.75em;
  //max-height: 30%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  border-radius: 0.5rem;
  border: $gray-300 1px solid;
  background-color: $white;

  //-webkit-box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.10);
  //-moz-box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.10);
  //box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.10);
}

.upload-first-data-message {
  position: absolute;
  top: 20px;
  left: 20px;
}
</style>
