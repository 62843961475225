<!--suppress PointlessArithmeticExpressionJS -->
<template>
  <div class="annotation-overlay">
    <loading-overlay-fullscreen v-show="inferenceInProgress" />

    <!-- HEADER -->
    <div class="annotation-overlay-header d-flex">
      <div class="d-flex">
        <b-button variant="link" class="text-dark mw-60px" @click="onClose">
          <fai icon="arrow-left" />
        </b-button>
      </div>
      <div class="d-flex justify-content-center align-items-center flex-grow-1">
        <b-button
          :disabled="previousItemDisabled"
          class="header-btn"
          variant="link"
          size="lg"
          @click="onPreviousItem"
        >
          <fai icon="arrow-circle-left" />
        </b-button>
        <span v-if="selectedItem" id="item-file-name" class="file-name"
          >{{ selectedItem.name | getFileName }}<template v-if="unsavedChanges">*</template></span
        >
        <b-popover
          v-if="selectedItem"
          id="item-popover"
          ref="itemPopover"
          target="item-file-name"
          triggers="hover"
          placement="auto"
          custom-class="item-popover"
        >
          <div class="popover-entry">ID: {{ selectedItem.id }}</div>
          <div class="popover-entry">
            Dataset:
            {{ dataset.id === selectedItem.dataset ? dataset.name : `#${selectedItem.dataset}` }}
          </div>
          <div class="popover-entry">Created: {{ selectedItem.created_date | formatDate }}</div>
          <div class="popover-entry">Last update: {{ selectedItem.updated_date | formatDate }}</div>
          <div
            v-if="
              detectionClassLabels.length > 0 &&
              selectedItem.annotations &&
              selectedItem.annotations.objects
            "
            class="popover-entry"
          >
            Objects: {{ selectedItem.annotations.objects.length }}
          </div>
          <div class="popover-entry">
            Subsets:
            <subset-badge
              v-for="subsetId in selectedItem.data_subsets"
              :key="`subset-badge-${subsetId}`"
              :data-subsets="dataSubsets"
              :subset-id="subsetId"
            />
          </div>
        </b-popover>
        <b-button
          :disabled="nextItemDisabled"
          class="header-btn"
          variant="link"
          size="lg"
          @click="onNextItem"
        >
          <fai icon="arrow-circle-right" />
        </b-button>
      </div>
      <div class="d-flex justify-content-end">
        <!--        <b-button @click=""-->
        <!--                  class="header-btn mb-2"-->
        <!--                  variant="link" size="lg">-->
        <!--          <fai icon="history"/>-->
        <!--        </b-button>-->

        <b-dropdown
          v-if="selectedItem"
          size="lg"
          variant="link"
          toggle-class="header-btn text-decoration-none"
          no-caret
          class="mb-2"
          right
        >
          <template #button-content>
            <fai icon="download" class="header-btn" />
          </template>
          <b-dropdown-item @click="onDownloadData">
            {{ selectedItem.data_type }}
          </b-dropdown-item>
          <b-dropdown-item @click="onDownloadAnnotations"> annotations</b-dropdown-item>
        </b-dropdown>

        <b-button
          :disabled="!unsavedChanges"
          class="header-btn mb-2"
          variant="link"
          size="lg"
          @click="saveAnnotations"
        >
          <b-spinner v-if="saving" small class="spinner-btn-offset" />
          <fai v-else icon="save" />
        </b-button>

        <!--        <b-button @click="onClose"-->
        <!--                  class="close-btn no-btn-outline mb-2"-->
        <!--                  variant="link" size="lg">-->
        <!--          <fai icon="times"/>-->
        <!--        </b-button>-->
      </div>
    </div>

    <!-- MAIN -->
    <div class="annotation-overlay-main">
      <div v-if="selectedItem && selectedItem.data_type === 'video'" class="pl-3 pr-3 pb-3 h-100">
        <video
          :key="`video-canvas-${selectedItem.id}`"
          ref="itemVideo"
          width="100%"
          controls
          class="h-100"
        >
          <source :src="selectedItem.data" />
        </video>
      </div>
      <template v-else>
        <div v-show="showPointCloud3d" v-if="pointCloudMode" class="pl-3 pr-3 pb-3 h-100">
          <point-cloud-canvas
            :key="`point-cloud-canvas-${selectedItem.id}`"
            ref="itemPointCloud"
            :dataset-item="selectedItem"
          />
        </div>
        <annotation-tool-canvas
          ref="annotationToolCanvas"
          :dataset-item="selectedItem"
          :class-labels="detectionClassLabelMap"
          :selected-label="selectedClassLabels.length > 0 ? selectedClassLabels[0] : -1"
          :oriented-boxes="orientedBoxes"
          :show-box-orientation="showBoxOrientation"
          :view-only="viewOnly"
          :show-cross-hair="showCrossHair"
          :active="active"
          :range-rgb-image-blending="rangeRgbImageBlending"
          :brightness="brightness"
          :contrast="contrast"
          :gamma="gamma"
          @select-object="onSelectObject"
          @annotations-update="onAnnotationsUpdate"
          @image-update="onImageUpdate"
          @save="saveAnnotations"
          @next="onNextItem"
          @esc="onClose"
          @loaded="loading = false"
          @load-image-start="imageLoading = true"
          @load-image-error="imageLoading = false"
          @class-label-changed="selectedClassLabels = [$event]"
        />
      </template>
    </div>

    <!-- LEFT -->
    <div class="annotation-overlay-left">
      <div class="d-flex flex-column h-100">
        <div ref="itemViewerWrapper" class="scroll-box flex-grow-1" @scroll="onScrollItemViewer">
          <!--          <transition name="fade-delayed">-->
          <!--            <loading-overlay v-if="itemsLoading"></loading-overlay>-->
          <!--          </transition>-->
          <item-viewer
            ref="itemViewer"
            :dataset-items="datasetItems"
            :item-size="itemViewer.itemSize"
            :selection-rectangle-enabled="false"
            :show-status="true"
            :show-filename="true"
            :show-image-labels="false"
            :show-objects="true"
            :show-subsets="false"
            :show-timestamp="false"
            @click="onClickItem"
            :timezone="'UTC'"
            @scroll-adjustment-required="onScrollAdjustmentRequired"
            :padding="0"
            :padding-factor="0"
            :outer-padding="0.375"
            :loading="itemsLoading"
          />
        </div>
      </div>
    </div>

    <!-- RIGHT -->
    <div class="annotation-overlay-right">
      <div class="d-flex flex-column h-100">
        <div class="col-right-heading pb-1">Status</div>
        <div class="mb-2 pl-1 pr-1 pl-3 pr-3">
          <b-form-select
            v-model="selectedStatus"
            :options="statusOptions"
            size="sm"
            @change="numberOfChanges++"
          />
        </div>

        <template v-if="!$_.isEmpty(dataset.attributes) && selectedItem">
          <div class="col-right-heading pt-1">Attributes</div>
          <form-generator
            :key="`form-generator-attributes-${selectedItem.id}`"
            :value="
              selectedItem && selectedItem.annotations
                ? selectedItem.annotations.attributes
                : undefined
            "
            :fields="dataset.attributes"
            :form-group-attributes="{}"
            size="sm"
            class="pl-3 pr-3 pb-1 pt-1"
            @input="onAttributeInput"
          />
        </template>

        <template v-if="imageClassLabels.length > 0">
          <div class="col-right-heading pb-1">Image Classes</div>
          <class-input
            :selected-class-labels="selectedImageClassLabels"
            :available-class-labels="imageClassLabels"
            class="pl-3 pr-3"
            @change="onImageClassesChanged"
          />
        </template>

        <template
          v-if="
            detectionClassLabels.length > 0 && selectedItem && selectedItem.data_type !== 'video'
          "
        >
          <div class="mt-2 pb-1">
            <div class="col-right-heading">Object Classes</div>
            <class-label-selection
              :class-labels="detectionClassLabels"
              :selected-class-labels="selectedClassLabels"
              visibility-toggle
              class="pl-2 mr-2"
              @click-class-label="changeClassLabel"
              @toggle-visibility-class-label="toggleShow"
            />
          </div>

          <div class="col-right-heading mt-2 pb-1">Objects</div>
          <div class="objects-table flex-grow-1 ml-3 mr-2 pr-2">
            <small>
              <b-table
                id="objects-table"
                hover
                :fields="objectsTableFields"
                :items="objectsTableItems"
                show-empty
                empty-text="objects will appear here"
                small
                @row-clicked="selectObjectById"
              />
            </small>
          </div>
        </template>
      </div>
    </div>

    <!-- TOOLBAR -->
    <div
      v-if="selectedItem && selectedItem.data_type !== 'video'"
      class="annotation-overlay-toolbar"
    >
      <b-button
        v-b-tooltip.hover
        :title="showCrossHair ? 'deactivate support lines' : 'activate support lines'"
        :class="{ 'toolbar-btn-active': showCrossHair }"
        variant="outline-primary"
        class="toolbar-btn pl-2 pr-2"
        @click="showCrossHair = !showCrossHair"
      >
        <svg height="20px" width="20px">
          <line x1="0" x2="100%" y1="50%" y2="50%" class="crosshairs-line" />
          <line x1="50%" x2="50%" y1="0%" y2="100%" class="crosshairs-line" />
        </svg>
      </b-button>
      <b-button
        v-b-tooltip.hover
        class="toolbar-btn ml-1"
        variant="outline-primary"
        :class="{ 'toolbar-btn-active': showBoxOrientation }"
        title="show/hide box orientation"
        @click="showBoxOrientation = !showBoxOrientation"
      >
        <fai icon="compass" />
      </b-button>
      <b-button
        v-b-tooltip.hover
        class="toolbar-btn ml-1"
        variant="outline-primary"
        title="duplicate [CTRL+D]"
        @click="duplicate"
      >
        <fai icon="clone" />
      </b-button>
      <b-button
        v-b-tooltip.hover
        class="toolbar-btn ml-1"
        variant="outline-primary"
        title="copy [CTRL+C]"
        @click="copy"
      >
        <fai icon="copy" />
      </b-button>
      <b-button
        v-b-tooltip.hover
        class="toolbar-btn ml-1"
        variant="outline-primary"
        title="paste [CTRL+V]"
        @click="paste"
      >
        <fai icon="paste" />
      </b-button>
      <b-button
        v-b-tooltip.hover
        class="toolbar-btn ml-1"
        variant="outline-primary"
        title="track objects from the previous image"
        :disabled="trackingAvailable"
        @click="trackObjects"
      >
        <b-spinner v-if="trackingLoading" small />
        <fai v-else icon="angle-double-right" />
      </b-button>

      <b-dropdown
        v-if="selectedItem && autoAiAvailable"
        ref="autoAnnotationDropdown"
        toggle-class="toolbar-btn"
        variant="outline-primary"
        no-caret
      >
        <template #button-content>
          <fai icon="brain" />
        </template>

        <b-dropdown-item
          v-for="(autoAiModel, i) in autoAIModels"
          :key="`auto-ai-model-${i}`"
          @click="onClickAutoAnnotation(autoAiModel)"
        >
          <div class="d-flex align-content-center justify-content-between">
            <div class="mr-3">
              {{ autoAiModel.parameters.name }}
            </div>
            <b-button
              size="sm"
              variant="danger"
              class="tiny-btn"
              @click.stop="onClickDeleteAutoAnnotation(i)"
            >
              <fai icon="trash" />
            </b-button>
          </div>
        </b-dropdown-item>

        <b-dropdown-item @click="onClickAddAutoAnnotation"> Add AI</b-dropdown-item>
      </b-dropdown>

      <b-dropdown
        v-if="selectedItem && viewSettingsAvailable"
        ref="viewSettingsDropdown"
        toggle-class="toolbar-btn"
        variant="outline-primary"
        no-caret
      >
        <template #button-content>
          <fai icon="sliders-h" />
        </template>

        <b-dropdown-form class="mb-0 pb-0">
          <b-form-group label="Brightness" size="sm">
            <b-form-input
              v-model="brightness"
              type="range"
              min="-1"
              max="1"
              step="0.001"
              size="sm"
            />
          </b-form-group>

          <b-form-group label="Contrast" size="sm">
            <b-form-input v-model="contrast" type="range" min="0" max="4" step="0.001" size="sm" />
          </b-form-group>

          <b-form-group label="Gamma" size="sm">
            <b-form-input v-model="gamma" type="range" min="0" max="2" step="0.001" size="sm" />
          </b-form-group>

          <b-form-group v-if="pointCloudMode" label="RGB / Range" size="sm">
            <b-form-input
              v-model="rangeRgbImageBlending"
              type="range"
              min="0"
              max="1"
              step="0.001"
              size="sm"
            />
          </b-form-group>

          <b-button
            v-if="pointCloudMode"
            variant="light"
            size="sm"
            block
            class="mt-3"
            @click="onTogglePointCloud3d"
          >
            toggle 2D / 3D
          </b-button>

          <b-button variant="light" size="sm" block class="mt-3" @click="resetImageAdjustment">
            reset
          </b-button>
        </b-dropdown-form>
      </b-dropdown>
    </div>

    <auto-annotation-modal
      v-if="viewModelsPermission"
      network-type="detection"
      :show="showAutoAnnotationModal"
      @save="onSaveAutoAnnotationModel"
      @show="showAutoAnnotationModal = $event"
    />
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'

import statusOptions from '@/options'
import { getColorForCSS, getTextColorForCSS } from '@/colors'
import AnnotationToolCanvas from '@/components/AnnotationToolCanvas'
import ClassLabelSelection from '@/components/class-labels/ClassLabelSelection'
import ItemViewer from '@/components/item-viewer/ItemViewer'
import SubsetBadge from '@/components/item-viewer/SubsetBadge'
import ClassInput from '@/components/ClassInput'
import FormGenerator from '@/components/form-generator/FormGenerator'
import { ctrlOrCmdPressed } from '@/keyboardShortcuts'
import PointCloudCanvas from '@/components/annotation/PointCloudCanvas'
import AutoAnnotationModal from '@/components/modals/AutoAnnotationModal'
import LoadingOverlayFullscreen from '@/components/LoadingOverlayFullscreen'

export default {
  name: 'AnnotationOverlay',
  components: {
    LoadingOverlayFullscreen,
    AutoAnnotationModal,
    PointCloudCanvas,
    FormGenerator,
    ClassInput,
    SubsetBadge,
    ClassLabelSelection,
    AnnotationToolCanvas,
    ItemViewer,
  },
  props: {
    selectedItem: Object,
    datasetItems: Array,
    dataSubsets: Object,
    itemsLoading: Boolean,
    dataset: Object,
    active: Boolean,
  },
  data() {
    return {
      imageLoading: false,

      itemViewer: {
        itemSize: 1.0,
      },

      viewNetworkExplorerPermission: false,

      trackingLoading: false,
      leftLoading: false,
      items: [],
      itemsPage: {
        current: 1,
        last: 1,
        size: 10,
      },
      itemSearchString: '',
      lastItemSearchString: '',
      filteredItemsLength: 0,

      canvasLoading: false,
      selectedItemId: -1,
      selectedItemListIndex: -1,
      datasetId: '',

      viewOnly: true,
      showCrossHair: false,
      showBoxOrientation: false,

      saving: false,
      numberOfChanges: 0,

      imageScale: 1,
      imageWidth: 1,
      imageHeight: 1,

      selectedVersion: -1,
      versions: [],

      objectsTableFields: [
        { key: 'id', label: 'id', sortable: true },
        { key: 'label', label: 'label', sortable: true },
        { key: 'x', label: 'x [px]', sortable: true },
        { key: 'y', label: 'y [px]', sortable: true },
        { key: 'width', label: 'width [px]', sortable: true },
        { key: 'height', label: 'height [px]', sortable: true },
      ],

      selectedImageClassLabels: [],
      detectionClassLabels: [],
      detectionClassLabelMap: {},
      selectedClassLabels: [],
      classLabelsPageSize: 16,
      classLabelsPage: 1,
      classLabelsPaginationNeeded: true,

      annotations: { objects: [], classes: [] },

      selectedStatus: '',
      statusOptions: statusOptions,

      statusFilters: ['uploaded', 'annotated', 'reviewed', 'ignored'],
      lastStatusFilters: ['uploaded', 'annotated', 'reviewed', 'ignored'],

      autoAnnotationsAvailable: true,
      autoAnnotationConfigs: [],
      modelData: {},

      autoAIModels: [],
      inference: undefined,
      showAutoAnnotationModal: false,
      inferenceInProgress: false,

      showPointCloud3d: false,
      rangeRgbImageBlending: 0.5,
      brightness: 0.0,
      contrast: 1.0,
      gamma: 1.0,
    }
  },
  computed: {
    ...mapState({
      classLabels: (state) => state.classLabels,
      classLabelMap: (state) => state.classLabelMap,
      server: (state) => state.server,
    }),
    ...mapGetters(['viewModelsPermission']),
    imageClassLabels() {
      if (this.dataset && this.dataset.classification && this.dataset.classification_class_labels) {
        return _.cloneDeep(this.dataset.classification_class_labels)
      }
      return []
    },
    previousItemDisabled() {
      return (
        !this.selectedItem ||
        !this.datasetItems ||
        this.datasetItems.length === 0 ||
        this.selectedItem.id === this.datasetItems[0].id
      )
    },
    nextItemDisabled() {
      return (
        !this.selectedItem ||
        !this.datasetItems ||
        this.datasetItems.length <= 1 ||
        this.selectedItem.id === this.datasetItems[this.datasetItems.length - 1].id
      )
    },
    orientedBoxes() {
      if (this.dataset) {
        return this.dataset.object_detection_orientation
      }
      return true
    },
    objectsTableItems: function () {
      let result = []
      if (this.annotations.objects) {
        for (let i = 0; i < this.annotations.objects.length; i++) {
          let o = this.annotations.objects[i]

          let rowVariant = 'default'

          if (o.selected) {
            rowVariant = 'secondary'
          }
          result.push({
            id: o.id,
            label: this.detectionClassLabelMap[o.label]
              ? this.detectionClassLabelMap[o.label].name
              : '?',
            x: (o.x * this.imageScale).toFixed(0),
            y: (o.y * this.imageScale).toFixed(0),
            // area: (o.width * this.imageWidth * o.height * this.imageHeight).toFixed(0),
            width: (o.width * this.imageScale).toFixed(0),
            height: (o.height * this.imageScale).toFixed(0),
            _rowVariant: rowVariant,
          })
        }
      }
      return result
    },

    availableVersions: function () {
      let result = []
      for (let i = 0; i < this.versions.length; i++) {
        let version = this.versions[i]

        let dateString = moment(String(version['date_created'])).format('L, HH:mm:ss')

        result.push({ value: version['id'], text: dateString })
      }

      return result
    },
    unsavedChanges() {
      return this.numberOfChanges > 0
    },
    trackingAvailable() {
      if (this.trackingLoading) {
        return true
      }

      if (this.datasetItems.length < 2) {
        return true
      }

      if (this.datasetItems.length > 0) {
        return this.datasetItems[0].id === this.selectedItemId
      }

      return false
    },
    autoAiAvailable() {
      return (
        this.viewModelsPermission &&
        this.selectedItem &&
        this.selectedItem.data_type === 'image' &&
        this.dataset &&
        this.dataset.object_detection &&
        this.dataset.object_detection_class_labels &&
        this.dataset.object_detection_class_labels.length > 0
      )
    },
    pointCloudMode() {
      return this.selectedItem && this.selectedItem.data_type === 'point_cloud'
    },
    viewSettingsAvailable() {
      return this.selectedItem && ['image', 'point_cloud'].includes(this.selectedItem.data_type)
    },
  },
  watch: {
    selectedItem(newItem) {
      if (newItem) {
        this.selectItem(newItem, true)
      }
    },
    dataset: {
      deep: true,
      handler() {
        this.initializeDataset()
      },
    },
    numberOfChanges() {
      if (this.numberOfChanges > 0) {
        window.onbeforeunload = function () {
          return 'Are you sure you want to leave?'
        }
      } else {
        window.onbeforeunload = undefined
      }
    },
  },
  mounted() {
    this.resetImageAdjustment()
    window.addEventListener('keydown', this.onDocumentKeyDown, false)
    this.initializeDataset()
    if (this.selectedItem) {
      this.selectItem(this.selectedItem, true)
    }
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.onDocumentKeyDown)
  },

  destroyed() {
    window.onbeforeunload = function () {}
  },
  methods: {
    resetImageAdjustment() {
      this.gamma = 1.0
      this.contrast = 1.0
      this.brightness = 0.0
      this.rangeRgbImageBlending = 0.5
    },
    initializeDataset() {
      let detectionClassLabels = []
      let detectionClassLabelMap = []

      if (
        this.dataset &&
        this.dataset.object_detection &&
        this.dataset.object_detection_class_labels &&
        this.dataset.object_detection_class_labels.length > 0
      ) {
        this.viewOnly = false
        this.showCrossHair = true
        for (const c of this.dataset.object_detection_class_labels) {
          let cb = {
            id: c.id,
            name: c.name,
            active: false,
            show: true,
          }
          detectionClassLabels.push(cb)
          detectionClassLabelMap[c.id] = cb
        }
      }

      if (detectionClassLabels.length > 0) {
        this.selectedClassLabels = [detectionClassLabels[0].id]
      } else {
        this.selectedClassLabels = []
      }
      this.detectionClassLabels = detectionClassLabels
      this.detectionClassLabelMap = detectionClassLabelMap
    },
    onScrollItemViewer(event) {
      this.$emit('scroll-item-viewer', event)
    },
    onClose() {
      this.openLeaveModalWithCallback(() => {
        window.onbeforeunload = undefined
        this.$emit('close')
      })
    },
    onClickItem({ item, evt }) {
      this.openLeaveModalWithCallback(() => this.$emit('click-item', { item, evt }))
    },
    onPreviousItem() {
      this.openLeaveModalWithCallback(() => this.$emit('previous-item'))
    },
    onNextItem() {
      this.openLeaveModalWithCallback(() => this.$emit('next-item'))
    },
    onSelectObject(objects) {
      this.annotations.objects = objects
    },
    onAnnotationsUpdate(objects) {
      this.annotations.objects = objects
      this.numberOfChanges++
    },
    onImageUpdate(evt) {
      this.imageScale = evt.scale
      this.imageWidth = evt.width
      this.imageHeight = evt.height
      this.imageLoading = false
    },
    onScrollAdjustmentRequired({ scrollDifference }) {
      this.$refs.itemViewerWrapper.scrollBy(0, scrollDifference)
    },
    onImageClassesChanged(newClasses) {
      this.selectedImageClassLabels = newClasses
      this.numberOfChanges++
    },
    onAttributeInput(attributes, initial) {
      if (!initial) {
        this.numberOfChanges++
      } else if (!_.isEqual(this.annotations.attributes, attributes)) {
        this.numberOfChanges++
      }
      this.annotations.attributes = attributes
    },
    onDownloadData() {
      if (this.selectedItem) {
        const a = document.createElement('a')
        a.setAttribute('download', 'download')
        a.setAttribute('target', '_blank')
        a.href = this.selectedItem.data
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    onDownloadAnnotations() {
      if (this.selectedItem) {
        const data = new Blob([JSON.stringify(this.selectedItem.annotations, null, 2)], {
          type: 'text/plain;charset=utf-8',
        })
        const url = window.URL || window.webkitURL
        const a = document.createElement('a')
        let split = this.selectedItem.name.split('/')
        let name = split[split.length - 1]
        split = name.split('.')
        split.pop()
        name = split.join('.')
        a.download = `${name}.json`
        a.href = url.createObjectURL(data)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    onDocumentKeyDown(event) {
      let keyCode = event.which
      if (keyCode == 83 && ctrlOrCmdPressed(event)) {
        // CTRL+S (save)
        event.preventDefault()
        this.saveAnnotations()
      } else if (keyCode == 32 && ctrlOrCmdPressed(event)) {
        // CTRL+SPACE (next)
        event.preventDefault()
        this.onNextItem()
      } else if (keyCode == 27) {
        // ESC
        event.preventDefault()
        this.onClose()
      } else if (keyCode == 32) {
        // SPACE
        if (this.selectedItem && this.selectedItem.data_type === 'video') {
          const video = this.$refs.itemVideo
          if (video) {
            if (video.paused == true) {
              video.play()
            } else {
              video.pause()
            }
          }
        }
      }
    },
    getTagStyle(classId) {
      return `color: ${getTextColorForCSS(classId)}; background-color: ${getColorForCSS(classId)};`
    },
    selectItem(newItem, scroll) {
      this.numberOfChanges = 0
      if (scroll) {
        this.$nextTick(() =>
          this.$refs.itemViewer.scrollToItem(
            newItem.id,
            this.$refs.itemViewerWrapper.scrollTop,
            this.$refs.itemViewerWrapper.clientHeight
          )
        )
      }

      if (newItem.annotations) {
        this.annotations = _.cloneDeep(newItem.annotations)
      } else {
        this.annotations = {}
      }

      if (!this.annotations.objects) {
        this.$set(this.annotations, 'objects', [])
      }

      if (!this.annotations.classes) {
        this.$set(this.annotations, 'classes', [])
      }

      this.selectedImageClassLabels = []
      for (let i = 0; i < this.annotations.classes.length; i++) {
        let c = this.annotations.classes[i]

        let name = c
        let cc = this.classLabelMap[c]
        if (cc) {
          name = cc.name
        }

        this.selectedImageClassLabels.push({
          id: c,
          name: name,
        })
      }

      this.selectedStatus = newItem.status
    },
    duplicate() {
      this.$refs.annotationToolCanvas.duplicate()
    },
    copy() {
      this.$refs.annotationToolCanvas.copy()
    },
    paste() {
      this.$refs.annotationToolCanvas.paste()
    },
    saveAnnotations() {
      if (this.saving) {
        return new Promise((resolve) => {
          resolve(false)
        })
      }

      if (this.loading || !this.unsavedChanges) {
        return new Promise((resolve) => {
          resolve(false)
        })
      }

      this.saving = true

      let annotations = { objects: [], classes: [] }

      if (this.annotations.attributes) {
        annotations.attributes = this.annotations.attributes
      }

      for (let i = 0; i < this.selectedImageClassLabels.length; i++) {
        let c = this.selectedImageClassLabels[i]
        annotations.classes.push(c.id)
      }

      for (let i = 0; i < this.annotations.objects.length; i++) {
        let o = this.annotations.objects[i]
        annotations.objects.push({
          id: o.id,
          label: o.label,
          x: o.x,
          y: o.y,
          width: o.width,
          height: o.height,
          orientation: o.orientation,
        })
      }

      const self = this

      return this.$axios({
        method: 'patch',
        url: `/api/dataset-items/${this.selectedItem.id}/`,
        data: { annotations: annotations, status: this.selectedStatus },
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
        .then((response) => {
          self.$emit('selected-item-changed', response.data)
          self.numberOfChanges = 0
        })
        .catch(() => {
          this.$bvToast.toast('Could not save annotations.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
        })
        .finally(() => {
          this.saving = false
          // this.loadVersions()
        })
    },
    openLeaveModal() {
      if (this.trackingLoading) {
        this.$bvToast.toast('Loading tracked objects ongoing.', {
          title: 'Warning',
          variant: 'warning',
          autoHideDelay: 4000,
          solid: true,
        })
        return new Promise((resolve) => {
          resolve(null)
        })
      }

      if (this.unsavedChanges) {
        return this.$bvModal.msgBoxConfirm('Do you want to leave, you have unsaved changes.', {
          title: 'You have unsaved changes.',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'success',
          okTitle: 'save and continue',
          cancelVariant: 'light',
          cancelTitle: 'continue without saving',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        return new Promise((resolve) => {
          resolve(false)
        })
      }
    },
    openLeaveModalWithCallback(callback) {
      this.openLeaveModal()
        .then((value) => {
          if (value == null) {
            // do nothing (cancel was clicked)
          } else if (value) {
            this.saveAnnotations().finally(() => {
              callback()
            })
          } else {
            callback()
          }
        })
        .catch(() => {
          // An error occurred
        })
    },
    changeClassLabel(classLabel) {
      this.selectedClassLabels = [classLabel.id]
    },

    toggleShow(classLabel) {
      this.$set(classLabel, 'show', !classLabel.show)
    },

    selectObjectById(obj) {
      this.$refs.annotationToolCanvas.selectObjectById(obj.id)
    },

    trackObjects() {
      this.trackingLoading = true

      if (!this.selectedItem) return

      let idx = -1
      for (let i = 1; i < this.datasetItems.length; i++) {
        const item = this.datasetItems[i]
        if (item.id === this.selectedItem.id) {
          idx = i - 1
          break
        }
      }

      if (idx === -1) {
        this.trackingLoading = false
        return
      }

      let itemSource = this.datasetItems[idx]
      let itemDestination = this.selectedItem

      if (itemSource.data_type === 'image' && itemDestination.data_type === 'image') {
        // in case both items are images, we can use the tracking endpoint of DLDS
        this.$axios
          .get('/api/tracking/objects', {
            params: {
              item_id_source: itemSource.id,
              item_id_destination: itemDestination.id,
            },
          })
          .then((response) => {
            let trackedObjects = response.data.tracked_objects

            let nextId = 0
            if (this.annotations.objects.length > 0) {
              nextId = this.annotations.objects[this.annotations.objects.length - 1].id + 1
            }

            for (let i = 0; i < trackedObjects.length; i++) {
              let o = trackedObjects[i]

              this.annotations.objects.push({
                id: nextId + i,
                label: o.label,
                x: o.x,
                y: o.y,
                width: o.width,
                height: o.height,
                orientation: o.orientation,
              })
            }

            this.$refs.annotationToolCanvas.setAnnotations(this.annotations)
          })
          .catch(() => {
            this.$bvToast.toast('Could not get tracked objects.', {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 4000,
              solid: true,
            })
          })
          .finally(() => {
            this.trackingLoading = false
          })
      } else {
        // in other cases, we simply copy the annotations of the previous item
        let objects =
          itemSource.annotations && itemSource.annotations.objects
            ? itemSource.annotations.objects
            : []
        let nextId = 0
        if (this.annotations.objects.length > 0) {
          nextId = this.annotations.objects[this.annotations.objects.length - 1].id + 1
        }
        for (let i = 0; i < objects.length; i++) {
          const o = objects[i]
          this.annotations.objects.push({
            id: nextId + i,
            label: o.label,
            x: o.x,
            y: o.y,
            width: o.width,
            height: o.height,
            orientation: o.orientation,
          })
        }
        this.$refs.annotationToolCanvas.setAnnotations(this.annotations)
        this.trackingLoading = false
      }
    },
    onClickAutoAnnotation(autoAiModel) {
      if (!autoAiModel.inference) {
        throw new Error('No inference instance in auto ai model.')
      } else {
        this.inferenceInProgress = true
        const self = this
        let img = new Image()
        img.crossOrigin = 'anonymous'
        img.addEventListener('load', () => {
          autoAiModel.inference
            .run(img, { confidenceThreshold: autoAiModel.parameters.confidenceThreshold })
            .then((detections) => {
              let nextId = 0
              if (self.annotations.objects.length > 0) {
                nextId = self.annotations.objects[self.annotations.objects.length - 1].id + 1
              }

              const classLabels = []
              for (const classLabel of self.dataset.object_detection_class_labels) {
                classLabels.push(classLabel.id)
              }

              const scaleX = Math.min(img.width / img.height, 1.0)
              const scaleY = Math.min(img.height / img.width, 1.0)
              for (let i = 0; i < detections.length; i++) {
                let d = detections[i]

                if (classLabels.includes(d.label)) {
                  self.annotations.objects.push({
                    id: nextId + i,
                    label: d.label,
                    x: d.x * scaleX,
                    y: d.y * scaleY,
                    width: d.width * scaleX,
                    height: d.height * scaleY,
                    orientation: d.orientation,
                  })
                }
              }
              self.$refs.annotationToolCanvas.setAnnotations(self.annotations)
              self.inferenceInProgress = false
            })
            .catch((error) => {
              console.error(error)
              self.inferenceInProgress = false
              self.$bvToast.toast('An unexpected error occurred during AI inference.', {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 4000,
                solid: true,
              })
            })
        })
        img.addEventListener('error', () => {
          self.inferenceInProgress = false
          self.$bvToast.toast(
            'An unexpected error occurred while loading image for AI inference.',
            {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 4000,
              solid: true,
            }
          )
        })

        this.$nextTick(() => {
          img.src = this.selectedItem.data
        })
      }
    },
    onClickAddAutoAnnotation() {
      this.showAutoAnnotationModal = true
    },
    onClickDeleteAutoAnnotation(i) {
      this.autoAIModels.splice(i, 1)
      this.$refs.autoAnnotationDropdown.hide(true)
    },
    onClickEditAutoAnnotation() {
      this.$refs.autoAnnotationDropdown.hide(true)
    },
    onSaveAutoAnnotationModel(autoAiModel) {
      this.autoAIModels.push(autoAiModel)
    },
    onTogglePointCloud3d() {
      this.showPointCloud3d = !this.showPointCloud3d
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';

.annotation-overlay {
  position: fixed;
  top: $navbar-height;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;

  width: 100%;

  @extend .full-height-wrapper;
  //height: 100vh;
  //height: 100vh;

  display: grid;
  grid-template-columns: calc(min(16.6667%, 250px)) auto calc(max(16.6667%, 250px));
  grid-template-rows: 50px auto;
  grid-template-areas:
    'header header header'
    'left main right';
  column-gap: 0;

  background-color: $white;
  //background-color: rgba(255, 255, 255, 0.7);
}

.annotation-overlay-header {
  grid-area: header;
}

.annotation-overlay-left {
  position: relative;

  grid-area: left;

  scrollbar-width: thin;
  overflow: hidden;

  @extend .shadow-xl;
  margin-left: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 0.5rem;
  border: $gray-300 1px solid;
  background-color: $white;
}

.annotation-overlay-main {
  grid-area: main;
  min-height: auto;
  max-height: 100%;
  overflow: hidden;
}

.annotation-overlay-right {
  grid-area: right;
  overflow: auto;
  scrollbar-width: thin;

  @extend .shadow-xl;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 0.5rem;
  border: $gray-300 1px solid;
  background-color: $white;
}

.objects-table {
  overflow-y: auto;
  scrollbar-width: thin;
  min-height: 300px;
}

.item-viewer-wrapper {
}

.header-btn {
  @extend .no-btn-outline;
  color: $primary;
}

.close-btn {
  color: $gray-700;
  font-size: larger;
}

.file-name {
  font-weight: bold;
  font-size: medium;
}

.fade-delayed-enter-active,
.fade-delayed-leave-active {
  transition: opacity 0.3s;
  transition-delay: 0.1s;
}

.fade-delayed-enter,
.fade-delayed-leave-to {
  opacity: 0;
}

.item-popover {
  font-weight: 500;
  max-width: 400px;
}

.popover-entry {
  padding-bottom: 0.125rem;
}

.spinner-btn-offset {
  margin-bottom: 0.125rem;
}

.col-right-heading {
  font-weight: 600;

  padding: 0.25rem 1rem 0.125rem;

  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  color: $gray-900;
}

.annotation-overlay-toolbar {
  position: fixed;
  @extend .shadow-xl;
  background-color: $white;

  bottom: 20px;
  left: 50%;

  margin-left: -170px;
  border-radius: 6px;

  //height: 40px;
  max-width: 340px;

  padding: 0.25rem;
}

.crosshairs-line {
  stroke-width: 1.5px;
}
</style>
