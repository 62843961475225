<template>
  <div>
    <b-form-group>
      <template #label>
        <slot />
        <b-form-checkbox v-model="allSelected" :indeterminate="indeterminate" @change="toggleAll">
          {{ allSelected ? 'Un-select All' : 'Select All' }}
        </b-form-checkbox>
      </template>

      <b-form-checkbox-group
        :id="$id('checkboxes')"
        v-model="selected"
        :options="options"
        name="checkboxes"
        stacked
      />
    </b-form-group>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CheckboxAllSelect',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectAllInitially: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      allSelected: false,
      indeterminate: false,
    }
  },
  watch: {
    value(newValue) {
      this.selected = newValue
    },
    options(newOptions, oldOptions) {
      if (!_.isEqual(newOptions, oldOptions)) {
        if (this.selectAllInitially) {
          this.toggleAll(true)
        }
      }
    },
    selected(newValue) {
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.options.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
      this.$emit('input', this.selected)
    },
  },
  mounted() {
    this.selected = this.value
    if (this.selectAllInitially) {
      this.toggleAll(true)
    }
  },
  methods: {
    toggleAll(checked) {
      this.selected = checked ? this.options.slice() : []
    },
  },
}
</script>
