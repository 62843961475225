<template>
  <b-form-group
    v-show="!field.hidden"
    v-bind="formGroupAttributes"
    :label="field.display_name"
    :description="field.description"
    :label-size="size"
  >
    <b-form-select
      :id="$id(field.name)"
      :disabled="plaintext || field.editable === false"
      :value="value"
      @input="onInput"
      :options="field.options"
      :size="size"
    />
  </b-form-group>
</template>

<script>
export default {
  name: 'SelectionDropdown',
  props: ['value', 'field', 'plaintext', 'size', 'formGroupAttributes'],
  data() {
    return {}
  },

  mounted() {
    if (this.value === undefined && this.field.options.length > 0) {
      this.onInput(this.field.options[0], true)
    }
  },

  methods: {
    onInput(event, initial) {
      this.$emit('input', event, initial)
    },
  },
}
</script>
