<template>
  <b-navbar class="sticky-top pt-0 pb-0" toggleable="md">
    <b-navbar-toggle target="nav_collapse" />
    <b-navbar-brand>
      <img class="logo-image no-select" src="@/assets/logo.png" />
    </b-navbar-brand>
    <b-collapse id="nav_collapse" is-nav>
      <b-navbar-nav
        v-if="
          !($_.isEmpty(user.license) || !user.license.valid) &&
          user.organization &&
          user.organization.license_valid
        "
        class="no-select"
      >
        <b-nav-item
          v-if="viewDatasetPermission"
          active-class="nav-active"
          class="p-0"
          to="/dataset-explorer"
        >
          Datasets
        </b-nav-item>
        <b-nav-item v-if="viewModelsPermission" active-class="nav-active" to="/model-explorer">
          AI Models
        </b-nav-item>
        <!--        <b-nav-item v-if="viewJobExplorerPermission" to="/job-explorer">Trainings</b-nav-item>-->
        <!--        <b-nav-item to="/device-explorer">Devices</b-nav-item>-->
        <b-nav-item
          v-if="viewModelsPermission && server.enableCloudDeployments"
          active-class="nav-active"
          to="/deployment-explorer"
        >
          Deployments
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <div v-if="viewModelsPermission" class="mr-2">
          <b-nav-item id="operations-button">
            <fai class="text-primary" icon="business-time" />
          </b-nav-item>
          <b-popover
            id="operations-popover"
            ref="operationsPopover"
            :delay="0"
            class="mw-300px"
            no-fade
            placement="auto"
            target="operations-button"
            triggers="click blur"
          >
            <div>
              <div class="text-secondary font-weight-700 mb-1 no-select">TRAINING</div>
              <b-list-group v-if="jobs && jobs.length > 0" class="mb-1" flush>
                <b-list-group-item
                  v-for="job in jobs"
                  :key="`job-entry-${job.id}`"
                  href="#"
                  class="d-flex justify-content-between"
                  @click="onClickJob(job)"
                >
                  <div class="font-weight-500 mr-2">
                    {{ job.model.name }}
                  </div>
                  <job-status-badge :status="job.status" />
                </b-list-group-item>
              </b-list-group>
              <div v-else class="text-center w-100 text-secondary no-select p-3">
                no training scheduled
              </div>
              <div />
            </div>
          </b-popover>
        </div>

        <b-nav-item-dropdown v-if="user.username !== ''" right :text="user.username">
          <b-dropdown-item to="/profile"> profile</b-dropdown-item>
          <b-dropdown-item
            v-if="user.organization && user.organization.is_admin"
            to="/organization"
          >
            organization
          </b-dropdown-item>
          <b-dropdown-divider v-if="viewDatasetPermission || viewModelsPermission" />
          <b-dropdown-item v-if="viewDatasetPermission" to="/class-labels">
            class labels
          </b-dropdown-item>
          <b-dropdown-item v-if="viewModelsPermission" to="/workers">
            training workers
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item @click="signout"> sign out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import JobStatusBadge from '@/components/jobs/JobStatusBadge'
import _ from 'lodash'

export default {
  name: 'TopNavigation',
  components: { JobStatusBadge },
  data() {
    return {}
  },
  computed: {
    ...mapState(['user', 'jobs']),
    ...mapGetters(['viewModelsPermission', 'viewDatasetPermission']),

    viewJobExplorerPermission() {
      let user = this.user
      if (user !== undefined && user.permissions !== undefined) {
        return user.permissions.includes('platform_api.view_job')
      }
      return false
    },
    server() {
      // check if the registration of new users and organizations is available on the server
      return this.$store.getters.server
    },
  },
  methods: {
    ...mapActions(['getModelDetails', 'openAiModal', 'setLoading', 'logout']),
    onClickJob(job) {
      // this.$router.push({path: 'model-explorer', query: {'model': job.model.id}})
      // :to="`/model-explorer?model=${job.model.id}`"
      this.openAiModal(undefined)
      this.getModelDetails({ model: job.model }).then((model) => {
        this.openAiModal(model)
      })
      this.$refs.operationsPopover.$emit('close')
    },
    async signout() {
      await this.setLoading(true)
      try {
        await this.logout()
      } catch (e) {
        console.error(e)
      }
      if (!_.isEmpty(this.$route.query)) {
        await this.$router.replace({
          query: {},
        })
      }
      await this.setLoading(false)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../custom';

.logo-image {
  max-height: 12px;
  margin: 0;
}

.navbar {
  min-height: 50px;
  background: $white;

  @extend .shadow-md;
}

.nav-item {
}

.nav-active {
  background: $gray-200;

  -webkit-transition: all 0.1s ease-in-out 0s;
  transition: all 0.1s ease-in-out 0s;
}
</style>
