<template>
  <div :class="{ 'border-radius-md': borderRadius }">
    <div
      :class="{ 'border-radius-md': borderRadius }"
      class="d-flex justify-content-between collapse-header"
      @click="view = !view"
    >
      <div :class="{ 'p-4': !small && !medium, 'p-3': medium, 'p-2': small, 'pl-3': small }">
        <slot name="header">
          <div v-if="small || medium" class="no-select font-weight-700">
            {{ title }}
          </div>
          <h5 v-else class="no-select m-0">
            <b>{{ title }}</b>
          </h5>
        </slot>
      </div>
      <div :class="{ 'p-4': !small && !medium, 'p-3': medium, 'p-2': small, 'pr-3': small }">
        <fai v-if="!view" icon="angle-down" />
        <fai v-if="view" icon="angle-up" />
      </div>
    </div>
    <b-collapse
      :id="collapseId"
      v-model="view"
      :accordion="accordion"
      :appear="false"
      :class="`${innerClass} ${noTransitionClass}`"
    >
      <slot />
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'CardCollapse',
  props: {
    collapseId: {
      default: 'collapse',
      type: String,
    },
    title: String,
    disabled: Boolean,
    small: Boolean,
    medium: Boolean,
    accordion: String,
    initiallyOpen: {
      default: false,
      type: Boolean,
    },
    borderRadius: {
      default: true,
      type: Boolean,
    },
    innerClass: {
      type: String,
      default: '',
    },
    noAnimation: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      view: false,
    }
  },

  computed: {
    noTransitionClass() {
      if (this.noAnimation) {
        if (this.view) {
          return 'no-transition'
        } else {
          return 'no-transition d-none'
        }
      }
      return ''
    },
  },
  watch: {
    view(newValue) {
      this.$emit('view', newValue)
    },
  },

  created() {
    this.view = this.initiallyOpen
  },

  mounted() {},
}
</script>

<style scoped lang="scss">
@import '../custom';

.collapse-header {
  &:hover {
    cursor: pointer;
    background-color: $blue-light;

    img {
      opacity: 0.8;
    }
  }
}

.no-transition {
  transition: none !important;
  //display: none;
}
</style>
