<template>
  <div>
    <div
      class="foreground d-flex justify-content-center align-items-center"
      :style="`z-index: ${zIndex + 1};`"
    >
      <slot />
    </div>
    <div class="background" :style="`z-index: ${zIndex};`" />
  </div>
</template>

<script>
export default {
  name: 'OverlayComponent',
  props: {
    light: Boolean,
    zIndex: {
      type: Number,
      default: 1500,
    },
  },
}
</script>

<style>
.foreground {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.background {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(2px);
}
</style>
