<template>
  <div>
    <loading-overlay-fullscreen v-if="loading" />

    <b-modal
      id="modal-filter-settings"
      ref="modalFilterSettings"
      v-model="showModal"
      size="lg"
      title="Filter Settings"
      @hidden="hidden"
      @ok="applySettings"
    >
      <div>
        <b-form-group
          v-if="showStatusFilter"
          id="group-status-filters-select"
          description="Show only items that have one of the selected status values."
          label="Status"
          label-for="status-filters-select"
        >
          <b-form-select
            id="status-filters-select"
            v-model="settings.status"
            :options="statusOptions"
            multiple
          />
        </b-form-group>

        <b-form-group
          v-if="imageClassOptions.length > 1"
          id="group-image-class-filter-select"
          description="Show only images that have the selected image class label."
          label="Image Class"
          label-for="image-class-filter-select"
        >
          <b-form-select
            id="image-class-filter-select"
            v-model="settings.imageClass"
            :options="imageClassOptions"
          />
        </b-form-group>

        <b-form-group
          v-if="objectClassOptions.length > 1"
          id="group-object-class-filter-select"
          description="Show only images that contains objects that have the selected class label."
          label="Object Class"
          label-for="object-class-filter-select"
        >
          <b-form-select
            id="object-class-filter-select"
            v-model="settings.objectClass"
            :options="objectClassOptions"
          />
        </b-form-group>

        <b-form-group
          id="group-data-subset-filter-select"
          description="Show only items that is part of one of the selected subset."
          label="Data Subset"
          label-for="data-subset-filter-select"
        >
          <b-form-select
            id="data-subset-filter-select"
            v-model="settings.dataSubset"
            :options="dataSubsetOptions"
          />
        </b-form-group>

        <b-form-group
          v-if="showTimeRangeFilter"
          id="group-datetime-from"
          description="Show only items that where created within the given date and time range."
          label="Time Range"
        >
          <date-time-picker
            key="filter-settings-modal-date-time-from"
            v-model="settings.dateTimeFrom"
            class="mb-2"
            label="from"
            size="md"
          />
          <date-time-picker v-model="settings.dateTimeTo" class="mb-2" label="to" size="md" />
          <!--          <date-time-picker label="from" size="md" class="mb-2"></date-time-picker>-->
          <!--          <date-time-picker label="to" size="md" class="mb-2"></date-time-picker>-->
        </b-form-group>

        <template v-if="showClassificationFilters">
          <b-form-group
            id="group-classification-correct-filters-select"
            description="Show only items that are correctly or incorrectly predicted."
            label="Classification Correct"
            label-for="classification-correct-filters-select"
          >
            <b-form-select
              id="classification-correct-filters-select"
              v-model="settings.correctClassificationClass"
              :options="[
                { text: 'no filter', value: undefined },
                { text: 'show correct', value: true },
                { text: 'show incorrect', value: false },
              ]"
            />
          </b-form-group>
        </template>

        <template v-if="attributes && attributes.length > 0">
          <div class="d-flex justify-content-between mb-1">
            <div class="font-size-large">
              <b>Attribute Filters</b>
            </div>
            <b-button class="mb-2" size="sm" variant="success" @click="addAttributeFilter">
              add attribute filter
            </b-button>
          </div>

          <div v-if="settings.attributes.length === 0" class="text-secondary text-center pt-3 pb-3">
            selected attribute filters will be shown here
          </div>

          <div
            v-for="(attrFilter, i) in settings.attributes"
            :key="`attribute-filter-${i}-${attrFilter.name}`"
            class="mb-2"
          >
            <b-input-group>
              <template #prepend>
                <b-dropdown
                  :text="
                    attrFilter.attribute
                      ? attrFilter.attribute.display_name
                        ? attrFilter.attribute.display_name
                        : attrFilter.attribute.name
                      : 'select attribute'
                  "
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="attribute in attributes"
                    :key="`attribute-selection-${attribute.name}`"
                    @click="$set(attrFilter, 'attribute', attribute)"
                  >
                    {{ attribute.display_name ? attribute.display_name : attribute.name }}
                  </b-dropdown-item>
                </b-dropdown>

                <b-dropdown
                  :disabled="attrFilter.attribute === undefined"
                  :text="
                    attrFilter.operator ? getOperatorText(attrFilter.operator) : 'select operator'
                  "
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="operator in getOperators(attrFilter)"
                    :key="`operator-${i}-${operator.name}`"
                    @click="selectOperator(attrFilter, operator.name)"
                  >
                    {{ operator.text }}
                  </b-dropdown-item>
                </b-dropdown>
              </template>
              <b-form-input
                v-model="attrFilter.value"
                :disabled="attrFilter.attribute === undefined || attrFilter.operator === undefined"
              />
              <template #append>
                <b-button variant="outline-primary" @click="removeAttributeFilter(i)">
                  <fai icon="times" />
                </b-button>
              </template>
            </b-input-group>

            <!--          <b-form-group v-if="['integer_input', 'double_input', 'string_input'].includes(attrFilter.type)"-->
            <!--                        :id="`form-group-${attrFilter.name}`"-->
            <!--                        :description="attrFilter.description">-->
            <!--          </b-form-group>-->
          </div>
        </template>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import LoadingOverlayFullscreen from '../../components/LoadingOverlayFullscreen'
import statusOptions from '../../options'
import DateTimePicker from '../../components/DateTimePicker'

export default {
  name: 'FilterSettingsModal',
  components: { DateTimePicker, LoadingOverlayFullscreen },
  props: {
    show: {
      type: Boolean,
    },
    imageClassLabels: {
      type: Array,
      default: () => [],
    },
    objectClassLabels: {
      type: Array,
      default: () => [],
    },
    subsets: {
      type: Object,
      default: () => {},
    },
    attributes: {
      type: Array,
      default: () => [],
    },
    filterSettings: {
      type: Object,
      default: () => {},
    },
    showStatusFilter: {
      type: Boolean,
      default: true,
    },
    showTimeRangeFilter: {
      type: Boolean,
      default: true,
    },
    showNotPartOfAnySubsetOption: {
      type: Boolean,
      default: true,
    },
    showClassificationFilters: {
      type: Boolean,
      default: false,
    },
    showDetectionFilters: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      showModal: false,
      statusOptions: statusOptions,
      settings: {
        status: [],
        imageClass: null,
        objectClass: null,
        attributes: [],
        dataSubset: null,
        dateTimeFrom: null,
        dateTimeTo: null,
        correctClassificationClass: null,
      },
      lastFilterSettings: undefined,
      operators: [
        {
          name: 'eq',
          text: '==',
          validFor: [
            'bool_input',
            'integer_input',
            'double_input',
            'string_input',
            'selection_dropdown',
          ],
        },
        {
          name: 'gt',
          text: '>',
          validFor: ['integer_input', 'double_input'],
        },
        {
          name: 'ge',
          text: '>=',
          validFor: ['integer_input', 'double_input'],
        },
        {
          name: 'lt',
          text: '<',
          validFor: ['integer_input', 'double_input'],
        },
        {
          name: 'le',
          text: '<=',
          validFor: ['integer_input', 'double_input'],
        },
      ],
    }
  },

  computed: {
    imageClassOptions: function () {
      let options = [{ value: null, text: 'no filter' }]
      for (const classLabel of this.imageClassLabels) {
        options.push({ value: classLabel.id, text: classLabel.name })
      }
      return options
    },
    objectClassOptions: function () {
      let options = [{ value: null, text: 'no filter' }]
      for (const classLabel of this.objectClassLabels) {
        options.push({ value: classLabel.id, text: classLabel.name })
      }
      return options
    },
    dataSubsetOptions: function () {
      let options = [{ value: null, text: 'no filter' }]

      if (this.showNotPartOfAnySubsetOption) {
        options.push({ value: -1, text: 'not part of any subset' })
      }

      for (let subsetId in this.subsets) {
        const subset = this.subsets[subsetId]
        options.push({ value: subset.id, text: subset.name })
      }

      return options
    },
  },

  watch: {
    show: function (newValue) {
      if (newValue && !this.showModal) {
        this.lastFilterSettings = _.cloneDeep(this.settings)
      }
      this.showModal = newValue
    },

    showModal: function (newValue) {
      this.$emit('show', newValue)
    },

    filterSettings: {
      handler: function (newSettings) {
        this.settings = _.cloneDeep(newSettings)
        this.lastFilterSettings = _.cloneDeep(newSettings)
      },
      deep: true,
    },
  },

  created: function () {
    this.lastFilterSettings = _.cloneDeep(this.settings)
  },

  mounted() {
    this.showModal = this.show
  },

  methods: {
    applySettings() {
      this.lastFilterSettings = this.settings
      this.$emit('new-settings', this.settings)
    },
    hidden() {
      this.settings = this.lastFilterSettings
    },
    addAttributeFilter() {
      this.settings.attributes.push({
        attribute: undefined,
        value: undefined,
        operator: undefined,
      })
    },
    removeAttributeFilter(idx) {
      this.settings.attributes.splice(idx, 1)
    },
    selectOperator(attrFilter, operator) {
      this.$set(attrFilter, 'operator', operator)
    },
    getOperators(attrFilter) {
      let operators = []
      for (const operator of this.operators) {
        if (attrFilter.attribute && operator.validFor.includes(attrFilter.attribute.type)) {
          operators.push(operator)
        }
      }
      return operators
    },
    getOperatorText(operatorName) {
      for (const operator of this.operators) {
        if (operator.name === operatorName) {
          return operator.text
        }
      }
    },
  },
}
</script>

<style scoped></style>
