<template>
  <div ref="chartCanvas" style="width: 100%" :style="`height: ${height}px;`" />
</template>

<script>
import * as echarts from 'echarts/core'
import {
  GridComponent,
  ToolboxComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components'
import { BarChart, LineChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { defaultTooltipFormatter } from '@/utils'
import { defaultTheme } from '@/chart-themes'

export default {
  name: 'BasicChart',

  props: {
    chartType: {
      type: String,
      default: 'line',
    },
    xData: {
      type: Array,
      default: () => [],
    },
    yData: {
      type: Array,
      default: () => [],
    },
    height: {
      type: Number,
      default: 300,
    },
    baseOptions: {
      type: Object,
      default: () => {
        return {
          grid: [
            {
              left: 20,
              right: 20,
              top: 10,
              bottom: 10,
              containLabel: true,
            },
          ],
          tooltip: {
            trigger: 'axis',
            valueFormatter: defaultTooltipFormatter,
            axisPointer: {
              type: 'shadow',
            },
          },
          xAxis: {
            type: 'category',
            data: [],
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              type: 'bar',
              data: [],
              symbol: 'none',
            },
          ],
          textStyle: {
            fontFamily: 'IBM Plex Sans, Helvetica, Arial, sans-serif',
          },
        }
      },
    },
    overwriteOptions: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    xData: {
      deep: true,
      handler: function () {
        this.updateChart()
      },
    },
    yData: {
      deep: true,
      handler: function () {
        this.updateChart()
      },
    },
  },

  created() {
    window.addEventListener('resize', this.onResize)
  },
  mounted() {
    echarts.use([
      GridComponent,
      BarChart,
      LineChart,
      CanvasRenderer,
      ToolboxComponent,
      TooltipComponent,
      LegendComponent,
    ])
    this.$nextTick(() => {
      const chartDom = this.$refs.chartCanvas
      echarts.registerTheme('custom-theme', defaultTheme)
      this.chart = echarts.init(chartDom, 'custom-theme')
      this.updateChart()
    })
  },

  destroyed() {
    window.removeEventListener('resize', this.onResize)
    if (this.chart) {
      this.chart.dispose()
    }
  },

  methods: {
    updateChart() {
      if (this.chart) {
        let option = Object.assign({}, this.baseOptions)
        option = Object.assign(option, this.overwriteOptions)

        if (this.chartType === 'bar') {
          option.xAxis.type = 'category'
          option.xAxis.data = this.xData
          option.series[0].data = this.yData
          option.series[0].type = this.chartType
        } else {
          option.xAxis.type = 'value'
          option.xAxis.data = undefined

          const data = []
          for (let i = 0; i < this.xData.length; i++) {
            data.push([this.xData[i], this.yData[i]])
          }
          option.series[0].data = data
          option.series[0].type = this.chartType
        }

        this.chart.setOption(option)
        this.chart.resize()
      }
    },
    onResize() {
      if (this.chart) {
        this.chart.resize()
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
