<template>
  <b-modal
    id="modal-auto-annotation"
    ref="modalAutoAnnotation"
    v-model="showModal"
    scrollable
    size="xl"
    title="AI for Automatic Annotation"
    body-class="fixed-modal-body"
  >
    <div class="container-fluid h-100 p-0">
      <loading-overlay v-if="inferenceInitializing">
        Loading model. This can take up to a few minutes.
      </loading-overlay>
      <b-tabs v-model="tabIndex" pills card vertical class="w-100 h-100" content-class="h-100">
        <b-tab title="Model" active class="h-100 flex-nowrap p-0">
          <div class="scroll-box mh-100">
            <div v-if="networkModelsLoading" class="mt-5 mb-5">
              <loading-spinner-light />
            </div>
            <div v-else>
              <div class="input-group input-group-sm p-3">
                <input
                  v-model="modelsSearchString"
                  type="text"
                  class="form-control"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="button-search"
                />
              </div>
              <div v-if="filteredNetworkModels.length > 0">
                <div
                  v-for="model in filteredNetworkModels"
                  :key="`model-${model.id}`"
                  class="shadow-md p-3 m-3 d-flex justify-content-between align-items-center"
                  :class="{
                    'selected-card': inference && selectedModel && model.id === selectedModel.id,
                  }"
                >
                  <div>
                    <span class="text-secondary mr-2">#{{ model.id }}</span
                    ><span class="mr-2">{{ model.name }}</span>
                  </div>
                  <b-button variant="outline-primary" size="sm" @click="onSelectModel(model)">
                    select
                  </b-button>
                </div>
              </div>
              <div v-else>No network models available.</div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Parameters" :disabled="!selectedModel || !inference">
          <div>
            <b-form-group
              id="model-name-label"
              label="Name:"
              label-for="model-name"
              description="Enter a descriptive name for the AI model."
            >
              <b-form-input
                id="model-name"
                v-model="parameters.name"
                type="text"
                placeholder="Model Name"
                required
              />
            </b-form-group>

            <b-form-group
              id="model-confidence-label"
              label="Confidence:"
              label-for="model-confidence"
              description="Set the confidence threshold (selectivity). Only objects with a
                confidence higher than this threshold will be used. Minimum: 0.0, Maximum: 1.0."
            >
              <b-input-group>
                <b-form-input
                  v-model="parameters.confidenceThreshold"
                  type="number"
                  style="max-width: 8rem"
                  min="0"
                  max="1"
                  step="0.0001"
                />
                <b-form-input
                  id="model-confidence"
                  v-model="parameters.confidenceThreshold"
                  type="range"
                  min="0"
                  max="1"
                  step="0.0001"
                  required
                />
              </b-input-group>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </div>

    <template #modal-footer>
      <div class="d-flex justify-content-end w-100">
        <b-button variant="secondary" class="ml-2" @click="showModal = false"> Close </b-button>
        <b-button v-if="tabIndex > 0" variant="primary" class="ml-2" @click="tabIndex--">
          Previous
        </b-button>
        <b-button
          v-if="tabIndex < nTabs - 1"
          :disabled="!selectedModel || !inference"
          variant="primary"
          class="ml-2"
          @click="onClickNext"
        >
          Next
        </b-button>
        <b-button v-if="tabIndex === nTabs - 1" variant="primary" class="ml-2" @click="onClickSave">
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import LoadingSpinnerLight from '@/components/LoadingSpinnerLight'
import Inference from '@/inds-web'
import LoadingOverlay from '@/components/LoadingOverlay'
import _ from 'lodash'

export default {
  name: 'AutoAnnotationModal',
  components: { LoadingOverlay, LoadingSpinnerLight },
  props: {
    show: Boolean,
    networkType: String,
  },

  data() {
    return {
      tabIndex: 0,
      nTabs: 2,
      showModal: false,
      selectedModel: undefined,
      networkModelsLoading: false,
      inferenceInitializing: false,
      networkModels: [],
      modelsSearchString: '',

      inference: undefined,
      parameters: {
        name: '',
        confidenceThreshold: 0.5,
      },
    }
  },

  computed: {
    filteredNetworkModels: function () {
      return this.networkModels.filter((m) => {
        return (
          this.networkType === m.network_type &&
          m.inference_data &&
          m.name.toLowerCase().includes(this.modelsSearchString.toLowerCase())
        )
      })
    },
  },

  watch: {
    show: function (newValue) {
      this.showModal = newValue
    },

    showModal: function (newValue) {
      this.$emit('show', newValue)
    },
  },

  created: function () {},

  mounted() {
    this.showModal = this.show
    this.loadNetworkModels()
  },

  methods: {
    loadNetworkModels() {
      this.networkModelsLoading = true
      this.networkModels = []
      this.$axios({
        method: 'get',
        url: '/api/models',
        withCredentials: true,
        crossDomain: true,
      })
        .then((response) => {
          this.networkModels = response.data
        })
        .catch(() => {
          this.$bvToast.toast('Could not load network models.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
        })
        .finally(() => {
          this.networkModelsLoading = false
        })
    },
    onSelectModel(model) {
      this.inferenceInitializing = true

      const self = this
      Inference.fromUrl(model.inference_data)
        .then((inference) => {
          self.selectedModel = model
          self.parameters.name = model.name
          self.inference = inference
          self.tabIndex++
        })
        .catch((error) => {
          console.log(error)
          self.selectedModel = undefined
          self.inference = undefined
          self.$bvToast.toast('An unknown error occurred while loading model.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
        })
        .finally(() => {
          self.inferenceInitializing = false
        })
    },
    onClickNext() {
      this.tabIndex++
    },
    onClickSave() {
      this.$emit('save', {
        inference: this.inference,
        parameters: _.cloneDeep(this.parameters),
      })
      this.showModal = false

      this.selectedModel = undefined
      this.inference = undefined
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';
</style>
