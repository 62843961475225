<template>
  <span>
    <b-button
      v-if="button"
      variant="light"
      class="item-badge-small subset-badge"
      :style="{ 'border-color': borderColor, 'background-color': backgroundColor }"
      @click="onClick"
    >
      {{ name }}
    </b-button>
    <span
      v-else
      class="item-badge-x-small subset-badge"
      :style="{ 'border-color': borderColor, 'background-color': backgroundColor }"
    >
      {{ name }}
    </span>
  </span>
</template>

<script>
import { getColorForCSS } from '@/colors'
import { subsetNameHash } from '@/utils'

export default {
  name: 'SubsetBadge',
  props: {
    dataSubsets: {
      type: Object,
    },
    subsetId: {
      type: Number,
    },
    button: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    name() {
      return this.dataSubsets[this.subsetId] !== undefined
        ? this.dataSubsets[this.subsetId].name
        : `#${this.subsetId}`
    },
    borderColor() {
      return getColorForCSS(subsetNameHash(this.name), 1.0)
    },
    backgroundColor() {
      return getColorForCSS(subsetNameHash(this.name), 0.35)
    },
  },
  methods: {
    onClick(evt) {
      this.$emit('click', evt)
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../custom';

.item-badge-x-small {
  @extend .item-badge;
  font-size: x-small;
}

.item-badge-small {
  @extend .item-badge;
  font-size: small;
}

.image-class-badge {
  border-radius: 64px;
}

.subset-badge {
  border-radius: 4px;
}
</style>
