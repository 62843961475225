function iouMatching(detection1, detection2, iouThreshold) {
  const x1 = detection1.x
  const y1 = detection1.y
  const w1 = detection1.width
  const h1 = detection1.height

  const x2 = detection2.x
  const y2 = detection2.y
  const w2 = detection2.width
  const h2 = detection2.height

  const dx = Math.abs(x1 - x2)
  const dy = Math.abs(y1 - y2)

  const wIntersect = Math.min(Math.max(0.0, 0.5 * w1 + 0.5 * w2 - dx), Math.min(w1, w2))
  const hIntersect = Math.min(Math.max(0.0, 0.5 * h1 + 0.5 * h2 - dy), Math.min(h1, h2))

  const area1 = w1 * h1
  const area2 = w2 * h2

  const areaIntersect = wIntersect * hIntersect
  const areaUnion = area1 + area2 - areaIntersect

  return areaUnion !== 0 && areaIntersect / areaUnion > iouThreshold
}

export function nonMaximumSuppression(
  detections,
  { maxSelections = 200, matchingThreshold = 0.5, matchingFn = iouMatching }
) {
  detections.sort((d1, d2) => {
    if (d1.confidence > d2.confidence) return -1
    else if (d1.confidence === d2.confidence) return 0
    return 1
  })

  const selectedDetections = []
  const nSelect = Math.min(detections.length, maxSelections)

  for (let i = 0; i < nSelect; i++) {
    const d1 = detections[i]

    let select = true
    for (let j = selectedDetections.length - 1; j >= 0; --j) {
      const d2 = selectedDetections[j]
      if (matchingFn(d1, d2, matchingThreshold)) {
        select = false
        break
      }
    }

    if (select) {
      selectedDetections.push(d1)
    }
  }

  return selectedDetections
}

export function multiClassNonMaximumSuppression(
  detections,
  classes,
  { maxSelections = 200, matchingThreshold = 0.5, matchingFn = iouMatching }
) {
  const allSelectedDetections = []

  for (let c of classes) {
    const detectionsCurrentClass = detections.filter((d) => d.label === c)
    allSelectedDetections.push(
      ...nonMaximumSuppression(detectionsCurrentClass, {
        maxSelections,
        matchingThreshold,
        matchingFn,
      })
    )
  }

  return allSelectedDetections
}
