// based on three.js/examples/jsm/loaders/PCDLoader.js
import { FileLoader, Loader, LoaderUtils } from 'three'

class PCDLoader extends Loader {
  constructor(manager) {
    super(manager)

    this.littleEndian = true
  }

  load(url, onLoad, onProgress, onError) {
    const scope = this

    const loader = new FileLoader(scope.manager)
    loader.setPath(scope.path)
    loader.setResponseType('arraybuffer')
    loader.setRequestHeader(scope.requestHeader)
    loader.setWithCredentials(scope.withCredentials)
    // loader.setCrossOrigin(scope.crossOrigin);
    loader.load(
      url,
      function (data) {
        try {
          onLoad(scope.parse(data))
        } catch (e) {
          if (onError) {
            onError(e)
          } else {
            console.error(e)
          }

          scope.manager.itemError(url)
        }
      },
      onProgress,
      onError
    )
  }

  parse(data) {
    // from https://gitlab.com/taketwo/three-pcd-loader/blob/master/decompress-lzf.js

    // eslint-disable-next-line no-unused-vars
    function decompressLZF(inData, outLength) {
      const inLength = inData.length
      const outData = new Uint8Array(outLength)
      let inPtr = 0
      let outPtr = 0
      let ctrl
      let len
      let ref
      do {
        ctrl = inData[inPtr++]
        if (ctrl < 1 << 5) {
          ctrl++
          if (outPtr + ctrl > outLength) throw new Error('Output buffer is not large enough')
          if (inPtr + ctrl > inLength) throw new Error('Invalid compressed data')
          do {
            outData[outPtr++] = inData[inPtr++]
          } while (--ctrl)
        } else {
          len = ctrl >> 5
          ref = outPtr - ((ctrl & 0x1f) << 8) - 1
          if (inPtr >= inLength) throw new Error('Invalid compressed data')
          if (len === 7) {
            len += inData[inPtr++]
            if (inPtr >= inLength) throw new Error('Invalid compressed data')
          }

          ref -= inData[inPtr++]
          if (outPtr + len + 2 > outLength) throw new Error('Output buffer is not large enough')
          if (ref < 0) throw new Error('Invalid compressed data')
          if (ref >= outPtr) throw new Error('Invalid compressed data')
          do {
            outData[outPtr++] = outData[ref++]
          } while (--len + 2)
        }
      } while (inPtr < inLength)

      return outData
    }

    function parseHeader(data) {
      const pcdHeader = {}
      const result1 = data.search(/[\r\n]DATA\s(\S*)\s/i)
      const result2 = /[\r\n]DATA\s(\S*)\s/i.exec(data.substr(result1 - 1))

      pcdHeader.data = result2[1]
      pcdHeader.headerLen = result2[0].length + result1
      pcdHeader.str = data.substr(0, pcdHeader.headerLen)

      // remove comments

      pcdHeader.str = pcdHeader.str.replace(/#.*/gi, '')

      // parse

      pcdHeader.version = /VERSION (.*)/i.exec(pcdHeader.str)
      pcdHeader.fields = /FIELDS (.*)/i.exec(pcdHeader.str)
      pcdHeader.size = /SIZE (.*)/i.exec(pcdHeader.str)
      pcdHeader.type = /TYPE (.*)/i.exec(pcdHeader.str)
      pcdHeader.count = /COUNT (.*)/i.exec(pcdHeader.str)
      pcdHeader.width = /WIDTH (.*)/i.exec(pcdHeader.str)
      pcdHeader.height = /HEIGHT (.*)/i.exec(pcdHeader.str)
      pcdHeader.viewpoint = /VIEWPOINT (.*)/i.exec(pcdHeader.str)
      pcdHeader.points = /POINTS (.*)/i.exec(pcdHeader.str)

      // evaluate

      if (pcdHeader.version !== null) pcdHeader.version = parseFloat(pcdHeader.version[1])

      if (pcdHeader.fields !== null) pcdHeader.fields = pcdHeader.fields[1].split(' ')

      if (pcdHeader.type !== null) pcdHeader.type = pcdHeader.type[1].split(' ')

      if (pcdHeader.width !== null) pcdHeader.width = parseInt(pcdHeader.width[1])

      if (pcdHeader.height !== null) pcdHeader.height = parseInt(pcdHeader.height[1])

      if (pcdHeader.viewpoint !== null) pcdHeader.viewpoint = pcdHeader.viewpoint[1]

      if (pcdHeader.points !== null) pcdHeader.points = parseInt(pcdHeader.points[1], 10)

      if (pcdHeader.points === null) pcdHeader.points = pcdHeader.width * pcdHeader.height

      if (pcdHeader.size !== null) {
        pcdHeader.size = pcdHeader.size[1].split(' ').map(function (x) {
          return parseInt(x, 10)
        })
      }

      if (pcdHeader.count !== null) {
        pcdHeader.count = pcdHeader.count[1].split(' ').map(function (x) {
          return parseInt(x, 10)
        })
      } else {
        pcdHeader.count = []

        for (let i = 0, l = pcdHeader.fields.length; i < l; i++) {
          pcdHeader.count.push(1)
        }
      }

      pcdHeader.offset = {}
      pcdHeader.types = {}
      pcdHeader.sizes = {}

      let sizeSum = 0

      for (let i = 0, l = pcdHeader.fields.length; i < l; i++) {
        if (pcdHeader.data === 'ascii') {
          pcdHeader.offset[pcdHeader.fields[i]] = i
        } else {
          pcdHeader.offset[pcdHeader.fields[i]] = sizeSum
          pcdHeader.sizes[pcdHeader.fields[i]] = pcdHeader.size[i]
          pcdHeader.types[pcdHeader.fields[i]] = pcdHeader.type[i]

          sizeSum += pcdHeader.size[i] * pcdHeader.count[i]
        }
      }

      // for binary only

      pcdHeader.rowSize = sizeSum

      return pcdHeader
    }

    const textData = LoaderUtils.decodeText(new Uint8Array(data))

    // parse header (always ascii format)
    const pcdHeader = parseHeader(textData)

    const pointCloud = new Float32Array(pcdHeader.points * 3)
    const rgb = new Float32Array(pcdHeader.points * 3)

    // ascii
    // if (pcdHeader.data === 'ascii') {
    //
    //   const offset = pcdHeader.offset;
    //   const pcdData = textData.substr(pcdHeader.headerLen);
    //   const lines = pcdData.split('\n');
    //
    //   for (let i = 0, l = lines.length; i < l; i++) {
    //
    //     if (lines[i] === '') continue;
    //
    //     const line = lines[i].split(' ');
    //
    //     if (offset.x !== undefined) {
    //
    //       position.push(parseFloat(line[offset.x]));
    //       position.push(parseFloat(line[offset.y]));
    //       position.push(parseFloat(line[offset.z]));
    //
    //     }
    //
    //     if (offset.rgb !== undefined) {
    //
    //       const rgb = parseFloat(line[offset.rgb]);
    //       const r = (rgb >> 16) & 0x0000ff;
    //       const g = (rgb >> 8) & 0x0000ff;
    //       const b = (rgb >> 0) & 0x0000ff;
    //       color.push(r / 255, g / 255, b / 255);
    //
    //     }
    //
    //     if (offset.normal_x !== undefined) {
    //
    //       normal.push(parseFloat(line[offset.normal_x]));
    //       normal.push(parseFloat(line[offset.normal_y]));
    //       normal.push(parseFloat(line[offset.normal_z]));
    //
    //     }
    //   }
    // }

    // binary-compressed

    // normally data in PCD files are organized as array of structures: XYZRGBXYZRGB
    // binary compressed PCD files organize their data as structure of arrays: XXYYZZRGBRGB
    // that requires a totally different parsing approach compared to non-compressed data
    // if (pcdHeader.data === 'binary_compressed') {
    //   const sizes = new Uint32Array(data.slice(pcdHeader.headerLen, pcdHeader.headerLen + 8));
    //   const compressedSize = sizes[0];
    //   const decompressedSize = sizes[1];
    //   const decompressed = decompressLZF(new Uint8Array(data, pcdHeader.headerLen + 8, compressedSize), decompressedSize);
    //   const dataview = new DataView(decompressed.buffer);
    //
    //   const offset = pcdHeader.offset;
    //
    //   for (let i = 0; i < pcdHeader.points; i++) {
    //
    //     if (offset.x !== undefined) {
    //       if (pcdHeader.types.x === 'f' && pcdHeader.sizes.x === 4) {
    //         position.push(dataview.getFloat32((pcdHeader.points * offset.x) + pcdHeader.sizes.x * i, this.littleEndian));
    //       } else if (pcdHeader.types.x === 'i' && pcdHeader.sizes.x === 2) {
    //         position.push(parseFloat(dataview.getInt16((pcdHeader.points * offset.x) + pcdHeader.sizes.x * i, this.littleEndian)));
    //       }
    //
    //       if (pcdHeader.types.y === 'f' && pcdHeader.sizes.y === 4) {
    //         position.push(dataview.getFloat32((pcdHeader.points * offset.y) + pcdHeader.sizes.y * i, this.littleEndian));
    //       } else if (pcdHeader.types.y === 'i' && pcdHeader.sizes.y === 2) {
    //         position.push(parseFloat(dataview.getInt16((pcdHeader.points * offset.y) + pcdHeader.sizes.y * i, this.littleEndian)));
    //       }
    //
    //       if (pcdHeader.types.z === 'f' && pcdHeader.sizes.z === 4) {
    //         position.push(dataview.getFloat32((pcdHeader.points * offset.z) + pcdHeader.sizes.z * i, this.littleEndian));
    //       } else if (pcdHeader.types.z === 'i' && pcdHeader.sizes.z === 2) {
    //         position.push(parseFloat(dataview.getInt16((pcdHeader.points * offset.z) + pcdHeader.sizes.z * i, this.littleEndian)));
    //       }
    //     }
    //
    //   }
    //
    // }

    // binary

    if (pcdHeader.data === 'binary') {
      const dataview = new DataView(data, pcdHeader.headerLen)
      const offset = pcdHeader.offset
      const rowSize = pcdHeader.rowSize

      const littleEndian = this.littleEndian
      const getValue = function (key, i, normalize) {
        const dtype = pcdHeader.types[key]
        let value = 0
        if (dtype === 'f') {
          if (pcdHeader.sizes[key] === 4) {
            value = dataview.getFloat32(i * rowSize + offset[key], littleEndian)
          } else if (pcdHeader.sizes[key] === 8) {
            value = dataview.getFloat64(i * rowSize + offset[key], littleEndian)
          }
        } else if (dtype === 'i') {
          if (pcdHeader.sizes[key] === 1) {
            value = dataview.getInt8(i * rowSize + offset[key])
          } else if (pcdHeader.sizes[key] === 2) {
            value = dataview.getInt16(i * rowSize + offset[key], littleEndian)
          } else if (pcdHeader.sizes[key] === 4) {
            value = dataview.getInt32(i * rowSize + offset[key], littleEndian)
          }
        } else if (dtype === 'u') {
          if (pcdHeader.sizes[key] === 1) {
            value = dataview.getUint8(i * rowSize + offset[key])
          } else if (pcdHeader.sizes[key] === 2) {
            value = dataview.getUint16(i * rowSize + offset[key], littleEndian)
          } else if (pcdHeader.sizes[key] === 4) {
            value = dataview.getUint32(i * rowSize + offset[key], littleEndian)
          }
        }

        if (normalize && (dtype === 'i' || dtype === 'u')) {
          value /= Math.pow(256, pcdHeader.sizes[key])
        }

        return value
      }

      if (offset.x !== undefined && offset.y !== undefined && offset.z !== undefined) {
        for (let i = 0; i < pcdHeader.points; i++) {
          pointCloud[3 * i] = getValue('x', i)
          pointCloud[3 * i + 1] = getValue('y', i)
          pointCloud[3 * i + 2] = getValue('z', i)
        }
      }

      if (offset.a !== undefined) {
        for (let i = 0; i < pcdHeader.points; i++) {
          const a = getValue('a', i, true)
          rgb[3 * i] = a
          rgb[3 * i + 1] = a
          rgb[3 * i + 2] = a
        }
      }

      if (offset.r !== undefined && offset.g !== undefined && offset.b !== undefined) {
        for (let i = 0; i < pcdHeader.points; i++) {
          rgb[3 * i] = getValue('r', i, true)
          rgb[3 * i + 1] = getValue('g', i, true)
          rgb[3 * i + 2] = getValue('b', i, true)
        }
      }
    }

    return {
      xyz: pointCloud,
      rgb: rgb,
      width: pcdHeader.width,
      height: pcdHeader.height,
    }
  }
}

export { PCDLoader }
