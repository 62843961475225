<template>
  <div class="container pb-3">
    <loading-overlay-fullscreen v-show="loading || billingLoading" />
    <template>
      <b-alert
        :show="
          server.cloud && organization && organization.billing_enabled && organization.deactivated
        "
        variant="danger"
        class="mt-4"
      >
        <div class="d-flex justify-content-between">
          <div class="flex-shrink-1 mr-2">
            <template v-if="organization.deactivation_reason === 'charge_failed'">
              Your organization has been deactivated due to a failed payment. Please revisit your
              billing settings.
            </template>
            <template v-else> Your organization has been deactivated.</template>
            <b>We will keep your existing data for up to 60 days after the failed payment.</b>
          </div>
          <b-button variant="primary" class="flex-shrink-0" @click="onOpenPaymentMethod">
            retry payment
          </b-button>
        </div>
      </b-alert>

      <b-tabs v-model="tabIndex" pills vertical class="pt-4 pb-4">
        <b-tab title="Users">
          <div class="card">
            <div class="p-3">
              <div>
                <h5 class="p-3">
                  <fai icon="certificate" class="mr-2" />
                  <b>User Licenses</b>
                </h5>
              </div>
              <licenses-settings
                :licenses="licenses"
                :organization="organization"
                @license-changed="licenseChanged"
              />
            </div>

            <users-settings
              :users="users"
              :licenses="licenses"
              :organization="organization"
              @user-created="userCreated"
              @user-changed="userChanged"
              @user-deleted="userDeleted"
              @license-changed="licenseChanged"
            />
          </div>
        </b-tab>

        <b-tab v-if="server.cloud && organization.billing_enabled" title="Subscription">
          <!--          <b-button @click="onOpenSubscriptionModal('Starter')">Create Starter</b-button>-->
          <!--          <b-button @click="onOpenSubscriptionModal('Team')">Create Team</b-button>-->
          <!--          <br>-->

          <!--          <b-button @click="onOpenSubscriptionModal('Starter')">Change Starter</b-button>-->
          <!--          <b-button @click="onOpenSubscriptionModal('Team')">Change Team</b-button>-->
          <!--          <br>-->
          <!--          <br>-->

          <div class="card p-4">
            <div class="w-100 d-flex justify-content-between mb-3">
              <div class="pl-2">
                <h5 class="m-0">
                  <fai icon="file-contract" class="mr-2" />
                  <b>Subscription</b>
                </h5>
              </div>
              <b-form-radio-group
                v-model="selectedBillingInterval"
                :options="[
                  { text: 'monthly', value: 'month' },
                  { text: 'annually', value: 'year' },
                ]"
                button-variant="outline-primary"
                size="sm"
                buttons
              />
            </div>

            <template v-if="activePlan && !!activePlan.cancel_at_period_end">
              <b-alert v-if="activePlan.status === 'trialing'" variant="info" show>
                You are currently registered in a trial subscription. Your trial ends
                <b>{{ (activePlan.trial_end * 1000) | formatTimestamp | formatDate }}</b
                >.
              </b-alert>
              <b-alert v-else variant="danger" show>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    Your subscription will expire on
                    <b>{{ (activePlan.cancel_at * 1000) | formatTimestamp | formatDate }}</b
                    >.
                  </div>
                  <!--                <b-button variant="outline-danger" size="sm">cancel change</b-button>-->
                </div>
              </b-alert>
            </template>

            <b-alert v-if="activePlan && !!activePlan.pending_update" variant="info" show>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  A subscription change is scheduled for
                  <b>
                    {{
                      (activePlan.pending_update.start_date * 1000) | formatTimestamp | formatDate
                    }}</b
                  >.
                </div>
                <!--                <b-button variant="outline-danger" size="sm">cancel change</b-button>-->
              </div>
            </b-alert>

            <template v-if="plans">
              <div class="pricing-grid">
                <div class="pricing-grid-item r-1 c-1" />
                <div class="pricing-grid-item r-1 c-2 pt-3 pb-3">
                  <div class="h-100 border-radius-md d-flex flex-column justify-content-between">
                    <div>
                      <div class="font-size-large font-weight-700">Free</div>
                    </div>

                    <div v-if="isActive('Free')" class="mt-2">
                      <b-alert show variant="info" class="font-weight-700 text-center p-1 mb-0">
                        ACTIVE
                      </b-alert>
                    </div>
                    <div v-else class="text-dark mt-3 font-size-small">
                      Cancel your subscription to downgrade to the free plan.
                    </div>
                  </div>
                </div>
                <div class="pricing-grid-item r-1 c-3 pt-3 pb-3">
                  <div class="h-100 border-radius-md d-flex flex-column justify-content-between">
                    <div>
                      <div class="font-size-large font-weight-700">Starter</div>
                      <div class="font-size-large">
                        {{
                          plans.Starter.prices[selectedBillingInterval].base.amount | formatPrice
                        }}
                        <span class="text-dark font-size-small">
                          / {{ selectedBillingInterval }}</span
                        >
                      </div>
                    </div>

                    <div v-if="isActive('Starter')" class="mt-2">
                      <b-alert show variant="info" class="font-weight-700 text-center p-1 mb-0">
                        ACTIVE
                      </b-alert>
                      <b-button
                        v-if="activePlan && !activePlan.cancel_at_period_end"
                        block
                        variant="outline-danger"
                        class="mt-2"
                        @click="onCancelSubscriptionModal"
                      >
                        cancel
                      </b-button>
                    </div>
                    <div v-else>
                      <b-button
                        v-if="
                          activePlan &&
                          !!activePlan.cancel_at_period_end &&
                          activePlan.status !== 'trialing'
                        "
                        block
                        variant="outline-primary"
                        class="mt-2"
                        @click="onReEnableSubscriptionModal"
                      >
                        revoke cancellation
                      </b-button>
                      <b-button
                        v-else
                        block
                        variant="outline-primary"
                        class="mt-2"
                        @click="onOpenSubscriptionModal('Starter')"
                      >
                        choose
                      </b-button>
                    </div>
                  </div>
                </div>
                <div class="pricing-grid-item r-1 c-4 pt-3 pb-3">
                  <div class="h-100 border-radius-md d-flex flex-column justify-content-between">
                    <div>
                      <div class="font-size-large font-weight-700">Team</div>
                      <div class="font-size-large">
                        {{ plans.Team.prices[selectedBillingInterval].base.amount | formatPrice }}
                        <span class="text-dark font-size-small">
                          / {{ selectedBillingInterval }}</span
                        >
                      </div>
                    </div>

                    <div v-if="isActive('Team')" class="mt-2">
                      <b-alert show variant="info" class="font-weight-700 text-center p-1 mb-0">
                        ACTIVE
                      </b-alert>
                      <b-button
                        v-if="activePlan && !activePlan.cancel_at_period_end"
                        block
                        variant="outline-danger"
                        class="mt-2"
                        @click="onCancelSubscriptionModal"
                      >
                        cancel
                      </b-button>
                    </div>
                    <div v-else>
                      <b-button
                        v-if="
                          activePlan &&
                          !!activePlan.cancel_at_period_end &&
                          activePlan.status !== 'trialing'
                        "
                        block
                        variant="outline-primary"
                        class="mt-2"
                        @click="onReEnableSubscriptionModal"
                      >
                        revoke cancellation
                      </b-button>
                      <b-button
                        v-else
                        block
                        variant="outline-primary"
                        class="mt-2"
                        @click="onOpenSubscriptionModal('Team')"
                      >
                        choose
                      </b-button>
                    </div>

                    <!--                    <div v-if="isActive('Team')" class="mt-2">-->
                    <!--                      <b-alert show variant="info" class="font-weight-700 text-center p-1 mb-0"-->
                    <!--                      >ACTIVE-->
                    <!--                      </b-alert>-->
                    <!--                      <b-button @click="onCancelSubscriptionModal" block variant="outline-danger"-->
                    <!--                                class="mt-2"-->
                    <!--                                v-if="activePlan && !activePlan.cancel_at_period_end">-->
                    <!--                        cancel-->
                    <!--                      </b-button>-->
                    <!--                      <b-button @click="onReEnableSubscriptionModal" block variant="outline-primary"-->
                    <!--                                class="mt-2"-->
                    <!--                                v-if="activePlan && !!activePlan.cancel_at_period_end">-->
                    <!--                        revoke cancellation-->
                    <!--                      </b-button>-->
                    <!--                    </div>-->
                    <!--                    <div v-else>-->
                    <!--                      <b-button @click="onOpenSubscriptionModal('Team')" block-->
                    <!--                                variant="outline-primary" class="mt-2">-->
                    <!--                        choose-->
                    <!--                      </b-button>-->
                    <!--                    </div>-->
                  </div>
                </div>

                <div class="pricing-grid-item r-2 c-1">
                  <div class="pricing-feature-name">
                    <tooltip-circle
                      icon-class="text-secondary"
                      tooltip="User with access to data management, labeling, and AI models."
                    >
                      Engineering users
                    </tooltip-circle>
                  </div>
                </div>
                <div class="pricing-grid-item r-2 c-2 pricing-feature-value">1</div>
                <div class="pricing-grid-item r-2 c-3 pricing-feature-value">1</div>
                <div class="pricing-grid-item r-2 c-4 pricing-feature-value">3</div>

                <div class="pricing-grid-item r-3 c-1">
                  <div class="pricing-feature-name">
                    <tooltip-circle
                      icon-class="text-secondary"
                      tooltip="User with access to data management and labeling."
                    >
                      Annotation users
                    </tooltip-circle>
                  </div>
                </div>
                <div class="pricing-grid-item r-3 c-2 pricing-feature-value">-</div>
                <div class="pricing-grid-item r-3 c-3 pricing-feature-value">-</div>
                <div class="pricing-grid-item r-3 c-4 pricing-feature-value">5</div>

                <div class="pricing-grid-item r-4 c-1">
                  <div class="pricing-feature-name">
                    <tooltip-circle
                      icon-class="text-secondary"
                      tooltip="The number of datasets you can manage."
                    >
                      Datasets
                    </tooltip-circle>
                  </div>
                </div>
                <div class="pricing-grid-item r-4 c-2 pricing-feature-value">1</div>
                <div class="pricing-grid-item r-4 c-3 pricing-feature-value">25</div>
                <div class="pricing-grid-item r-4 c-4 pricing-feature-value">75</div>

                <div class="pricing-grid-item r-5 c-1">
                  <div class="pricing-feature-name">
                    <tooltip-circle
                      icon-class="text-secondary"
                      tooltip="The number of items (images) allowed per dataset."
                    >
                      Items per dataset
                    </tooltip-circle>
                  </div>
                </div>
                <div class="pricing-grid-item r-5 c-2 pricing-feature-value">up to 500</div>
                <div class="pricing-grid-item r-5 c-3 pricing-feature-value">up to 250,000</div>
                <div class="pricing-grid-item r-5 c-4 pricing-feature-value">up to 500,000</div>

                <div class="pricing-grid-item r-6 c-1">
                  <div class="pricing-feature-name">
                    <tooltip-circle
                      icon-class="text-secondary"
                      tooltip="Total storage consumption including images, model checkpoints, and model results. The free plan includes 500 MB of storage for the dataset items (model checkpoints and results are excluded)."
                    >
                      Storage
                    </tooltip-circle>
                  </div>
                </div>
                <div class="pricing-grid-item r-6 c-2 pricing-feature-value">
                  max. 500 MB<br /><span class="font-size-small text-dark"
                    >for datasets, model checkpoints and results are excluded</span
                  >
                </div>
                <div class="pricing-grid-item r-6 c-3 pricing-feature-value">
                  20 GB included<br /><span class="font-size-small text-dark">
                    {{ plans.Starter.prices[selectedBillingInterval].storage.amount | formatPrice }}
                    per GB per month
                  </span>
                </div>
                <div class="pricing-grid-item r-6 c-4 pricing-feature-value">
                  100 GB included<br /><span class="font-size-small text-dark">
                    {{ plans.Team.prices[selectedBillingInterval].storage.amount | formatPrice }}
                    per GB per month
                  </span>
                </div>

                <div class="pricing-grid-item r-7 c-1">
                  <div class="pricing-feature-name">
                    <tooltip-circle
                      icon-class="text-secondary"
                      tooltip="Train AI models on your local machine(s). The number of local workers is limited based on your plan."
                    >
                      Local training workers
                    </tooltip-circle>
                  </div>
                </div>
                <div class="pricing-grid-item r-7 c-2 pricing-feature-value">1</div>
                <div class="pricing-grid-item r-7 c-3 pricing-feature-value">1</div>
                <div class="pricing-grid-item r-7 c-4 pricing-feature-value">3</div>

                <div class="pricing-grid-item r-8 c-1">
                  <div class="pricing-feature-name">
                    <tooltip-circle
                      icon-class="text-secondary"
                      tooltip="You can use cloud training in paid plans. One training unit is equivalent to one hour of training on our standard GPUs."
                    >
                      Cloud training
                    </tooltip-circle>
                  </div>
                </div>
                <div class="pricing-grid-item r-8 c-2 pricing-feature-value">
                  <span class="font-size-small text-dark">only available in paid plans</span>
                </div>
                <div class="pricing-grid-item r-8 c-3 pricing-feature-value">
                  4 units included<br />(every month)<br /><span class="font-size-small text-dark">
                    {{
                      plans.Starter.prices[selectedBillingInterval].computing_time.amount
                        | formatPrice
                    }}
                    per unit
                  </span>
                </div>
                <div class="pricing-grid-item r-8 c-4 pricing-feature-value">
                  8 units included<br />(every month)<br /><span class="font-size-small text-dark">
                    {{
                      plans.Team.prices[selectedBillingInterval].computing_time.amount | formatPrice
                    }}
                    per unit
                  </span>
                </div>

                <div class="pricing-grid-item r-9 c-1">
                  <div class="pricing-feature-name">
                    <tooltip-circle
                      icon-class="text-secondary"
                      tooltip="The number of AI model instances you can manage."
                    >
                      AI model instances
                    </tooltip-circle>
                  </div>
                </div>
                <div class="pricing-grid-item r-9 c-2 pricing-feature-value">3</div>
                <div class="pricing-grid-item r-9 c-3 pricing-feature-value">100</div>
                <div class="pricing-grid-item r-9 c-4 pricing-feature-value">500</div>

                <div class="pricing-grid-item r-10 c-1">
                  <div class="pricing-feature-name">
                    <tooltip-circle
                      icon-class="text-secondary"
                      tooltip="Unlimited training in paid plans."
                    >
                      Iterations per training
                    </tooltip-circle>
                  </div>
                </div>
                <div class="pricing-grid-item r-10 c-2 pricing-feature-value">10,000</div>
                <div class="pricing-grid-item r-10 c-3 pricing-feature-value">unlimited</div>
                <div class="pricing-grid-item r-10 c-4 pricing-feature-value">unlimited</div>

                <div class="pricing-grid-item r-11 c-1">
                  <div class="pricing-feature-name">
                    <tooltip-circle
                      icon-class="text-secondary"
                      tooltip="You can schedule and train as many models as possible in parallel in the paid plans."
                    >
                      Parallel training<br />sessions
                    </tooltip-circle>
                  </div>
                </div>
                <div class="pricing-grid-item r-11 c-2 pricing-feature-value">1</div>
                <div class="pricing-grid-item r-11 c-3 pricing-feature-value">unlimited</div>
                <div class="pricing-grid-item r-11 c-4 pricing-feature-value">unlimited</div>

                <div
                  v-if="
                    selectedBillingInterval === activePlan.billing_interval ||
                    (activePlan && !activePlan.plan)
                  "
                  class="pricing-cells-highlighted"
                  :style="{ 'grid-column-start': highlightedColumnIdx }"
                />
              </div>
            </template>
          </div>

          <!--          <div v-for="(plan, name) in plans">-->

          <!--            <span v-if="name" class="text-danger">ACTIVE</span>-->

          <!--            {{ name }}-->

          <!--            {{ plan }}-->

          <!--          </div>-->

          <!--          <div>-->
          <!--            <b-form-group>-->
          <!--              <b-form-radio-group-->
          <!--                v-model="selectedBillingInterval"-->
          <!--                :options="[{text: 'monthly', value: 'month'}, {text: 'annually', value: 'year'}]"-->
          <!--                button-variant="outline-primary"-->
          <!--                size="sm"-->
          <!--                buttons-->
          <!--              ></b-form-radio-group>-->
          <!--            </b-form-group>-->
          <!--          </div>-->
          <!--          <div class="d-flex justify-content-between align-content-stretch">-->
          <!--            <div :key="`plan-${i}`" v-for="(plan, i) in plans"-->
          <!--                 class="p-3 shadow-md border-radius-md w-100 mr-3">-->
          <!--              <div class="font-weight-700 mb-2">{{ plan.name }}</div>-->

          <!--              <div :key="`price-${i}-${j}`" v-for="(price, j) in plan.prices"-->
          <!--                   v-if="price.recurring_interval === selectedBillingInterval">-->
          <!--                <span class="text-uppercase font-size-large">{{-->
          <!--                    price.amount / 100-->
          <!--                  }} {{ price.currency }} </span> /-->
          <!--                <span class="text-dark">{{ price.recurring_interval }}</span>-->
          <!--              </div>-->

          <!--              &lt;!&ndash; TODO LIST FEATURES / DIFFERENCES &ndash;&gt;-->
          <!--              <div></div>-->
          <!--            </div>-->
          <!--          </div>-->
        </b-tab>

        <b-tab
          v-if="server.cloud && organization.billing_enabled"
          title="Billing"
          @click="onOpenBillingTab"
        >
          <div class="card p-4">
            <div class="pl-2 pr-2 mb-5">
              <!--              <div class="mb-3">-->
              <!--                <h4><b>{{ organization.name }}</b></h4>-->
              <!--              </div>-->

              <div class="w-100 d-flex justify-content-between mb-3">
                <div class="pl-2">
                  <h5 class="m-0">
                    <fai icon="building" class="mr-2" />
                    <b>{{ organization.name }}</b>
                  </h5>
                </div>
                <div>
                  <b-button @click="onOpenEditBillingDetails" variant="primary"
                    >edit billing details
                  </b-button>
                </div>
              </div>

              <div class="mt-3">
                <b-form-group class="m-1" label="Country:" label-align-sm="left" label-cols-sm="2">
                  <b-form-input v-model="organization.country" plaintext placeholder="-" />
                </b-form-group>

                <b-form-group
                  class="m-1"
                  label="Address line 1:"
                  label-align-sm="left"
                  label-cols-sm="2"
                >
                  <b-form-input v-model="organization.address_line1" plaintext placeholder="-" />
                </b-form-group>

                <b-form-group
                  class="m-1"
                  label="Address line 2:"
                  label-align-sm="left"
                  label-cols-sm="2"
                >
                  <b-form-input v-model="organization.address_line2" plaintext placeholder="-" />
                </b-form-group>

                <b-form-group
                  class="m-1"
                  label="Postal Code:"
                  label-align-sm="left"
                  label-cols-sm="2"
                >
                  <b-form-input v-model="organization.postal_code" plaintext placeholder="-" />
                </b-form-group>

                <b-form-group class="m-1" label="City:" label-align-sm="left" label-cols-sm="2">
                  <b-form-input v-model="organization.city" plaintext placeholder="-" />
                </b-form-group>

                <b-form-group class="m-1" label="State:" label-align-sm="left" label-cols-sm="2">
                  <b-form-input v-model="organization.state" plaintext placeholder="-" />
                </b-form-group>

                <template v-if="!server.cloud">
                  <hr />

                  <b-form-group
                    class="m-1"
                    label="Organization Key:"
                    label-align-sm="left"
                    label-cols-sm="2"
                  >
                    <b-form-input v-model="organization.key" plaintext placeholder="-" />
                  </b-form-group>

                  <b-form-group
                    class="m-1"
                    label="Organization License:"
                    label-align-sm="left"
                    label-cols-sm="2"
                  >
                    <b-input-group>
                      <template v-if="organization.license_valid">
                        <b-form-input plaintext class="text-success" value="valid" />
                      </template>
                      <template v-else>
                        <b-form-input plaintext class="text-danger" value="invalid/expired" />
                      </template>
                      <b-input-group-append>
                        <b-button
                          class="small"
                          squared
                          variant="outline-primary"
                          @click="openOrganizationLicense"
                        >
                          open
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </template>
              </div>
            </div>

            <div class="mb-5">
              <div class="w-100 d-flex justify-content-between mb-3">
                <div class="pl-2">
                  <h5 class="m-0">
                    <fai icon="credit-card" class="mr-2" />
                    <b>Payment Methods</b>
                  </h5>
                </div>
                <div>
                  <b-button variant="success" @click="onOpenPaymentMethod">
                    add payment method
                  </b-button>
                </div>
              </div>

              <!--            <div class="font-size-large font-weight-700 mb-3">Next Invoice (Preview)</div>-->
              <!--            <invoice-preview :invoice="invoicePreview"></invoice-preview>-->

              <!--            <div class="pb-3">-->
              <!--              <h5 class="m-0">-->
              <!--                <fai icon="credit-card" class="mr-2"/>-->
              <!--                <b>Payment Methods</b></h5>-->
              <!--            </div>-->

              <!--            <div class="mb-3">-->
              <!--              <b-button @click="onAddPaymentMethod" size="sm" variant="primary">add payment method-->
              <!--              </b-button>-->
              <!--            </div>-->

              <div v-if="paymentMethods.length === 0" class="text-center p-4 text-dark">
                payment methods are shown here
              </div>

              <div
                v-for="(method, i) in paymentMethods"
                :key="`payment-method-card-${i}`"
                class="pb-3"
              >
                <div
                  class="border-gray-200 shadow-sm border-radius-sm d-flex justify-content-between pl-3 pr-3 pt-2 pb-2 align-items-center"
                >
                  <div>
                    <template v-if="method.type === 'card'">
                      <span class="font-weight-700 text-uppercase mr-3">{{
                        method.card.brand
                      }}</span>
                      <span class="mr-3">Card: •••• •••• •••• {{ method.card.last4 }}</span>
                      <span class="text-dark"
                        >expires {{ method.card.exp_month }} / {{ method.card.exp_year }}</span
                      >
                    </template>
                    <template v-else-if="method.type === 'sepa_debit'">
                      <span class="font-weight-700 mr-3">SEPA Direct Debit</span>
                      <span>IBAN: •••• •••• •••• {{ method.sepa_debit.last4 }}</span>
                    </template>
                  </div>
                  <div class="d-flex justify-content-end">
                    <div>
                      <b-badge v-if="method.default" variant="info" class="mr-3">active</b-badge>
                    </div>
                    <div :id="`tooltip-wrapper-remove-disabled-${i}`">
                      <b-button
                        v-if="!method.default"
                        variant="primary"
                        size="sm"
                        class="mr-3"
                        @click="onSetActivePaymentMethod(method)"
                      >
                        set as active
                      </b-button>
                      <b-button
                        variant="danger"
                        size="sm"
                        :disabled="paymentMethods.length < 2 || method.default"
                        @click="onRemovePaymentMethod(method)"
                      >
                        remove
                      </b-button>
                      <b-tooltip
                        v-if="paymentMethods.length < 2 || method.default"
                        :target="`tooltip-wrapper-remove-disabled-${i}`"
                        triggers="hover"
                        delay="0"
                      >
                        You can delete this payment method if it is not active and not the last one.
                      </b-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="">
              <div class="w-100 d-flex justify-content-between mt-5">
                <div class="pl-2">
                  <h5 class="m-0">
                    <fai icon="file-invoice" class="mr-2" />
                    <b>Upcoming Invoice (Preview)</b>
                  </h5>
                </div>
                <div>
                  <b-button variant="primary" class="mr-2" @click="onOpenInvoices">
                    all invoices
                  </b-button>
                </div>
              </div>
              <div class="p-3">
                <invoice-preview v-if="upcomingInvoice" :invoice="upcomingInvoice" />
                <div v-else class="text-dark text-center mt-3 mb-3">no upcoming invoice</div>
              </div>
            </div>
          </div>
        </b-tab>

        <b-tab title="Usage" lazy>
          <div class="card">
            <div class="p-3">
              <usage-statistics :organization="organization" />
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <b-modal
        id="modal-edit-billing-details"
        ref="modalEditBillingDetails"
        size="md"
        title="Edit Billing Details"
        @show="onBillingDetailsShow"
        @ok="onBillingDetailsOk"
      >
        <loading-overlay v-if="billingDetailsForm.loading" />
        <b-form class="mt-3" @submit.stop.prevent="onBillingDetailsSubmit">
          <b-form-group id="country" label="Country" label-for="countryInput">
            <b-form-select
              id="countryInput"
              v-model="billingDetailsForm.country"
              :options="countries"
              placeholder="Country"
              required
            />
          </b-form-group>

          <b-form-group id="addressLine1" label="Address line 1" label-for="addressLine1Input">
            <b-form-input
              id="addressLine1Input"
              v-model="billingDetailsForm.addressLine1"
              type="text"
              placeholder="Address line 1"
              required
            />
          </b-form-group>

          <b-form-group id="addressLine2" label="Address line 2" label-for="addressLine2Input">
            <b-form-input
              id="addressLine2Input"
              v-model="billingDetailsForm.addressLine2"
              type="text"
              placeholder="Address line 2"
              required
            />
          </b-form-group>

          <b-form-group id="postalCode" label="Postal Code" label-for="postalCodeInput">
            <b-form-input
              id="postalCodeInput"
              v-model="billingDetailsForm.postalCode"
              type="text"
              placeholder="Postal Code"
              required
            />
          </b-form-group>

          <b-form-group id="city" label="City" label-for="cityInput">
            <b-form-input
              id="cityInput"
              v-model="billingDetailsForm.city"
              type="text"
              placeholder="City"
              required
            />
          </b-form-group>

          <b-form-group id="state" label="State" label-for="stateInput">
            <b-form-input
              id="stateInput"
              v-model="billingDetailsForm.state"
              type="text"
              placeholder="State"
              required
            />
          </b-form-group>

          <div class="text-danger font-weight-700 font-size-small mr-1">
            {{ billingDetailsForm.errorMessage }}
          </div>
        </b-form>
      </b-modal>

      <b-modal
        id="modal-payment-method"
        ref="modalPaymentMethod"
        size="md"
        no-close-on-backdrop
        :title="paymentModalTitle"
        @ok="onPaymentMethodOk"
      >
        <div v-show="paymentMethodIntentLoading" class="mh-300px">
          <loading-spinner />
        </div>

        <form id="payment-form">
          <div id="payment-element">
            <!-- Elements will create form elements here -->
          </div>
          <!--              <button id="submit" class="btn btn-primary">Submit</button>-->
          <div id="error-message" class="mt-3">
            <b-alert :show="paymentMethodError !== ''" variant="danger">
              {{ paymentMethodError }}
            </b-alert>
            <!-- Display error message to your customers here -->
          </div>
        </form>
      </b-modal>

      <b-modal
        id="modal-subscription"
        ref="modalSubscription"
        size="lg"
        ok-title="Buy Now"
        :ok-disabled="
          !invoicePreview ||
          !invoicePreview.customer_address ||
          invoicePreview.mode === 'change_none'
        "
        no-close-on-backdrop
        title="Subscription Summary"
        @ok="onSubscriptionOk"
      >
        <template v-if="selectedPlan && plans && plans[selectedPlan] && invoicePreview">
          <div class="font-size-large font-weight-700 mb-3">New Plan</div>
          <div class="p-3 mb-3 border-primary border-radius-md shadow-md">
            <div class="font-size-large font-weight-700">
              {{ selectedPlan }}
            </div>
            {{ plans[selectedPlan].prices[selectedBillingInterval].base.amount | formatPrice }}
            <span class="text-dark font-size-small"> / {{ selectedBillingInterval }}</span>
          </div>

          <div class="mt-2 mb-3">
            <!--            <b-alert class="mb-0" show v-if="invoicePreview.mode === 'create_subscription'">A new subscription </b-alert>-->
            <b-alert v-if="invoicePreview.mode === 'change_immediately'" class="mb-0" show>
              The modified subscription will be activated immediately after purchase.
            </b-alert>
            <b-alert
              v-if="invoicePreview.mode === 'change_at_period_end'"
              class="mb-0"
              show
              variant="warning"
            >
              The subscription will be changed at the end of the current billing period.
            </b-alert>
            <b-alert
              v-if="invoicePreview.mode === 'change_none'"
              class="mb-0"
              show
              variant="danger"
            >
              Invalid request.
            </b-alert>
          </div>

          <div class="font-size-large font-weight-700 mb-3">Upcoming Invoice (Preview)</div>
          <invoice-preview :invoice="invoicePreview" />

          <div class="text-dark font-size-small mt-3 text-right">
            Your active payment method will be charged.
          </div>
        </template>
      </b-modal>

      <b-modal
        id="modal-invoices"
        ref="modalInvoices"
        size="lg"
        ok-only
        ok-title="close"
        title="Invoices"
      >
        <div v-for="(invoice, i) in invoices" :key="`invoice-line-${i}`" class="pb-3">
          <div
            class="border-gray-200 shadow-sm border-radius-sm d-flex justify-content-between pl-3 pr-3 pt-2 pb-2 align-items-center"
          >
            <div>
              <span class="font-weight-700 mr-2">{{ invoice.number }}</span>
              <span>{{ invoice.total | formatPrice }}</span>
            </div>
            <b-link variant="primary" size="sm" :href="invoice.pdf" class="mr-3"> download</b-link>
          </div>
        </div>
      </b-modal>

      <!--      <div class="card mt-4">-->
      <!--        <div @click="viewTeams = !viewTeams" class="row m-0 collapse-header">-->
      <!--          <div class="col-10 pt-4 pl-4 pr-3 p-4">-->
      <!--            <h5 class="m-0">-->
      <!--              <fai icon="user-friends" class="mr-2"/>-->
      <!--              <b>Teams</b></h5>-->
      <!--          </div>-->
      <!--          <div class="col-2 p-4">-->
      <!--            <div class="d-flex flex-row-reverse">-->
      <!--              <fai v-if="!viewTeams"-->
      <!--                                 icon="angle-down"/>-->
      <!--              <fai v-if="viewTeams" icon="angle-up"/>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <b-collapse id="collapse-teams"-->
      <!--                    accordion="teams"-->
      <!--                    v-model="viewTeams">-->
      <!--          <div class="row m-0 p-3">-->
      <!--            <div class="col-12">-->
      <!--              <teams-settings :teams="teams"></teams-settings>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </b-collapse>-->
      <!--      </div>-->
    </template>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { mapActions, mapState } from 'vuex'
// import {loadStripe} from '@stripe/stripe-js'
import countries from '@/countries'
import LicensesSettings from '../components/organization-settings/LicensesSettings'
import LoadingSpinner from '../components/LoadingSpinner'
import UsersSettings from '../components/organization-settings/UsersSettings'
import Cookie from 'js-cookie'
import LoadingOverlayFullscreen from '@/components/LoadingOverlayFullscreen'
import InvoicePreview from '@/components/organization-settings/InvoicePreview'
import UsageStatistics from '@/components/organization-settings/UsageStatistics'
import LoadingOverlay from '@/components/LoadingOverlay'
import TooltipCircle from '@/components/TooltipCircle'

export default {
  name: 'OrganizationSettings',
  components: {
    TooltipCircle,
    LoadingOverlay,
    UsageStatistics,
    InvoicePreview,
    LoadingOverlayFullscreen,
    LicensesSettings,
    UsersSettings,
    LoadingSpinner,
  },
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      paymentMethodIntentLoading: false,
      paymentSetupIntentMode: undefined,
      billingLoading: false,
      tabIndex: 0,

      organization: {},
      users: [],
      licenses: [],
      teams: [],
      viewUsers: true,
      viewTeams: true,
      viewLicenses: true,

      countries: countries,
      billingDetailsForm: {
        loading: false,
        errorMessage: '',
        addressLine1: '',
        addressLine2: '',
        postalCode: '',
        city: '',
        state: '',
        country: '',
      },
      selectedPlan: undefined,
      selectedBillingInterval: 'month',
      requireAddPaymentMethod: false,

      plans: undefined,
      activePlan: undefined,
      paymentMethods: [],
      paymentMethodError: '',
      invoicePreview: undefined,
      invoices: [],
      upcomingInvoice: undefined,
    }
  },
  computed: {
    ...mapState(['user', 'server']),
    highlightedColumnIdx() {
      if (this.activePlan.plan === 'Starter') return 3
      else if (this.activePlan.plan === 'Team') return 4
      return 2
    },
    subscriptionIsIncomplete() {
      return this.activePlan && this.activePlan.status === 'incomplete'
    },
    paymentModalTitle() {
      if (this.subscriptionIsIncomplete) {
        return 'Retry Payment'
      }
      return 'Add Payment Method'
    },
  },

  created: function () {},

  mounted() {
    if (this.$env === 'development') {
      // eslint-disable-next-line no-undef
      this.stripe = Stripe('pk_test_zilBt5AXF33ZCNpW5dPdxnbY00Aw3xRt7s')
    } else {
      // eslint-disable-next-line no-undef
      this.stripe = Stripe('pk_live_bE8d01kOkJM8ts2JXNMuKRwz00U7gm3EHN')
    }

    this.loadData(true)
  },

  methods: {
    ...mapActions(['getWorkers']),
    async loadData(initialLoading) {
      this.loading = true
      let user = this.user

      try {
        this.organization = (
          await this.$axios.get(`/api/organizations/${user.organization.id}`)
        ).data
        this.users = (
          await this.$axios.get(`/api/organizations/${user.organization.id}/users/`)
        ).data
        this.loadLicenses(user.organization.id)
        this.teams = (
          await this.$axios.get(`/api/organizations/${user.organization.id}/teams/`)
        ).data
        await this.loadPlans()
        this.loadPaymentMethods(initialLoading)
      } catch (e) {
        this.$bvToast.toast('Could not load organization details.', {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 4000,
          solid: true,
        })
      }

      if (initialLoading) {
        let q = this.$route.query
        if (q['plan'] !== undefined && q['billing_interval'] !== undefined) {
          this.tabIndex = 1
          this.$router.replace({ query: {} })
          this.selectedPlan = q['plan']
          this.selectedBillingInterval = q['billing_interval']
          this.createOrChangeSubscription()
          // this.onOpenSubscriptionModal()
        } else if (q['setup_intent'] !== undefined) {
          this.tabIndex = 2
          this.$router.replace({ query: {} })
          this.loading = false
        } else {
          this.loading = false
        }
      }
    },
    async loadPlans() {
      if (!this.server.cloud || !this.organization.billing_enabled) {
        return
      }
      const plansResponse = (
        await this.$axios.get(`/api/organizations/${this.user.organization.id}/plans/`)
      ).data
      this.plans = plansResponse.plans
      this.activePlan = plansResponse.active_plan
      this.selectedBillingInterval = this.activePlan.billing_interval
        ? this.activePlan.billing_interval
        : this.selectedBillingInterval
    },
    async loadPaymentMethods() {
      if (!this.server.cloud || !this.organization.billing_enabled) {
        return
      }
      this.billingLoading = true
      try {
        this.paymentMethods = (
          await this.$axios.get(`/api/organizations/${this.user.organization.id}/payment_methods/`)
        ).data
      } catch (e) {
        this.paymentMethods = []
      }
      this.billingLoading = false
    },

    async loadCurrentInvoice() {
      if (!this.server.cloud || !this.organization.billing_enabled) {
        return
      }
      this.billingLoading = true
      try {
        this.upcomingInvoice = (
          await this.$axios.get(`/api/organizations/${this.user.organization.id}/upcoming_invoice/`)
        ).data
      } catch (e) {
        this.upcomingInvoice = undefined
      }
      this.billingLoading = false
    },

    async onOpenBillingTab() {
      await this.loadPaymentMethods()
      await this.loadCurrentInvoice()
    },

    onOpenSubscriptionModal(plan) {
      if (!this.server.cloud || !this.organization.billing_enabled) {
        return
      }
      this.selectedPlan = plan

      // if (this.paymentMethods.length === 0) {
      // }

      this.invoicePreview = undefined
      this.requireAddPaymentMethod = this.paymentMethods.length === 0

      this.loading = true
      const self = this
      this.$axios({
        method: 'post',
        url: `/api/organizations/${this.organization.id}/subscription_preview/`,
        data: {
          new_plan: this.selectedPlan,
          new_billing_interval: this.selectedBillingInterval,
        },
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
        .then((response) => {
          self.loading = false
          self.invoicePreview = response.data
        })
        .catch(() => {
          self.$bvToast.toast('Could not load subscription preview.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
          self.loading = false
        })

      this.$refs.modalSubscription.show()
    },

    createOrChangeSubscription() {
      if (!this.server.cloud || !this.organization.billing_enabled) {
        return
      }
      const self = this
      self.loading = true
      if (!this.activePlan.plan) {
        this.$axios({
          method: 'post',
          url: `/api/organizations/${this.organization.id}/subscription/`,
          data: {
            plan_name: this.selectedPlan,
            billing_interval: this.selectedBillingInterval,
          },
          withCredentials: true,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': Cookie.get('csrftoken'),
          },
        })
          .then(() => {
            self.loadData(false).then(() => {
              self.$refs.modalSubscription.hide()
              self.loading = false
              self.getWorkers({ forceReload: true })
            })
            // self.loadPlans().then(() => {
            //   self.$refs.modalSubscription.hide()
            //   self.loading = false
            // })
          })
          .catch((error) => {
            if (error && error.response && error.response.data && error.response.data.error) {
              this.$bvToast.toast(error.response.data.error, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 4000,
                solid: true,
              })
            } else {
              console.error(error)
              this.$bvToast.toast('Could not create subscription.', {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 4000,
                solid: true,
              })
            }
            self.loading = false
          })
      } else {
        this.$axios({
          method: 'patch',
          url: `/api/organizations/${this.organization.id}/subscription/`,
          data: {
            plan_name: this.selectedPlan,
            billing_interval: this.selectedBillingInterval,
          },
          withCredentials: true,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': Cookie.get('csrftoken'),
          },
        })
          .then(() => {
            self.loadPaymentMethods()
            self.loadPlans().then(() => {
              self.$refs.modalSubscription.hide()
              self.getWorkers({ forceReload: true })
              self.loading = false
            })
          })
          .catch((error) => {
            if (error && error.response && error.response.data && error.response.data.error) {
              this.$bvToast.toast(error.response.data.error, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 4000,
                solid: true,
              })
            } else {
              console.error(error)
              this.$bvToast.toast('Could not change subscription.', {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 4000,
                solid: true,
              })
            }
            self.loading = false
          })
      }
    },

    onSubscriptionOk(event) {
      if (!this.server.cloud || !this.organization.billing_enabled) {
        return
      }
      event.preventDefault()
      this.invoicePreview = undefined
      if (this.requireAddPaymentMethod) {
        this.onOpenPaymentMethod()
      } else {
        this.createOrChangeSubscription()
      }
    },

    onOpenInvoices() {
      this.$refs.modalInvoices.show()
      this.loading = true
      this.invoices = []
      const self = this
      const organizationId = this.organization.id
      this.$axios
        .get(`/api/organizations/${organizationId}/invoices/`)
        .then((response) => {
          self.invoices = response.data
          self.loading = false
        })
        .catch(() => {
          self.$bvToast.toast('Could not load the list of invoices. Please try again later.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
          self.$refs.modalInvoices.hide()
          self.loading = false
        })
    },
    onOpenEditBillingDetails() {
      if (!this.server.cloud || !this.organization.billing_enabled) {
        return
      }
      this.$refs.modalEditBillingDetails.show()
    },

    onBillingDetailsShow() {
      if (!this.organization) return
      this.billingDetailsForm.addressLine1 = this.organization.address_line1
      this.billingDetailsForm.addressLine2 = this.organization.address_line2
      this.billingDetailsForm.postalCode = this.organization.postal_code
      this.billingDetailsForm.city = this.organization.city
      this.billingDetailsForm.state = this.organization.state
      this.billingDetailsForm.country = this.organization.country
    },

    onBillingDetailsOk(event) {
      event.preventDefault()
      this.onBillingDetailsSubmit()
    },

    onBillingDetailsSubmit() {
      const self = this
      this.billingDetailsForm.loading = true
      this.billingDetailsForm.errorMessage = ''
      this.$axios({
        method: 'patch',
        url: `/api/organizations/${this.organization.id}/`,
        data: {
          address_line1: this.billingDetailsForm.addressLine1,
          address_line2: this.billingDetailsForm.addressLine2,
          postal_code: this.billingDetailsForm.postalCode,
          city: this.billingDetailsForm.city,
          state: this.billingDetailsForm.state,
          country: this.billingDetailsForm.country,
        },
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
        .then((response) => {
          self.organization = response.data
          self.billingDetailsForm.loading = false
          self.$refs.modalEditBillingDetails.hide()
        })
        .catch(() => {
          self.billingDetailsForm.errorMessage = 'An error occurred.'
          self.billingDetailsForm.loading = false
        })
    },

    onOpenPaymentMethod() {
      if (!this.server.cloud || !this.organization.billing_enabled) {
        return
      }
      this.$refs.modalPaymentMethod.show()
      this.paymentMethodIntentLoading = true

      const self = this
      const organizationId = this.organization.id
      this.$axios
        .get(`/api/organizations/${organizationId}/payment_setup_intent/`)
        .then((response) => {
          self.paymentSetupIntentMode = response.data.mode
          const options = {
            clientSecret: response.data.client_secret,
            // Fully customizable with appearance API.
            appearance: {
              /*...*/
            },
          }

          self.elements = self.stripe.elements(options)
          self.paymentElement = self.elements.create('payment')
          self.paymentElement.mount('#payment-element')
          self.paymentElement.on('ready', () => {
            self.paymentMethodIntentLoading = false
          })
        })
        .catch(() => {
          let errorMsg = 'Could not start adding payment process. Please try again later.'
          if (this.subscriptionIsIncomplete) {
            errorMsg = 'Could not start payment process. Please try again later.'
          }
          self.$bvToast.toast(errorMsg, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
          self.paymentMethodIntentLoading = false
          self.$refs.modalPaymentMethod.hide()
        })
    },

    async onPaymentMethodOk(event) {
      if (!this.server.cloud || !this.organization.billing_enabled) {
        return
      }
      event.preventDefault()

      let returnUrl = window.location.origin + window.location.pathname
      if (this.requireAddPaymentMethod) {
        returnUrl += `?plan=${this.selectedPlan}&billing_interval=${this.selectedBillingInterval}`
      }

      const self = this
      self.loading = true

      if (this.paymentSetupIntentMode === 'payment_intent') {
        const { error } = await self.stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements: self.elements,
          confirmParams: {
            return_url: returnUrl,
          },
        })
        if (error) {
          self.paymentMethodError = error.message
        }
      } else {
        const { error } = await self.stripe.confirmSetup({
          //`Elements` instance that was used to create the Payment Element
          elements: self.elements,
          confirmParams: {
            return_url: returnUrl,
          },
        })
        if (error) {
          self.paymentMethodError = error.message
        }
      }

      self.loading = false
    },

    onCancelSubscriptionModal() {
      if (!this.server.cloud || !this.organization.billing_enabled) {
        return
      }
      const self = this
      this.$bvModal
        .msgBoxConfirm(
          `Your subscription will be cancelled at the end of the current period. Are you sure that you want to cancel your subscription?`,
          {
            title: `Cancel Subscription`,
            okVariant: 'danger',
            cancelTitle: 'Back',
            okTitle: 'Cancel Subscription',
          }
        )
        .then((value) => {
          if (value) {
            self.loading = true
            self
              .$axios({
                method: 'patch',
                url: `/api/organizations/${self.organization.id}/subscription/`,
                data: {
                  plan_name: self.activePlan.plan,
                  billing_interval: self.activePlan.billing_interval,
                  cancel_at_period_end: true,
                },
                withCredentials: true,
                headers: {
                  'X-Requested-With': 'XMLHttpRequest',
                  'X-CSRFToken': Cookie.get('csrftoken'),
                },
              })
              .then(() => {
                self.loadPlans().then(() => {
                  self.loading = false
                })
              })
              .catch(() => {
                self.loading = false
                self.$bvToast.toast(
                  'Could not cancel the subscription. Please contact the support.',
                  {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 4000,
                    solid: true,
                  }
                )
              })
          }
        })
    },
    onReEnableSubscriptionModal() {
      if (!this.server.cloud || !this.organization.billing_enabled) {
        return
      }
      const self = this
      this.$bvModal
        .msgBoxConfirm(
          `Your subscription will be cancelled at the end of the current period. Do you want to revoke the cancellation?`,
          {
            title: `Re-enable Subscription`,
            okVariant: 'primary',
            okTitle: 'Revoke Cancellation',
          }
        )
        .then((value) => {
          if (value) {
            self.loading = true
            self
              .$axios({
                method: 'patch',
                url: `/api/organizations/${self.organization.id}/subscription/`,
                data: {
                  plan_name: self.activePlan.plan,
                  billing_interval: self.activePlan.billing_interval,
                  cancel_at_period_end: false,
                },
                withCredentials: true,
                headers: {
                  'X-Requested-With': 'XMLHttpRequest',
                  'X-CSRFToken': Cookie.get('csrftoken'),
                },
              })
              .then(() => {
                self.loadPlans().then(() => {
                  self.loading = false
                })
              })
              .catch(() => {
                self.loading = false
                self.$bvToast.toast(
                  'Could not revoke the cancellation of the subscription. Please contact the support.',
                  {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 4000,
                    solid: true,
                  }
                )
              })
          }
        })
    },

    onSetActivePaymentMethod(paymentMethod) {
      if (!this.server.cloud || !this.organization.billing_enabled) {
        return
      }
      this.billingLoading = true
      this.$axios({
        method: 'post',
        url: `/api/organizations/${this.organization.id}/activate_payment_method/`,
        data: {
          payment_method_id: paymentMethod.id,
        },
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
        .then(() => {
          this.loadPaymentMethods()
        })
        .catch(() => {
          this.billingLoading = false
          this.$bvToast.toast('Could not set payment method as active.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
        })
    },
    onRemovePaymentMethod(paymentMethod) {
      if (!this.server.cloud || !this.organization.billing_enabled) {
        return
      }
      this.$bvModal
        .msgBoxConfirm(`Are you sure that you want to remove this payment method?`, {
          title: `Remove Payment Method`,
          okVariant: 'danger',
          okTitle: 'Remove',
        })
        .then((value) => {
          if (value) {
            this.billingLoading = true
            this.$axios({
              method: 'post',
              url: `/api/organizations/${this.organization.id}/remove_payment_method/`,
              data: {
                payment_method_id: paymentMethod.id,
              },
              withCredentials: true,
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': Cookie.get('csrftoken'),
              },
            })
              .then(() => {
                this.loadPaymentMethods()
                this.$bvToast.toast('Payment method removed.', {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 4000,
                  solid: true,
                })
              })
              .catch(() => {
                this.billingLoading = false
                this.$bvToast.toast('Could not remove payment method.', {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 4000,
                  solid: true,
                })
              })
          }
        })
    },
    isActive(planName) {
      if (planName === 'Free' && !this.activePlan.plan) return true

      return (
        this.activePlan.plan === planName &&
        !this.activePlan.cancel_at_period_end &&
        this.activePlan.billing_interval === this.selectedBillingInterval
      )
    },
    openOrganizationLicense() {
      this.$router.push({ path: 'organization-license' })
    },
    loadLicenses(organizationId) {
      if (organizationId === undefined) {
        organizationId = this.organization.id
      }
      this.$axios
        .get(`/api/organizations/${organizationId}/licenses/`)
        .then((response) => {
          this.licenses = response.data
        })
        .catch(() => {})
        .finally(() => {})
    },
    userCreated(user) {
      this.users.push(user)
    },
    userDeleted(user) {
      this.users = this.$_.filter(this.users, function (u) {
        return user.id !== u.id
      })

      for (let i in this.licenses) {
        let license = this.licenses[i]
        if (license.user === user.id) {
          license.username = null
          license.user = null
        }
      }
    },
    userChanged(user) {
      if (user.id === this.user.id) {
        this.$store.dispatch('getUser')
      }
      for (let i in this.users) {
        let u = this.users[i]
        if (u.id === user.id) {
          this.$set(this.users, i, user)
        }
      }

      for (let i in this.licenses) {
        let license = this.licenses[i]
        if (license.user === user.id) {
          license.username = user.username
          license.user = user.id
        }
      }
    },

    licenseChanged(event) {
      let license = event.license
      let user = event.user
      let previousUser = event.previousUser

      if (user === this.user.id || previousUser === this.user.id) {
        this.$store.dispatch('getUser')
      }

      for (let i in this.licenses) {
        let l = this.licenses[i]
        if (l.id === license) {
          if (user !== null) {
            l.username = user.username
            l.user = user.id
          } else {
            l.username = null
            l.user = null
          }
        }
      }

      for (let i in this.users) {
        let u = this.users[i]
        if (previousUser !== null && u.id === previousUser) {
          this.$set(this.users[i], 'license', null)
        }

        if (u.id === user) {
          this.$set(this.users[i], 'license', license)
        }
      }

      this.loadLicenses()
    },
  },
}
</script>

<style scoped lang="scss">
@import '../custom';

.container {
  min-height: calc(100vh - 70px);
}

.highlighted-col {
  //background: $blue-light;
  //outline: 2px solid $primary;
}

.pricing-grid {
  padding-bottom: 1rem;
  grid-template-columns: [col-line-1] 1fr [col-line-2] 1fr [col-line-3] 1fr [col-line-4] 1fr [col-line-5];
  grid-template-rows: [row-line-1] auto [row-last-line];

  display: grid;

  //grid-template-columns: 1fr 1fr 1fr 1fr;
  //grid-template-rows: auto;

  //grid-template-areas:
  //  "col-1 col-2 col-3 col-4";

  column-gap: 1rem;
  row-gap: 1rem;
}

@mixin c-x {
  @for $i from 1 through 4 {
    .c-#{$i} {
      grid-column-start: #{$i};
    }
  }
}

@include c-x;

@mixin r-x {
  @for $i from 1 through 12 {
    .r-#{$i} {
      grid-row-start: #{$i};
    }
  }
}

@include r-x;

.pricing-grid-item {
  padding-left: 1rem;
  padding-right: 1rem;

  //&:nth-child(8n) {
  //  background-color: $gray-100;
  //}
}

.pricing-cells-highlighted {
  @extend .border-radius-md;
  margin-bottom: -1rem;
  grid-row-start: row-line-1;
  grid-row-end: span 11;

  outline: 2px solid $primary;
  z-index: 1;
  pointer-events: none;
}

.pricing-feature-name {
  @extend .text-dark;
}

.pricing-feature-value {
  @extend .font-weight-500;
  @extend .text-center;
}
</style>
