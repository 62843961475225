<template>
  <div v-show="show" class="context-menu" :style="{ top: `${y}px`, left: `${x}px` }">
    <div
      v-for="(entry, i) in entries"
      :key="`context-entry-${i}`"
      class="context-menu-entry"
      @click="click(entry)"
    >
      {{ entry.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContextMenu',
  props: {
    entries: Array,
    show: Boolean,
    x: Number,
    y: Number,
  },
  created() {
    document.addEventListener('click', this.documentClick)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.documentClick)
  },
  methods: {
    documentClick() {
      if (this.show) {
        this.$root.$emit('close-context-menu')
      }
    },
    click(entry) {
      entry.click()
      this.$root.$emit('close-context-menu')
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../custom';

.context-menu {
  position: fixed;

  width: 150px;

  border: #eeeeee solid 1px;
  border-radius: 3px;

  background: #ffffff;

  -webkit-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.08);

  z-index: 2000;
}

.context-menu-entry {
  @extend .no-select;

  border-radius: 2px;

  width: 100%;
  text-align: left;
  cursor: pointer;

  padding: 0.25em 0.7em;

  &:hover {
    //color: $white;
    background-color: $gray-300;
  }

  &:active {
    color: $white;
    background-color: $primary;
  }
}
</style>
