<template>
  <div>
    <b-form-group :label="field.display_name" v-b-popover.hover.left="field.description">
      <b-button @click="formatJson()" size="sm" class="mb-2">format json</b-button>
      <b-form-textarea :id="$id(field.name)" class="mb-1" type="text" plaintext :value="jsonString">
      </b-form-textarea>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'JsonTextParameter',
  props: ['value', 'field'],
  data() {
    return {
      jsonString: '',
      validJson: true,
    }
  },

  mounted() {
    this.jsonString = JSON.stringify(this.value)
  },

  methods: {
    formatJson() {
      this.jsonString = JSON.stringify(this.value, null, 2)
    },
  },
}
</script>
