<template>
  <div class="p-2 mb-1">
    <strong>{{ field.display_name }}: {{ value.selection }}</strong>
    <hr />
    <parameter-viewer
      v-if="fields !== undefined"
      :key="selectedGroup"
      :fields="fields"
      v-bind:value="value"
    ></parameter-viewer>
  </div>
</template>

<script>
export default {
  props: ['value', 'field'],
  name: 'group-selection',
  components: {
    ParameterViewer: () => import('../ParameterViewer'),
  },
  data() {
    return {
      selectedGroup: -1,
    }
  },

  computed: {
    fields: function () {
      if (this.selectedGroup >= 0) {
        return this.field.groups[this.selectedGroup]['fields']
      }

      return []
    },

    availableGroups: function () {
      let result = []
      for (let i = 0; i < this.field.groups.length; i++) {
        let group = this.field.groups[i]
        result.push({ text: group.display_name, value: i })
      }

      return result
    },
  },

  mounted() {
    for (let i = 0; i < this.field.groups.length; i++) {
      let group = this.field.groups[i]
      if (this.value !== undefined && group.name === this.value.selection) {
        this.selectedGroup = i
        break
      }
    }
  },

  methods: {},
}
</script>

<style scoped>
/*.group {*/
/*  border: #ced4da 1px solid;*/
/*  border-radius: 0.25rem;*/
/*}*/
</style>
