<template>
  <div id="app">
    <basic-overlay
      v-if="connectionError"
      class="d-flex flex-column justify-content-center align-items-center gray-overlay blur-background"
    >
      <div class="bg-white border-gray-200 shadow-md border-radius-md p-4 text-center">
        <div class="mb-3 font-weight-700">Connection error - cannot connect to API</div>
        <div v-if="$statusPageEnabled" class="mb-3">
          <b-link href="https://data-spree.betteruptime.com/" target="_blank"
            >status page
            <fai icon="external-link-alt" class="ml-1" size="sm"></fai>
          </b-link>
        </div>
        <b-button @click="onClickReload" variant="primary">Reload</b-button>
      </div>
    </basic-overlay>
    <div v-if="loading" id="loading">
      <loading-spinner />
    </div>
    <template v-else-if="user.username !== '' || anonymousRoutes.includes($route.name)">
      <template v-if="!anonymousRoutes.includes($route.name)">
        <div
          v-if="$_.isEmpty(user.license) || !user.license.valid"
          class="w-100 bg-danger text-white text-center font-weight-500"
        >
          You do not have a valid user license. User licenses are managed in the settings of your
          organisation.
        </div>
        <top-navigation v-if="!hideNavbar" />
      </template>
      <transition name="fade" mode="out-in">
        <div class="position-relative">
          <overlay-component
            v-if="protectedRoutes.includes($route.name) && !userHasLicense"
            :z-index="500"
          >
            <div class="text-dark maxw-300px">
              <fai icon="lock" class="mr-1" />
              You need a valid user license to access {{ $route.name }}. Go to the settings of your
              organization or ask your organization's administrator to assign you a user license.
            </div>
          </overlay-component>
          <router-view />
        </div>
      </transition>
    </template>
    <template v-else>
      <login-component />
    </template>
    <footer v-if="anonymousRoutes.includes($route.name) || user.username === ''" class="footer">
      <div class="container-fluid bg-primary text-right text-light p-3">
        <div class="d-flex justify-content-between">
          <img class="squares-image" src="@/assets/squares.svg" />
          <small>
            <template v-if="user.username !== ''">
              <b-link v-b-modal.modal-downloads class="text-light">Downloads</b-link>
              |
            </template>
            <b-link
              class="text-light"
              href="https://docs.data-spree.com/deep-learning-ds"
              target="_blank"
            >
              Documentation
            </b-link>
            |
            <b-link v-b-modal.modal-terms-and-conditions class="text-light">
              Terms and Conditions / AGB
            </b-link>
            <!--Terms and Conditions</b-link>-->
            |
            <b-link v-b-modal.modal-impressum class="text-light">Imprint</b-link>
            | Copyright &copy; Data Spree
          </small>
        </div>
      </div>
    </footer>

    <b-modal id="modal-terms-and-conditions" ok-only title="Allgemeine Geschäftsbedingungen">
      <b-button
        block
        href="/agb/2019-07-29_Allgemeine_Vertragsbedingungen.pdf"
        target="_blank"
        variant="info"
      >
        Allgemeine Vertragsbedingungen
      </b-button>
      <b-button
        block
        href="/agb/2019-07-29_SaaS_Nutzungsbedingungen.pdf"
        target="_blank"
        variant="info"
      >
        Nutzungsbedingungen für Software as a Service (SaaS)
      </b-button>
    </b-modal>

    <b-modal id="modal-impressum" ok-only title="Impressum">
      <p>Angaben gemäß §5 TMG</p>

      <p>
        <strong>Adresse</strong><br />
        Data Spree GmbH<br />
        Waldenserstr. 2-4<br />
        10551 Berlin
      </p>

      <p>
        <strong>Geschäftsführer</strong><br />
        Eric Dörheit<br />
        Manuel Haß
      </p>

      <p>
        <strong>Handelsregister</strong><br />
        Amtsgericht Charlottenburg<br />
        HRB 200465 B
      </p>

      <p>
        <strong>Kontakt</strong><br />
        info@data-spree.com
      </p>
    </b-modal>

    <b-modal id="modal-downloads" ok-only size="lg" title="Downloads">
      <table class="table table-sm table-striped">
        <thead>
          <tr>
            <th scope="col">Release version</th>
            <th scope="col">Release date</th>
            <th scope="col">Downloads</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Inference DS 0.6.1</td>
            <td>March 18, 2020</td>
            <td>
              <b-link
                href="/downloads/InferenceDS/v0.6.1/Inference DS Installer.exe"
                target="_blank"
              >
                Windows CPU
              </b-link>
              <br />
              <b-link
                href="/downloads/InferenceDS/v0.6.1/Inference DS Documentation 0.6.1.pdf"
                target="_blank"
              >
                Documentation
              </b-link>
              <br />
            </td>
          </tr>
          <tr>
            <td>Inference DS 0.6.0</td>
            <td>March 03, 2020</td>
            <td>
              <b-link
                href="/downloads/InferenceDS/v0.6.0/Inference DS Installer.exe"
                target="_blank"
              >
                Windows CPU
              </b-link>
              <br />
              <b-link
                href="/downloads/InferenceDS/v0.6.0/Inference DS Documentation 0.6.0.pdf"
                target="_blank"
              >
                Documentation
              </b-link>
              <br />
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>

    <support-widget v-if="server.enableSupport && user && user.id" />

    <template v-if="user && user.id">
      <ai-model-details-modal
        :model="modals.aiModelDetails.model"
        :show="modals.aiModelDetails.show"
        @show="onAiModelDetailsShow"
      />
    </template>
    <add-worker-modal />

    <context-menu v-bind="contextMenu" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import TopNavigation from '@/components/TopNavigation.vue'
import LoginComponent from '@/components/LoginComponent.vue'
import LoadingSpinner from '@/components/LoadingSpinner'
import ContextMenu from '@/components/contextmenu/ContextMenu'
import AiModelDetailsModal from './components/modals/AiModelModalDetails'
import AddWorkerModal from '@/components/modals/AddWorkerModal'
import OverlayComponent from '@/components/OverlayComponent'
import SupportWidget from '@/components/SupportWidget'
import BasicOverlay from '@/components/BasicOverlay'
import _ from 'lodash'

export default {
  components: {
    BasicOverlay,
    SupportWidget,
    OverlayComponent,
    AddWorkerModal,
    AiModelDetailsModal,
    ContextMenu,
    TopNavigation,
    LoginComponent,
    LoadingSpinner,
  },

  data() {
    return {
      connectionError: false,
      contextMenu: {
        show: false,
        x: 0,
        y: 0,
        entries: [],
      },
      anonymousRoutes: ['register', 'activate-user', 'confirm-password-reset'],
      protectedRoutes: [
        'class-labels',
        'dataset-explorer',
        'model-explorer',
        'job-explorer',
        'workers',
      ],
    }
  },
  computed: {
    ...mapState(['server', 'user', 'loading', 'modals', 'hideNavbar']),
    ...mapGetters(['userHasLicense']),
  },
  created() {
    this.$root.$on('open-context-menu', (contextMenu) => {
      this.contextMenu.show = true
      this.contextMenu.x = contextMenu.x
      this.contextMenu.y = contextMenu.y
      this.contextMenu.entries = contextMenu.entries
    })

    this.$root.$on('close-context-menu', () => {
      this.contextMenu.show = false
    })

    this.loadApplicationData()
  },
  watch: {
    'modals.aiModelDetails.model'() {
      if (this.modals.aiModelDetails.show) {
        if (this.modals.aiModelDetails.model) {
          const q = { ...this.$route.query, model: `${this.modals.aiModelDetails.model.id}` }
          if (!_.isEqual(q, this.$route.query)) {
            this.$router.replace({
              query: q,
            })
          }
        }
      } else {
        let q = { ...this.$route.query }
        delete q.model
        if (!_.isEqual(q, this.$route.query)) {
          this.$router.replace({
            query: q,
          })
        }
      }
    },
  },
  methods: {
    ...mapActions([
      'getCSRF',
      'getUser',
      'getServer',
      'getDatasets',
      'getClassLabels',
      'getJobs',
      'login',
      'setLoading',
      'openAiModal',
      'closeAiModal',
    ]),
    async loadApplicationData() {
      try {
        await this.setLoading(true)

        this.connectionError = false
        try {
          await this.getServer()
          await this.getCSRF()
        } catch (e) {
          this.connectionError = true
        }

        if (!this.connectionError) {
          await this.getUser()
          await this.getClassLabels()
          await this.getJobs()

          if (!this.updateInterval) {
            this.updateInterval = setInterval(() => {
              this.getJobs()
            }, 10000)
          }
        }
        await this.setLoading(false)
      } catch (e) {
        console.log(e)
        await this.setLoading(false)
      }
    },

    onClickReload() {
      this.loadApplicationData()
    },

    onAiModelDetailsShow(show) {
      if (!show) {
        this.closeAiModal()
      }
    },
  },
}
</script>

<style lang="scss">
@import 'custom';

html {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100%;
}

#app {
  //font-family: 'IBM Plex Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-image: url('./assets/background.svg');
  background-repeat: no-repeat;
  background-position: bottom right;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /*background: none;*/
}

#loading {
  height: 100vh;
  position: relative;
}

.squares-image {
  max-height: 1rem;
}

.footer {
}

.white-background {
  background: #ffffff;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  /*border: 1px solid rgba(0, 0, 0, 0.125);*/
  border: 1px solid rgba(0, 0, 0, 0.075);
  //border-radius: 0.25rem;

  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
}

//.collapse-header:hover {
//  @extend .border-radius-md;
//  cursor: pointer;
//  background-color: $blue-light;
//}

.caret-icon {
  min-width: 0.8em;
}
</style>
