<template>
  <div>
    <div>
      <loading-spinner id="loading-spinner" />
    </div>
    <div class="loading-background" />
  </div>
</template>

<script>
import LoadingSpinner from '../components/LoadingSpinner'

export default {
  name: 'LoadingOverlayFullscreen',
  components: { LoadingSpinner },
}
</script>

<style scoped>
#loading-spinner {
  position: fixed;
  z-index: 1100;
}

.loading-background {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1075;
}
</style>
