<template>
  <div
    class="group"
    :class="{ 'parent-level': level === 0, 'child-level': level > 0 }"
    v-show="!field.hidden"
  >
    <span class="font-weight-700" :class="{ 'text-capitalize': level === 0 }">{{
      field.display_name
    }}</span>
    <!--    <hr>-->
    <div class="mt-2">
      <b-form-group :description="field.description">
        <b-form-select
          :id="$id(field.name)"
          v-model="selectedGroup"
          :disabled="plaintext || field.editable === false"
          :options="availableGroups"
        />
      </b-form-group>
      <form-generator
        v-if="fields !== undefined"
        :key="selectedGroup"
        :fields="fields"
        :value="value"
        :all-plaintext="plaintext || field.editable === false"
        :size="size"
        :form-group-attributes="formGroupAttributes"
        @input="onInput"
        :level="this.level + 1"
      ></form-generator>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'field', 'plaintext', 'size', 'formGroupAttributes', 'level'],
  name: 'group-selection',
  components: {
    FormGenerator: () => import('../FormGenerator'),
  },
  data() {
    return {
      selectedGroup: 0,
    }
  },

  computed: {
    fields: function () {
      if (this.selectedGroup >= 0) {
        return this.field.groups[this.selectedGroup]['fields']
      }

      return []
    },

    availableGroups: function () {
      let result = []
      for (let i = 0; i < this.field.groups.length; i++) {
        let group = this.field.groups[i]
        result.push({ text: group.display_name, value: i })
      }

      return result
    },
  },

  mounted() {
    for (let i = 0; i < this.field.groups.length; i++) {
      let group = this.field.groups[i]

      if (
        this.value !== undefined &&
        Object.prototype.hasOwnProperty.call(this.value, 'selection')
      ) {
        if (group.name === this.value.selection) {
          this.selectedGroup = i
          break
        }
      } else if (this.field.groups.length > 0) {
        this.selectedGroup = 0
      }
    }
  },

  methods: {
    onInput(event) {
      if (this.selectedGroup !== -1) {
        event['selection'] = this.field.groups[this.selectedGroup].name
        this.$emit('input', event)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.parent-level {
  margin-bottom: 1rem;
}

.child-level {
  margin-bottom: 0;
}
</style>
