<template>
  <div
    class="group"
    :class="{ 'parent-level': level === 0, 'child-level': level > 0 }"
    v-show="!field.hidden"
  >
    <b-form-checkbox
      :id="$id('groupSwitchableBoolInput') + field.name"
      v-model="active"
      @input="onChange"
      :disabled="plaintext || field.editable === false"
    >
      <span :class="{ 'text-capitalize': level === 0 }">{{ field.display_name }}</span>
    </b-form-checkbox>

    <div class="pl-0 pt-3 pb-0" v-if="active">
      <form-generator
        v-if="field.fields !== undefined"
        :key="'group-switchable' + field.name"
        :fields="field.fields"
        :value="value"
        @input="onInput"
        :form-group-attributes="formGroupAttributes"
        :size="size"
        :all-plaintext="plaintext || field.editable === false"
        :level="this.level + 1"
      ></form-generator>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'field', 'plaintext', 'size', 'formGroupAttributes', 'level'],
  name: 'group-switchable',
  components: {
    FormGenerator: () => import('../FormGenerator'),
  },
  data() {
    return {
      active: false,
      data: undefined,
    }
  },

  created() {
    this.data = this.value
    this.active = this.value !== undefined
  },

  mounted() {},

  methods: {
    onChange() {
      if (this.active) {
        this.$emit('input', this.data)
      } else {
        this.$emit('input', undefined)
      }
    },

    onInput(event) {
      this.data = event
      if (this.active) {
        this.$emit('input', this.data)
      } else {
        this.$emit('input', undefined)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.parent-level {
  margin-bottom: 1rem;
}

.child-level {
  margin-bottom: 0;
}
</style>
