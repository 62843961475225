<template>
  <b-form-group :label="field.display_name" v-b-popover.hover.left="field.description">
    <b-form-select :id="$id(field.name)" disabled :value="value" :options="field.options" />
  </b-form-group>
</template>

<script>
export default {
  name: 'SelectionDropdown',
  props: ['value', 'field'],
  data() {
    return {}
  },

  mounted() {
    // console.log(JSON.stringify(this.field))
  },

  methods: {},
}
</script>
