<template>
  <div class="container">
    <div
      v-if="!centerLoading && user !== undefined && user.organization !== undefined"
      class="row mt-2"
    >
      <div v-if="server.cloud" class="col-12 pt-5 text-center">
        <h4>Valid Organisation License</h4>
        The license of your organization is valid.
      </div>
      <template v-else-if="user.organization.is_admin">
        <div class="col-12 pt-5 text-center">
          <template v-if="user.organization.license_valid">
            <fai icon="lock-open" class="mt-5 mb-5 lock text-success" />
            <h4>Valid Organisation License</h4>
            <p class="mt-3">The license of your organization is valid.</p>
          </template>
          <template v-else>
            <fai icon="lock" class="mt-5 mb-5 lock text-danger" />
            <h4>Invalid Organisation License</h4>
            <p class="mt-3">The license of your organization has expired or is invalid.</p>
          </template>
          <p class="mt-3">
            <b
              >Upload new license for {{ user.organization.name }} (key:
              {{ user.organization.organization_key }}):</b
            >
          </p>
          <vue-dropzone
            id="dropzone"
            ref="licenseUploadDropzone"
            class="mt-4"
            :options="dropzoneOptions"
            @vdropzone-sending="fileSendingEvent"
            @vdropzone-success="fileSuccessEvent"
            @vdropzone-error="fileErrorEvent"
            @vdropzone-complete="fileCompleteEvent"
          />
          <b-button to="/organization" variant="primary" class="mt-4">
            Organization Settings
          </b-button>
        </div>
      </template>
      <div v-else class="col-12 pt-5 text-center">
        <template v-if="user.organization.license_valid">
          <fai icon="lock-open" class="mt-5 mb-5 lock text-secondary" />
          <h4>Valid Organisation License</h4>
          <p class="mt-3">
            The license of your organization is valid. Please contact an administrator of your
            organization for details.
          </p>
        </template>
        <template v-else>
          <fai icon="lock" class="mt-5 mb-5 lock text-secondary" />
          <h4>Invalid Organisation License</h4>
          <p class="mt-3">
            The license of your organization has expired or is invalid. Please contact an
            administrator of your organization for details.
          </p>
        </template>
      </div>
    </div>
    <div v-else class="row mt-2">
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { mapState } from 'vuex'

import LoadingSpinner from '../components/LoadingSpinner'

export default {
  name: 'OrganizationLicense',
  components: {
    vueDropzone: vue2Dropzone,
    LoadingSpinner,
  },
  data() {
    return {
      centerLoading: false,
      user: {},
      dropzoneOptions: {
        dictDefaultMessage: 'Upload new license',
        paramName: 'license_file',
        method: 'patch',
        url: '',
        createImageThumbnails: false,
        headers: { 'X-CSRFToken': Cookie.get('csrftoken') },
        withCredentials: true,
      },
    }
  },

  created: function () {},

  mounted() {
    this.loadUser()
  },

  computed: {
    ...mapState(['server']),
  },

  methods: {
    loadUser() {
      let user = this.$store.getters.user
      if (user !== undefined) {
        this.user = user
        this.dropzoneOptions.url = `${this.$axios.defaults.baseURL}/api/organizations/${this.user.organization.id}/`
      }
    },

    fileSendingEvent() {
      this.cookie = 'COOKIE: ' + Cookie.get('csrftoken')
    },

    fileSuccessEvent() {
      this.centerLoading = true
      this.$store
        .dispatch('getUser')
        .catch(() => {})
        .finally(() => {
          let user = this.$store.getters.user
          this.user = user
          this.centerLoading = false
        })
    },

    fileErrorEvent() {
      this.$bvToast.toast('Could not upload license.', {
        title: 'Error',
        variant: 'danger',
        autoHideDelay: 4000,
        solid: true,
      })
    },

    fileCompleteEvent(file) {
      this.$refs.licenseUploadDropzone.removeFile(file)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
  min-height: calc(100vh - 70px);
}

.lock {
  font-size: 8em;
}

.dropzone {
  border-style: dashed;
  min-height: 100px;
}
</style>
