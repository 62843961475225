<template>
  <div>
    <b-modal
      id="modal-ai-model-details"
      ref="modalAiModelDetails"
      v-model="showModal"
      body-class="fixed-modal-body"
      scrollable
      size="xl"
    >
      <template #modal-header>
        <div v-if="model" class="d-flex justify-content-between align-items-center w-100">
          <div>
            <h5>
              {{ model.name }}
              <span class="ml-2 model-type-badge">{{ model.network_config_option_name }}</span>
            </h5>
          </div>
          <div>
            <model-dropdown
              :model="model"
              :details="false"
              refresh
              size="md"
              @click-refresh="onClickRefresh"
            />
          </div>
        </div>
        <span v-else>AI Model Details</span>
      </template>

      <div class="w-100 h-100 scroll-box-always">
        <loading-overlay v-if="modelsLoading || modelDetailsLoading" />
        <div v-if="model" class="h-100">
          <card-collapse
            :border-radius="false"
            initially-open
            inner-class="pt-2 pl-4 pr-4 pb-4"
            class="bg-gray-200 position-relative"
          >
            <template #header>
              <div class="text-primary font-weight-700">TRAINING STATUS</div>
            </template>
            <transition name="slow-fade">
              <loading-overlay v-if="jobsLoading || !jobs" light />
            </transition>
            <b-alert v-if="alert" show :variant="alert.variant" class="mb-4">
              <fai
                v-if="alert.variant === 'warning'"
                icon="exclamation-triangle"
                class="mb-1 mr-1"
              />
              <fai
                v-else-if="alert.variant === 'danger'"
                icon="exclamation-circle"
                class="mb-1 mr-1"
              />
              {{ alert.message }}
            </b-alert>
            <div class="mb-2 d-flex justify-content-end"></div>
            <milestone-line
              :milestones="trainingMilestones"
              class="pl-2 pr-2 pl-sm-5 pr-sm-5 flex-grow-1"
            />
            <div class="mt-4 d-flex justify-content-between flex-wrap">
              <div class="d-flex justify-content-start align-items-end">
                <b-button
                  v-if="modelJobs.length === 0"
                  variant="primary"
                  size="sm"
                  @click="onClickStartTraining"
                >
                  {{ isTrained ? 'Train More' : 'Start Training' }}
                </b-button>
                <div v-else>
                  <b-button variant="danger" size="sm" @click="onClickCancelTraining">
                    <template v-if="trainingState === trainingStates.error">
                      Acknowledge Error
                    </template>
                    <template v-else>Cancel Training</template>
                  </b-button>
                </div>
                <div
                  v-b-tooltip.d250.o0
                  :title="
                    isTrained
                      ? ''
                      : 'You can download the model as soon as the first checkpoint is available.'
                  "
                >
                  <b-button
                    :disabled="!isTrained"
                    variant="primary"
                    size="sm"
                    class="ml-2 d-flex align-items-center"
                    @click="onClickDownloadModel"
                  >
                    <b-spinner small class="mr-2" v-if="downloadLinkLoading" />
                    Download Model
                  </b-button>
                </div>
              </div>
              <div class="pt-2 text-sm-right pr-sm-4">
                <div v-if="modelJob" class="text-dark font-size-small">
                  <div v-if="trainingState === trainingStates.enqueued">
                    <div>training requested {{ modelJob.created_date | formatDateDuration }}</div>
                    <div class="d-flex">
                      <div class="mr-2">worker</div>
                      <worker-status-badge :worker="modelJob.worker" />
                    </div>
                  </div>
                  <div v-if="trainingState === trainingStates.running">
                    <div v-if="modelJob.estimated_time_of_completion && !alert">
                      finished in {{ modelJob.estimated_time_of_completion | formatDateDuration }}
                    </div>
                    <!--                    <div>last update {{ modelJob.updated_date | formatDateDuration }}</div>-->
                    <div v-if="modelJob.current_iteration !== undefined && modelJob.end_iteration">
                      iteration {{ modelJob.current_iteration }} / {{ modelJob.end_iteration }}
                    </div>
                    <template v-if="modelJob.details && !alert">
                      <div v-if="modelJob.details.sub_status">
                        currently in {{ modelJob.details.sub_status }} mode
                      </div>
                      <div>{{ modelJob.details.sub_status_info }}</div>
                    </template>
                  </div>
                  <div v-if="trainingState === trainingStates.stalling">
                    <div v-if="modelJob.current_iteration !== undefined && modelJob.end_iteration">
                      iteration {{ modelJob.current_iteration }} / {{ modelJob.end_iteration }}
                    </div>
                  </div>
                </div>
              </div>
              <!--                <b-button-->
              <!--                  variant="light"-->
              <!--                  size="sm"-->
              <!--                  class=""-->
              <!--                >-->
              <!--                  Training Logs-->
              <!--                </b-button>-->
            </div>
          </card-collapse>

          <!--          <div-->
          <!--            class="p-4"-->
          <!--          >-->
          <!--            <div class="mb-2">-->
          <!--              <div class="text-dark font-weight-700">METRICS</div>-->
          <!--              <model-metrics-overview-->
          <!--                :model="model"-->
          <!--              />-->

          <!--            </div>-->
          <!--            <b-button-->
          <!--              @click="onClickOpenStatistics"-->
          <!--              variant="outline-primary"-->
          <!--              size="sm"-->
          <!--            >-->
          <!--              Show More Metrics-->
          <!--            </b-button>-->
          <!--          </div>-->

          <card-collapse
            :border-radius="false"
            initially-open
            inner-class="pl-4 pr-4 pb-4"
            class=""
          >
            <template #header>
              <div class="text-primary font-weight-700">METRICS</div>
            </template>
            <div class="pt-2">
              <div class="mb-2">
                <model-metrics-overview ref="modelMetricsOverview" :model="model" />
              </div>
              <b-button variant="outline-primary" size="sm" @click="onClickOpenStatistics">
                Open Metrics
              </b-button>
            </div>
          </card-collapse>

          <card-collapse
            :border-radius="false"
            initially-open
            inner-class="pl-4 pr-4 pb-4"
            class="border-gray-300-top"
          >
            <template #header>
              <div class="text-primary font-weight-700">TEST ITEMS</div>
            </template>
            <div class="pt-1">
              <template v-if="items.length > 0">
                <div v-if="model" class="d-flex flex-wrap mb-2">
                  <item-tile
                    v-for="item in items"
                    :id="`preview-item-${item.id}`"
                    :key="`preview-item-${item.id}`"
                    :ref="`preview-item-${item.id}`"
                    :draw-ground-truth-dashed="true"
                    :item="item"
                    :item-size="0.2"
                    :object-selectivity="0.3"
                    :padding="0.0"
                    :predicted-object-class-ids="model.class_labels"
                    :show-confidence="false"
                    :show-filename="false"
                    :show-objects="isDetectionModel"
                    :show-predicted-labels="isClassificationModel"
                    :show-predicted-objects="isDetectionModel"
                    :show-true-labels="isClassificationModel"
                    :show-labels-in-overlay="true"
                    :show-status="false"
                    :show-subsets="false"
                    :show-timestamp="false"
                    :hoverable="false"
                    timezone=""
                  />
                </div>
                <b-button variant="outline-primary" size="sm" @click="onClickOpenTestItems">
                  Show More Test Items
                </b-button>
              </template>
              <div v-else class="text-secondary p-3">
                no items to show<br />
                <small>Once the model is sufficiently trained, test images will appear here.</small>
              </div>
            </div>
          </card-collapse>

          <card-collapse :border-radius="false" inner-class="pl-4 pr-4" class="border-gray-300-top">
            <template #header>
              <div class="text-primary font-weight-700">MODEL PARAMETERS</div>
            </template>
            <div>
              <parameter-viewer
                v-if="
                  model.parameters &&
                  !$_.isEmpty(model.parameters) &&
                  model.parameters !== '' &&
                  parameterFields.length > 0
                "
                :key="`parameter-viewer-form-generator-${model.id}`"
                :value="model.parameters"
                :fields="parameterFields"
              />
              <div v-else class="text-secondary">- no parameters -</div>
            </div>
          </card-collapse>

          <card-collapse
            :border-radius="false"
            inner-class="pl-4 pr-4 pt-2 pb-3"
            class="border-gray-300-top"
          >
            <template #header>
              <div class="text-primary font-weight-700">DATA</div>
            </template>
            <div>
              <div
                v-for="(category, i) in modelSubsets"
                :key="`category-${i}`"
                class="table-responsive"
              >
                <table class="table table-sm border">
                  <thead>
                    <tr>
                      <th scope="col" colspan="5">
                        Data subsets for "{{ category.display_name }}"
                      </th>
                    </tr>
                    <tr>
                      <th scope="col">Subset ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Dataset</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="subset in category.subsets"
                      :key="`category-${i}-subset-${subset.id}`"
                    >
                      <td>{{ subset.id }}</td>
                      <td>{{ subset.name }}</td>
                      <td>{{ subset.dataset_name }}</td>
                      <td class="text-right">
                        <b-button
                          :href="`/dataset-explorer?dataset=${subset.dataset}`"
                          variant="outline-primary"
                          size="sm"
                          class="mr-1"
                        >
                          open dataset
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </card-collapse>

          <card-collapse
            :border-radius="false"
            inner-class="pl-4 pr-4 pt-2 pb-2 bg-gray-200 "
            class="border-gray-300-top"
          >
            <template #header>
              <div class="text-primary font-weight-700">CLASS LABELS</div>
            </template>
            <div class="p-2">
              <class-label-selection :class-labels="modelClassLabels" disabled />
            </div>
          </card-collapse>

          <card-collapse
            :border-radius="false"
            inner-class="pl-4 pr-4 pt-2 pb-2 bg-gray-200"
            class="border-gray-300-top"
          >
            <template #header>
              <div class="text-primary font-weight-700">TRAINING HISTORY</div>
            </template>
            <div class="p-2">
              <div
                v-for="(evt, i) in model.job_events"
                :key="`job-event-${i}`"
                class="m-2 p-2 pl-3 pr-3 bg-white shadow-sm"
              >
                <span class="">
                  {{ evt.end_iteration - evt.start_iteration }} iterations in
                  {{ [evt.start, evt.end] | formatDistanceStrict }}
                </span>
                <br />
                <span class="text-secondary font-size-small">
                  from {{ evt.start | formatDate }} to {{ evt.end | formatDate }}
                </span>
              </div>
              <div v-if="!model.job_events || model.job_events.length === 0" class="text-dark">
                nothing to show so far
              </div>
            </div>
          </card-collapse>

          <!--          <collapse-->
          <!--            :border-radius="false"-->
          <!--            inner-class="pl-4 pr-4 pb-3 pt-1"-->
          <!--            initially-open-->
          <!--            class="border-gray-300-top"-->
          <!--          >-->
          <!--            <template #header>-->
          <!--              <div class="text-danger font-weight-700">DANGER ZONE</div>-->
          <!--            </template>-->
          <!--            <div class="d-flex justify-content-start">-->
          <!--              <div-->
          <!--                :title="modelJobs.length === 0 ? '' : 'You need to stop the current training session before you can delete the model.'"-->
          <!--                v-b-tooltip.d250.o0-->
          <!--              >-->
          <!--                <b-button-->
          <!--                  v-if="deleteModelPermission"-->
          <!--                  :disabled="modelJobs.length !== 0"-->
          <!--                  variant="danger"-->
          <!--                  @click="onClickDeleteModel"-->
          <!--                >-->
          <!--                  Delete Model-->
          <!--                </b-button>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </collapse>-->
        </div>
        <div v-else class="p-4 text-secondary">no data loaded</div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button class="ml-2" variant="secondary" @click="showModal = false"> Close</b-button>
        </div>
      </template>
    </b-modal>

    <start-training-modal
      :model="model"
      :show="modals.startTraining.show"
      @show="modals.startTraining.show = $event"
    />
    <cancel-training-modal
      :model="model"
      :show="modals.cancelTraining.show"
      @show="modals.cancelTraining.show = $event"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'

import { getModelJobs, getTrainingState, TrainingState } from '@/utils'

import LoadingOverlay from '../LoadingOverlay'
import ModelDropdown from '../models/ModelDropdown'
import MilestoneLine from '../MilestoneLine'
import CardCollapse from '@/components/CardCollapse'
import ParameterViewer from '@/components/parameter-viewer/ParameterViewer'
import ItemTile from '@/components/item-viewer/ItemTile'
import ModelMetricsOverview from '@/components/models/ModelMetricsOverview'
import ClassLabelSelection from '@/components/class-labels/ClassLabelSelection'
import StartTrainingModal from '@/components/modals/StartTrainingModal'
import CancelTrainingModal from '@/components/modals/CancelTrainingModal'
import { differenceInSeconds, formatDistanceToNow, parseISO } from 'date-fns'
import WorkerStatusBadge from '@/components/worker/WorkerStatusBadge'

export default {
  name: 'AiModelDetailsModal',
  components: {
    WorkerStatusBadge,
    CancelTrainingModal,
    StartTrainingModal,
    ClassLabelSelection,
    ModelMetricsOverview,
    ItemTile,
    ParameterViewer,
    CardCollapse,
    MilestoneLine,
    ModelDropdown,
    LoadingOverlay,
  },
  props: {
    show: Boolean,
    model: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
      items: [],
      modals: {
        startTraining: {
          show: false,
        },
        cancelTraining: {
          show: false,
        },
      },
      downloadLinkLoading: false,
    }
  },

  computed: {
    ...mapState([
      'modelsLoading',
      'modelDetailsLoading',
      'jobs',
      'jobsLoading',
      'modelConfigurationOptions',
    ]),
    ...mapGetters(['deleteModelPermission', 'classLabelMap']),
    modelJobs() {
      return getModelJobs(this.model, this.jobs)
    },
    modelJob() {
      if (this.modelJobs.length > 0) {
        return this.modelJobs[0]
      }
      return undefined
    },
    alert() {
      if (this.modelJob && this.trainingState === TrainingState.error) {
        const msg =
          this.modelJob && this.modelJob.details && this.modelJob.details.error
            ? this.modelJob.details.error
            : `An error occurred during training!`

        return {
          variant: 'danger',
          message: msg,
        }
      }

      if (
        this.modelJob &&
        this.modelJob.updated_date &&
        this.trainingState === TrainingState.stalling
      ) {
        const timeDiff = differenceInSeconds(new Date(), parseISO(this.modelJob.updated_date))
        if (timeDiff > 1800) {
          return {
            variant: 'danger',
            message: `The current training session is stalling. There was no update from the
                      training  worker since
                      ${formatDistanceToNow(parseISO(this.modelJob.updated_date))}.`,
          }
        } else {
          return {
            variant: 'warning',
            message: `The current training session is stalling. There was no update from the
                      training  worker since
                      ${formatDistanceToNow(parseISO(this.modelJob.updated_date))}.`,
          }
        }
      }
      return undefined
    },
    parameterFields() {
      if (this.model) {
        for (const modelCfg of this.modelConfigurationOptions) {
          if (modelCfg.id === this.model.network_config_option) {
            if (modelCfg.options && modelCfg.options.fields) {
              return modelCfg.options.fields
            }
          }
        }
      }
      return []
    },
    modelSubsets() {
      let result = {}
      if (this.model && this.model.data_subsets) {
        for (let i = 0; i < this.model.data_subsets.length; i++) {
          let subset = this.model.data_subsets[i]
          if (!Object.prototype.hasOwnProperty.call(result, subset.category)) {
            result[subset.category] = {
              category: subset.category,
              display_name: subset.category_display_name,
              subsets: [],
            }
          }
          result[subset.category].subsets.push(subset.data_subset)
        }
      }
      return result
    },
    modelClassLabels() {
      let result = []
      if (this.model && this.model.class_labels) {
        for (const c of this.model.class_labels) {
          let classLabelId = c.target_class_label ? c.target_class_label : c.class_label
          let classLabel = this.classLabelMap[classLabelId]
          if (classLabel) {
            result.push(classLabel)
          } else {
            result.push({
              id: c.class_label,
              name: '<unknown name>',
            })
          }
        }
      }
      return result
    },
    isTrained() {
      return !!this.model.inference_data
    },
    trainingState() {
      return getTrainingState(this.model, this.modelJobs)
    },
    trainingStates() {
      return TrainingState
    },
    isClassificationModel() {
      return this.model.network_type === 'classification'
    },
    isDetectionModel() {
      return this.model.network_type === 'detection'
    },
    trainingMilestones() {
      const trainingState = getTrainingState(this.model, this.modelJobs)

      let milestones = [
        {
          text: 'requested',
          variant: 'dark',
          active: false,
          animate: false,
          tooltip: 'Click "Start Training" to request a session.',
        },
        {
          text: 'enqueued',
          variant: 'dark',
          active: false,
          animate: false,
          tooltip: 'Training session has been scheduled and will begin shortly.',
        },
        {
          text: 'running',
          variant: 'dark',
          active: false,
          animate: false,
          tooltip: 'The training is in progress.',
        },
        {
          text: 'finished',
          variant: 'dark',
          active: false,
          animate: false,
          tooltip: 'The training is finished.',
        },
      ]

      if (this.jobs) {
        if (trainingState === TrainingState.requested) {
          milestones[0].variant = 'info'
          milestones[0].active = true
          milestones[1].animate = true
        } else if (trainingState === TrainingState.enqueued) {
          milestones[0].variant = 'info'
          milestones[1].variant = 'info'
          milestones[1].active = true
          milestones[1].animate = true
        } else if (trainingState === TrainingState.running) {
          milestones[0].variant = 'info'
          milestones[1].variant = 'info'
          milestones[2].variant = 'info'
          milestones[2].active = true
          milestones[2].animate = true
        } else if (trainingState === TrainingState.stalling) {
          milestones[0].variant = 'info'
          milestones[1].variant = 'info'
          milestones[2].icon = 'hourglass-half'
          milestones[2].variant = 'warning'
          milestones[2].active = true
          milestones[2].animate = false
        } else if (trainingState === TrainingState.error) {
          milestones[0].variant = 'success'
          milestones[1].variant = 'success'
          milestones[2].variant = 'success'
          milestones[3].variant = 'danger'
          if (this.modelJobs.length > 0) {
            const job = this.modelJobs[0]
            milestones[3].text = job.status
          }
          milestones[3].active = true
          milestones[3].tooltip = 'The training was terminated by an error.'
        } else if (trainingState === TrainingState.finished) {
          for (const milestone of milestones) {
            milestone.variant = 'success'
          }
          milestones[3].active = true
        }
      }

      return milestones
    },
  },

  watch: {
    show(newValue) {
      this.showModal = newValue
    },

    showModal(newValue) {
      this.$emit('show', newValue)
      if (newValue) {
        this.getPreviewTestItems()
      } else {
        // cancel pending requests
        if (this.cancelToken) {
          this.cancelToken.cancel()
          this.loadingCanceled = true
        }
      }
    },
    model(newModel, oldModel) {
      if (oldModel !== undefined && newModel === undefined) {
        this.showModal = false
      }
    },
  },

  created() {},

  mounted() {
    this.getPreviewTestItems()
    this.updateInterval = setInterval(() => {
      if (this.model) {
        this.refresh()
      }
    }, 30000)
  },

  beforeDestroy() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval)
    }
  },

  methods: {
    ...mapActions(['getJobs', 'getModelDetails']),
    onClickStartTraining() {
      this.modals.startTraining.show = true
    },
    onClickCancelTraining() {
      this.getJobs()
      this.getModelDetails({ model: this.model, forceReload: true })
      this.modals.cancelTraining.show = true
    },
    onClickRefresh() {
      this.refresh()
    },
    onClickDownloadModel() {
      this.downloadLinkLoading = true
      this.getModelDetails({ model: this.model, forceReload: true })
        .then(() => {
          this.downloadLinkLoading = false
          if (this.model.inference_data) {
            window.open(this.model.inference_data, '_blank')
          } else {
            this.$bvToast.toast('This model has no downloadable checkpoint yet.', {
              title: 'Warning',
              variant: 'warning',
              autoHideDelay: 4000,
              solid: true,
            })
          }
        })
        .catch(() => {
          this.downloadLinkLoading = false
          this.$bvToast.toast('Cannot retrieve model link. Please try again.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
        })
    },
    onClickOpenStatistics() {
      this.$router.replace({
        path: 'model-explorer',
        query: {
          view: 'training-progress',
          models: `${this.model.id}`,
        },
      })
      this.showModal = false
    },
    onClickOpenTestItems() {
      this.$router.replace({
        path: 'model-explorer',
        query: {
          view: 'test-items',
          models: `${this.model.id}`,
        },
      })
      this.showModal = false
    },
    refresh() {
      this.getJobs()
      this.getModelDetails({ model: this.model, forceReload: true })
      this.getPreviewTestItems()
      this.$refs.modelMetricsOverview.getStatistics()
    },
    getPreviewTestItems() {
      this.items = []

      if (!this.model) {
        return
      }

      if (this.cancelToken) {
        // cancel previous request
        this.cancelToken.cancel()
        this.loadingCanceled = true
      }
      this.cancelToken = axios.CancelToken.source()
      const itemPageSize = 5
      const url = `/api/inference-results/?page_size=${itemPageSize}&network_model__id=${this.model.id}`
      let self = this
      this.$axios({
        method: 'get',
        url: url,
        cancelToken: this.cancelToken.token,
      })
        .then((response) => {
          self.items = response.data.results
          self.itemsLoading--
        })
        .catch((error) => {
          self.itemsLoading--
          if (!self.loadingCanceled) {
            self.$bvToast.toast('Could not load items.', {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 4000,
              solid: true,
            })
            console.error(error)
          }
          self.items = []
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';

.model-type-badge {
  font-size: 0.75em;
  color: $white;
  background-color: $success;
  font-weight: 500;
  padding: 0.125em 0.4em;
  border-radius: 3px;
}
</style>
