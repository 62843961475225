<template>
  <auth-wrapper>
    <transition name="fade">
      <basic-overlay v-if="showPasswordReset" class="bg-white p-5">
        <div class="font-size-large font-weight-700 mb-3 d-flex align-items-center">
          <b-button class="text-dark" variant="link" @click="onClickClosePasswordReset">
            <fai icon="arrow-left" />
          </b-button>
          <div>Reset Password</div>
        </div>
        <p>
          Enter the email address associated with your account and we'll send you a link to reset
          your password.
        </p>
        <b-form class="" @submit="resetPassword">
          <b-form-group id="reset-password-email" label="Email" label-for="resetPasswordEmailInput">
            <b-form-input
              id="resetPasswordEmailInput"
              v-model="form.email"
              type="text"
              placeholder="Email"
              required
            />
          </b-form-group>
          <div class="d-flex justify-content-end mt-2">
            <b-button type="submit" variant="primary">Send instructions</b-button>
          </div>
          <div class="text-center mt-4">
            <a @click="onClickClosePasswordReset" href="#">go back to login</a>
          </div>
        </b-form>
      </basic-overlay>
    </transition>
    <div class="p-5">
      <div class="font-size-large font-weight-700 mb-3">Log in to your account</div>
      <b-form class="mt-3" @submit="onLogin">
        <b-form-group id="username" label="Username" label-for="usernameInput">
          <b-form-input
            id="usernameInput"
            v-model="form.username"
            type="text"
            placeholder="Username"
            required
          />
        </b-form-group>
        <b-form-group id="password" label-for="passwordInput">
          <div class="d-flex justify-content-between">
            <label>Password</label>
            <a @click="onClickPasswordForgotten" href="#" tabindex="1">Forgot password?</a>
          </div>
          <b-form-input
            id="passwordInput"
            v-model="form.password"
            type="password"
            placeholder="Password"
            required
          />
        </b-form-group>
        <b-form-checkbox
          id="remember-me-checkbox"
          v-model="form.rememberMe"
          name="remember-me-checkbox"
        >
          remember me
        </b-form-checkbox>

        <div class="d-flex justify-content-between align-items-center mt-3 form-footer">
          <div class="text-danger font-weight-700 font-size-small mr-1">
            {{ errorMessage }}
          </div>
          <div class="flex-shrink-0">
            <b-button type="submit" variant="primary" tabindex="0">
              <b-spinner v-if="loading" small class="mr-1" />
              Log in
            </b-button>
          </div>
        </div>
      </b-form>
    </div>
    <template v-slot:footer>
      <div class="text-dark p-3" v-if="server.enableRegistration">
        Don't have an account?
        <b-link to="register">Sign up</b-link>
      </div>
    </template>
  </auth-wrapper>
</template>

<script>
import countries from '@/countries'
import Cookie from 'js-cookie'
import { mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import AuthWrapper from '@/components/AuthWrapper'
import BasicOverlay from '@/components/BasicOverlay'

export default {
  name: 'LoginComponent',
  components: { BasicOverlay, AuthWrapper },
  mixins: [validationMixin],
  data() {
    return {
      errorMessage: '',
      loading: false,
      showPasswordReset: false,
      form: {
        email: '',
        username: '',
        password: '',
        rememberMe: true,
      },

      countries: countries,
    }
  },
  validations: {},
  computed: {
    server() {
      // check if the registration of new users and organizations is available on the server
      return this.$store.getters.server
    },
  },
  mounted() {},
  methods: {
    ...mapActions(['login']),
    onClickClosePasswordReset() {
      this.showPasswordReset = false
    },
    onClickPasswordForgotten() {
      this.showPasswordReset = true
    },

    resetPassword(evt) {
      if (evt) {
        evt.preventDefault()
      }

      this.$axios({
        method: 'post',
        url: '/api/auth/users/reset_password/',
        withCredentials: true,
        data: { email: this.form.email },
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
        .then(() => {
          this.$bvToast.toast(
            'You will receive a link to reset your password if the email address you entered exists.',
            {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 4000,
              solid: true,
            }
          )
          this.showPasswordReset = false
        })
        .catch((error) => {
          this.$bvToast.toast('Unknown error appeared during password reset.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
          console.error(error)
        })
    },

    onLogin(evt) {
      if (evt) {
        evt.preventDefault()
      }

      this.loading = true
      this.errorMessage = ''
      this.login({
        username: this.form.username,
        password: this.form.password,
        rememberMe: this.form.rememberMe,
      })
        .then(() => {
          this.loading = false
        })
        .catch((e) => {
          this.errorMessage = e.message
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../custom';
</style>
