<template>
  <b-input-group class="date-time-text-input-group" :size="size" :prepend="label">
    <b-form-input
      v-model="textInput"
      class="date-time-text-input"
      :state="valid"
      :class="{ 'flex-grow-1': block }"
      type="text"
      placeholder="YYYY-MM-DD HH:mm:ss"
      autocomplete="off"
      @change="onTextInputChange"
    />
    <b-input-group-append>
      <b-button :disabled="textInput === ''" variant="outline-primary" @click="clear">
        <fai icon="times" />
      </b-button>
      <b-form-datepicker
        v-model="dateInput"
        button-only
        button-variant="outline-primary"
        right
        dropup
        locale="en-US"
        aria-controls="date-input"
        :size="size"
        @input="onDateTimeInputChange"
      />
      <b-form-timepicker
        v-model="timeInput"
        button-only
        button-variant="outline-primary"
        right
        dropup
        show-seconds
        locale="en"
        aria-controls="time-input"
        :size="size"
        @input="onDateTimeInputChange"
      />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { format, parse } from 'date-fns'

export default {
  name: 'DateTimePicker',
  props: {
    label: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'sm',
    },
    block: {
      type: Boolean,
      default: false,
    },
    value: {
      // type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      textInput: '',
      dateInput: '',
      timeInput: '00:00:00',
      valid: null,
    }
  },

  watch: {
    value: function (newValue) {
      this.updateFromTimestamp(newValue, false)
    },
  },

  mounted() {
    this.updateFromTimestamp(this.value, true)

    // if (this.value === undefined) {
    //   this.onInput(0.0)
    // }
  },

  methods: {
    updateFromTimestamp(timestamp, emit) {
      if (timestamp === undefined || timestamp === null || timestamp === '') {
        this.textInput = ''
      } else {
        this.textInput = format(new Date(timestamp), 'yyyy-MM-dd HH:mm:ss')
      }
      this.onTextInputChange(emit)
    },

    clear() {
      this.textInput = ''
      this.onTextInputChange(true)
    },

    onDateTimeInputChange() {
      if (this.dateInput === '') {
        this.dateInput = format(new Date(), 'yyyy-MM-dd')
      }
      this.textInput = `${this.dateInput} ${this.timeInput}`
      this.onTextInputChange(true)
    },
    onTextInputChange(emit) {
      let datetime = null
      if (this.textInput === '') {
        this.valid = null
      } else {
        try {
          datetime = parse(this.textInput, 'yyyy-MM-dd HH:mm:ss', new Date())
          // eslint-disable-next-line no-empty
        } catch (e) {}

        if (isNaN(datetime)) {
          try {
            datetime = parse(this.textInput, 'yyyy-MM-dd HH:mm', new Date())
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }

        if (isNaN(datetime)) {
          try {
            datetime = parse(this.textInput, 'yyyy-MM-dd', new Date())
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }

        if (!isNaN(datetime)) {
          this.dateInput = format(datetime, 'yyyy-MM-dd')
          this.timeInput = format(datetime, 'HH:mm:ss')
          this.valid = true
        } else {
          this.valid = false
        }
      }

      if (emit && (this.valid === null || this.valid === true)) {
        const v = datetime === null ? null : datetime.getTime()
        this.$emit('input', v)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.date-time-text-input-group {
  flex-wrap: nowrap !important;
}

.date-time-text-input {
  min-width: 13em;
}
</style>
