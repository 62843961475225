<template>
  <div>
    <loading-spinner-light v-if="light" class="loading-spinner">
      <slot />
    </loading-spinner-light>
    <loading-spinner v-else class="loading-spinner">
      <slot />
    </loading-spinner>
    <div class="loading-background" />
  </div>
</template>

<script>
import LoadingSpinner from '../components/LoadingSpinner'
import LoadingSpinnerLight from '@/components/LoadingSpinnerLight'

export default {
  name: 'LoadingOverlay',
  components: { LoadingSpinnerLight, LoadingSpinner },
  props: {
    light: Boolean,
  },
}
</script>

<style scoped>
.loading-spinner {
  z-index: 1500;
}

.loading-background {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1400;
}
</style>
