<template>
  <div class="d-flex flex-wrap class-labels-selection">
    <div
      v-for="(classLabel, index) in internalClassLabels"
      :key="`class-label-${index}`"
      class="p-1 class-label-wrapper"
      :class="{
        'class-selected': selectedClassLabels.includes(classLabel.id),
        disabled: disabled,
        'w-50': !fullWidth,
        'w-100': fullWidth,
      }"
    >
      <div class="class-label-bg">
        <div
          :style="getClassStyle(classLabel.id)"
          class="pl-2 pr-2 pt-1 pb-1 class-label d-flex flex-wrap justify-content-between align-center"
          :class="{ 'cursor-pointer': !disabled, disabled: disabled }"
          @click.self="onClickClassLabel(classLabel)"
        >
          <div class="d-flex no-select" @click="onClickClassLabel(classLabel)">
            <div :style="getSquareStyle(classLabel.id)" class="class-label-square" />
            <span class="text-ellipsis">{{ classLabel.name }}</span>
          </div>
          <span v-if="visibilityToggle" class="cursor-pointer">
            <fai
              v-if="classLabel.show"
              icon="eye"
              :enabled="true"
              @click="onToggleVisibility(classLabel)"
            />
            <fai
              v-if="classLabel.show === false"
              icon="eye-slash"
              :enabled="true"
              @click="onToggleVisibility(classLabel)"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getClassStyle, getColorForCSS } from '@/colors'

export default {
  name: 'ClassLabelSelection',
  props: {
    classLabels: {
      type: Array,
      default: () => [],
    },
    selectedClassLabels: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    visibilityToggle: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    internalClassLabels() {
      if (this.classLabels) return this.classLabels
      return []
    },
  },

  watch: {},

  mounted() {},

  methods: {
    onClickClassLabel(classLabel) {
      this.$emit('click-class-label', classLabel)
    },
    onToggleVisibility(classLabel) {
      this.$emit('toggle-visibility-class-label', classLabel)
    },
    getSquareStyle(idx) {
      return {
        'background-color': getColorForCSS(idx, 1.0),
      }
    },
    getClassStyle(idx) {
      return getClassStyle(idx, { alpha: 0.35 })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';

.class-labels-selection {
  @extend .scroll-box;
  max-height: 400px;
  overflow-x: hidden;
}

.class-label-wrapper {
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #ffffff;

  &:not(.disabled):hover {
    background-color: $gray-400;
  }
}

.disabled {
  border: 1px solid rgba(1, 1, 1, 0) !important;
}

.class-label-bg {
  border-radius: 4px;
  background: $white;
}

.class-label {
  font-size: small;
  font-weight: 600;
  border-radius: 4px;
  border: 1px solid #ffffff;
  word-wrap: break-word;
}

.class-selected {
  background-color: $primary;

  &:not(.disabled):hover {
    background-color: $primary;
  }
}

.class-label-square {
  display: block;
  width: 24px;
  height: 100%;
  border-radius: 2px;
  padding: -0.5rem;
  margin-right: 0.5rem;
  margin-left: -0.25rem;
}
</style>
