<template>
  <div class="container">
    <div class="row mt-2">
      <b-alert
        :show="server.cloud && user.organization && user.organization.deactivated"
        variant="danger"
        class="mt-4 w-100"
      >
        Your organization has been deactivated.
        <template v-if="user.organization.is_admin">
          Please revisit your organization's billing settings.
        </template>
        <template v-else> Please contact your organization's administrator.</template>
      </b-alert>

      <div class="col-12 pt-5">
        <h4>
          <b>{{ displayName }}</b
          ><br /><span v-if="displayName !== user.username" class="text-info">{{
            user.username
          }}</span>
        </h4>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 pt-3">
        <b-form-group label="E-Mail:" label-align-sm="left" label-cols-sm="2">
          <b-form-input v-model="user.email" plaintext placeholder="-" />
        </b-form-group>

        <template v-if="user.organization">
          <h5>
            <b>
              <fai icon="building" class="mt-3 mr-2" />
              Organization</b
            >
          </h5>
          <b-form-group label="Name:" label-align-sm="left" label-cols-sm="2">
            <b-form-input v-model="user.organization.name" plaintext placeholder="-" />
          </b-form-group>
        </template>

        <h5>
          <b>
            <fai icon="user-friends" class="mt-3 mr-2" />
            Teams</b
          >
        </h5>
        <ul>
          <li v-for="team in user.teams" :key="`user-team-list-${team.name}`">
            {{ team.organization.name }} &#8594; {{ team.name }}
          </li>
        </ul>

        <h5>
          <b>
            <fai icon="certificate" class="mt-3 mr-2" />
            License</b
          >
        </h5>
        <div v-if="user.license !== undefined && !$_.isEmpty(user.license)">
          <div class="row pt-2 pb-2">
            <div class="col-12 col-sm-2">Type:</div>
            <div class="col-12 col-sm-10">
              {{ user.license.type }}
            </div>
          </div>

          <div v-if="!server.cloud" class="row pt-2 pb-2">
            <div class="col-12 col-sm-2">Expires:</div>
            <div class="col-12 col-sm-10">
              {{ user.license.valid_until | formatDate }}
            </div>
          </div>
        </div>
        <div v-else class="text-dark ml-4">- no license -</div>
      </div>
    </div>

    <b-modal id="modal-no-license" ref="modalNoLicense" title="No License" ok-only>
      <p>There is no license associated to this user.</p>
    </b-modal>

    <b-modal id="modal-expired-license" ref="modalExpiredLicense" title="Expired License" ok-only>
      <p>
        The license of this user is expired.
        <template v-if="user.license">
          The license was valid until {{ user.license.valid_until | formatDate }}.
        </template>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UserProfile',
  components: {},
  data() {
    return {
      centerLoading: false,
    }
  },

  computed: {
    ...mapState(['user', 'server']),
    displayName() {
      let displayName = ''
      if (this.user.first_name) {
        displayName += this.user.first_name + ' '
      }

      if (this.user.last_name) {
        displayName += this.user.last_name
      }

      if (displayName === '') {
        displayName = this.user.username
      }

      return displayName
    },
  },

  watch: {
    user() {
      if (this.user !== undefined) {
        if (this.$_.isEmpty(this.user.license)) {
          this.$refs.modalNoLicense.show()
        } else if (!this.user.license.valid) {
          this.$refs.modalExpiredLicense.show()
        }
      }
    },
  },

  created: function () {},

  mounted() {},

  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
  min-height: calc(100vh - 70px);
}
</style>
