<template>
  <div class="mh-100 h-100 d-flex flex-column">
    <loading-overlay v-if="modelsLoading" />
    <div class="pl-3 pt-3 pr-3 pb-3 selection-header-element">
      <div class="d-flex">
        <div
          v-if="showCreateModel"
          v-b-tooltip.bottom.nofade.hover.d0.o0
          :title="createModelDisabledMessage"
        >
          <b-button
            class="flex-grow-0 mr-3"
            variant="success"
            size="sm"
            @click="onClickCreateAiModel"
            :disabled="createModelDisabled"
          >
            Create Model
          </b-button>
        </div>
        <div class="flex-grow-1">
          <b-form-input v-model="searchString" placeholder="search" size="sm" />
        </div>
      </div>
      <!--          <b-form-group-->
      <!--            class="mt-2"-->
      <!--          >-->
      <!--            <b-form-checkbox-group-->
      <!--              v-model="selectedModelTypes"-->
      <!--              :options="['classification', 'detection']"-->
      <!--              button-variant="outline-primary"-->
      <!--              buttons-->
      <!--              name="network-types"-->
      <!--              size="sm"-->
      <!--            ></b-form-checkbox-group>-->
      <!--          </b-form-group>-->
    </div>
    <div
      v-if="showModelSelection && modelSelection.length > 0"
      class="pl-3 pt-2 pr-3 pb-2 selection-header-element"
    >
      <b-badge
        v-for="model in modelSelection"
        :key="`selected-model-badge-${model.id}`"
        variant="primary"
        class="mr-1"
      >
        <span class="">{{ model.name }}</span>
        <b-link @click="onClickDeselectModel(model)" class="ml-1 primary-link">
          <fai icon="times" />
        </b-link>
      </b-badge>
    </div>
    <div
      v-if="models.length === 0 && !modelsLoading"
      class="create-first-ai-model-message text-dark no-select"
    >
      <div class="">
        <fai icon="arrow-up" class="mt-1 mr-2" />
        <div class="text-left mt-2">Create your first AI model</div>
      </div>
    </div>

    <div v-if="filteredModels.length === 0" class="text-dark text-center mt-5 mb-3 pl-5 pr-5">
      <p>no models</p>
    </div>

    <div v-if="filteredModels.length > 0" class="scroll-box flex-grow-1 p-3">
      <div class="model-grid">
        <template v-for="(group, key) in groupedModels">
          <div
            :key="`group-${key}-title`"
            v-if="key !== '\uFFFF'"
            class="model-grid-item-group text-dark text-uppercase font-weight-700"
          >
            {{ key }}
          </div>
          <div
            :key="`group-${key}-all-models`"
            v-else
            class="model-grid-item-group text-dark text-uppercase font-weight-700"
          >
            <template v-if="modelFilters.selectedGroupBy === 'none'">all models</template>
            <template v-else>other models</template>
          </div>
          <div v-for="(model, j) in group" :key="`group-${key}-model-${j}`" class="model-grid-item">
            <model-entry
              v-if="viewMode === 'grid'"
              :model="model"
              show-training-state
              show-updated-date
              card
              @click="onClickModel(model)"
            />
            <model-entry
              v-else
              :model="model"
              show-details-button
              :highlight="multiSelection"
              @click="onClickModel(model)"
              @click-details="onClickModelDetails(model)"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { parseISO, format } from 'date-fns'
import { mapActions, mapState } from 'vuex'
// import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import { compareTwoStrings } from 'string-similarity'

import ModelEntry from './ModelEntry'
import ModelListEntry from './ModelListEntry'
import LoadingOverlay from '@/components/LoadingOverlay'

export default {
  name: 'ModelSelection',
  components: {
    LoadingOverlay,
    ModelEntry,
  },
  props: {
    models: {
      type: Array,
      default: () => [],
    },
    viewMode: {
      type: String,
      default: 'list',
    },
    showCreateModel: {
      type: Boolean,
      default: false,
    },
    showModelSelection: {
      type: Boolean,
      default: false,
    },
    multiSelection: {
      type: Boolean,
      default: false,
    },
    modelFilters: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      searchString: '',
      virtualListComponent: ModelListEntry,
    }
  },
  computed: {
    ...mapState(['modelsLoading', 'user', 'modelSelection']),
    createModelDisabled() {
      return (
        this.user.organization.max_models !== -1 &&
        this.models.length >= this.user.organization.max_models
      )
    },
    createModelDisabledMessage() {
      if (!this.createModelDisabled) {
        return ''
      }
      if (this.user.organization.max_models === 1) {
        return `Your organization is limited to ${this.user.organization.max_models} model.`
      }
      return `Your organization is limited to ${this.user.organization.max_models} models.`
    },
    datasetFilterOptions() {
      const resultSet = new Set()
      for (const model of this.models) {
        for (const modelSubset of model.data_subsets) {
          resultSet.add(modelSubset.data_subset.dataset_name)
        }
      }
      return [...resultSet].sort()
    },
    architectureFilterOptions() {
      const resultSet = new Set()
      for (const model of this.models) {
        resultSet.add(model.network_config_option_name)
      }
      return [...resultSet].sort()
    },
    creatorFilterOptions() {
      const resultSet = new Set()
      for (const model of this.models) {
        resultSet.add(model.created_by.username)
      }
      return [...resultSet].sort()
    },
    filteredModels() {
      const s = this.searchString
      let result = this.models

      if (this.modelFilters.selectedModelTypes) {
        result = this.models.filter((m) => {
          return this.modelFilters.selectedModelTypes.includes(m.network_type)
        })
      }

      if (this.modelFilters.selectedDatasets) {
        result = result.filter((m) => {
          if (m.data_subsets.length === 0) {
            return true
          }

          for (const subset of m.data_subsets) {
            if (this.modelFilters.selectedDatasets.includes(subset.data_subset.dataset_name)) {
              return true
            }
          }

          return false
        })
      }

      if (this.modelFilters.selectedArchitectures) {
        result = result.filter((m) => {
          return this.modelFilters.selectedArchitectures.includes(m.network_config_option_name)
        })
      }

      if (this.modelFilters.selectedCreators) {
        result = result.filter((m) => {
          return this.modelFilters.selectedCreators.includes(m.created_by.username)
        })
      }

      if (s.length > 1) {
        result = result.filter((m) => {
          for (const subset of m.data_subsets) {
            if (subset.data_subset.dataset_name.toLowerCase().includes(s.toLowerCase())) {
              return true
            }
          }
          return m.name.toLowerCase().includes(s.toLowerCase())
        })

        result.sort((a, b) => {
          return compareTwoStrings(s, b.name) - compareTwoStrings(s, a.name)
        })
      } else {
        result = result.sort((a, b) => {
          if (a.updated_date < b.updated_date) {
            return 1
          } else {
            return -1
          }
        })
      }

      return result
    },

    groupedModels() {
      let result = {}
      for (const model of this.filteredModels) {
        let groupKeys = new Set([])
        if (this.modelFilters.selectedGroupBy === 'dataset') {
          for (const modelSubset of model.data_subsets) {
            groupKeys.add(modelSubset.data_subset.dataset_name)
          }
        } else if (this.modelFilters.selectedGroupBy === 'architecture') {
          groupKeys.add(model.network_config_option_name)
        } else if (this.modelFilters.selectedGroupBy === 'type') {
          groupKeys.add(model.network_type)
        } else if (this.modelFilters.selectedGroupBy === 'date') {
          const creationDate = parseISO(model.created_date)
          groupKeys.add(format(creationDate, 'yyyy-MM'))
        } else if (this.modelFilters.selectedGroupBy === 'creator') {
          groupKeys.add(model.created_by.username)
        }

        if (groupKeys.size === 0) {
          // The keys are sorted after we processed all models. That's why we
          // pick the last unicode symbol as a placeholder for all models that
          // do not belong to a group. All those models are displayed as
          // "other models" after all groups.
          groupKeys.add('\uFFFF')
        }

        groupKeys = Array.from(groupKeys)
        for (const key of groupKeys) {
          if (result[key] === undefined) {
            result[key] = []
          }
          result[key].push(model)
        }
      }

      let sortOrder = 'asc'
      if (this.modelFilters.selectedGroupBy === 'date') {
        sortOrder = 'desc'
      }

      result = _(result).toPairs().orderBy([0], [sortOrder]).fromPairs().value()

      // let latestModels = _.cloneDeep(this.filteredModels).sort((a, b) => {
      //   if (a.updated_date < b.updated_date) {
      //     return 1
      //   } else {
      //     return -1
      //   }
      // })
      //
      // if (latestModels.length > 0) {
      //   latestModels = latestModels.slice(0, Math.min(latestModels.length, 6))
      // }
      //
      // if (latestModels.length > 0) {
      //   result = Object.assign({ 'latest models': latestModels }, result)
      // }

      return result
    },
  },
  methods: {
    ...mapActions(['openAiModal']),
    onClickCreateAiModel() {
      this.$emit('click-create-model')
    },
    onClickModel(model) {
      this.$emit('click-model', model)
    },
    onClickDeselectModel(model) {
      this.$emit('click-deselect-model', model)
    },
    onClickModelDetails(model) {
      this.$emit('click-model-details', model)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';

.selection-header-element {
  border-bottom: $gray-400 solid 1px;
}

.model-grid {
  display: grid;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.25em;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.model-grid-item-group {
  grid-column: 1/-1;
  padding-top: 0.25em;
}

.model-grid-item {
  //@extend .border-radius-sm;
  //@extend .shadow-sm;
  //@extend .p-2;
  //background-color: $white;
  margin-bottom: 0.25em;
}

.create-first-ai-model-message {
  position: relative;
  top: 15px;
  left: 56px;
}
</style>
