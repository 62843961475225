import { render, staticRenderFns } from "./AnnotationOverlay.vue?vue&type=template&id=665f4e05&scoped=true&"
import script from "./AnnotationOverlay.vue?vue&type=script&lang=js&"
export * from "./AnnotationOverlay.vue?vue&type=script&lang=js&"
import style0 from "./AnnotationOverlay.vue?vue&type=style&index=0&id=665f4e05&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "665f4e05",
  null
  
)

export default component.exports