<template>
  <div>
    <div v-for="(field, i) in fields" :key="$id(`param-viewer-input-${i}`)">
      <string-parameter
        v-if="field.type === 'string_input'"
        v-model="data[field.name]"
        :field="field"
      />

      <integer-parameter
        v-if="field.type === 'integer_input'"
        v-model="data[field.name]"
        :field="field"
      />

      <double-parameter
        v-if="field.type === 'double_input'"
        v-model="data[field.name]"
        :field="field"
      />

      <bool-parameter
        v-if="field.type === 'bool_input'"
        v-model="data[field.name]"
        :field="field"
      />

      <string-array-parameter
        v-if="field.type === 'string_array_input'"
        v-model="data[field.name]"
        :field="field"
      />

      <!-- TODO array inputs -->

      <selection-dropdown
        v-if="field.type === 'selection_dropdown'"
        v-model="data[field.name]"
        :field="field"
      />

      <json-text-parameter
        v-if="field.type === 'json_text_input'"
        v-model="data[field.name]"
        :field="field"
      />

      <param-elem-group
        v-if="field.type === 'group'"
        v-model="data[field.name]"
        :field="field"
        :fields="field.fields"
      />

      <group-selection
        v-if="field.type === 'group_selection'"
        v-model="data[field.name]"
        :field="field"
      />

      <group-switchable
        v-if="field.type === 'group_switchable'"
        v-model="data[field.name]"
        :field="field"
        :fields="field.fields"
      />
    </div>
  </div>
</template>

<script>
import StringParameter from './parameter-elements/StringParameter'
import IntegerParameter from './parameter-elements/IntegerParameter'
import DoubleParameter from './parameter-elements/DoubleParameter'
import BoolParameter from './parameter-elements/BoolParameter'
import StringArrayParameter from './parameter-elements/StringArrayParameter'
import JsonTextParameter from './parameter-elements/JsonTextParameter'
import GroupSelection from './parameter-elements/GroupSelection'
import GroupSwitchable from './parameter-elements/GroupSwitchable'
import ParamElemGroup from './parameter-elements/ParamElemGroup'
import SelectionDropdown from './parameter-elements/SelectionDropdown'

export default {
  name: 'ParameterViewer',
  components: {
    StringParameter,
    IntegerParameter,
    DoubleParameter,
    BoolParameter,
    StringArrayParameter,
    JsonTextParameter,
    GroupSelection,
    GroupSwitchable,
    ParamElemGroup,
    SelectionDropdown,
  },
  props: {
    value: Object,
    fields: Array,
  },
  data() {
    return {
      data: {},
    }
  },

  computed: {},

  watch: {},

  created() {
    if (this.value !== undefined) {
      this.data = this.value
    }
  },

  methods: {},
}
</script>
