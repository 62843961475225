<template>
  <b-form-group
    v-show="!field.hidden"
    v-bind="formGroupAttributes"
    :description="field.description"
    :label-size="size"
  >
    <b-form-checkbox
      :id="$id(field.name)"
      :disabled="plaintext || field.editable === false"
      v-model="checkboxValue"
      @input="onChange"
      :size="size"
    >
      {{ field.display_name }}
    </b-form-checkbox>
  </b-form-group>
</template>

<script>
export default {
  name: 'BoolInput',
  props: ['value', 'field', 'plaintext', 'size', 'formGroupAttributes', 'hidden'],
  data() {
    return {
      checkboxValue: false,
    }
  },

  mounted() {
    this.checkboxValue = this.value === true
    this.onChange(true)
  },

  methods: {
    onChange(initial) {
      this.$emit('input', this.checkboxValue === true, initial)
    },
  },
}
</script>
