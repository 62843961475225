<template>
  <div
    class="subset d-flex justify-content-start no-select"
    :class="{ highlight: highlight, 'no-hover': noHover }"
    :style="style"
    @click="onClick"
  >
    <!--    <div :style="getSquareStyle(subset)" class="class-label-square"></div>-->
    {{ subset.name }}
  </div>
</template>

<script>
import { getClassStyle, getColorForCSS, getTextColorForCSS } from '@/colors'
import { subsetNameHash } from '@/utils'

export default {
  name: 'SubsetEntry',
  props: ['subset', 'highlight', 'noHover'],
  computed: {
    style() {
      const hash = subsetNameHash(this.subset.name)
      const borderColor = getColorForCSS(hash, 1.0)
      const backgroundColor = getColorForCSS(hash, 0.35)
      const color = getTextColorForCSS(hash)
      return { 'border-color': borderColor, 'background-color': backgroundColor, color: color }
    },
  },
  methods: {
    getBadgeStyle(subset) {
      return getClassStyle(subsetNameHash(subset.name), { alpha: 0.35 })
    },
    getSquareStyle(subset) {
      return {
        'background-color': getColorForCSS(subsetNameHash(subset.name), 1.0),
      }
    },
    onClick(evt) {
      this.$emit('click', evt)
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../custom';

.subset {
  @extend .border-radius-md;
  word-break: break-word;
  padding: 0.5rem;
  border: 2px solid rgba(255, 255, 255, 0);
  font-weight: 600;

  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.no-hover {
  cursor: default !important;
  opacity: 1 !important;
}

.class-label-square {
  display: inline-block;
  width: 12px;
  height: 24px;
  border-radius: 2px;
  margin-right: 0.5rem;
}

.highlight {
  outline: $primary solid 3px;
}
</style>
