import Vue from 'vue'
import Vuex from 'vuex'
import Cookie from 'js-cookie'
import _ from 'lodash'
import { listTimeZones } from 'timezone-support'
import { initializeAnalytics, resetAnalytics } from '@/analytics'
import defaultState from '@/defaultState'

Vue.use(Vuex)

export class StoreValidationError extends Error {
  constructor(message) {
    super(message)
    this.name = 'StoreValidationError'
  }
}

const store = new Vuex.Store({
  state: { ...defaultState },
  mutations: {
    RESET_STATE(state) {
      const server = state.server
      Object.assign(state, defaultState)
      state.server = server
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_SERVER(state, server) {
      state.server = server
    },
    SET_CLASS_LABELS(state, classLabels) {
      state.classLabels = classLabels
      let classLabelMap = {}
      for (let i = 0; i < state.classLabels.length; i++) {
        let classLabel = state.classLabels[i]
        classLabelMap[classLabel.id] = classLabel
      }
      state.classLabelMap = classLabelMap
    },
    ADD_CLASS_LABELS(state, newClassLabels) {
      state.classLabels.push(...newClassLabels)
      state.classLabels = _.sortBy(state.classLabels, [
        (c) => {
          return c.name.toLowerCase()
        },
      ])
      for (let i = 0; i < newClassLabels.length; i++) {
        let classLabel = newClassLabels[i]
        state.classLabelMap[classLabel.id] = classLabel
      }
    },
    DELETE_CLASS_LABEL(state, classLabelId) {
      for (let i = 0; i < state.classLabels.length; i++) {
        if (state.classLabels[i].id === classLabelId) {
          state.classLabels.splice(i, 1)
          break
        }
      }

      state.classLabelMap[classLabelId] = undefined
    },
    SET_CLASS_LABELS_LOADING(state, loading) {
      state.classLabelsLoading = loading
    },

    SET_JOBS_LOADING(state, loading) {
      state.jobsLoading = loading
    },
    SET_JOBS(state, jobs) {
      state.jobs = jobs
    },
    ADD_JOBS(state, jobs) {
      state.jobs.push(...jobs)
      state.jobs = _.sortBy(state.jobs, ['id'])
    },
    DELETE_JOB(state, jobId) {
      for (let i = 0; i < state.jobs.length; i++) {
        if (state.jobs[i].id === jobId) {
          state.jobs.splice(i, 1)
          break
        }
      }
    },

    SET_DATASETS(state, datasets) {
      state.datasets = datasets
    },
    ADD_DATASETS(state, datasets) {
      state.datasets.push(...datasets)
    },
    DELETE_DATASET(state, datasetId) {
      for (let i = 0; i < state.datasets.length; i++) {
        if (state.datasets[i].id === datasetId) {
          state.datasets.splice(i, 1)
          break
        }
      }
    },
    UPDATE_DATASET(state, updatedDataset) {
      for (let i = 0; i < state.datasets.length; i++) {
        const dataset = state.datasets[i]
        if (dataset.id === updatedDataset.id) {
          Vue.set(state.datasets, i, updatedDataset)
          break
        }
      }
    },
    ADD_SUBSET_TO_DATASET(state, subset) {
      for (let i = 0; i < state.datasets.length; i++) {
        const dataset = state.datasets[i]
        if (dataset.id === subset.dataset) {
          dataset.data_subsets.push({
            id: subset.id,
            name: subset.name,
            description: subset.description,
          })
          Vue.set(state.datasets, i, dataset)
          break
        }
      }
    },

    SET_MODELS_LOADING(state, loading) {
      state.modelsLoading = loading
    },
    SET_MODEL_DETAILS_LOADING(state, loading) {
      state.modelDetailsLoading = loading
    },
    ADD_MODEL(state, model) {
      Vue.set(model, 'selected', false)
      state.models.push(model)
    },
    SET_MODELS(state, models) {
      for (const model of models) {
        model.selected = false
      }
      state.modelSelection = []

      state.models = models
      if (state.modals.aiModelDetails.model) {
        for (let i = 0; i < state.models.length; i++) {
          const model = state.models[i]
          if (model.id === state.modals.aiModelDetails.model.id) {
            state.modals.aiModelDetails.model = model
            break
          }
        }
      }
    },
    UPDATE_MODEL_SELECTION(state, { updatedModel, selected }) {
      if (selected && !updatedModel.selected) {
        state.modelSelection.push(updatedModel)
      } else if (!selected && updatedModel.selected) {
        state.modelSelection = state.modelSelection.filter((m) => {
          return m.id !== updatedModel.id
        })
      }
      Vue.set(updatedModel, 'selected', selected)

      // for (let i = 0; i < state.models.length; i++) {
      //   const model = state.models[i]
      //   if (model.id === updatedModel.id) {
      //     if (selected && !model.selected) {
      //       state.modelSelection.push(model)
      //     } else if (!selected && model.selected) {
      //       state.modelSelection = state.modelSelection.filter((m) => {
      //         return m.id !== updatedModel.id
      //       })
      //     }
      //     Vue.set(model, 'selected', selected)
      //     break
      //   }
      // }
    },
    UPDATE_MODEL(state, updatedModel) {
      for (let i = 0; i < state.models.length; i++) {
        const model = state.models[i]
        if (model.id === updatedModel.id) {
          if (updatedModel.selected && !model.selected) {
            state.modelSelection.push(updatedModel)
          } else if (updatedModel.selected === false && model.selected) {
            state.modelSelection = state.modelSelection.filter((m) => {
              return m.id !== updatedModel.id
            })
          }

          if (updatedModel.selected === undefined) {
            Vue.set(updatedModel, 'selected', model.selected)
          }

          Vue.set(state.models, i, updatedModel)
          break
        }
      }

      if (
        state.modals.aiModelDetails.model &&
        state.modals.aiModelDetails.model.id === updatedModel.id
      ) {
        state.modals.aiModelDetails.model = updatedModel
      }
    },
    DELETE_MODEL(state, modelId) {
      for (let i = 0; i < state.models.length; i++) {
        if (state.models[i].id === modelId) {
          state.models.splice(i, 1)
          break
        }
      }

      state.modelSelection = state.modelSelection.filter((m) => {
        return m.id !== modelId
      })

      if (state.modals.aiModelDetails.model && state.modals.aiModelDetails.model.id === modelId) {
        state.modals.aiModelDetails.model = undefined
      }
    },

    SET_MODEL_CONFIGURATION_OPTIONS(state, modelConfigurationOptions) {
      state.modelConfigurationOptions = modelConfigurationOptions
    },

    SET_WORKERS_LOADING(state, loading) {
      state.workersLoading = loading
    },
    SET_WORKERS(state, workers) {
      state.workers = workers
    },
    DELETE_WORKER(state, workerId) {
      for (let i = 0; i < state.workers.length; i++) {
        if (state.workers[i].id === workerId) {
          state.workers.splice(i, 1)
          break
        }
      }
    },

    SET_HIDE_NAVBAR(state, hideNavbar) {
      state.hideNavbar = hideNavbar
    },
    SET_MODAL_SHOW(state, { modalName, show }) {
      // Vue.set(state.modals[modalName], 'show', show)
      state.modals[modalName].show = show
    },
    SET_MODAL_AI_DETAILS_MODEL(state, model) {
      state.modals.aiModelDetails.model = model
    },
  },
  actions: {
    setLoading(context, loading) {
      context.commit('SET_LOADING', loading)
    },
    async getCSRF() {
      await Vue.prototype.$axios({ method: 'get', url: '/api/csrf' })
    },
    async getUser(context) {
      let user = defaultState.user
      try {
        const response = await Vue.prototype.$axios({
          method: 'get',
          url: '/api/auth/users/me/',
          withCredentials: true,
        })
        user = response.data
        initializeAnalytics(user)
      } catch (e) {
        if (!e.response || e.response.status !== 403) {
          throw e
        }
      }

      context.commit('SET_USER', user)
      return user
    },
    getServer(context) {
      return new Promise((resolve, reject) => {
        Vue.prototype
          .$axios({ method: 'get', url: '/api/server' })
          .then((response) => {
            response.data
            let server = {
              enableRegistration: response.data.enable_registration,
              enableSupport: response.data.enable_support,
              enableCloudDeployments: response.data.enable_cloud_deployments,
              enableAutomaticAnnotations: response.data.enable_automatic_annotations,
              timezones: ['UTC'],
              cloud: response.data.cloud,
              version: response.data.version,
            }
            let timezones = listTimeZones()
            for (const timezone of timezones) {
              if (response.data.timezones.includes(timezone)) {
                server.timezones.push(timezone)
              }
            }

            server.timezones.sort()
            context.commit('SET_SERVER', server)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    async login(context, { username, password, rememberMe }) {
      const requestData = {
        username: username,
        password: password,
        remember_me: rememberMe,
      }

      try {
        Vue.prototype.$axios.defaults.withCredentials = true
        const response = await Vue.prototype.$axios({
          method: 'post',
          url: '/api/auth/users/login/',
          data: requestData,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': Cookie.get('csrftoken'),
          },
        })
        let user = response.data
        context.commit('SET_USER', user)
        initializeAnalytics(user)
      } catch (e) {
        context.commit('SET_USER', defaultState.user)
        if (!e.response) {
          console.error(e)
          throw new Error('No connection to server')
        } else if (e.response.status === 403) {
          if (e.response.data.detail) {
            throw new Error(e.response.data.detail)
          } else {
            throw new Error('Wrong username or password')
          }
        } else if (e.response.status === 500) {
          throw new Error('Server error')
        } else {
          console.error(e)
          throw new Error('Unknown error')
        }
      }
    },
    async logout(context) {
      try {
        await Vue.prototype.$axios({
          method: 'post',
          data: {},
          url: '/api/auth/users/logout/',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': Cookie.get('csrftoken'),
          },
        })
        context.commit('RESET_STATE', defaultState)
      } catch (e) {
        console.error(e)
      }
      resetAnalytics()
    },
    async getClassLabels({ commit, getters }) {
      if (!getters.userHasLicense) {
        return
      }
      commit('SET_CLASS_LABELS_LOADING', true)
      const response = await Vue.prototype.$axios({
        method: 'get',
        url: '/api/class-labels',
        withCredentials: true,
      })
      const classLabels = response.data
      commit('SET_CLASS_LABELS', classLabels)
      commit('SET_CLASS_LABELS_LOADING', false)
    },
    async addClassLabel({ commit, getters }, { name }) {
      if (!getters.userHasLicense) {
        return
      }
      if (name.length === 0) throw new Error('class names must be at least one characters long')
      let requestData = {
        name: name,
      }
      const response = await Vue.prototype.$axios({
        method: 'post',
        url: '/api/class-labels/',
        data: requestData,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
      let newClassLabel = response.data
      commit('ADD_CLASS_LABELS', [newClassLabel])
      return newClassLabel
    },
    async deleteClassLabel({ commit, getters }, { classLabelId }) {
      if (!getters.userHasLicense) {
        return
      }
      commit('SET_CLASS_LABELS_LOADING', true)
      try {
        await Vue.prototype.$axios({
          method: 'delete',
          url: `/api/class-labels/${classLabelId}/`,
          withCredentials: true,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': Cookie.get('csrftoken'),
          },
        })
        commit('DELETE_CLASS_LABEL', classLabelId)
      } catch (e) {
        console.error(e)
      }
      commit('SET_CLASS_LABELS_LOADING', false)
    },
    async getJobs({ commit, getters }) {
      if (!getters.userHasLicense) {
        return
      }
      commit('SET_JOBS_LOADING', true)
      const response = await Vue.prototype.$axios({
        method: 'get',
        url: '/api/jobs',
        withCredentials: true,
      })
      const jobs = response.data
      commit('SET_JOBS', jobs)
      commit('SET_JOBS_LOADING', false)
    },
    async createJob({ commit, getters }, job) {
      if (!getters.userHasLicense) {
        return
      }
      let requestData = job
      const response = await Vue.prototype.$axios({
        method: 'post',
        url: '/api/jobs/',
        data: requestData,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
      let newJob = response.data

      commit('ADD_JOBS', [newJob])
      return newJob
    },
    async deleteJob({ commit, getters }, { jobId }) {
      if (!getters.userHasLicense) {
        return
      }
      try {
        await Vue.prototype.$axios({
          method: 'delete',
          url: `/api/jobs/${jobId}/`,
          withCredentials: true,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': Cookie.get('csrftoken'),
          },
        })
      } catch (e) {
        if (e.response && e.response.status !== 404) {
          throw e
        }
      }
      commit('DELETE_JOB', jobId)
    },
    async getDatasets({ commit, getters }) {
      if (!getters.userHasLicense) {
        return
      }
      const response = await Vue.prototype.$axios({
        method: 'get',
        url: '/api/datasets/preview/',
        withCredentials: true,
      })
      const datasets = response.data
      commit('SET_DATASETS', datasets)
    },
    async updateDataset({ commit, getters }, { dataset }) {
      if (!getters.userHasLicense) {
        return
      }
      if (!dataset.name) {
        throw new StoreValidationError('Dataset name is missing.')
      }

      if (!dataset.object_detection && !dataset.classification) {
        throw new StoreValidationError(
          'Dataset must have at least classification or detection activated.'
        )
      }

      if (dataset.classification && dataset.classification_class_label_ids.length === 0) {
        throw new StoreValidationError(
          'Classification is active but no classification class labels provided.'
        )
      }

      if (dataset.object_detection && dataset.object_detection_class_label_ids.length === 0) {
        throw new StoreValidationError(
          'Detection is active but no detection class labels provided.'
        )
      }

      let requestData = {
        name: dataset.name,
        classification: dataset.classification,
        object_detection: dataset.object_detection,
        object_detection_orientation: dataset.object_detection_orientation,
        classification_class_label_ids: dataset.classification_class_label_ids,
        object_detection_class_label_ids: dataset.object_detection_class_label_ids,
        retention: dataset.retention,
      }

      const response = await Vue.prototype.$axios({
        method: 'patch',
        url: `/api/datasets/${dataset.id}/`,
        data: requestData,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
      let updatedDataset = response.data

      commit('UPDATE_DATASET', updatedDataset)
      return updatedDataset
    },
    async addDataset({ commit, getters }, { dataset }) {
      if (!getters.userHasLicense) {
        return
      }
      if (!dataset.name) {
        throw new StoreValidationError('Dataset name is missing.')
      }

      if (!dataset.object_detection && !dataset.classification) {
        throw new StoreValidationError(
          'Dataset must have at least classification or detection activated.'
        )
      }

      if (dataset.classification && dataset.classification_class_label_ids.length === 0) {
        throw new StoreValidationError(
          'Classification is active but no classification class labels provided.'
        )
      }

      if (dataset.object_detection && dataset.object_detection_class_label_ids.length === 0) {
        throw new StoreValidationError(
          'Detection is active but no detection class labels provided.'
        )
      }

      let requestData = {
        name: dataset.name,
        classification: dataset.classification,
        key_points: false,
        roi_classification: false,
        roi_key_points: false,
        object_detection: dataset.object_detection,
        object_detection_orientation: dataset.object_detection_orientation,
        object_detection_key_points: false,
        object_detection_mask: false,
        semantic_segmentation: false,
        classification_class_label_ids: dataset.classification_class_label_ids,
        object_detection_class_label_ids: dataset.object_detection_class_label_ids,
        retention: dataset.retention,
      }

      const response = await Vue.prototype.$axios({
        method: 'post',
        url: '/api/datasets/',
        data: requestData,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
      let newDataset = response.data

      commit('ADD_DATASETS', [newDataset])
      return newDataset
    },
    async deleteDataset({ commit, getters }, { datasetId }) {
      if (!getters.userHasLicense) {
        return
      }
      await Vue.prototype.$axios({
        method: 'delete',
        url: `/api/datasets/${datasetId}/`,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
      commit('DELETE_DATASET', datasetId)
    },
    async addSubset({ commit, getters }, { name, description, datasetId }) {
      if (!getters.userHasLicense) {
        return
      }
      let requestData = {
        name: name,
        description: description,
        dataset: datasetId,
      }
      const response = await Vue.prototype.$axios({
        method: 'post',
        url: '/api/data-subsets/',
        data: requestData,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
      let newSubset = response.data
      commit('ADD_SUBSET_TO_DATASET', newSubset)
      return newSubset
    },

    async createModel({ commit, getters }, { model }) {
      if (!getters.userHasLicense) {
        return
      }
      commit('SET_MODELS_LOADING', true)
      try {
        const response = await Vue.prototype.$axios({
          method: 'post',
          url: '/api/models/',
          data: model,
          withCredentials: true,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': Cookie.get('csrftoken'),
          },
        })
        const newModel = response.data
        commit('ADD_MODEL', newModel)
        commit('SET_MODELS_LOADING', false)
        return newModel
      } catch (e) {
        commit('SET_MODELS_LOADING', false)
        throw e
      }
    },
    async getModels({ commit, state, getters }, { forceReload = false }) {
      if (!getters.userHasLicense) {
        return
      }
      if (state.models.length === 0 || forceReload) {
        commit('SET_MODELS_LOADING', true)
        try {
          const response = await Vue.prototype.$axios({
            method: 'get',
            url: '/api/models/',
            withCredentials: true,
          })
          const models = response.data
          commit('SET_MODELS', models)
        } catch (e) {
          if (e.response && e.response.status === 403) {
            commit('SET_MODELS', [])
          }
        }
        commit('SET_MODELS_LOADING', false)
      }
    },
    async getModelDetails({ state, commit, dispatch }, { model, forceReload = false }) {
      commit('SET_MODEL_DETAILS_LOADING', true)
      if (state.models.length === 0) {
        await dispatch('getModels', { forceReload: false })
      }
      if (!model) {
        return undefined
      }
      let completeModel
      for (let i = 0; i < state.models.length; i++) {
        const m = state.models[i]
        if (m.id === model.id) {
          if (!m.parameters || forceReload) {
            try {
              const response = await Vue.prototype.$axios({
                method: 'get',
                url: `/api/models/${m.id}/`,
                withCredentials: true,
              })
              completeModel = response.data
              commit('UPDATE_MODEL', completeModel)
            } catch (e) {
              if (!e.response || e.response.status !== 403) {
                throw e
              }
            }
          } else {
            completeModel = m
          }
          break
        }
      }
      commit('SET_MODEL_DETAILS_LOADING', false)
      return completeModel
    },
    async deleteModel({ commit, getters }, { modelId }) {
      if (!getters.userHasLicense) {
        return
      }
      await Vue.prototype.$axios({
        method: 'delete',
        url: `/api/models/${modelId}/`,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
      commit('DELETE_MODEL', modelId)
    },
    async getModelConfigurationOptions({ state, getters, commit }) {
      if (!getters.userHasLicense) {
        return
      }
      if (state.modelConfigurationOptions.length === 0) {
        const response = await Vue.prototype.$axios({
          method: 'get',
          url: '/api/model-configuration-options/',
        })
        const modelConfigurationOptions = response.data
        commit('SET_MODEL_CONFIGURATION_OPTIONS', modelConfigurationOptions)
      }
    },
    updateModel({ commit, getters }, { model }) {
      if (!getters.userHasLicense) {
        return
      }
      commit('UPDATE_MODEL', model)
    },
    selectModel({ commit }, { model }) {
      commit('UPDATE_MODEL_SELECTION', { updatedModel: model, selected: true })
    },
    deselectModel({ commit }, { model }) {
      commit('UPDATE_MODEL_SELECTION', { updatedModel: model, selected: false })
    },
    toggleSelectModel({ commit }, { model }) {
      commit('UPDATE_MODEL_SELECTION', { updatedModel: model, selected: !model.selected })
    },
    async getWorkers({ state, getters, commit }, { forceReload = false }) {
      if (!getters.userHasLicense) {
        return
      }
      if (state.workers.length === 0 || forceReload) {
        commit('SET_WORKERS_LOADING', true)
        const response = await Vue.prototype.$axios({
          method: 'get',
          url: '/api/workers/',
          withCredentials: true,
        })
        const workers = response.data
        commit('SET_WORKERS', workers)
        commit('SET_WORKERS_LOADING', false)
      }
    },
    async deleteWorker({ commit, getters, dispatch }, { workerId }) {
      if (!getters.userHasLicense) {
        return
      }
      await Vue.prototype.$axios({
        method: 'delete',
        url: `/api/workers/${workerId}/`,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
      commit('DELETE_WORKER', workerId)
      dispatch('getJobs')
    },
    hideNavbar(context) {
      context.commit('SET_HIDE_NAVBAR', true)
    },
    showNavbar(context) {
      context.commit('SET_HIDE_NAVBAR', false)
    },
    openAiModal({ commit, dispatch }, model) {
      dispatch('getModels', { forceReload: false })
      dispatch('getModelDetails', { model })
      commit('SET_MODAL_AI_DETAILS_MODEL', model)
      commit('SET_MODAL_SHOW', { modalName: 'aiModelDetails', show: true })
    },
    closeAiModal({ commit }) {
      commit('SET_MODAL_SHOW', { modalName: 'aiModelDetails', show: false })
      commit('SET_MODAL_AI_DETAILS_MODEL', undefined)
    },
  },
  getters: {
    loading(state) {
      return state.loading
    },
    user(state) {
      return state.user
    },
    userHasLicense(state) {
      return state.user && state.user.license && state.user.license.valid
    },
    viewDatasetPermission(state) {
      if (state.user && state.user.permissions) {
        return state.user.permissions.includes('platform_api.view_dataset')
      }
      return false
    },
    deleteDatasetPermission(state) {
      if (state.user && state.user.permissions) {
        return state.user.permissions.includes('platform_api.delete_dataset')
      }
      return false
    },
    viewModelsPermission(state) {
      if (state.user && state.user.permissions) {
        return state.user.permissions.includes('platform_api.view_networkmodel')
      }
      return false
    },
    deleteModelPermission(state) {
      if (state.user && state.user.permissions) {
        return state.user.permissions.includes('platform_api.delete_networkmodel')
      }
      return false
    },
    hideNavbar(state) {
      return state.hideNavbar
    },
    server(state) {
      return state.server
    },
    classLabels(state) {
      return state.classLabels
    },
    classLabelMap(state) {
      return state.classLabelMap
    },
  },
})

export default store
