<template>
  <div>
    <template v-if="worker.status === 'running'">
      <b-badge variant="primary" class="mr-2">busy</b-badge>
      <span v-if="worker.estimated_time_of_completion" class="text-dark font-size-small">
        current job finished {{ worker.estimated_time_of_completion | formatDateDuration }}
      </span>
    </template>
    <template v-else-if="worker.status === 'idle'">
      <b-badge variant="info" class="mr-2">idle</b-badge>
      <span v-if="worker.last_running" class="text-dark font-size-small">
        last running {{ worker.last_running | formatDateDuration }}
      </span>
    </template>
    <template v-else>
      <b-badge variant="secondary" class="mr-1">offline</b-badge>
      <span v-if="worker.last_online" class="text-dark font-size-small">
        last online {{ worker.last_online | formatDateDuration }}
      </span>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'WorkerStatusBadge',
  props: ['worker'],
  data() {
    return {}
  },
  methods: {},
  computed: {
    ...mapState(['jobs']),
  },
}
</script>

<style scoped lang="scss"></style>
