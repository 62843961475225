<template>
  <div class="container-fluid full-height-wrapper login-container">
    <div class="d-flex justify-content-center align-items-center mh-100 h-100">
      <div class="form-signin">
        <img src="@/assets/logo_data_spree.svg" class="brand-logo img-fluid mt-0 mb-4 ml-3" />
        <div class="border-radius-md shadow-md border-gray-200 bg-white position-relative">
          <slot></slot>
        </div>
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthWrapper',
}
</script>

<style scoped lang="scss">
@import '../custom';

.login-container {
  background-color: rgba(50, 80, 240, 0.03);
}

.form-signin {
  width: 100%;
  max-width: 500px;
}

.brand-logo {
  max-width: 180px;
}
</style>
