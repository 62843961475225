<template>
  <div>
    <b-modal
      id="modal-dataset"
      ref="modalDataset"
      v-model="showModal"
      scrollable
      size="xl"
      title="Dataset"
      body-class="fixed-modal-body"
    >
      <div class="container-fluid h-100">
        <loading-overlay v-if="loading" />

        <div class="row h-100">
          <div class="col-3 bg-gray-200 p-3 h-100 scroll-box">
            <b-nav pills vertical>
              <b-nav-item class="wizard-nav-item" :active="tabIndex === 0" @click="tabIndex = 0">
                <fai class="nav-item-icon" icon="adjust" />
                <div class="nav-item-text">General</div>
                <exclamation-mark v-if="generalTabValid === false" class="ml-2" />
              </b-nav-item>

              <b-nav-item
                v-if="classificationClassLabelTab"
                class="wizard-nav-item"
                :active="tabIndex === classificationTabIndex"
                @click="tabIndex = classificationTabIndex"
              >
                <fai class="nav-item-icon" icon="tags" />
                <div class="nav-item-text">Classification</div>
                <exclamation-mark v-if="selectedClassificationLabelsValid === false" class="ml-2" />
              </b-nav-item>

              <b-nav-item
                v-if="detectionClassLabelTab"
                class="wizard-nav-item"
                :active="tabIndex === detectionTabIndex"
                @click="tabIndex = detectionTabIndex"
              >
                <fai class="nav-item-icon" icon="tags" />
                <div class="nav-item-text">Detection</div>
                <exclamation-mark v-if="selectedDetectionLabelsValid === false" class="ml-2" />
              </b-nav-item>

              <template v-if="additionalOptionsAvailable">
                <div class="horizontal-line mt-3 mb-3" />
                <b-nav-item
                  class="wizard-nav-item"
                  :active="tabIndex === subsetTabIndex"
                  @click="tabIndex = subsetTabIndex"
                >
                  <fai class="nav-item-icon" icon="chart-pie" />
                  <div class="nav-item-text">Subsets</div>
                </b-nav-item>
              </template>
            </b-nav>
          </div>

          <div v-show="tabIndex === 0" class="col-9 h-100 p-3 scroll-box">
            <b-form-group
              id="dataset-name-label"
              description="Enter a descriptive name for the dataset."
            >
              <label>
                <exclamation-mark v-if="datasetNameValid === false" />
                Name</label
              >
              <b-form-input
                id="dataset-name"
                v-model="datasetName"
                type="text"
                placeholder="Dataset Name"
                :state="datasetNameValid"
                required
              />
            </b-form-group>

            <label class="mb-0">
              <exclamation-mark v-if="annotationSelectionValid === false" />
              Annotations</label
            >
            <b-form-text class="mb-2">
              Select the annotation methods you need within this dataset.
            </b-form-text>

            <b-alert :show="dataset !== undefined" class="mt-3 mb-3">
              <fai icon="info-circle" class="mb-1 mr-1" />
              When you remove an annotation method of the dataset, the existing annotations will be
              kept. No object will be deleted and no class label removed. You can switch on a method
              again at any time.
            </b-alert>

            <div class="row pl-2 pr-2">
              <div class="col-4 pl-2 pr-2">
                <selectable-card v-model="categoriesSelected">
                  <img src="@/assets/dataset-explorer/categories.png" class="w-100" />
                  <div class="pt-2 pl-1 pr-1">
                    <div class="font-weight-600">Categories</div>
                    <small>Add class labels to whole images for image classification.</small>
                  </div>
                </selectable-card>
              </div>
              <div class="col-4 pl-2 pr-2">
                <selectable-card
                  v-model="boundingBoxesSelected"
                  :disabled="rotatedBoundingBoxesSelected"
                >
                  <img src="@/assets/dataset-explorer/bounding_boxes.png" class="w-100" />
                  <div class="pt-2 pl-1 pr-1">
                    <div class="font-weight-600">Bounding Boxes</div>
                    <small>Draw bounding boxes including class labels for object detection.</small>
                  </div>
                </selectable-card>
              </div>
              <div class="col-4 pl-2 pr-2">
                <selectable-card
                  v-model="rotatedBoundingBoxesSelected"
                  :disabled="boundingBoxesSelected"
                >
                  <img src="@/assets/dataset-explorer/rotated_bounding_boxes.jpg" class="w-100" />
                  <div class="pt-2 pl-1 pr-1">
                    <div class="font-weight-600">Rotated Bounding Boxes</div>
                    <small
                      >Draw rotated bounding boxes including class labels for object
                      detection.</small
                    >
                  </div>
                </selectable-card>
              </div>
            </div>
            <label class="mt-4 mb-0">Data Retention</label>
            <b-form-text class="mb-2">
              Set up a data retention policy to automatically delete unneeded files.
            </b-form-text>
            <b-form-checkbox id="retention-checkbox" v-model="retentionDisabled" class="mb-3">
              Keep all items
            </b-form-checkbox>
            <div v-if="!retentionDisabled">
              <b-form-group
                id="group-retention-period"
                description="Items are automatically deleted after the specified retention period (in hours)."
                label="Retention period"
                label-for="retention-period"
              >
                <b-form-input
                  id="retention-period"
                  type="number"
                  v-model="retentionPeriod"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="group-retention-status-select"
                description="Items with the selected states will be removed after the retention period is exceeded."
                label="Status"
                label-for="retention-status-select"
              >
                <b-form-select
                  id="retention-status-select"
                  v-model="retentionStatus"
                  :options="statusOptions"
                  multiple
                />
              </b-form-group>
            </div>
          </div>

          <div v-show="tabIndex === classificationTabIndex" class="col-9 h-100 p-0">
            <div class="d-flex flex-column h-100">
              <div class="pl-3 pt-3 pr-3">
                <span class="font-weight-700">Classification Labels Selection</span>
                <div class="text-dark d-none d-md-block">
                  Select or create the class labels that you would like to use as tags for whole
                  images.
                </div>

                <b-alert :show="dataset !== undefined" dismissible class="mt-3 mb-3">
                  <fai icon="info-circle" class="mb-1 mr-1" />
                  When you remove class labels here, these class labels won't appear as options in
                  the annotation tool anymore and cannot be selected for training an AI model. The
                  existing annotations will be kept, i.e. no class label will be removed from an
                  image. You can activate the class labels again by selecting them here.
                </b-alert>
              </div>

              <div class="flex-grow-1 position-relative overflow-hidden p-3">
                <div class="h-100">
                  <columnar-class-label-selection
                    :warning="selectedClassificationLabelsValid === false"
                    :class-labels="classLabels"
                    :selected-class-labels="selectedClassificationClassLabels"
                    create-enabled
                    @selection-changed="selectedClassificationClassLabels = $event"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-show="tabIndex === detectionTabIndex" class="col-9 h-100 p-0">
            <div class="d-flex flex-column h-100">
              <div class="pl-3 pt-3 pr-3">
                <span class="font-weight-700">Object Labels Selection</span>
                <div class="text-dark d-none d-md-block">
                  Select or create the class labels that you would like to use as tags for objects.
                </div>

                <b-alert :show="dataset !== undefined" dismissible class="mt-3 mb-3">
                  <fai icon="info-circle" class="mb-1 mr-1" />
                  When you remove class labels here, these class labels won't appear as options in
                  the annotation tool anymore and cannot be selected for training an AI model. The
                  existing annotations will be kept, i.e. no object will be removed from an image.
                  You can activate the class labels again by selecting them here.
                </b-alert>
              </div>

              <div class="flex-grow-1 position-relative overflow-hidden p-3">
                <div class="h-100">
                  <columnar-class-label-selection
                    :warning="selectedDetectionLabelsValid === false"
                    :class-labels="classLabels"
                    :selected-class-labels="selectedDetectionClassLabels"
                    create-enabled
                    @selection-changed="selectedDetectionClassLabels = $event"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-show="tabIndex === subsetTabIndex" class="col-9 h-100 p-0">
            <div class="d-flex flex-column h-100 p-3">
              <div class="mb-3">
                <span class="font-weight-700">Subsets</span>
                <div class="text-dark d-none d-md-block">
                  Create subsets that contain only parts of a dataset. It is best practice to train
                  AI models with one subset and evaluate the AI model on another part of the data.
                </div>
              </div>

              <div class="row pl-2 pr-2 mb-4">
                <div class="col-4 pl-2 pr-2">
                  <selectable-card @click="openCreateEmpty">
                    <div class="p-2">
                      <div class="font-weight-600">Empty Subset</div>
                      <small
                        >Create an empty subset. You can assign items to the subsets later in the
                        dataset explorer.</small
                      >
                    </div>
                  </selectable-card>
                </div>
                <div class="col-4 pl-2 pr-2">
                  <selectable-card @click="openCreateRandomSplit">
                    <div class="p-2">
                      <div class="font-weight-600">Random Split</div>
                      <small
                        >Randomly split the dataset into two subsets. You can specify the amount of
                        data for each subset (default: 80%).</small
                      >
                    </div>
                  </selectable-card>
                </div>
                <div class="col-4 pl-2 pr-2">
                  <selectable-card @click="openCreateRangeSplit">
                    <div class="p-2">
                      <div class="font-weight-600">Range Split</div>
                      <small
                        >Create two subsets and assign the first part (ordered by date) to one
                        subset and the second part to the other subset</small
                      >
                    </div>
                  </selectable-card>
                </div>
              </div>

              <div
                v-if="dataset"
                class="flex-grow-1 position-relative scroll-box p-2 bg-gray-200 border-radius-md"
              >
                <div
                  v-for="subset in dataset.data_subsets"
                  :key="`subset-${subset.id}`"
                  class="bg-white shadow-md p-3 m-3 d-flex justify-content-between align-items-center border-radius-sm"
                >
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="text-secondary mr-2">#{{ subset.id }}</div>

                    <b-form-input
                      v-if="editedSubset && editedSubset.id === subset.id"
                      v-model="editedSubset.name"
                    />
                    <b-form-input v-else plaintext :value="subset.name" />
                  </div>
                  <div>
                    <template v-if="editedSubset && editedSubset.id === subset.id">
                      <b-button
                        variant="primary"
                        size="sm"
                        class="mr-2"
                        @click="onClickSaveSubset(subset)"
                      >
                        save
                      </b-button>
                      <b-button
                        variant="secondary"
                        size="sm"
                        class="mr-2"
                        @click="editedSubset = undefined"
                      >
                        cancel
                      </b-button>
                    </template>
                    <b-button
                      v-else
                      variant="primary"
                      size="sm"
                      class="mr-2"
                      @click="onClickEditSubset(subset)"
                    >
                      edit
                    </b-button>
                    <b-button variant="danger" size="sm" @click="onClickDeleteSubset(subset)">
                      delete
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button
            v-if="dataset && deleteDatasetPermission"
            v-b-modal.modal-delete-dataset
            variant="danger"
            class="mr-2"
          >
            Delete
          </b-button>
          <div class="d-flex justify-content-end w-100">
            <b-button variant="secondary" class="ml-2" @click="showModal = false"> Close</b-button>
            <b-button v-if="tabIndex > 0" variant="primary" class="ml-2" @click="onClickPrevious">
              Previous
            </b-button>
            <b-button
              v-if="tabIndex === 0 || tabIndex < nTabs - 1"
              :disabled="tabIndex === 0 && nTabs === 1"
              variant="primary"
              class="ml-2"
              @click="onClickNext"
            >
              Next
            </b-button>
            <b-button v-if="saveAvailable" variant="primary" class="ml-2" @click="onClickSave">
              Save
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="modal-delete-dataset"
      ref="modalDeleteDataset"
      size="md"
      title="Delete dataset"
      @ok="onDeleteDataset"
    >
      <div>
        <b-alert variant="danger" show>
          Are you absolutely sure to delete this dataset ({{ dataset ? dataset.name : '' }}) and all
          contained items?
        </b-alert>
        <b-form-group
          id="deleteDatasetNameGroup"
          label="Dataset Name"
          label-for="deleteDatasetName"
          description="Confirm to delete the correct dataset by entering its name."
        >
          <b-form-input
            id="deleteDatasetName"
            v-model="deleteDatasetName"
            type="text"
            required
            placeholder="Name"
          />
        </b-form-group>
      </div>
    </b-modal>
    <create-subset-modal
      :show="showCreateSubsetModal"
      :type="createSubsetType"
      :dataset="dataset"
      @show="showCreateSubsetModal = $event"
      @created="$emit('subset-updated')"
    />
  </div>
</template>

<script>
import ColumnarClassLabelSelection from '@/components/class-labels/ColumnarClassLabelSelection'
import CreateSubsetModal from '@/components/modals/CreateSubsetModal'
import ExclamationMark from '@/components/ExclamationMark'
import LoadingOverlay from '@/components/LoadingOverlay'
import SelectableCard from '@/components/selectable-card/SelectableCard'
import { mapActions, mapGetters, mapState } from 'vuex'
import { StoreValidationError } from '@/store'
import Cookie from 'js-cookie'
import statusOptions from '@/options'

export default {
  name: 'DatasetModal',
  components: {
    CreateSubsetModal,
    ColumnarClassLabelSelection,
    ExclamationMark,
    SelectableCard,
    LoadingOverlay,
  },
  props: {
    show: Boolean,
    dataset: Object,
  },

  data() {
    return {
      loading: false,
      tabIndex: 0,
      showModal: false,

      showCreateSubsetModal: false,
      createSubsetType: '',
      editedSubset: { id: undefined, name: undefined },

      categoriesSelected: false,
      boundingBoxesSelected: false,
      rotatedBoundingBoxesSelected: false,
      datasetName: '',
      retentionDisabled: true,
      retentionStatus: true,
      retentionPeriod: 90,
      selectedClassificationClassLabels: [],
      selectedDetectionClassLabels: [],

      statusOptions: statusOptions,

      // delete modal
      deleteDatasetName: '',

      // validation
      datasetNameValid: null,
      annotationSelectionValid: null,
      selectedClassificationLabelsValid: null,
      selectedDetectionLabelsValid: null,
    }
  },

  computed: {
    ...mapState({
      classLabels: (state) => state.classLabels,
    }),
    ...mapGetters(['deleteDatasetPermission']),
    nTabs() {
      let n = 1
      if (this.classificationClassLabelTab) n++
      if (this.detectionClassLabelTab) n++
      if (this.additionalOptionsAvailable) n++
      return n
    },
    classificationTabIndex() {
      if (!this.classificationClassLabelTab) return -1
      return 1
    },
    detectionTabIndex() {
      if (!this.detectionClassLabelTab) return -1
      if (this.classificationClassLabelTab) return 2
      return 1
    },
    subsetTabIndex() {
      if (!this.additionalOptionsAvailable) return -1
      return this.nTabs - 1
    },

    classificationClassLabelTab() {
      return this.categoriesSelected
    },
    detectionClassLabelTab() {
      return this.boundingBoxesSelected || this.rotatedBoundingBoxesSelected
    },
    additionalOptionsAvailable() {
      return this.dataset !== undefined
    },
    generalTabValid() {
      return this.datasetNameValid && this.annotationSelectionValid
    },
    saveAvailable() {
      return this.tabIndex !== 0 && this.tabIndex === this.nTabs - 1
    },
  },

  watch: {
    show: function (newValue) {
      if (newValue) {
        this.reset()
        this.loadFromDataset()
      }
      this.showModal = newValue
    },

    showModal: function (newValue) {
      this.$emit('show', newValue)
    },
  },

  created: function () {},

  mounted() {
    this.showModal = this.show
    this.reset()
    this.loadFromDataset()
  },

  methods: {
    ...mapState(['classLabelMap']),
    ...mapActions(['addDataset', 'updateDataset', 'deleteDataset']),
    reset() {
      this.loading = false
      this.tabIndex = 0
      this.categoriesSelected = false
      this.boundingBoxesSelected = false
      this.rotatedBoundingBoxesSelected = false
      this.datasetName = ''
      this.selectedClassificationClassLabels = []
      this.selectedDetectionClassLabels = []
      this.datasetNameValid = null
      this.annotationSelectionValid = null
      this.selectedClassificationLabelsValid = null
      this.selectedDetectionLabelsValid = null
    },
    loadFromDataset() {
      if (this.dataset) {
        this.datasetName = this.dataset.name
        this.categoriesSelected = this.dataset.classification
        if (this.dataset.object_detection) {
          if (this.dataset.object_detection_orientation) {
            this.rotatedBoundingBoxesSelected = true
          } else {
            this.boundingBoxesSelected = true
          }
        }

        this.retentionDisabled = !this.dataset.retention
        if (this.dataset.retention) {
          this.retentionPeriod = this.dataset.retention.retention_period / 3600
          this.retentionStatus = this.dataset.retention.status
        }

        const selectedClassificationClassLabels = []
        for (const classLabel of this.dataset.classification_class_labels) {
          selectedClassificationClassLabels.push(classLabel)
        }
        this.selectedClassificationClassLabels = selectedClassificationClassLabels

        const selectedDetectionClassLabels = []
        for (const classLabel of this.dataset.object_detection_class_labels) {
          selectedDetectionClassLabels.push(classLabel)
        }
        this.selectedDetectionClassLabels = selectedDetectionClassLabels
      }
    },
    validate() {
      this.datasetNameValid = this.datasetName !== ''
      this.annotationSelectionValid =
        this.categoriesSelected || this.boundingBoxesSelected || this.rotatedBoundingBoxesSelected
      this.selectedClassificationLabelsValid =
        !this.categoriesSelected || this.selectedClassificationClassLabels.length > 0
      this.selectedDetectionLabelsValid =
        (!this.boundingBoxesSelected && !this.rotatedBoundingBoxesSelected) ||
        this.selectedDetectionClassLabels.length > 0
      return (
        this.datasetNameValid &&
        this.annotationSelectionValid &&
        this.selectedClassificationLabelsValid &&
        this.selectedDetectionLabelsValid
      )
    },
    openCreateEmpty() {
      this.createSubsetType = 'empty'
      this.showCreateSubsetModal = true
    },
    openCreateRandomSplit() {
      this.createSubsetType = 'random_split'
      this.showCreateSubsetModal = true
    },
    openCreateRangeSplit() {
      this.createSubsetType = 'range_split'
      this.showCreateSubsetModal = true
    },
    onClickEditSubset(subset) {
      this.editedSubset.id = subset.id
      this.editedSubset.name = subset.name
    },
    onClickSaveSubset(subset) {
      let requestData = {
        name: this.editedSubset.name,
      }
      let self = this
      this.$axios({
        method: 'patch',
        url: `/api/data-subsets/${subset.id}/`,
        data: requestData,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
        .then(() => {
          self.$bvToast.toast('Changes saved.', {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 4000,
            solid: true,
          })
          self.editedSubset.id = undefined
          self.editedSubset.name = undefined
          self.$emit('subset-updated')
        })
        .catch(() => {
          self.$bvToast.toast('Could not save changes.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
        })
    },
    onClickDeleteSubset(subset) {
      this.$bvModal
        .msgBoxConfirm(`Do you want to delete the subset "${subset.name}"?`, {
          title: 'Delete Subset',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'delete',
          cancelTitle: 'cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            // confirmation
            this.$axios({
              method: 'delete',
              url: `/api/data-subsets/${subset.id}`,
              withCredentials: true,
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': Cookie.get('csrftoken'),
              },
            })
              .then(() => {
                this.$emit('subset-updated')
              })
              .catch(() => {
                this.$bvToast.toast('Could not delete subset.', {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 4000,
                  solid: true,
                })
              })
          } else {
            // cancel
          }
        })
        .catch(() => {
          // An error occurred
        })
    },
    onClickPrevious() {
      this.tabIndex--
    },
    onClickNext() {
      this.tabIndex++
    },
    onClickSave() {
      if (!this.validate()) return

      this.loading = true

      const classificationClassLabelIds = []
      const detectionClassLabelIds = []

      for (const classLabel of this.selectedClassificationClassLabels) {
        classificationClassLabelIds.push(classLabel.id)
      }

      for (const classLabel of this.selectedDetectionClassLabels) {
        detectionClassLabelIds.push(classLabel.id)
      }

      const dataset = {
        name: this.datasetName,
        classification: this.categoriesSelected,
        object_detection: this.boundingBoxesSelected || this.rotatedBoundingBoxesSelected,
        object_detection_orientation: this.rotatedBoundingBoxesSelected,
        classification_class_label_ids: classificationClassLabelIds,
        object_detection_class_label_ids: detectionClassLabelIds,
        retention: null,
      }

      if (!this.retentionDisabled) {
        dataset.retention = {
          retention_period: this.retentionPeriod * 3600,
          status: this.retentionStatus,
        }
      }

      if (this.dataset) {
        dataset.id = this.dataset.id

        // update existing dataset
        this.updateDataset({ dataset })
          .then((updatedDataset) => {
            this.showModal = false
            this.$bvToast.toast('Dataset updated.', {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 4000,
              solid: true,
            })
            this.$emit('dataset-updated', updatedDataset)
          })
          .catch((error) => {
            let errorMessage = 'Unexpected error during dataset update.'
            if (error instanceof StoreValidationError) {
              errorMessage = error.message
            } else {
              console.error(error)
            }
            this.$bvToast.toast(errorMessage, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 4000,
              solid: true,
            })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        // create a new dataset
        this.addDataset({ dataset })
          .then((newDataset) => {
            this.showModal = false
            this.$bvToast.toast('New dataset created.', {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 4000,
              solid: true,
            })
            this.$emit('dataset-created', newDataset)
          })
          .catch((error) => {
            let errorMessage = 'Unexpected error during dataset creation.'
            if (error instanceof StoreValidationError) {
              errorMessage = error.message
            } else if (error.response && error.response.data && error.response.data.error) {
              errorMessage = error.response.data.error
            } else {
              console.error(error)
            }
            this.$bvToast.toast(errorMessage, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 10000,
              solid: true,
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    onDeleteDataset(evt) {
      evt.preventDefault()
      if (this.deleteDatasetName === this.dataset.name) {
        this.deleteDataset({ datasetId: this.dataset.id })
          .then(() => {
            this.deleteDatasetName = ''
            this.$refs.modalDeleteDataset.hide()
            this.showModal = false
            this.$emit('dataset-deleted', this.dataset.id)
          })
          .catch((error) => {
            console.error(error)
            this.$bvToast.toast('Could not delete dataset.', {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 4000,
              solid: true,
            })
          })
      } else {
        this.$bvToast.toast(
          'Please enter the correct name of the dataset that should be removed.',
          {
            title: 'Could not delete dataset.',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          }
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';
</style>
