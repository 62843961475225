<template>
  <div>
    <template v-if="server.cloud && !hoverable">
      <div class="mb-3">
        Available licenses:
        {{ availableLicenses.quantity - availableLicenses.inUse }} /
        {{ availableLicenses.quantity }}
      </div>
    </template>
    <template v-else>
      <table class="table table-sm">
        <thead>
          <tr>
            <th v-if="!server.cloud" scope="col">Remaining Time</th>
            <th v-if="!server.cloud" scope="col">Expiration Date</th>
            <th scope="col">Available Licenses</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(entry, i) in tableEntries.selectable"
            :key="`license-table-row-selectable-${i}`"
            :class="{
              'row-selected': entry[1].availableLicenses.includes(selectedLicenseId),
              'row-hoverable': hoverable,
            }"
            @click="select(entry[1])"
          >
            <td v-if="!server.cloud">
              {{ entry[1].expirationDate | formatDateDurationFuture }}
            </td>
            <td v-if="!server.cloud">
              {{ entry[1].expirationDate | formatDateOnly }}
            </td>
            <td>{{ entry[1].quantity - entry[1].inUse }} / {{ entry[1].quantity }}</td>
          </tr>
          <tr
            v-for="(entry, i) in tableEntries.unselectable"
            class="text-secondary"
            :key="`license-table-row-unselectable-${i}`"
          >
            <td v-if="!server.cloud">
              {{ entry[1].expirationDate | formatDateDurationFuture }}
            </td>
            <td v-if="!server.cloud">
              {{ entry[1].expirationDate | formatDateOnly }}
            </td>
            <td>{{ entry[1].quantity - entry[1].inUse }} / {{ entry[1].quantity }}</td>
          </tr>
          <tr v-if="tableEntries.selectable.length === 0 && tableEntries.unselectable.length === 0">
            <td colspan="3" class="text-center text-secondary">- no licenses -</td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  name: 'CompactLicenseTable',
  components: {},
  props: {
    licenses: Array,
    licenseType: String,
    selectedLicenseId: Number,
    hoverable: Boolean,
  },

  data() {
    return {}
  },

  computed: {
    ...mapState(['server']),

    tableEntries() {
      let entries = {}
      for (let i in this.licenses) {
        const license = this.licenses[i]
        if (license.type === this.licenseType) {
          // if()
          const date = moment(String(license.valid_until)).format('YYYY-MM-DD')
          if (!Object.prototype.hasOwnProperty.call(entries, date)) {
            entries[date] = {
              type: license.type,
              expirationDate: license.valid_until,
              quantity: 0,
              inUse: 0,
              availableLicenses: [],
            }
          }
          entries[date].quantity += 1
          if (license.user !== null) {
            entries[date].inUse += 1
          } else {
            entries[date].availableLicenses.push(license.id)
          }
        }
      }

      let selectableEntries = []
      let unselectableEntries = []
      const now = moment()
      for (let key in entries) {
        let entry = entries[key]
        if (entry.quantity - entry.inUse > 0 && moment(entry.expirationDate) > now) {
          selectableEntries.push([key, entry])
        } else {
          unselectableEntries.push([key, entry])
        }
      }

      selectableEntries = this.$_(selectableEntries).orderBy(0, 'desc').value()
      unselectableEntries = this.$_(unselectableEntries).orderBy(0, 'desc').value()

      return {
        selectable: selectableEntries,
        unselectable: unselectableEntries,
      }
    },

    availableLicenses() {
      let result = {
        quantity: 0,
        inUse: 0,
      }
      for (let entry of this.tableEntries.selectable) {
        result.quantity += entry[1].quantity
        result.inUse += entry[1].inUse
      }
      for (let entry of this.tableEntries.unselectable) {
        result.quantity += entry[1].quantity
        result.inUse += entry[1].inUse
      }
      return result
    },
  },

  created() {},

  mounted() {},

  methods: {
    getUser(license) {
      if (license.username) {
        return license.username
      } else {
        return '-'
      }
    },
    select(entry) {
      this.$emit('select-license', entry.availableLicenses[0])
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';

.row-hoverable {
}

.row-hoverable:hover {
  cursor: pointer;
  background: $light;
  color: #000000;
}

.row-selected {
  color: $light;
  background: $primary;
}

.row-selected:hover {
  color: $light;
  background: $primary;
}
</style>
