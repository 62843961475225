import Vue from 'vue'

// bootstrap
import { BIcon, BIconExclamationCircleFill, BootstrapVue } from 'bootstrap-vue'
import './custom.scss'

// cookies
import 'js-cookie/src/js.cookie'

// axios
// import 'axios/dist/axios';
import axios from 'axios'

// lodash
import _ from 'lodash'

import Vuelidate from 'vuelidate'
// fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAdjust,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBars,
  faBrain,
  faBriefcase,
  faBuilding,
  faBullseye,
  faBusinessTime,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCertificate,
  faChartBar,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckCircle,
  faClipboardCheck,
  faClipboardList,
  faClock,
  faClone,
  faCloudUploadAlt,
  faCog,
  faCogs,
  faCompass,
  faCopy,
  faCreditCard,
  faCropAlt,
  faCrosshairs,
  faDatabase,
  faDotCircle,
  faDownload,
  faEdit,
  faEllipsisV,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileContract,
  faFileImport,
  faFileInvoice,
  faFilter,
  faGlobe,
  faHistory,
  faHourglass,
  faHourglassHalf,
  faImages,
  faInfoCircle,
  faList,
  faLock,
  faLockOpen,
  faMinus,
  faMinusCircle,
  faObjectGroup,
  faObjectUngroup,
  faPaste,
  faPlayCircle,
  faPlus,
  faSave,
  faShareAlt,
  faSlidersH,
  faSortDown,
  faSortUp,
  faSpinner,
  faSquare,
  faSync,
  faTachometerAlt,
  faTags,
  faTh,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUpload,
  faUser,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons'

// import {
//   faObjectUngroup,
// } from '@fortawesome/free-regular-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome'

// moment
import moment from 'moment'

import { format, formatDistance, formatDistanceStrict, parseISO } from 'date-fns'

// apex charts
import VueApexCharts from 'vue-apexcharts'

// vue-scroll-to
import VueScrollTo from 'vue-scrollto'
// vue
import App from './App.vue'
import router from './router'
import store from './store'

Object.defineProperty(Vue.prototype, '$_', { value: _ })

Vue.use(Vuelidate)

let baseURL = process.env.VUE_APP_API_URL
if (process.env.NODE_ENV === 'development') {
  baseURL = 'http://localhost:8000'
}

Vue.prototype.$env = process.env.NODE_ENV
Vue.prototype.$analyticsEnabled = false
Vue.prototype.$statusPageEnabled = false

if (baseURL === 'https://api.vision.data-spree.com') {
  Vue.prototype.$analyticsEnabled = true
  Vue.prototype.$statusPageEnabled = true
}

let ax = axios.create({
  baseURL: baseURL,
  timeout: 30000,
  crossDomain: true,
  withCredentials: true,
})

Vue.prototype.$axios = ax

Vue.component('font-awesome-icon', FontAwesomeIcon)

library.add(
  faAdjust,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBars,
  faBrain,
  faBriefcase,
  faBuilding,
  faBullseye,
  faBusinessTime,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCertificate,
  faChartBar,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckCircle,
  faClipboardCheck,
  faClipboardList,
  faClock,
  faClone,
  faCloudUploadAlt,
  faCog,
  faCogs,
  faCompass,
  faCopy,
  faCreditCard,
  faCropAlt,
  faCrosshairs,
  faDatabase,
  faDotCircle,
  faDownload,
  faEdit,
  faEllipsisV,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileContract,
  faFileImport,
  faFileInvoice,
  faFilter,
  faGlobe,
  faHourglass,
  faHourglassHalf,
  faHistory,
  faImages,
  faInfoCircle,
  faList,
  faLock,
  faLockOpen,
  faMinus,
  faMinusCircle,
  faObjectGroup,
  faObjectUngroup,
  faPaste,
  faPlayCircle,
  faPlus,
  faSave,
  faShareAlt,
  faSlidersH,
  faSortDown,
  faSortUp,
  faSpinner,
  faSquare,
  faSync,
  faTachometerAlt,
  faTags,
  faTh,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUpload,
  faUser,
  faUserFriends
)

// eslint-disable-next-line vue/multi-word-component-names
Vue.component('fai', FontAwesomeIcon)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('fal', FontAwesomeLayers)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('fat', FontAwesomeLayersText)

Vue.config.productionTip = false

Vue.use(BootstrapVue, {
  BTooltip: {
    delay: { show: 500, hide: 50 },
  },
})
Vue.component('BIcon', BIcon)
Vue.component('BIconExclamationCircleFill', BIconExclamationCircleFill)
Vue.use(VueApexCharts)

// eslint-disable-next-line vue/multi-word-component-names
Vue.component('apexchart', VueApexCharts)

Vue.use(VueScrollTo)

Vue.use((Vue) => {
  // source: https://stackoverflow.com/questions/34950867/vue-js-how-to-set-a-unique-id-for-each-component-instance
  // Assign a unique id to each component
  let uuid = 0
  Vue.mixin({
    beforeCreate: function () {
      this.uuid = uuid.toString()
      uuid += 1
    },
  })

  // Generate a component-scoped id
  Vue.prototype.$id = function (id) {
    return 'uid-' + this.uuid + '-' + id
  }
})

Vue.filter('formatTimestamp', function (value) {
  if (value) {
    if (typeof value === 'string') {
      value = new Date(value)
    }
    return format(value, 'yyyy-MM-dd HH:mm:ss.SSS')
  }
})

Vue.filter('formatPrice', function (value) {
  // prices are provided in cents
  let p = value / 100
  return p.toLocaleString('en-IN', { style: 'currency', currency: 'EUR' })
})

Vue.filter('formatTimestampAsDate', function (value) {
  if (value) {
    if (typeof value === 'string') {
      value = new Date(value)
    }
    return format(value, 'yyyy-MM-dd')
  }
})

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('lll')
  }
})

Vue.filter('formatDateOnly', function (value) {
  if (value) {
    return moment(String(value)).format('ll')
  }
})

Vue.filter('formatDateDuration', function (value) {
  if (value) {
    return formatDistance(parseISO(value), new Date(), { addSuffix: true })
  }
})

Vue.filter('formatDistanceStrict', function (value) {
  if (value) {
    const start = parseISO(value[0])
    const end = parseISO(value[1])
    return formatDistanceStrict(start, end)
  }
})

Vue.filter('formatDateDurationFuture', function (value) {
  if (value) {
    let timeDiff = moment(String(value)) - moment()
    if (timeDiff > 0) {
      return moment.duration(timeDiff).humanize()
    } else {
      return '-'
    }
  }
})

Vue.filter('capitalize', function (value) {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
})

Vue.filter('formatFileName', function (value) {
  let maxLength = 15
  if (value) {
    if (value.length >= maxLength) {
      return value.slice(0, maxLength) + '...'
    } else {
      return value
    }
  }
})

Vue.filter('getFileName', function (value) {
  let split = value.split('/')
  return split[split.length - 1]
})

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')
