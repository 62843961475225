<template>
  <div>
    <b-badge v-if="status === 'enqueued'" class="ml-1" variant="info"> enqueued</b-badge>
    <b-badge v-else-if="status === 'provisioning'" class="ml-1" variant="info">
      provisioning
    </b-badge>
    <b-badge v-else-if="status === 'pending'" class="ml-1" variant="info"> pending</b-badge>
    <b-badge v-else-if="status === 'running'" class="ml-1" variant="info"> running</b-badge>
    <b-badge v-else-if="status === 'stalling'" class="ml-1" variant="warning"> stalling</b-badge>
    <b-badge v-else-if="status === 'stopped'" class="ml-1" variant="danger"> stopped</b-badge>
    <b-badge v-else-if="status === 'exception'" class="ml-1" variant="danger"> exception</b-badge>
    <b-badge v-else class="ml-1">
      {{ status }}
    </b-badge>
  </div>
</template>

<script>
export default {
  name: 'JobStatusBadge',
  props: ['status'],
}
</script>

<style scoped lang="scss"></style>
