<template>
  <div
    ref="item-viewer-container"
    class="item-viewer-container"
    @mousedown="onMouseDown"
    @mousemove="onMouseMove"
  >
    <div class="selection-rectangle-wrapper">
      <div id="selection-rectangle" ref="selection-rectangle" />
    </div>
    <!--    <div v-if="loading"-->
    <!--         class="d-flex align-items-center justify-content-center loading-spinner-item">-->
    <!--      <b-spinner variant="primary" label="items loading"></b-spinner>-->
    <!--    </div>-->
    <div
      id="item-background"
      :class="containerClass"
      :style="{ padding: `${outerPadding}rem` }"
      class="d-flex flex-wrap justify-content-start align-items-start"
    >
      <item-tile
        v-for="item in datasetItems"
        :id="`item-${item.id}`"
        :key="`item-${item.id}`"
        :ref="`item-${item.id}`"
        :box-line-width="boxLineWidth"
        :data-subsets="dataSubsets"
        :draw-ground-truth-dashed="drawGroundTruthDashed"
        :highlighted="itemsInSelectionRectangle.includes(item.id) && selectionRectangle.active"
        :item="item"
        :item-size="itemSize"
        :object-selectivity="objectSelectivity"
        :padding="padding"
        :padding-factor="paddingFactor"
        :predicted-object-class-ids="predictedObjectClassIds"
        :show-confidence="showConfidence"
        :show-confidence-histogram="showConfidenceHistogram"
        :show-filename="showFilename"
        :show-objects="showObjects"
        :show-predicted-labels="showPredictedLabels"
        :show-predicted-objects="showPredictedObjects"
        :show-labels-in-overlay="showLabelsInOverlay"
        :show-status="showStatus"
        :show-subsets="showSubsets"
        :show-timestamp="showTimestamp"
        :show-true-labels="showTrueLabels"
        :timezone="timezone"
        @click="onClickItem(item, $event)"
        @dblclick.native="onDoubleClickItem(item, $event)"
      />
      <!--      <div v-if="loading" :style="{ width: `${(itemSize*100).toFixed(4)}%` }">-->
      <!--        <loading-spinner></loading-spinner>-->
      <!--      </div>-->

      <!--      <div v-for="item in datasetItems" class="item" :id="`item-${item.id}`" :ref="`item-${item.id}`"-->
      <!--           :style="{ width: `${(itemSize*100).toFixed(4)}%`, padding: `${(itemSize*0.5*paddingFactor).toFixed(4)}em` }">-->
      <!--        <div @click="onClickItem(item, $event)" @click.middle="onClickMiddleItem(item, $event)" class="hover-area"-->
      <!--             :class="{'selected': item.selected, 'highlighted': (itemsInSelectionRectangle.includes(item.id) && selectionRectangle.active)}"-->
      <!--             :style="{padding: `${(padding+itemSize*0.5).toFixed(4)}em` }">-->
      <!--          <div style="display:none;">&ndash;&gt;-->
      <!--            &lt;!&ndash;            <img v-if="item.thumbnail" :ref="`image-${item.id}`" :src="item.thumbnail"&ndash;&gt;-->
      <!--            <img :ref="`image-${item.id}`" :src="item.thumbnail"-->
      <!--                 @load="drawThumbnail(item)" @error="drawPlaceholder(item)">-->
      <!--            &lt;!&ndash;            <img v-else src="@/assets/dataset-explorer/image-placeholder.png" :ref="'image'+item.id">&ndash;&gt;-->
      <!--          </div>-->
      <!--          <div class="timestamp-wrapper"><span v-if="showTimestamp" class="timestamp text-monospace">{{-->
      <!--              formatCreatedDate(item.created_date)-->
      <!--            }}</span></div>-->
      <!--          <canvas width="960" height="360" :ref="`canvas-${item.id}`" class="item-canvas w-100"></canvas>-->
      <!--          <div class="d-flex">-->
      <!--            <template v-if="showStatus">-->
      <!--              <span v-if="item.status === 'uploaded'" class="text-info"><fai class="status-icon mb-1"-->
      <!--                                                                             icon="file-import"/></span>-->
      <!--              <span v-if="item.status === 'annotated'" class="text-info"><fai class="status-icon mb-1"-->
      <!--                                                                              icon="object-group"/></span>-->
      <!--              <span v-if="item.status === 'reviewed'" class="text-info"><fai class="status-icon mb-1"-->
      <!--                                                                             icon="check"/></span>-->
      <!--              <span v-if="item.status === 'ignored'" class="text-info"><fai class="status-icon mb-1"-->
      <!--                                                                            icon="ban"/></span>-->
      <!--            </template>-->
      <!--            <span v-if="showFilename" class="filename ml-2">-->
      <!--              {{ getItemName(item) }}-->
      <!--            </span>-->
      <!--          </div>-->
      <!--          <div class="d-flex flex-wrap">-->
      <!--            <template v-if="showSubsets && item.data_subsets">-->
      <!--              <subset-badge v-for="subsetId in item.data_subsets"-->
      <!--                            :key="`subset-badge-${subsetId}`"-->
      <!--                            :data-subsets="dataSubsets" :subset-id="subsetId"></subset-badge>-->

      <!--            </template>-->
      <!--            <template v-if="showImageLabels && item.annotations && item.annotations.hasOwnProperty('classes')">-->
      <!--          <span v-for="classId in item.annotations.classes"-->
      <!--                class="item-badge-x-small image-class-badge mt-1 mr-1"-->
      <!--                :style="getClassStyle(classId)">-->
      <!--            {{ classLabelMap[classId] !== undefined ? classLabelMap[classId] : '?' }}-->
      <!--          </span>-->
      <!--            </template>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center loading-spinner-item"
    >
      <b-spinner label="items loading" variant="primary" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import {
  classColors,
  getClassStyle,
  getColorForCSS,
  getColorRGB,
  getTextColorForCSS,
} from '@/colors'
import { formatTimestamp } from '@/datetime'
import ItemTile from '@/components/item-viewer/ItemTile'

export default {
  name: 'ItemViewer',
  components: { ItemTile },
  props: {
    datasetItems: {
      type: Array,
      default: () => [],
    },
    dataSubsets: {
      type: Object,
      default: () => {},
    },
    containerClass: {
      type: String,
      default: '',
    },
    timezone: {
      type: String,
      default: 'UTC',
    },
    // selectedModelIds: Array,
    itemSize: {
      type: Number,
      default: 0.25,
    },
    selectionRectangleEnabled: {
      type: Boolean,
      default: true,
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
    showFilename: {
      type: Boolean,
      default: true,
    },
    showTimestamp: {
      type: Boolean,
      default: true,
    },
    showLabelsInOverlay: {
      type: Boolean,
      default: false,
    },
    showTrueLabels: {
      type: Boolean,
      default: true,
    },
    showPredictedLabels: {
      type: Boolean,
      default: true,
    },
    showSubsets: {
      type: Boolean,
      default: true,
    },
    showObjects: {
      type: Boolean,
      default: true,
    },
    showPredictedObjects: {
      type: Boolean,
      default: true,
    },
    predictedObjectClassIds: {
      type: Array,
      default: () => [],
    },
    objectSelectivity: {
      type: Number,
      default: 0.3,
    },
    showConfidence: {
      type: Boolean,
      default: true,
    },
    showConfidenceHistogram: {
      type: Boolean,
      default: false,
    },
    drawGroundTruthDashed: {
      type: Boolean,
      default: false,
    },
    boxLineWidth: {
      type: Number,
      default: 2,
    },
    padding: {
      type: Number,
      default: 0.375,
    },
    paddingFactor: {
      type: Number,
      default: 1.0,
    },
    outerPadding: {
      type: Number,
      default: 0.0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectionRectangle: {
        x0: 0,
        y0: 0,
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        active: false,
      },
      minSelectionRectangleSize: 25,
      itemsInSelectionRectangle: [],
      classColors: classColors,
    }
  },

  computed: {
    ...mapState({
      classLabelMap: (state) => state.classLabelMap,
    }),
  },

  watch: {
    selectionRectangleEnabled: function (newValue) {
      if (!newValue) {
        this.hideSelectionRectangle()
      }
    },
    showObjects: function () {},
    datasetItems: function (newItems, oldItems) {
      if (newItems === undefined) {
        newItems = []
      }

      if (oldItems === undefined) {
        oldItems = []
      }

      const firstOldItem = oldItems.length > 0 ? oldItems[0] : undefined
      const lastOldItem = oldItems.length > 1 ? oldItems[oldItems.length - 1] : undefined
      if (!firstOldItem && !lastOldItem) return

      const firstItemId = firstOldItem ? firstOldItem.id : undefined
      const lastItemId = lastOldItem ? lastOldItem.id : undefined

      const firstOldItemElement = firstOldItem ? this.$refs[`item-${firstItemId}`] : undefined
      const lastOldItemElement = lastOldItem ? this.$refs[`item-${lastItemId}`] : undefined

      let firstOldElementTop, lastOldElementTop
      if (firstOldItemElement && firstOldItemElement[0]) {
        firstOldElementTop = firstOldItemElement[0].$refs.item.offsetTop
      }
      if (lastOldItemElement && lastOldItemElement[0]) {
        lastOldElementTop = lastOldItemElement[0].$refs.item.offsetTop
      }

      this.$nextTick(() => {
        const firstNewItemElement = this.$refs[`item-${firstItemId}`]
        const lastNewItemElement = this.$refs[`item-${lastItemId}`]

        let scrollDifference
        if (firstOldElementTop !== undefined && firstNewItemElement && firstNewItemElement[0]) {
          scrollDifference = firstNewItemElement[0].$refs.item.offsetTop - firstOldElementTop
        } else if (lastOldElementTop !== undefined && lastNewItemElement && lastNewItemElement[0]) {
          scrollDifference = lastNewItemElement[0].$refs.item.offsetTop - lastOldElementTop
        }
        if (scrollDifference) {
          // scroll to the first visible element
          // element.$refs.item.scrollIntoView()
          this.$emit('scroll-adjustment-required', { scrollDifference })
        }
      })
    },
  },

  created() {
    window.addEventListener('mouseup', this.onMouseUp)
    // this.$store.dispatch('getClassLabels')
  },

  mounted() {
    // this.$refs['item-viewer-container'].addEventListener('scroll', this.onScrollItemViewer);
  },

  beforeDestroy() {
    // this.$refs['item-viewer-container'].removeEventListener('scroll', this.onScrollItemViewer);
    window.removeEventListener('mouseup', this.onMouseUp, false)
  },

  methods: {
    scrollToItem(itemId, scrollTop, wrapperHeight) {
      let element = this.$refs[`item-${itemId}`]
      if (element) {
        element = element[0]
        let firstItemId = this.datasetItems.length > 0 ? this.datasetItems[0].id : 0
        let firstElement = this.$refs[`item-${firstItemId}`]
        let scrollTo = 'start'
        if (firstElement && firstElement[0]) {
          firstElement = firstElement[0]
          const elementTop = element.$refs.item.offsetTop - firstElement.$refs.item.offsetTop
          const elementBottom =
            element.$refs.item.offsetTop -
            firstElement.$refs.item.offsetTop +
            element.$refs.item.offsetHeight
          if (scrollTop && elementTop >= scrollTop && elementBottom < scrollTop + wrapperHeight) {
            // do not scroll to the item in case it is in the visible area
            return
          }
          if (elementTop > scrollTop + 0.5 * wrapperHeight) {
            scrollTo = 'end'
          }
        }

        element.$refs.item.scrollIntoView({
          behavior: 'smooth',
          block: scrollTo,
        })

        // if (isFirefox()) {
        //   element.$refs.item.scrollIntoView({
        //     behavior: 'smooth',
        //     block: scrollTo
        //   })
        // } else {
        //   element.$refs.item.scrollIntoView()
        // }
      }
    },
    formatCreatedDate(timestamp) {
      return formatTimestamp(timestamp, this.timezone)
    },
    getSubsetColorForCSS(idx) {
      let color = getColorRGB(idx)
      return `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.25)`
    },

    getClassStyle(idx) {
      return getClassStyle(idx, { alpha: 0.35 })
    },

    getColorForCSS(idx) {
      return getColorForCSS(idx)
    },
    getTextColorForCSS(idx) {
      return getTextColorForCSS(idx)
    },
    onClickItem(item, evt) {
      if (
        !this.selectionRectangleEnabled ||
        this.selectionRectangle.width * this.selectionRectangle.height <
          this.minSelectionRectangleSize
      ) {
        this.$emit('click', { item: item, evt: evt })
      }
    },
    onDoubleClickItem(item, evt) {
      this.$emit('double-click', { item: item, evt: evt })
    },
    onClickMiddleItem(item, evt) {
      this.$emit('click-middle', { item: item, evt: evt })
    },
    drawSelectionRectangle() {
      if (this.selectionRectangle.active) {
        const rect = this.$refs['selection-rectangle']
        if (
          this.selectionRectangle.width * this.selectionRectangle.height >=
          this.minSelectionRectangleSize
        ) {
          // applying the styles directly results in a much smoother experience than using the
          // vue binding v-bind:style=...
          rect.style.opacity = 1.0
          rect.style.top = `${this.selectionRectangle.y}px`
          rect.style.left = `${this.selectionRectangle.x}px`
          rect.style.width = `${this.selectionRectangle.width}px`
          rect.style.height = `${this.selectionRectangle.height}px`
        } else {
          rect.style.opacity = 0.0
        }
      }
    },
    hideSelectionRectangle() {
      const rect = this.$refs['selection-rectangle']
      rect.style.opacity = 0.0
      this.selectionRectangle.active = false
      this.itemsInSelectionRectangle = []
    },
    onMouseDown(evt) {
      if (!this.selectionRectangleEnabled) return
      this.selectionRectangle.active = true
      const container = this.$refs['item-viewer-container']
      const containerRect = container.getBoundingClientRect()
      this.selectionRectangle.x0 = evt.clientX - containerRect.left
      this.selectionRectangle.y0 = evt.clientY - containerRect.top
      this.selectionRectangle.width = 0
      this.selectionRectangle.height = 0
      this.drawSelectionRectangle()
      this.itemsInSelectionRectangle = []
    },
    onMouseMove(evt) {
      if (this.selectionRectangle.active && this.selectionRectangleEnabled) {
        const container = this.$refs['item-viewer-container']
        const containerRect = container.getBoundingClientRect()
        let x = evt.clientX - containerRect.left
        let y = evt.clientY - containerRect.top

        this.selectionRectangle.x = Math.min(x, this.selectionRectangle.x0)
        this.selectionRectangle.y = Math.min(y, this.selectionRectangle.y0)
        this.selectionRectangle.width = Math.abs(this.selectionRectangle.x0 - x)
        this.selectionRectangle.height = Math.abs(this.selectionRectangle.y0 - y)
        this.drawSelectionRectangle()

        let itemsInSelectionRectangle = []
        const items = container.querySelectorAll('.item')
        if (
          this.selectionRectangle.width * this.selectionRectangle.height >=
          this.minSelectionRectangleSize
        ) {
          for (const item of items) {
            const box = item.getBoundingClientRect()
            if (
              this.selectionRectangle.x <= box.right - containerRect.left &&
              this.selectionRectangle.y <= box.bottom - containerRect.top &&
              this.selectionRectangle.x + this.selectionRectangle.width >=
                box.left - containerRect.left &&
              this.selectionRectangle.y + this.selectionRectangle.height >=
                box.top - containerRect.top
            ) {
              const itemId = parseInt(item.id.replace('item-', ''))
              itemsInSelectionRectangle.push(itemId)
            }
          }
        }
        this.itemsInSelectionRectangle = itemsInSelectionRectangle
      }
    },
    onMouseUp(evt) {
      if (this.selectionRectangleEnabled) {
        if (
          this.selectionRectangle.active &&
          this.selectionRectangle.width * this.selectionRectangle.height >=
            this.minSelectionRectangleSize
        ) {
          this.$emit('multi-selection', {
            itemIds: _.cloneDeep(this.itemsInSelectionRectangle),
            evt: evt,
          })
        } else {
          if (
            evt.target &&
            (evt.target.id === 'item-background' || evt.target.id.startsWith('item-'))
          ) {
            this.$emit('click-background', evt)
          }
        }
        this.hideSelectionRectangle()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';

.selection-rectangle-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

#selection-rectangle {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(200, 200, 200, 0.5);
  border: 1px solid #cccccc;
  opacity: 0;
  pointer-events: none;
}

.item-viewer-container {
  flex: 1 1 auto;
  position: relative;
  //position: relative;
  //min-height: 300px;
  //height: 100%;
  min-height: 100%;
}

.item {
  //padding: 0.25em;
  max-width: 100%;

  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  img {
    pointer-events: none;
  }

  .item-canvas {
    margin: 0;
  }

  .timestamp-wrapper {
    margin: 0;
    padding-left: 0.3em;
    padding-right: 0.3em;
    width: 100%;
    background-color: $gray-200;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .timestamp {
    display: inline-block;
    font-size: smaller;
    font-weight: 500;
    max-width: 100%;
    color: $gray-800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -0.25em;
  }

  .filename {
    display: inline-block;
    font-size: smaller;
    font-weight: 500;
    max-width: 100%;
    color: $gray-800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .status-icon {
    font-size: smaller;
  }

  .selected {
    .filename {
      color: $white;
    }

    .subset-badge {
      color: $white;
    }

    background-color: $primary;
  }

  .selected:hover {
    background-color: $blue-variation !important;
  }

  .highlighted {
    background-color: $blue-variation !important;
  }

  .hover-area {
    //padding: 1em;
    //border: 1px solid $gray-300;
    border-radius: 6px;
  }

  .hover-area:hover {
    background-color: $gray-300;
    cursor: pointer;
  }
}

.loading-spinner-item {
  width: 100%;
  height: 100px;
}
</style>
