import { differenceInSeconds, parseISO } from 'date-fns'

export function binarySearch(arr, val, arrToVal) {
  let start = 0
  let end = arr.length - 1

  while (start <= end) {
    let mid = Math.floor((start + end) / 2)

    let arrVal = arrToVal(arr[mid])

    if (arrVal === val) {
      return mid
    }

    if (val < arrVal) {
      end = mid - 1
    } else {
      start = mid + 1
    }
  }
  return -1
}

export function isFirefox() {
  return navigator.userAgent.indexOf('Firefox') > -1
}

export function subsetNameHash(name) {
  let hash = 0

  if (name === 'Test') return 1
  if (name === 'Train') return 4

  for (let i = 0; i < name.length; i++) {
    const c = name.charCodeAt(i)
    hash += c
  }
  return hash
}

export function argMax(arr) {
  if (arr.length === 0) {
    return -1
  }

  let max = arr[0]
  let maxIndex = 0

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      maxIndex = i
      max = arr[i]
    }
  }

  return maxIndex
}

export function getModelJobs(model, jobs) {
  if (model && jobs) {
    return jobs.filter((job) => job.model.id === model.id)
  }
  return []
}

export function getTrainingState(model, jobs) {
  const modelJobs = getModelJobs(model, jobs)
  if (modelJobs.length > 0) {
    const job = modelJobs[0]
    if (['enqueued', 'provisioning', 'pending'].includes(job.status)) {
      return TrainingState.enqueued
    } else if (job.status === 'running') {
      if (job.updated_date && differenceInSeconds(new Date(), parseISO(job.updated_date)) > 120) {
        return TrainingState.stalling
      } else {
        return TrainingState.running
      }
    } else if (['stopped', 'exception'].includes(job.status)) {
      return TrainingState.error
    } else {
      return TrainingState.requested
    }
  } else if (model.inference_data) {
    return TrainingState.finished
  }

  return TrainingState.notTrained
}

export const TrainingState = {
  notTrained: 'not trained',
  requested: 'requested',
  enqueued: 'enqueued',
  running: 'running',
  stalling: 'stalling',
  finished: 'finished',
  error: 'error',
}

export function defaultTooltipFormatter(value) {
  if (value === undefined) {
    return value
  } else if (value === 0.0) {
    return '0.0'
  } else if (value < 0.0001) {
    return value.toExponential(3)
  } else {
    return value.toFixed(4)
  }
}
