<template>
  <div>
    <bar-chart-entry
      v-for="(stat, i) in stats"
      :key="`stat-${i}`"
      :element-map="elementMap"
      :stat="stat"
      :color-from-name="colorFromName"
      :border="border"
      :not-found-name="notFoundName"
    />
  </div>
</template>

<script>
import BarChartEntry from '@/components/statistics/BarChartEntry'

export default {
  name: 'BarChart',
  components: { BarChartEntry },
  props: {
    stats: {
      type: Array,
    },
    elementMap: {
      type: Object,
    },
    colorFromName: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    notFoundName: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="scss"></style>
