export function isMac() {
  return navigator.appVersion.indexOf('Mac') >= 0
}

export function ctrlOrCmdPressed(evt) {
  if (isMac(evt)) {
    return evt.metaKey
  } else {
    return evt.ctrlKey
  }
}

export function shiftPressed(evt) {
  return evt.shiftKey
}
