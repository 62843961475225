<template>
  <b-modal
    id="modal-add-worker"
    ref="modalAddWorker"
    size="lg"
    ok-variant="secondary"
    ok-only
    ok-title="close"
  >
    <template #modal-title>
      Add Worker
      <b-badge variant="info" class="ml-2" v-if="server.cloud">BETA</b-badge>
    </template>
    <b-alert variant="info" show v-if="server.cloud">
      <fai icon="info-circle" class="mb-1 mr-1" />
      On-premises training workers are in beta. We recommend using our cloud training that is
      available as part of the paid plans.
    </b-alert>
    <p>
      For training deep neural networks, you need an instance of a Worker. Workers download AI model
      parameters, the necessary datasets and perform the training. You can install a worker on your
      own hardware.
    </p>
    <h5 class="font-weight-700">Installation</h5>
    <p>
      The Data Spree Training Worker is available for Python and can be installed via the pip
      package manager. Training is significantly accelerated by the use of graphics cards (Nvidia
      only).
      <!--      Visit <a href="https://developer.nvidia.com/cuda-zone">Nvidia CUDA</a> and follow the-->
      <!--      instructions to install Nvidia CUDA if you would like to use GPU-accelerated training.-->
    </p>
    <p>Install the training worker via the command line:</p>
    <div class="border-radius-md bg-gray-100 border-gray-200">
      <b-tabs
        pills
        nav-class="pl-1 pr-1 pt-1 bg-gray-200 font-weight-600 font-size-small"
        content-class="p-3"
      >
        <b-tab
          v-for="{ title, command } in workerVersions"
          :key="`worker-tab-${title}`"
          :title="title"
        >
          <b-card-text>
            <code class="text-dark">{{ command }}</code>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>
    <p class="font-size-small text-secondary p-2">
      Our training worker relies on the PyTorch framework. The binaries of PyTorch are not directly
      distributed via PyPI for all platforms but by PyTorch. Hence, the PyTorch repository is to be
      specified during installation based on your hardware.
    </p>
    <h5 class="font-weight-700">Start Training</h5>
    <p>Start the worker via the command:</p>
    <div class="p-3 mb-3 border-radius-md bg-gray-100">
      <code class="text-dark">ds-worker</code>
    </div>
    <p>Afterwards, you're prompted to set up the worker. Open a separate shell and call:</p>
    <div class="p-3 mb-3 border-radius-md bg-gray-100">
      <code class="text-dark">ds worker setup</code>
    </div>
    <p>
      Once that is finished, you can select your worker for training AI models in the AI Platform.
    </p>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AddWorkerModal',
  components: {},
  data() {
    return {
      centerLoading: false,
      workerVersions: [
        {
          title: 'CPU',
          command: 'pip install dlds-training[cpu] -f https://www.data-spree.com/python-packages',
        },
        {
          title: 'GPU - CUDA 11.6 (recommended)',
          command:
            'pip install dlds-training[cuda] -f https://www.data-spree.com/python-packages --extra-index-url https://download.pytorch.org/whl/cu116/',
        },
        {
          title: 'GPU - CUDA 10.2',
          command:
            'pip install dlds-training[cuda10.2] -f https://www.data-spree.com/python-packages --extra-index-url https://download.pytorch.org/whl/cu102/',
        },
      ],
    }
  },
  computed: {
    ...mapState(['server']),
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';

.selection-square {
  border-radius: 2px;
  display: inline-block;
  margin-right: 0.25rem;
  margin-bottom: -0.125rem;
  background-color: $primary;
  width: 6px;
  height: 1rem;
}

.selection-card {
  &:hover {
    background: $blue-light !important;
  }
}
</style>
