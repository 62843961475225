<template>
  <div class="p-2 mb-1">
    <b-form-checkbox
      :id="$id('groupSwitchableBoolInput') + field.name"
      v-model="active"
      disabled
      v-b-popover.hover.left="field.description"
      >{{ field.display_name }}</b-form-checkbox
    >
    <hr v-if="active" />
    <parameter-viewer
      :key="'group-switchable' + field.name"
      v-if="active"
      :fields="field.fields"
      v-bind:value="value"
    ></parameter-viewer>
  </div>
</template>

<script>
export default {
  props: ['value', 'field'],
  name: 'group-switchable',
  components: {
    ParameterViewer: () => import('../ParameterViewer'),
  },
  data() {
    return {
      active: false,
      data: {},
    }
  },

  created() {
    this.data = this.value
    this.active = this.value !== undefined
  },

  mounted() {},

  methods: {
    onChange() {
      if (this.active) {
        this.$emit('input', this.data)
      } else {
        this.$emit('input', {})
      }
    },

    onInput(event) {
      this.data = event
      if (this.active) {
        this.$emit('input', this.data)
      } else {
        this.$emit('input', {})
      }
    },
  },
}
</script>

<style scoped>
/*.group {*/
/*  border: #ced4da 1px solid;*/
/*  border-radius: 0.25rem;*/
/*}*/
</style>
