<template>
  <div>
    <div>
      <h5 class="p-3">
        <fai icon="bars" class="mr-2" />
        <b>Resource Usage</b>
      </h5>
    </div>
    <div>
      <loading-overlay v-if="loading" />
      <div class="font-weight-700 text-center mb-2">Storage Utilization</div>
      <basic-chart
        v-if="usageChart.xData.length > 0 && usageChart.yData.length > 0"
        :x-data="usageChart.xData"
        :y-data="usageChart.yData"
        :overwrite-options="usageChart.overwriteOptions"
        :height="200"
        chart-type="bar"
        class="w-100"
      />
      <div v-else class="p-5 text-center text-dark">no data</div>
    </div>
  </div>
</template>

<script>
import BasicChart from '@/components/charts/BasicChart'
import LoadingOverlay from '@/components/LoadingOverlay'
import { parseISO, format } from 'date-fns'

export default {
  name: 'UsageStatistics',
  components: { LoadingOverlay, BasicChart },
  props: {
    organization: Object,
  },

  data() {
    return {
      loading: false,
      errorMessage: '',
      usageEvents: [],
      usageChart: {
        xData: [],
        yData: [],
        overwriteOptions: {
          tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'cross' },
            valueFormatter: function (value) {
              return `${value} GB`
            },
          },
          yAxis: {
            type: 'value',
            name: 'storage [GB]',
            axisLabel: {
              formatter: '{value} GB',
            },
          },
        },
      },
    }
  },
  computed: {},

  created() {},

  mounted() {
    this.getUsageEvents().then()
  },

  methods: {
    async getUsageEvents() {
      this.loading = true
      this.errorMessage = ''
      try {
        const response = await this.$axios.get(`/api/usage/`)
        this.usageEvents = response.data

        this.usageChart.xData = []
        this.usageChart.yData = []
        for (const usageEvent of this.usageEvents) {
          const dateTime = parseISO(usageEvent.timestamp)
          this.usageChart.xData.push(format(dateTime, 'yyyy-MM-dd'))
          const storageGigabytes = (usageEvent.total_storage / (1024 * 1024 * 1024)).toFixed(2)
          this.usageChart.yData.push(storageGigabytes)
        }
      } catch (e) {
        this.usageEvents = []
        this.usageChart.xData = []
        this.usageChart.yData = []
        this.errorMessage = 'Could not load usage data.'
        console.error(e)
      }
      this.loading = false
    },
  },
}
</script>

<style scoped></style>
