<template>
  <b-form-group
    v-show="!field.hidden"
    v-bind="formGroupAttributes"
    :label="field.display_name"
    :description="field.description"
    :label-size="size"
  >
    <b-form-input
      :id="$id(field.name)"
      type="number"
      required
      :plaintext="plaintext || field.editable === false"
      :state="valid ? null : valid"
      step="1"
      :value="value"
      @input="onInput"
      :placeholder="field.display_name"
      :size="size"
    >
    </b-form-input>
  </b-form-group>
</template>

<script>
export default {
  name: 'IntegerInput',
  props: ['value', 'field', 'plaintext', 'size', 'formGroupAttributes'],
  data() {
    return {
      valid: null,
    }
  },

  mounted() {
    this.parseValue(this.value)
    if (this.valid === null || this.valid === false) {
      this.onInput(0, true)
    }
  },

  methods: {
    parseValue(v) {
      if (v === undefined || v === '') {
        v = undefined
        this.valid = null
      } else {
        const vFloat = parseFloat(v)
        v = parseInt(v)
        this.valid = !isNaN(v) && v === vFloat
      }
      return v
    },

    onInput(event, initial) {
      const v = this.parseValue(event)
      if (this.valid) {
        this.$emit('input', v, initial)
      }
    },
  },
}
</script>
