<template>
  <div class="d-flex h-100">
    <loading-overlay v-if="loading" />
    <div class="w-50 d-flex flex-column pr-3">
      <div class="pb-2 font-weight-600">Class Labels</div>
      <div class="flex-grow-1 position-relative overflow-hidden">
        <div class="h-100 selection-column d-flex flex-column">
          <div class="p-3 search-wrapper">
            <b-form-input
              v-model="leftSearchString"
              :placeholder="searchPlaceholder"
              size="sm"
              @focus="leftSearchFocus = true"
              @blur="leftSearchFocus = false"
              @keydown="onLeftSearchKeyDown"
            />
          </div>
          <div
            v-if="filteredClassLabels.length === 0"
            class="text-dark text-center mt-5 mb-3 pl-5 pr-5"
          >
            <p v-if="availableClassLabels && availableClassLabels.length > 0">no results</p>
          </div>

          <b-link
            v-if="
              availableClassLabels &&
              availableClassLabels.length > 0 &&
              filteredClassLabels.length > 0
            "
            class="text-dark font-weight-500 no-select text-center mt-2 mb-2 w-100"
            @click="onSelectAll"
          >
            <span>select all</span>
            <fai class="ml-2" icon="arrow-right" />
          </b-link>

          <div class="scroll-box flex-grow-1 pt-2 pb-2">
            <template v-if="filteredClassLabels.length > 0">
              <div
                v-for="(classLabel, i) in filteredClassLabels"
                :key="`class-${i}`"
                :ref="`class-${i}`"
                class="pl-3 pr-3 pb-2"
              >
                <class-label-entry
                  :class-label="classLabel"
                  :highlight="i === highlightIdx"
                  @click="onClickClassLabel(classLabel)"
                />
              </div>
            </template>

            <div v-if="createClassLabelAvailable" class="p-3 text-dark text-center">
              <div class="pl-5 pr-5 pb-3">
                You can use <b>CTRL + ENTER</b> to create a new class label based on your query.
              </div>
              <class-label-entry
                :class-label="{ id: 0, name: leftSearchString }"
                @click="onClickCreateClassLabel"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-50 d-flex flex-column">
      <div class="pb-2 font-weight-600">
        <exclamation-mark v-if="warning" class="mr-2" />
        Selected Class Labels
      </div>
      <div class="flex-grow-1 position-relative overflow-hidden">
        <div class="h-100 selection-column d-flex flex-column">
          <div
            v-if="!selectedClassLabelsInternal || selectedClassLabelsInternal.length === 0"
            class="text-dark text-center mt-5 pt-3 mb-5 no-select"
          >
            selected class labels appear here
          </div>
          <template v-else>
            <b-link
              class="text-dark font-weight-500 no-select text-center mt-3 mb-2 w-100"
              @click="onRemoveAll"
            >
              <fai class="mr-2" icon="arrow-left" />
              <span>remove all</span>
            </b-link>
            <div class="scroll-box flex-grow-1 pt-3 pb-2">
              <div
                v-for="classLabel in selectedClassLabelsInternal"
                :key="`selected-class-${classLabel.id}`"
                class="pl-3 pr-3 pb-2"
              >
                <class-label-entry
                  :class-label="classLabel"
                  @click="onClickSelectedClassLabel(classLabel)"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExclamationMark from '@/components/ExclamationMark'
import ClassLabelEntry from '@/components/class-labels/ClassLabelEntry'
import { ctrlOrCmdPressed } from '@/keyboardShortcuts'
import LoadingOverlay from '@/components/LoadingOverlay'

import _ from 'lodash'
import { mapActions } from 'vuex'
import { compareTwoStrings } from 'string-similarity'

export default {
  name: 'ColumnarClassLabelSelection',
  components: { LoadingOverlay, ClassLabelEntry, ExclamationMark },
  props: {
    warning: {
      type: Boolean,
      default: false,
    },
    selectAllEnabled: {
      type: Boolean,
      default: false,
    },
    classLabels: Array,
    selectedClassLabels: {
      type: Array,
      default: () => [],
    },
    createEnabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      leftSearchString: '',
      availableClassLabels: [],
      selectedClassLabelsInternal: [],
      highlightIdx: -1,
      leftSearchFocus: false,
    }
  },

  computed: {
    // ...mapState({
    //   classLabels: state => state.classLabels
    // }),
    searchPlaceholder() {
      if (this.createEnabled) {
        return 'search / create'
      }

      return 'search'
    },
    filteredClassLabels() {
      const s = this.leftSearchString

      let result = []

      if (s.length === 0) {
        return this.availableClassLabels
      } else if (s.length === 1) {
        result = this.availableClassLabels.filter((d) => {
          return d.name.toLowerCase().startsWith(s.toLowerCase())
        })
      } else {
        result = this.availableClassLabels.filter((d) => {
          return d.name.toLowerCase().includes(s.toLowerCase())
        })
      }

      if (s.length > 1) {
        result.sort((a, b) => {
          return compareTwoStrings(s, b.name) - compareTwoStrings(s, a.name)
        })
      }
      return result
    },
    createClassLabelAvailable() {
      const found = _.find(this.classLabels, ['name', this.leftSearchString]) !== undefined
      return this.leftSearchString.length > 0 && !found && this.createEnabled
    },
  },

  watch: {
    classLabels(labels) {
      this.loadClassLabels(labels)
    },
    selectedClassLabels(labels) {
      this.selectedClassLabelsInternal = labels
      this.loadClassLabels(this.classLabels, true)
    },
    leftSearchString() {
      if (this.filteredClassLabels.length === 0) {
        this.highlightIdx = -1
      } else {
        this.highlightIdx = 0
      }
    },
    leftSearchFocus(newValue) {
      if (newValue) {
        this.highlightIdx = 0
      } else {
        this.highlightIdx = -1
      }
    },
    highlightIdx(newIdx) {
      if (newIdx >= 0 && this.filteredClassLabels.length > 0) {
        this.scrollLeftTo(newIdx)
      }
    },
  },

  mounted() {
    this.selectedClassLabelsInternal = this.selectedClassLabels
    this.loadClassLabels(this.classLabels)
  },

  methods: {
    ...mapActions(['addClassLabel']),
    loadClassLabels(labels, externalChange) {
      if (!labels) this.availableClassLabels = []
      this.availableClassLabels = _.cloneDeep(labels)

      for (let i = this.selectedClassLabelsInternal.length - 1; i >= 0; i--) {
        const selectedClassLabel = this.selectedClassLabelsInternal[i]
        let selectedFound = false
        for (let j = this.availableClassLabels.length - 1; j >= 0; j--) {
          const newClassLabel = this.availableClassLabels[j]
          if (newClassLabel.id === selectedClassLabel.id) {
            this.availableClassLabels.splice(j, 1)
            selectedFound = true
          }
        }

        if (!selectedFound) {
          this.selectedClassLabelsInternal.splice(i, 1)
        }
      }

      if (!externalChange) {
        this.$emit('selection-changed', _.cloneDeep(this.selectedClassLabelsInternal))
      }
    },
    async createClassLabel() {
      if (this.leftSearchString.length === 0) {
        this.$bvToast.toast('Class labels must be at least one characters long.', {
          title: 'Class Label',
          variant: 'warning',
          autoHideDelay: 3000,
          solid: true,
        })
      } else {
        try {
          // dispatch action to create the new class label
          this.loading = true
          const newClassLabel = await this.addClassLabel({ name: this.leftSearchString })

          // add class label to selected labels
          this.onClickClassLabel(newClassLabel)
          this.loading = false
        } catch (e) {
          console.error(e)
          this.$bvToast.toast('Error during class label creation.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
        }
      }
    },
    onSelectAll() {
      for (let i = 0; i < this.filteredClassLabels.length; i++) {
        const classLabel = this.filteredClassLabels[i]
        for (let j = 0; j < this.availableClassLabels.length; j++) {
          const c = this.availableClassLabels[j]
          if (c.id === classLabel.id) {
            this.availableClassLabels.splice(j, 1)
            this.selectedClassLabelsInternal.push(classLabel)
            i--
            break
          }
        }
      }
      this.$emit('selection-changed', _.cloneDeep(this.selectedClassLabelsInternal))
    },
    onRemoveAll() {
      for (let i = 0; i < this.selectedClassLabelsInternal.length; i++) {
        const c = this.selectedClassLabelsInternal[i]
        this.availableClassLabels.push(c)
      }
      this.selectedClassLabelsInternal = []
      this.$emit('selection-changed', _.cloneDeep(this.selectedClassLabelsInternal))
    },
    onClickClassLabel(classLabel) {
      for (let i = 0; i < this.availableClassLabels.length; i++) {
        const c = this.availableClassLabels[i]
        if (c.id === classLabel.id) {
          this.availableClassLabels.splice(i, 1)
          break
        }
      }
      this.selectedClassLabelsInternal.push(classLabel)
      this.$emit('selection-changed', _.cloneDeep(this.selectedClassLabelsInternal))
    },
    onClickSelectedClassLabel(classLabel) {
      for (let i = 0; i < this.selectedClassLabelsInternal.length; i++) {
        const c = this.selectedClassLabelsInternal[i]
        if (c.id === classLabel.id) {
          this.selectedClassLabelsInternal.splice(i, 1)
          break
        }
      }
      this.availableClassLabels.push(classLabel)
      this.$emit('selection-changed', _.cloneDeep(this.selectedClassLabelsInternal))
    },
    onLeftSearchKeyDown(evt) {
      if (evt.keyCode === 13) {
        // enter
        evt.preventDefault()
        if (ctrlOrCmdPressed(evt)) {
          if (this.createClassLabelAvailable) {
            this.createClassLabel()
          }
        } else {
          let classLabel = this.filteredClassLabels[this.highlightIdx]
          if (classLabel) {
            this.onClickClassLabel(classLabel)
            this.highlightIdx = Math.min(this.filteredClassLabels.length - 1, this.highlightIdx)
          }
        }
      } else if (evt.keyCode === 38) {
        // arrow up
        evt.preventDefault()
        this.highlightIdx = Math.max(0, this.highlightIdx - 1)
      } else if (evt.keyCode === 40) {
        // arrow down
        evt.preventDefault()
        this.highlightIdx = Math.min(this.filteredClassLabels.length - 1, this.highlightIdx + 1)
      }
    },
    scrollLeftTo(i) {
      const elem = this.$refs[`class-${i}`]
      if (elem && elem[0]) {
        elem[0].scrollIntoView(false)
      }
    },
    onClickCreateClassLabel() {
      if (this.createClassLabelAvailable) {
        this.createClassLabel()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';

.selection-column {
  background: $gray-200;
  border-radius: 5px;
}

.search-wrapper {
  border-bottom: $gray-400 solid 1px;
}

.class-label {
  padding: 0.5rem;
  border-radius: 5px;
  font-weight: 600;

  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.class-label-square {
  display: inline-block;
  width: 12px;
  height: 24px;
  border-radius: 2px;
  margin-right: 0.5rem;
}

.highlight {
  outline: $primary solid 3px;
}
</style>
