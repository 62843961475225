<template>
  <div v-show="!field.hidden">
    <b-form-group v-bind="formGroupAttributes" :description="field.description" :label-size="size">
      <div class="d-flex justify-content-between">
        <div>{{ field.display_name }}</div>
        <b-button @click="formatJson()" size="sm" class="mb-2" variant="light"
          >format json</b-button
        >
      </div>
      <b-form-textarea
        :id="$id(field.name)"
        class="mb-1"
        type="text"
        required
        :plaintext="plaintext || field.editable === false"
        :value="jsonString"
        @input="onInput"
        :state="validJson"
        :size="size"
        :placeholder="field.display_name"
      >
      </b-form-textarea>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'JsonTextInput',
  props: ['value', 'field', 'plaintext', 'size', 'formGroupAttributes'],
  data() {
    return {
      jsonString: '',
      validJson: true,
    }
  },

  mounted() {
    this.jsonString = JSON.stringify(this.value)
    this.onInput(this.jsonString, true)
  },

  methods: {
    formatJson() {
      this.jsonString = JSON.stringify(this.value, null, 2)
    },
    onInput(event, initial) {
      try {
        let json = JSON.parse(event)
        this.$emit('input', json, initial)
        this.validJson = true
      } catch (e) {
        this.validJson = false
      }
    },
  },
}
</script>
