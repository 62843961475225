<template>
  <div>
    <div class="d-flex">
      <div class="flex-grow-1 text-ellipsis font-weight-700">
        {{ worker.name }}
      </div>
      <b-dropdown no-caret right size="sm" variant="link" class="mt-n1 mr-n2">
        <template #button-content>
          <fai icon="ellipsis-v" />
        </template>
        <b-dropdown-item-button @click="onDeleteWorker" variant="danger">
          delete
        </b-dropdown-item-button>
      </b-dropdown>
    </div>
    <worker-status-badge :worker="worker" />
    <div class="mt-2 font-size-small">
      <span v-if="enqueuedJobs && enqueuedJobs.length === 0" class="text-info">
        0 enqueued jobs
      </span>
      <b-link v-else-if="enqueuedJobs" @click="showJobs = !showJobs">
        {{ enqueuedJobs.length }} enqueued jobs
      </b-link>
      <span v-else class="text-info">
        <b-spinner class="mr-1" type="grow" variant="info"></b-spinner>
        enqueued jobs
      </span>
    </div>
    <b-collapse v-model="showJobs">
      <b-list-group flush class="mt-2">
        <b-list-group-item
          v-for="job in enqueuedJobs"
          :key="`worker-job-${job.id}`"
          href="#"
          size="sm"
          class="d-flex justify-content-between"
          @click="onClickJob(job)"
        >
          <div class="font-weight-500 mr-2">
            {{ job.model.name }}
          </div>
          <job-status-badge :status="job.status" />
        </b-list-group-item>
      </b-list-group>
    </b-collapse>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import JobStatusBadge from '@/components/jobs/JobStatusBadge'
import WorkerStatusBadge from '@/components/worker/WorkerStatusBadge'

export default {
  name: 'WorkerEntry',
  components: { WorkerStatusBadge, JobStatusBadge },
  props: ['worker'],
  data() {
    return {
      showJobs: false,
    }
  },
  methods: {
    ...mapActions(['openAiModal', 'getModelDetails']),
    onClickJob(job) {
      this.openAiModal(undefined)
      this.getModelDetails({ model: job.model }).then((model) => {
        this.openAiModal(model)
      })
    },
    onDeleteWorker() {
      this.$emit('click-delete', this.worker)
    },
  },
  computed: {
    ...mapState(['jobs']),
    enqueuedJobs() {
      if (!this.worker || !this.jobs) return undefined
      return this.jobs.filter((job) => job.worker && job.worker.id === this.worker.id)
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../custom';

.list-group-item-action {
  @extend .border-radius-sm;
}
</style>
