<template>
  <b-form-group
    :label="field.display_name"
    label-cols-sm="5"
    v-b-popover.hover.left="field.description"
  >
    <b-form-input :id="$id(field.name)" type="text" plaintext :value="value"> </b-form-input>
  </b-form-group>
</template>

<script>
export default {
  name: 'StringParameter',
  props: ['value', 'field'],
  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>
