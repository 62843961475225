<template>
  <span v-b-tooltip.hover.nofade.d0.o0="{ customClass: 'no-tooltip-arrow' }" :title="tooltip">
    <slot />
    <fai icon="info-circle" :class="iconClass" class="ml-1"></fai>
  </span>
</template>

<script>
export default {
  name: 'TooltipCircle',
  props: {
    tooltip: String,
    iconClass: String,
  },
}
</script>

<style scoped lang="scss"></style>
