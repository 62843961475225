<template>
  <div>
    <!--    <div class="row m-0 p-3">-->
    <!--      <div class="col-12">-->
    <!--        <license-table-->
    <!--          @assign-user="onAssignUser"-->
    <!--          @unassign-user="onUnassignUser"-->
    <!--          :licenses="licenses">-->
    <!--        </license-table>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <b-modal @ok="handleAssignUserOk" id="modal-assign-user" ref="modalAssignUser" title="Assign User"-->
    <!--             size="lg">-->
    <!--      <div>-->
    <!--        <b-form @submit.stop.prevent="assignUser">-->
    <!--          {{ selectedLicense }}-->
    <!--        </b-form>-->

    <!--      </div>-->
    <!--    </b-modal>-->

    <div class="row m-0 p-0">
      <div class="col-12 p-0">
        <div
          v-if="organization.deactivated"
          class="invalid-overlay border-radius-md font-weight-bold lead"
        >
          <div class="text-center">
            <fai icon="lock" class="lock text-secondary mb-2" />
            <div class="mb-3">Organization is deactivated.</div>
          </div>
        </div>
        <div
          v-else-if="!organization.license_valid"
          class="invalid-overlay border-radius-md font-weight-bold lead"
        >
          <div class="text-center">
            <fai icon="lock" class="lock text-secondary mb-2" />
            <div class="mb-3">Organization license is invalid.</div>
            <b-button variant="primary" to="/organization-license"> upload license</b-button>
          </div>
        </div>
        <div class="p-3">
          <h5 class="mb-2">
            <b>Annotation</b>
          </h5>
          <compact-license-table :licenses="licenses" license-type="Annotation" />

          <h5 class="mb-2">
            <b>Engineering</b>
          </h5>
          <compact-license-table :licenses="licenses" license-type="Engineering" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import CompactLicenseTable from '@/components/organization-settings/CompactLicenseTable'

export default {
  name: 'LicensesSettings',
  components: { CompactLicenseTable },
  props: {
    licenses: Array,
    organization: Object,
  },

  data() {
    return {
      loading: true,
      selectedLicense: null,
    }
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {
    onAssignUser(license) {
      this.selectedLicense = license
      this.$refs.modalAssignUser.show()
    },
    handleAssignUserOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.assignUser()
    },
    assignUser() {},

    onUnassignUser(license) {
      // let displayName = this.getUserDisplayName(user);
      let displayName = license.username
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure that you want to unassign the license from the user ${displayName}?`,
          {
            title: `Unassign License`,
            okVariant: 'danger',
            okTitle: 'Unassign',
          }
        )
        .then((value) => {
          if (value) {
            let data = {
              license: null,
            }
            this.$axios({
              method: 'patch',
              url: `/api/organizations/${this.organization.id}/change_user/`,
              params: {
                username: license.username,
              },
              data: data,
              withCredentials: true,
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': Cookie.get('csrftoken'),
              },
            })
              .then(() => {
                this.$emit('license-changed', {
                  previousUser: license.user,
                  user: null,
                  license: license,
                })
                this.$bvToast.toast('Unassigned user from license.', {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 4000,
                  solid: true,
                })
              })
              .catch(() => {
                this.$bvToast.toast('Could not unassign license from user.', {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 4000,
                  solid: true,
                })
              })
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err)
        })
    },
  },
}
</script>

<style scoped>
.lock {
  font-size: 1.5em;
}
</style>
