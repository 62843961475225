<template>
  <div>
    <div v-for="(field, i) in fields" :key="$id(`form-generator-input-${i}`)">
      <string-input
        v-if="field.type === 'string_input'"
        v-model="data[field.name]"
        :field="field"
        :plaintext="allPlaintext"
        :size="size"
        :form-group-attributes="formGroupAttributes"
        @input="onInput"
      />

      <integer-input
        v-if="field.type === 'integer_input'"
        v-model="data[field.name]"
        :field="field"
        :plaintext="allPlaintext"
        :size="size"
        :form-group-attributes="formGroupAttributes"
        @input="onInput"
      />

      <double-input
        v-if="field.type === 'double_input'"
        v-model="data[field.name]"
        :field="field"
        :plaintext="allPlaintext"
        :size="size"
        :form-group-attributes="formGroupAttributes"
        @input="onInput"
      />

      <bool-input
        v-if="field.type === 'bool_input'"
        v-model="data[field.name]"
        :field="field"
        :plaintext="allPlaintext"
        :size="size"
        :form-group-attributes="formGroupAttributes"
        @input="onInput"
      />

      <list-input
        v-if="field.type === 'list_input'"
        v-model="data[field.name]"
        :plaintext="allPlaintext"
        :size="size"
        :field="field"
        :form-group-attributes="formGroupAttributes"
        @input="onInput"
      />

      <!-- TODO array inputs -->

      <selection-dropdown
        v-if="field.type === 'selection_dropdown'"
        v-model="data[field.name]"
        :field="field"
        :plaintext="allPlaintext"
        :size="size"
        :form-group-attributes="formGroupAttributes"
        @input="onInput"
      />

      <json-text-input
        v-if="field.type === 'json_text_input'"
        v-model="data[field.name]"
        :field="field"
        :plaintext="allPlaintext"
        :size="size"
        :form-group-attributes="formGroupAttributes"
        @input="onInput"
      />

      <param-group
        v-if="field.type === 'group'"
        v-model="data[field.name]"
        :plaintext="allPlaintext"
        :size="size"
        :form-group-attributes="formGroupAttributes"
        :field="field"
        :fields="field.fields"
        :level="level"
        @input="onInput"
      />

      <group-selection
        v-if="field.type === 'group_selection'"
        v-model="data[field.name]"
        :plaintext="allPlaintext"
        :size="size"
        :form-group-attributes="formGroupAttributes"
        :field="field"
        :level="level"
        @input="onInput"
      />

      <group-switchable
        v-if="field.type === 'group_switchable'"
        v-model="data[field.name]"
        :plaintext="allPlaintext"
        :size="size"
        :form-group-attributes="formGroupAttributes"
        :field="field"
        :fields="field.fields"
        :level="level"
        @input="onInput"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import StringInput from './form-elements/StringInput'
import IntegerInput from './form-elements/IntegerInput'
import DoubleInput from './form-elements/DoubleInput'
import BoolInput from './form-elements/BoolInput'
import ListInput from './form-elements/ListInput'
import JsonTextInput from './form-elements/JsonTextInput'
import GroupSelection from './form-elements/GroupSelection'
import GroupSwitchable from './form-elements/GroupSwitchable'
import ParamGroup from './form-elements/ParamGroup'
import SelectionDropdown from './form-elements/SelectionDropdown'

export default {
  name: 'FormGenerator',
  components: {
    SelectionDropdown,
    GroupSelection,
    GroupSwitchable,
    StringInput,
    IntegerInput,
    DoubleInput,
    BoolInput,
    ListInput,
    JsonTextInput,
    ParamGroup,
  },
  props: {
    value: Object,
    fields: Array,
    allPlaintext: Boolean,
    size: {
      default: undefined,
    },
    formGroupAttributes: {
      type: Object,
      default: () => {
        return {
          labelColsSm: 2,
        }
      },
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: {},
    }
  },

  computed: {},

  watch: {
    // fields: function (newValue, oldValue) {
    //   this.initializeForm();
    //   this.onInput();
    // },
    // value: function (newValue, oldValue) {
    //   this.initializeForm();
    // this.value = newValue;
    //   this.updateForm();
    // }
  },

  created() {
    this.initializeForm()
    this.onInput(undefined, true)
  },

  methods: {
    initializeForm() {
      this.data = {}

      for (let i = 0; i < this.fields.length; i++) {
        let field = this.fields[i]

        let value = {}
        if (field.type === 'group') {
          value = {}
        } else if (field.type === 'selection_dropdown') {
          const selectionDefault = field.options[field.default]
          if (selectionDefault !== undefined) {
            value = field.options[field.default].value
          } else if (field.options.length > 0) {
            value = field.options[0].value
          } else {
            value = undefined
          }
        } else if (field.type === 'group_switchable') {
          if (field.default_active) {
            value = {}
          } else {
            value = undefined
          }
        } else if (field.type === 'group_selection') {
          value = {
            selection: field.default,
          }
        } else if (field.type === 'list_input') {
          value = []
        } else {
          value = field.default
        }

        if (this.value && Object.prototype.hasOwnProperty.call(this.value, field.name)) {
          let v1 = _.cloneDeep(this.value[field.name])

          let v2
          if (field.type === 'group_selection') {
            v2 = _.merge(value, v1)
          } else {
            v2 = _.merge(v1, value)
          }

          if (!(v1 === undefined && _.isEmpty(v2))) {
            if (field.type === 'selection_dropdown') {
              this.$set(this.data, field.name, v2.valueOf())
            } else {
              this.$set(this.data, field.name, v2)
            }
          }
        } else {
          this.$set(this.data, field.name, value)
        }

        // this.$set(this.data, field.name, value);
      }

      // if(_.isEmpty(this.data)) {
      //   this.data = undefined
      // }

      // if (this.unidirectional) {
      // this.onInput(this.data)
      // }

      // if(this.fields.length > 0) {
      //   console.log(this.fields[0].name, this.fields.length)
      // }
      // console.log(this.fields.length, JSON.stringify(this.data))
      // this.data);
    },

    onInput(data, initial) {
      this.$emit('input', _.cloneDeep(this.data), initial)
    },
  },
}
</script>
