<template>
  <div>
    <b-form-group
      v-if="horizontal"
      description="Choose the worker for training the model."
      label-align-lg="left"
      label-cols-lg="3"
    >
      <template v-slot:label>
        <div>
          <b-tooltip target="worker-warn-icon" title="No worker for training selected" />
          Worker
          <fai
            v-if="!valid"
            id="worker-warn-icon"
            class="text-warning mb-1 mr-1 ml-1"
            icon="exclamation-triangle"
          />
        </div>
        <div>
          <b-button v-b-modal.modal-add-worker variant="link" size="sm" class="pl-1 pr-1">
            <fai icon="plus" size="xs" />
            <span class="ml-1">add worker</span>
          </b-button>
          <b-button @click="onClickRefreshWorkers" variant="link" size="sm" class="pl-1 pr-1">
            <fai :class="{ rotating: workersLoading }" icon="sync" size="xs" />
          </b-button>
        </div>
      </template>

      <b-form-select
        id="training-worker"
        @input="$emit('input', $event)"
        :value="value"
        :disabled="disabled"
        :options="workerOptions"
        required
      />
    </b-form-group>
    <b-form-group
      v-else
      label-for="trainingWorker"
      description="Choose the worker for training the model."
    >
      <div class="d-flex justify-content-between mb-1">
        <label class="mb-0">Worker</label>
        <div>
          <b-button v-b-modal.modal-add-worker variant="link" size="sm">
            <fai icon="plus" />
            <span class="ml-2">add worker</span>
          </b-button>
          <b-button @click="onClickRefreshWorkers" variant="link" size="sm">
            <fai :class="{ rotating: workersLoading }" icon="sync" />
          </b-button>
        </div>
      </div>
      <b-form-select
        id="trainingWorker"
        @input="$emit('input', $event)"
        :value="value"
        :options="workerOptions"
        required
      />
    </b-form-group>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'WorkerSelection',
  components: {},
  props: ['value', 'disabled', 'valid', 'horizontal'],
  data() {
    return {}
  },
  methods: {
    ...mapActions(['getWorkers']),
    onClickRefreshWorkers() {
      this.getWorkers({ forceReload: true })
    },
  },
  computed: {
    ...mapState(['workers', 'workersLoading']),
    workerOptions() {
      const options = []
      for (const worker of this.workers) {
        options.push({ value: worker.id, text: worker.name })
      }
      return options
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../custom';

.list-group-item-action {
  @extend .border-radius-sm;
}
</style>
