<template>
  <div>
    <div class="d-flex justify-content-between w-100 flex-column flex-lg-row">
      <div class="d-flex justify-content-center justify-content-lg-start flex-wrap flex-grow-1">
        <div class="mb-2 mr-2">
          <date-time-picker v-model="dateTimeFrom" label="from" @input="emitDateRangeChange" />
        </div>
        <div class="mb-2">
          <date-time-picker v-model="dateTimeTo" label="to" @input="emitDateRangeChange" />
        </div>
      </div>
      <div class="d-flex justify-content-center justify-content-lg-end flex-wrap">
        <b-button-group size="sm" class="ml-2 mb-2">
          <b-button
            :variant="timelineQuantity === 'item' ? 'primary' : 'outline-primary'"
            @click="switchTimelineQuantity('item')"
          >
            items
          </b-button>
          <b-button
            :variant="timelineQuantity === 'object' ? 'primary' : 'outline-primary'"
            :disabled="!this.dataset.object_detection"
            @click="switchTimelineQuantity('object')"
          >
            objects
          </b-button>
        </b-button-group>
        <div class="ml-2 mb-2">
          <b-input-group prepend="group by class" size="sm" class="flex-nowrap">
            <b-input-group-append is-text>
              <input v-model="groupByClass" type="checkbox" :disabled="!groupingAvailable" />
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="ml-2 mb-2">
          <b-input-group size="sm" class="flex-nowrap">
            <b-input-group-prepend is-text> zoom</b-input-group-prepend>
            <b-form-input
              size="sm"
              type="range"
              :min="minZoom.x"
              :max="maxZoom.x"
              step="0.001"
              :value="mainTimeSeries.zoom.x"
              @input="changeZoomX"
            />
          </b-input-group>
        </div>
        <div class="ml-2 mb-2">
          <b-input-group prepend="squeeze" size="sm" class="flex-nowrap">
            <b-input-group-append is-text>
              <input v-model="bins.squeeze" type="checkbox" />
            </b-input-group-append>
          </b-input-group>
        </div>
        <b-button-group size="sm" class="ml-2 mb-2">
          <b-button
            v-for="resolution in binResolutionOptions"
            :key="`btn-resolution-${resolution.value}`"
            :variant="bins.aggregationWindow === resolution.value ? 'primary' : 'outline-primary'"
            @click="changeBinResolution(resolution.value)"
          >
            {{ resolution.text }}
          </b-button>
        </b-button-group>
      </div>
    </div>
    <div class="main-time-series-wrapper">
      <loading-overlay v-if="loading > 0" light />
      <div ref="main-canvas-wrapper" class="w-100">
        <canvas
          ref="main-canvas"
          class="main-canvas"
          :class="{ dragging: Math.abs(dragging.startPos - dragging.lastPos) > 1 && dataAvailable }"
          :width="mainTimeSeries.canvasWidth"
          :height="mainTimeSeries.canvasHeight"
          @wheel.prevent="onTimelineScroll($event, mainTimeSeries)"
          :style="{
            width: mainTimeSeries.canvasStyleWidth,
            height: mainTimeSeries.canvasStyleHeight,
          }"
          @mouseenter="mouseEnterMainSeries"
          @mouseleave="mouseLeaveMainSeries"
          @mousedown="mouseDownMainSeries"
          @mouseup="mouseUpMainSeries"
          @mousemove="mouseMoveMainSeries"
          @dblclick.self="doubleClickCanvas"
          @contextmenu="contextMenuOpen"
        />
        <div v-if="loading === 0 && loadingError" class="white-overlay">
          <div class="d-flex flex-column justify-content-center align-items-center h-100">
            <div class="text-secondary mb-1">AN ERROR OCCURRED</div>
            <b-button variant="light" size="sm" @click="getMainTimeSeriesData()"> retry</b-button>
          </div>
        </div>
        <div v-else-if="loading === 0 && !dataAvailable" class="no-data-overlay">
          <div class="d-flex justify-content-center align-items-center h-100">
            <div class="text-secondary">NO DATA PRESENT</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  add,
  formatDistanceStrict,
  milliseconds,
  startOfMinute,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfYear,
} from 'date-fns'

import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

import { mapState } from 'vuex'
import axios from 'axios'

import { getFilterQuery } from '@/filter'
import { ctrlOrCmdPressed, shiftPressed } from '@/keyboardShortcuts'
import { getColorForCSS } from '@/colors'
import { formatDatetimeURI, formatTimestamp } from '@/datetime'

import LoadingOverlay from '@/components/LoadingOverlay'
import DateTimePicker from '@/components/DateTimePicker'

export default {
  name: 'ItemTimeline',
  components: { DateTimePicker, LoadingOverlay },
  props: ['dataset', 'filterSettings', 'timezone'],
  data() {
    return {
      dragPrevious: false,
      dragNext: false,

      loading: 0,
      loadingError: false,
      font: '"IBM Plex Mono", Helvetica, Arial, sans-serif',
      binResolutionOptions: [
        { value: 'second', text: '1s' },
        { value: 'minute', text: '1min' },
        { value: 'hour', text: '1h' },
        { value: 'day', text: '1d' },
        { value: 'month', text: '1mo' },
      ],
      dateTimeTo: null,
      dateTimeFrom: null,
      bins: {
        timelinePos: 0,
        timeMin: 0,
        timeMax: 0,
        aggregationWindow: 'day',
        milliSecondsPerBin: 86400000,
        gapWidth: 40,
        width: 20,
        minHeight: 2,
        padding: 5,
        scrollSpeed: 15,
        squeeze: false,
      },
      indicator: {
        show: false,
        x: 0,
        text: '',
        date: '',
        time: '',
        width: 600,
      },
      minZoom: {
        x: 0.2,
        y: 0.0,
      },
      maxZoom: {
        x: 15.0,
        y: 50.0,
      },
      mainTimeSeries: {
        paddingTop: 21,
        paddingBottom: 40,
        canvasWidth: 0,
        canvasHeight: 0,
        canvasStyleWidth: '0px',
        canvasStyleHeight: '0px',
        height: 120,
        zoom: {
          x: 5,
          y: 10,
        },
        classLabels: {},
        firstVisibleElementIdx: undefined,
        lastVisibleElementIdx: undefined,
        maxElements: 1000,
        data: [],
        container: undefined,
        displayedData: [],
        viewBox: '0 0 100 181',
        binColor: '#a3d1f5',
      },
      dragging: {
        active: false,
        startPos: 0,
        lastPos: 0,
      },
      timelineQuantity: 'item',
      groupByClass: false,
    }
  },
  mounted() {
    this.dateTimeFrom = this.filterSettings.dateTimeFrom
    this.dateTimeTo = this.filterSettings.dateTimeTo

    window.addEventListener('resize', this.windowResize)
    this.changeBinResolution('month')
    this.updateCanvas()
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize)
  },
  computed: {
    ...mapState({
      classLabelMap: (state) => state.classLabelMap,
    }),
    groupingAvailable: function () {
      return this.isGroupingAvailable()
    },
    dataAvailable: function () {
      return this.mainTimeSeries.data.length > 0
    },
  },
  watch: {
    timezone() {
      this.getMainTimeSeriesData()
    },
    // dateTimeFrom: function (newValue, oldValue) {
    //   if (oldValue !== undefined && oldValue !== newValue) {
    //     this.$emit('date-time-from-change', this.dateTimeFrom)
    //   }
    // },
    // dateTimeTo: function (newValue, oldValue) {
    //   if (oldValue !== undefined && oldValue !== newValue) {
    //     this.$emit('date-time-to-change', this.dateTimeTo)
    //   }
    // },
    dataset() {
      this.datasetChanged = true
      this.groupByClass = false
      this.timelineQuantity = 'item'
      this.changeBinResolution('day')
    },
    filterSettings: {
      handler: function () {
        this.dateTimeFrom = this.filterSettings.dateTimeFrom
        this.dateTimeTo = this.filterSettings.dateTimeTo

        // watch for changes except for dateTimeFrom and dateTimeTo (changes of the filter query)
        // in case the dataset changed recently (a new one was selected) we don't update either
        // (this is already done by the watcher on the dataset)
        if (
          !this.datasetChanged &&
          this.filterQuery !== getFilterQuery(this.filterSettings, true)
        ) {
          this.getMainTimeSeriesData()
        }
      },
      deep: true,
    },
    'bins.squeeze': function (newValue) {
      this.bins.timelinePos = 0
      if (newValue) {
        if (this.mainTimeSeries.data.length > 0) {
          this.bins.timelinePos = this.mainTimeSeries.data[0].squeezedPosition - this.bins.width
        }
      } else {
        if (this.mainTimeSeries.data.length > 0) {
          this.bins.timelinePos = this.mainTimeSeries.data[0].unsqueezedPosition - this.bins.width
        }
      }
      this.render(this.mainTimeSeries)
    },
    groupByClass() {
      this.getMainTimeSeriesData()
    },
  },
  methods: {
    emitDateRangeChange() {
      this.$emit('date-time-range-change', {
        dateTimeFrom: this.dateTimeFrom,
        dateTimeTo: this.dateTimeTo,
      })
    },
    clearData() {
      this.mainTimeSeries.data = []
      this.mainTimeSeries.classLabels = {}
      this.mainTimeSeries.displayedData = []
      this.dragPrevious = false
      this.dragNext = false
    },

    windowResize() {
      this.updateCanvas()
      this.$nextTick(() => this.render(this.mainTimeSeries))
    },

    updateCanvas() {
      const canvas = this.$refs['main-canvas']
      const canvasWrapper = this.$refs['main-canvas-wrapper']
      if (canvas) {
        const scale = window.devicePixelRatio
        const width = Math.floor(canvasWrapper.clientWidth)
        const height = Math.floor(
          this.mainTimeSeries.paddingTop +
            this.mainTimeSeries.height +
            this.mainTimeSeries.paddingBottom
        )
        this.mainTimeSeries.canvasWidth = Math.floor(width * scale)
        this.mainTimeSeries.canvasHeight = Math.floor(height * scale)
        this.mainTimeSeries.canvasStyleWidth = `${width}px`
        this.mainTimeSeries.canvasStyleHeight = `${height}px`
      }
    },
    getBinPos(x, series) {
      return x - 0.5 * this.bins.width * series.zoom.x
    },
    getPadding(series) {
      return this.bins.padding * series.zoom.x
    },
    getBinSegmentY(segments, idx, series) {
      let sum = 0
      for (let i = idx + 1; i < segments.length; i++) {
        sum += segments[i]
      }
      return this.getBinHeight(segments[idx] + sum, series)
    },
    getBinHeight(value, series) {
      return series.zoom.y * value
    },
    getViewableBins(zoom) {
      let canvasWrapper = this.$refs['main-canvas-wrapper']
      let canvasWidth = 4000
      if (canvasWrapper) {
        canvasWidth = canvasWrapper.clientWidth
      }
      return Math.ceil(canvasWidth / (this.bins.width * zoom))
    },
    getMaxViewableBins() {
      return this.getViewableBins(this.minZoom.x)
    },
    getViewableTimeRange(zoom) {
      return this.getViewableBins(zoom) * this.bins.milliSecondsPerBin
    },
    getMaxViewableTimeRange() {
      return this.getViewableTimeRange(this.minZoom.x)
    },
    getMainTimeSeriesData(requestedTime, loadPrevious, loadNext, callback) {
      if (!loadPrevious && !loadNext) {
        this.clearData()
      } else if (this.loading > 0) {
        return
      }

      if (this.dataset === undefined || this.dataset.id === undefined) return
      if (loadPrevious && !this.mainTimeSeries.firstElement) {
        return
      }
      if (loadNext && !this.mainTimeSeries.lastElement) {
        return
      }

      this.loading += 1

      this.filterQuery = getFilterQuery(this.filterSettings, true)

      let timelineMode = 'count_items'
      if (this.timelineQuantity === 'item') {
        if (this.groupByClass) {
          timelineMode = 'count_items_by_class'
        } else {
          timelineMode = 'count_items'
        }
      } else if (this.timelineQuantity === 'object') {
        if (this.groupByClass) {
          timelineMode = 'count_objects_by_class'
        } else {
          timelineMode = 'count_objects'
        }
      }

      this.mainTimeSeries.maxElements = Math.max(
        this.mainTimeSeries.maxElements,
        30 * this.getMaxViewableBins()
      )
      let limit = Math.min(3 * this.getMaxViewableBins(), this.mainTimeSeries.maxElements)
      let encodedTimezone = encodeURI(`"${this.timezone}"`)
      let url = `/api/dataset-items/timeline/?dataset__id=${this.dataset.id}&mode=${timelineMode}&aggregation_window=${this.bins.aggregationWindow}&timezone=${encodedTimezone}&limit=${limit}${this.filterQuery}`
      if (!loadPrevious && !loadNext && requestedTime !== undefined) {
        url = `${url}&ge(created_date,${formatDatetimeURI(requestedTime, this.timezone)})`
      } else {
        if (loadPrevious && this.mainTimeSeries.firstElement) {
          url = `${url}&lt(created_date,${formatDatetimeURI(
            this.mainTimeSeries.firstElement.timestamp,
            this.timezone
          )})&order=desc`
        } else if (loadNext && this.mainTimeSeries.lastElement) {
          // 'created_date_after' is inclusive, hence we need to add one second, minute, hour, day, or
          // month to the timestamp of the last element
          let duration = {}
          duration[`${this.bins.aggregationWindow}s`] = 1
          let nextTimestamp = this.mainTimeSeries.lastElement.timestamp + milliseconds(duration)
          url = `${url}&ge(created_date,${formatDatetimeURI(nextTimestamp, this.timezone)})`
        }
      }

      this.firstVisibleElementIdx = undefined
      this.lastVisibleElementIdx = undefined

      if (this.mainTimeSeries.cancelTokenSource) {
        // cancel previous request
        this.mainTimeSeries.cancelTokenSource.cancel()
      }
      this.mainTimeSeries.cancelTokenSource = axios.CancelToken.source()

      let self = this
      this.$axios({
        method: 'get',
        url: url,
        withCredentials: true,
        crossDomain: true,
        cancelToken: this.mainTimeSeries.cancelTokenSource.token,
      })
        .then((response) => {
          this.mainTimeSeries.cancelTokenSource = undefined
          if (response && response.data) {
            let data = response.data.data
            if (loadPrevious) {
              // reverse ordering
              data.reverse()
            }

            let header = response.data.header

            let newClassLabels = self.mainTimeSeries.classLabels
            if (!loadPrevious && !loadNext) {
              newClassLabels = {}
            }
            const classPrefix = 'class_'
            let firstClassIdx = -1
            for (let i = 0; i < header.length; i++) {
              let element = header[i]
              const p = element.indexOf(classPrefix)
              if (p >= 0) {
                if (firstClassIdx === -1) {
                  firstClassIdx = i
                }
                self.$set(
                  newClassLabels,
                  i - firstClassIdx,
                  parseInt(element.slice(p + classPrefix.length))
                )
              }
            }
            self.mainTimeSeries.classLabels = newClassLabels

            let newData = []
            let length = data.length
            for (let i = 0; i < data.length; i++) {
              let d = data[i]
              let timestamp = d[0]
              let totalCount = d[1]

              newData.push({
                timestamp: timestamp,
                totalCount: totalCount,
                classCounts: d.slice(2),
                unsqueezedPosition: undefined,
                squeezedPosition: undefined,
                gap: undefined,
              })
            }

            let updatedData = []
            if (loadPrevious) {
              // prepend data
              updatedData = newData.concat(self.mainTimeSeries.data)
              if (updatedData.length > self.mainTimeSeries.maxElements) {
                updatedData = updatedData.slice(0, self.mainTimeSeries.maxElements)
                self.mainTimeSeries.lastElement = updatedData[updatedData.length - 1]
                self.mainTimeSeries.hasNext = true
              }

              if (
                length >= limit &&
                self.mainTimeSeries.firstElement.timestamp !== newData[0].timestamp
              ) {
                self.mainTimeSeries.firstElement = updatedData[0]
                self.mainTimeSeries.hasPrevious = true
              } else {
                // reached very beginning
                self.mainTimeSeries.hasPrevious = false
              }
            }

            if (loadNext) {
              // append data
              updatedData = self.mainTimeSeries.data.concat(newData)
              self.mainTimeSeries.slicingOffset = 0
              if (updatedData.length > self.mainTimeSeries.maxElements) {
                let firstElementAfterSlicing =
                  updatedData[updatedData.length - self.mainTimeSeries.maxElements]
                self.mainTimeSeries.slicingOffset = self.bins.squeeze
                  ? firstElementAfterSlicing.squeezedPosition
                  : firstElementAfterSlicing.unsqueezedPosition
                updatedData = updatedData.slice(-self.mainTimeSeries.maxElements)
                self.mainTimeSeries.firstElement = updatedData[0]
                self.mainTimeSeries.hasPrevious = true
              }

              if (
                length >= limit &&
                self.mainTimeSeries.lastElement.timestamp !== newData[length - 1].timestamp
              ) {
                self.mainTimeSeries.lastElement = newData[length - 1]
                self.mainTimeSeries.hasNext = true
              } else {
                // reached the very end
                self.mainTimeSeries.hasNext = false
              }
            }

            if (!loadPrevious && !loadNext) {
              if (length > 0) {
                self.mainTimeSeries.firstElement = newData[0]
                self.mainTimeSeries.lastElement = newData[length - 1]
                self.mainTimeSeries.hasPrevious = true
                self.mainTimeSeries.hasNext = true
                updatedData = newData
              }

              if (length === 0) {
                self.bins.timeMin = 0
                self.bins.timelinePos = 0
                self.bins.timeMax = 0
                updatedData = []
                self.mainTimeSeries.displayedData = []
              }
            }

            let requestedTimestampIdx = 0
            let maxY = 0
            let nGaps = 0
            let nPseudoGaps = 0
            let lastT = 0
            let requestedTimestampFound = false
            if (updatedData.length > 0) {
              self.bins.timeMin = updatedData[0].timestamp
              self.bins.timeMax = updatedData[updatedData.length - 1].timestamp
              let t0 = updatedData[0].timestamp
              for (let i = 0; i < updatedData.length; i++) {
                let d = updatedData[i]
                let timestamp = d.timestamp
                let totalCount = d.totalCount
                maxY = Math.max(totalCount, maxY)
                // add squeezed position
                d.unsqueezedPosition =
                  ((timestamp - t0) / self.bins.milliSecondsPerBin) * self.bins.width

                // add unsqueezed position
                d.gap = 0
                if (lastT !== 0) {
                  if (timestamp > lastT + 2 * self.bins.milliSecondsPerBin) {
                    nGaps += 1
                    d.gap = timestamp - lastT
                  } else if (timestamp > lastT + self.bins.milliSecondsPerBin) {
                    nPseudoGaps += 1
                  }
                }

                d.squeezedPosition =
                  i * self.bins.width + nPseudoGaps * self.bins.width + nGaps * self.bins.gapWidth
                lastT = timestamp
                if (!requestedTimestampFound && timestamp > requestedTime) {
                  // find the first element after the current time position
                  requestedTimestampIdx = i
                  requestedTimestampFound = true
                }
              }
            }

            if (self.mainTimeSeries.data.length > 0 && updatedData.length > 0) {
              // update timelinePos
              let diff = 0

              if (loadPrevious) {
                if (self.bins.squeeze) {
                  diff =
                    self.mainTimeSeries.data[0].squeezedPosition - updatedData[0].squeezedPosition
                } else {
                  diff =
                    self.mainTimeSeries.data[0].unsqueezedPosition -
                    updatedData[0].unsqueezedPosition
                }
              } else if (loadNext && self.mainTimeSeries.slicingOffset) {
                diff = -self.mainTimeSeries.slicingOffset
              }
              self.bins.timelinePos += diff
            }

            if (updatedData.length > 0 && !loadPrevious && !loadNext) {
              if (self.bins.squeeze) {
                self.bins.timelinePos =
                  updatedData[requestedTimestampIdx].squeezedPosition - this.bins.width
              } else {
                self.bins.timelinePos =
                  updatedData[requestedTimestampIdx].unsqueezedPosition - this.bins.width
              }

              // compute optimal y zoom level
              self.mainTimeSeries.zoom.y = (self.mainTimeSeries.height / Math.max(maxY, 1.0)) * 0.95
            }
            self.mainTimeSeries.data = updatedData
          }

          self.loadingError = false
          self.loading -= 1
          self.$nextTick(() => {
            self.render(self.mainTimeSeries)

            // in case of the first loading, also load one previous and one next chunk if available
            if (!loadPrevious && !loadNext) {
              self.getMainTimeSeriesData(requestedTime, true, false, () => {
                self.getMainTimeSeriesData(requestedTime, false, true)
              })
            } else {
              self.checkLoadPreviousOrNext()
            }
          })
        })
        .catch((error) => {
          console.log(error)
          self.loadingError = true
          self.loading -= 1
        })
        .finally(() => {
          self.datasetChanged = false
          if (callback) {
            callback()
          }
        })
    },

    onTimelineScroll(event, series) {
      let scrollDelta = event.deltaY / 30
      if (event.wheelDeltaY) {
        scrollDelta = -event.wheelDeltaY / 120
      }

      if (ctrlOrCmdPressed(event)) {
        let oldZoom = series.zoom.x
        let newZoom = Math.min(
          Math.max(oldZoom - 0.05 * scrollDelta, this.minZoom.x),
          this.maxZoom.x
        )
        const canvasRect = this.$refs['main-canvas-wrapper'].getBoundingClientRect()
        let x = event.clientX - canvasRect.left
        this.bins.timelinePos += x / oldZoom - x / newZoom

        series.zoom.x = newZoom
      } else if (shiftPressed(event)) {
        let factor = 0.15 * series.zoom.y
        if (factor < 1e-6) {
          factor = 1e-4
        }
        series.zoom.y = Math.min(
          Math.max(series.zoom.y - factor * scrollDelta, this.minZoom.y),
          this.maxZoom.y
        )
      } else {
        let delta = (scrollDelta * this.bins.scrollSpeed) / series.zoom.x
        this.bins.timelinePos += delta
      }

      this.checkLoadPreviousOrNext()
      this.$nextTick(() => {
        this.updateIndicator(event)
        this.render(series)
      })
    },

    addTicksBetween(xref, tref, dxdt, rounding, skip, tickFormat1, tickFormat2, xmin, xmax) {
      let ticks = []

      let x = xmin
      let t = tref
      if (dxdt !== 0) {
        t = new Date((xmin - xref) / dxdt + tref)
      }

      let tz = this.timezone

      let duration
      t = utcToZonedTime(t, tz)
      if (rounding === 'month') {
        duration = { months: 1 + skip }
        t = startOfYear(t)
      } else if (rounding === 'day') {
        duration = { days: 1 + skip }
        t = startOfMonth(t)
        // t = startOfYear(t)
      } else if (rounding === 'hour') {
        duration = { hours: 1 + skip }
        t = startOfDay(t)
        // t = startOfMonth(t)
      } else if (rounding === 'minute') {
        duration = { minutes: 1 + skip }
        t = startOfHour(t)
        // t = startOfDay(t)
      } else if (rounding === 'second') {
        t = startOfMinute(t)
        // t = startOfHour(t)
        duration = { seconds: 1 + skip }
      }
      t = zonedTimeToUtc(t, tz)

      let i = 0

      // eslint-disable-next-line no-constant-condition
      while (true) {
        if (dxdt === 0) {
          x = xref
          t = tref
          if (t % milliseconds(duration) !== 0) {
            break
          }
        } else {
          x = (t - tref) * dxdt + xref
        }

        let tTick = utcToZonedTime(t, tz)
        let l1 = tickFormat1 !== '' ? format(tTick, tickFormat1, { timeZone: tz }) : ''
        let l2 = tickFormat2 !== '' ? format(tTick, tickFormat2, { timeZone: tz }) : ''

        if (x > xmax) {
          break
        }

        if ((xmin === undefined || xmin <= x) && (xmax === undefined || x <= xmax)) {
          ticks.push({ x: x, line1: l1, line2: l2 })
        }

        if (dxdt === 0) {
          break
        }

        if (rounding === 'month') {
          t = utcToZonedTime(t, tz)
          t = add(t, duration)
          t = zonedTimeToUtc(t, tz)
        } else {
          t = add(t, duration)
        }

        i += 1
        if (i > 10000) {
          console.warn('Very high number of iterations during adding ticks')
          break
        }
      }

      return ticks
    },

    getX(element) {
      if (element) return this.bins.squeeze ? element.squeezedPosition : element.unsqueezedPosition
      return undefined
    },

    checkX(x, x1, x2) {
      if (x === undefined) return false
      return x1 <= x && x <= x2
    },

    binarySearchElement(series, x1, x2) {
      // binary search to find start index
      let resultIdx = -1
      let j1 = 0
      let j2 = series.data.length - 1
      const updateRange = () => {
        return Math.floor((j1 + j2) / 2)
      }
      let x, idx
      let i = 0

      // eslint-disable-next-line no-constant-condition
      while (true) {
        idx = updateRange()
        x = this.getX(series.data[idx])
        if (idx === j1 && j2 === j1 + 1) {
          if (this.checkX(x, x1, x2)) {
            resultIdx = idx
          } else if (this.checkX(this.getX(series.data[j2]), x1, x2)) {
            resultIdx = j2
          }
          break
        }

        if (x < x1) {
          // continue search right
          j1 = idx
        } else if (x > x2) {
          // continue search left
          j2 = idx
        } else {
          resultIdx = idx
          break
        }

        if (idx === j1 && j1 === j2) {
          break
        }

        i += 1
        if (i > 10000) {
          console.warn(`Very high number of iterations during binary search ${j1} ${j2} ${idx}`)
        }
        if (i > 10010) {
          break
        }
      }
      return resultIdx
    },

    render(series) {
      if (this.renderInProgress === undefined) {
        this.renderInProgress = false
      }

      if (this.renderInProgress) {
        return
      }

      this.renderInProgress = true
      let binIdx = 0
      let maxViewableBins = this.getViewableBins(series.zoom.x)

      let updateFunction = (fn) => fn()

      // if (series.width === '0') {
      //   this.updateCanvas()
      //   updateFunction = this.$nextTick
      // }

      updateFunction(() => {
        const canvas = this.$refs['main-canvas']
        if (canvas && canvas.getContext) {
          const ctx = canvas.getContext('2d')

          const scale = window.devicePixelRatio
          ctx.clearRect(0, 0, canvas.width, canvas.height)

          ctx.strokeStyle = '#ced4da'
          ctx.beginPath()
          ctx.moveTo(0, series.paddingTop * scale)
          ctx.lineTo(canvas.width, series.paddingTop * scale)
          ctx.stroke()

          ctx.beginPath()
          ctx.moveTo(0, (series.paddingTop + series.height) * scale)
          ctx.lineTo(canvas.width, (series.paddingTop + series.height) * scale)
          ctx.stroke()

          if (series.data.length > 0) {
            const x1 = this.bins.timelinePos - 2 * this.bins.width
            const x2 = this.bins.timelinePos + (2 + maxViewableBins) * this.bins.width

            // const dx = x2 - x1

            const x1z = (x1 - this.bins.timelinePos) * series.zoom.x
            const x2z = (x2 - this.bins.timelinePos) * series.zoom.x

            if (series.firstVisibleElementIdx === undefined) {
              series.firstVisibleElementIdx = 0
            }

            if (series.lastVisibleElementIdx === undefined) {
              series.lastVisibleElementIdx = 0
            }

            const getX = (element) => {
              if (element)
                return this.bins.squeeze ? element.squeezedPosition : element.unsqueezedPosition
              return undefined
            }
            const checkX = (x) => {
              if (x === undefined) return false
              return x1 <= x && x <= x2
            }
            const checkElement = (element) => checkX(getX(element))

            let start = -1
            for (let i = series.firstVisibleElementIdx; i <= series.lastVisibleElementIdx; i++) {
              if (checkElement(series.data[i])) {
                start = i
                break
              }
            }

            if (start === -1) {
              // binary search to find start index
              start = this.binarySearchElement(series, x1, x2)
            }

            if (start === -1) {
              // there are no elements to visualize
            } else {
              const updateBin = (element) => {
                // draw rectangles
                let x =
                  (getX(element) - this.bins.timelinePos - 0.5 * this.bins.width) *
                  series.zoom.x *
                  scale
                let width = this.bins.width * series.zoom.x * scale
                let padding = this.bins.padding * series.zoom.x * scale
                let binHeight =
                  Math.min(
                    Math.max(series.zoom.y * element.totalCount, this.bins.minHeight),
                    series.height
                  ) * scale
                let y = (series.paddingTop + series.height) * scale - binHeight

                if (
                  this.indicator.show &&
                  this.indicator.x * scale >= x &&
                  this.indicator.x * scale <= x + width
                ) {
                  ctx.globalAlpha = 0.8
                }

                if (!this.groupByClass) {
                  ctx.fillStyle = series.binColor
                  ctx.fillRect(x + padding / 2, y, width - padding, binHeight)
                } else {
                  let segmentSums = []
                  let segmentSum = 0
                  for (let i = element.classCounts.length - 1; i >= 0; i--) {
                    const value = element.classCounts[i]
                    segmentSum += value
                    segmentSums.push(segmentSum)
                  }
                  for (let i = 0; i < element.classCounts.length; i++) {
                    const value = element.classCounts[i]
                    const classId = series.classLabels[i]
                    ctx.fillStyle = getColorForCSS(classId)
                    const segmentSum = segmentSums[element.classCounts.length - 1 - i]
                    let segmentHeight = this.getBinHeight(value, series) * scale
                    let segmentY1 =
                      (series.paddingTop + series.height - this.getBinHeight(segmentSum, series)) *
                      scale
                    const overlap = series.paddingTop * scale - segmentY1
                    if (overlap > 0) {
                      segmentY1 = series.paddingTop * scale
                      segmentHeight = Math.max(0.0, segmentHeight - overlap)
                    }
                    ctx.fillRect(x + padding / 2, segmentY1, width - padding, segmentHeight)
                  }
                }

                ctx.globalAlpha = 1.0

                if (this.bins.squeeze && element.gap > 0) {
                  // draw gap line
                  const gapX = x - 0.5 * this.bins.gapWidth * series.zoom.x * scale
                  const gapColor = '#6c757d'
                  ctx.strokeStyle = gapColor
                  ctx.setLineDash([2, 3])
                  ctx.beginPath()
                  ctx.moveTo(gapX, series.paddingTop * scale)
                  ctx.lineTo(gapX, (series.paddingTop + series.height) * scale)
                  ctx.stroke()
                  ctx.setLineDash([0, 0])

                  if (series.zoom.x >= 0.75) {
                    // draw gap text
                    const gapText = formatDistanceStrict(new Date(0), new Date(element.gap))
                    const gapTextW = ctx.measureText(gapText).width
                    const gapTextX = gapX - 4 * scale
                    const gapTextY = (series.paddingTop + 4) * scale + gapTextW
                    ctx.translate(gapTextX, gapTextY)
                    ctx.rotate(-0.5 * Math.PI)
                    ctx.font = `${Math.floor(12 * scale)}px ${this.font}`
                    ctx.fillStyle = gapColor
                    ctx.fillText(gapText, 0, 0)
                    ctx.rotate(0.5 * Math.PI)
                    ctx.translate(-gapTextX, -gapTextY)
                  }
                }
              }

              let e = undefined
              // backward update
              for (let j1 = start; j1 >= 0; j1--) {
                e = series.data[j1]
                if (checkElement(e)) {
                  updateBin(e, j1, binIdx++)
                  series.firstVisibleElementIdx = j1
                } else {
                  break
                }
              }

              // forward update
              for (let j2 = start + 1; j2 < series.data.length; j2++) {
                e = series.data[j2]
                if (checkElement(e)) {
                  updateBin(e, j2, binIdx++)
                  series.lastVisibleElementIdx = j2
                } else {
                  break
                }
              }
            }

            // forward iteration to determine chunks (only necessary for squeezed mode)
            let chunks = []
            if (this.bins.squeeze) {
              let chunkBegin
              let chunkEnd
              let lastElement

              for (let i = series.firstVisibleElementIdx; i <= series.lastVisibleElementIdx; i++) {
                const element = series.data[i]
                if (!element) continue
                let x = getX(element)
                let xd = (x - this.bins.timelinePos) * series.zoom.x
                let e = { t: element.timestamp, x: xd }
                if (!chunkBegin) {
                  chunkBegin = e
                }

                if (!lastElement) {
                  lastElement = e
                }
                if (element.gap > 0) {
                  chunkEnd = lastElement
                  chunks.push({
                    x1: chunkBegin.x,
                    t1: chunkBegin.t,
                    x2: chunkEnd.x,
                    t2: chunkEnd.t,
                  })
                  chunkBegin = e
                }
                lastElement = e
              }

              if (chunkBegin && lastElement !== chunkEnd) {
                chunks.push({
                  x1: chunkBegin.x,
                  t1: chunkBegin.t,
                  x2: lastElement.x,
                  t2: lastElement.t,
                })
              }
            }

            // draw ticks
            let tickRounding = this.bins.aggregationWindow
            let tickFormat1 = 'HH:mm'
            let tickFormat2 = 'yyyy-MM-dd'

            if (this.bins.aggregationWindow === 'month') {
              // tickFormat1 = 'MM/yyyy dd'
              tickFormat1 = 'yyyy-MM-dd'
              tickFormat2 = ''
            } else if (this.bins.aggregationWindow === 'day') {
              tickFormat1 = 'yyyy-MM-dd'
              tickFormat2 = ''
            } else if (this.bins.aggregationWindow === 'minute') {
              tickRounding = 'minute'
            } else if (this.bins.aggregationWindow === 'second') {
              tickRounding = 'second'
              tickFormat1 = 'HH:mm:ss'
              tickFormat2 = 'yyyy-MM-dd'
            }

            let skipTicks = 0
            if (series.zoom.x < 0.055) {
              skipTicks = 119
            } else if (series.zoom.x < 0.11) {
              skipTicks = 59
            } else if (series.zoom.x < 0.225) {
              skipTicks = 29
            } else if (series.zoom.x < 0.45) {
              skipTicks = 14
            } else if (series.zoom.x < 0.9) {
              skipTicks = 9
            } else if (series.zoom.x < 1.75) {
              skipTicks = 4
            } else if (series.zoom.x < 3.5) {
              skipTicks = 1
            }

            // compute ticks
            let ticks = []
            if (this.bins.squeeze) {
              for (let i = 0; i < chunks.length; i++) {
                let chunk = chunks[i]
                let dt = chunk.t2 - chunk.t1
                let dx = chunk.x2 - chunk.x1
                let dxdt = 0
                if (dt !== 0) {
                  dxdt = dx / dt
                }
                if (dt >= 0) {
                  ticks.push(
                    ...this.addTicksBetween(
                      chunk.x1,
                      chunk.t1,
                      dxdt,
                      tickRounding,
                      skipTicks,
                      tickFormat1,
                      tickFormat2,
                      chunk.x1 - 1,
                      chunk.x2 + 1
                    )
                  )
                }
              }
            } else {
              let t1 = (x1 / this.bins.width) * this.bins.milliSecondsPerBin + this.bins.timeMin
              let t2 = (x2 / this.bins.width) * this.bins.milliSecondsPerBin + this.bins.timeMin
              let dt = t2 - t1
              let dx = x2z - x1z
              if (dx !== 0 || dt !== 0) {
                let dxdt = dx / dt
                ticks = this.addTicksBetween(
                  x1z,
                  t1,
                  dxdt,
                  tickRounding,
                  skipTicks,
                  tickFormat1,
                  tickFormat2,
                  x1z,
                  x2z
                )
              }
            }

            let lastTickX = undefined
            let lastTickWidth = undefined
            for (let i = 0; i < ticks.length; i++) {
              const tick = ticks[i]
              const x = tick.x * scale
              const y1 = (series.paddingTop + series.height) * scale
              const y2 = (series.paddingTop + series.height + 4) * scale

              if (
                lastTickX === undefined ||
                lastTickWidth === undefined ||
                x - lastTickX > lastTickWidth
              ) {
                ctx.strokeStyle = '#6f7c7e'
                ctx.beginPath()
                ctx.moveTo(x, y1)
                ctx.lineTo(x, y2)
                ctx.stroke()

                const tickTextSize = 12
                const line1width = ctx.measureText(tick.line1).width
                const line2width = ctx.measureText(tick.line2).width

                ctx.font = `${Math.floor(tickTextSize * scale)}px ${this.font}`
                ctx.fillStyle = '#000000'
                ctx.fillText(
                  tick.line1,
                  x - 0.5 * line1width,
                  y2 + (0.5 * tickTextSize + 10) * scale
                )
                ctx.fillText(
                  tick.line2,
                  x - 0.5 * line2width,
                  y2 + (0.5 * tickTextSize + 26) * scale
                )

                lastTickX = x
                if (this.bins.squeeze) {
                  lastTickWidth = Math.max(line1width, line2width) + 3 * scale
                  if (x <= 0) {
                    lastTickWidth = 0.5 * lastTickWidth
                    // reduce last tick width if x <= 0 because there is an issue with the lastTickWidth in case
                    // the text is outside the visible range (the result of measureText is too large)
                  }
                } else {
                  lastTickWidth = 0
                }
              }
            }
          }

          // draw indicator line
          if (this.indicator.show) {
            ctx.strokeStyle = '#6f7c7e'
            ctx.beginPath()
            ctx.moveTo(this.indicator.x * scale, 0)
            ctx.lineTo(
              this.indicator.x * scale,
              (series.paddingTop + series.height + series.paddingBottom) * scale
            )
            ctx.stroke()
          }

          // draw indicator text
          const indicatorTextSize = 16
          ctx.font = `${Math.floor(indicatorTextSize * scale)}px ${this.font}`
          const indicatorDate = this.indicator.date
          const indicatorText = this.indicator.text
          const xRight = (this.indicator.x + 5) * scale
          const y = (8 + 0.5 * indicatorTextSize) * scale
          const xLeft = (this.indicator.x - 5) * scale - ctx.measureText(indicatorText).width
          ctx.fillStyle = '#000000'
          ctx.fillText(indicatorDate, xRight, y)
          ctx.fillText(indicatorText, xLeft, y)

          this.renderInProgress = false
        }
      })
    },

    changeZoomX(value) {
      let oldZoom = this.mainTimeSeries.zoom.x
      let newZoom = Math.min(Math.max(value, this.minZoom.x), this.maxZoom.x)
      this.mainTimeSeries.zoom.x = newZoom
      const svgRect = this.$refs['main-canvas-wrapper'].getBoundingClientRect()
      let x = 0.5 * (svgRect.left + svgRect.right)
      this.bins.timelinePos += x / oldZoom - x / newZoom

      this.render(this.mainTimeSeries)
    },

    changeBinResolution(resolution, requestedTimestamp) {
      if (resolution === 'second') {
        this.bins.milliSecondsPerBin = 1000
        this.mainTimeSeries.binColor = '#a3d1f5'
      } else if (resolution === 'minute') {
        this.bins.milliSecondsPerBin = 60000
        this.mainTimeSeries.binColor = '#8ab5d7'
      } else if (resolution === 'hour') {
        this.bins.milliSecondsPerBin = 3600000
        this.mainTimeSeries.binColor = '#5d93bf'
      } else if (resolution === 'day') {
        this.bins.milliSecondsPerBin = 86400000
        this.mainTimeSeries.binColor = '#286794'
      } else if (resolution === 'month') {
        this.bins.milliSecondsPerBin = 30 * 86400000
        this.mainTimeSeries.binColor = '#1b4364'
      }
      this.bins.aggregationWindow = resolution
      this.getMainTimeSeriesData(requestedTimestamp)
    },

    showBinLabel(y, element) {
      if (element === undefined) {
        this.hideBinLabel()
      } else {
        if (this.bins.aggregationWindow === 'month') {
          this.indicator.date = formatTimestamp(element.timestamp, this.timezone, 'MM/yyyy')
        } else if (this.bins.aggregationWindow === 'day') {
          this.indicator.date = formatTimestamp(element.timestamp, this.timezone, 'yyyy-MM-dd')
        } else {
          this.indicator.date = formatTimestamp(
            element.timestamp,
            this.timezone,
            'yyyy-MM-dd HH:mm:ss'
          )
        }

        let displayValue = element.totalCount
        let displayQuantity = `${this.timelineQuantity}`
        if (this.groupByClass) {
          let segmentSums = []
          let segmentSum = 0
          for (let i = element.classCounts.length - 1; i >= 0; i--) {
            const value = element.classCounts[i]
            segmentSum += value
            segmentSums.push(segmentSum)
          }

          for (let i = 0; i < element.classCounts.length; i++) {
            const value = element.classCounts[i]
            const classId = this.mainTimeSeries.classLabels[i]

            const segmentSum = segmentSums[element.classCounts.length - 1 - i]
            const segmentHeight = this.getBinHeight(value, this.mainTimeSeries)
            const segmentY =
              this.mainTimeSeries.paddingTop +
              this.mainTimeSeries.height -
              this.getBinHeight(segmentSum, this.mainTimeSeries)

            if (y >= segmentY && y <= segmentY + segmentHeight) {
              let label = this.classLabelMap ? this.classLabelMap[classId].name : classId
              label = label ? label : classId
              displayValue = value
              displayQuantity = label
              break
            }
          }
        }
        this.indicator.text = `${displayQuantity}: ${displayValue}`
      }
    },

    hideBinLabel() {
      this.indicator.text = ''
      this.indicator.date = ''
      this.indicator.time = ''
    },

    doubleClickCanvas(event) {
      this.doubleClickBin(event)
    },

    contextMenuOpen(event) {
      // eslint-disable-next-line no-unused-vars
      let [x, y] = this.getCanvasPosition(event)
      const elementIdx = this.findElementAt(x)

      if (elementIdx !== -1) {
        const element = this.mainTimeSeries.data[elementIdx]

        const contextMenu = {
          show: true,
          x: event.clientX,
          y: event.clientY,
          entries: [
            {
              title: 'filter',
              click: () => {
                this.dateTimeFrom = utcToZonedTime(new Date(element.timestamp), this.timezone)
                // set dateTimeTo to the end of the selected bin
                let duration = {}
                duration[`${this.bins.aggregationWindow}s`] = 1
                this.dateTimeTo = utcToZonedTime(add(element.timestamp, duration), this.timezone)
                this.emitDateRangeChange()
              },
            },
            {
              title: 'filter from here',
              click: () => {
                // set dateTimeTo to the beginning of the selected bin
                this.dateTimeFrom = utcToZonedTime(new Date(element.timestamp), this.timezone)
                if (this.dateTimeTo < this.dateTimeFrom) {
                  this.dateTimeTo = null
                }
                this.emitDateRangeChange()
              },
            },
            {
              title: 'filter up to here',
              click: () => {
                this.dateTimeTo = utcToZonedTime(new Date(element.timestamp), this.timezone)
                if (this.dateTimeFrom > this.dateTimeTo) {
                  this.dateTimeFrom = null
                }
                this.emitDateRangeChange()
              },
            },
          ],
        }
        this.$root.$emit('open-context-menu', contextMenu)
      }
      event.preventDefault()
    },

    findElementAt(x) {
      x = x / this.mainTimeSeries.zoom.x
      return this.binarySearchElement(
        this.mainTimeSeries,
        this.bins.timelinePos + x - 0.5 * this.bins.width,
        this.bins.timelinePos + x + 0.5 * this.bins.width
      )
    },

    doubleClickBin(event) {
      // eslint-disable-next-line no-unused-vars
      let [x, y] = this.getCanvasPosition(event)
      const elementIdx = this.findElementAt(x)
      let element
      if (elementIdx !== -1) {
        element = this.mainTimeSeries.data[elementIdx]
      }

      let t
      if (element !== undefined) {
        t = element.timestamp
      }

      if (shiftPressed(event)) {
        for (let j = 0; j < this.binResolutionOptions.length - 1; j++) {
          if (this.bins.aggregationWindow === this.binResolutionOptions[j].value) {
            this.changeBinResolution(this.binResolutionOptions[j + 1].value, t)
            break
          }
        }
      } else {
        for (let j = 1; j < this.binResolutionOptions.length; j++) {
          if (this.bins.aggregationWindow === this.binResolutionOptions[j].value) {
            this.changeBinResolution(this.binResolutionOptions[j - 1].value, t)
            break
          }
        }
      }
    },

    getCanvasPosition(event) {
      const canvas = this.$refs['main-canvas']
      const rect = canvas.getBoundingClientRect()
      return [event.clientX - rect.left, event.clientY - rect.top]
    },

    updateIndicator(event) {
      let [x, y] = this.getCanvasPosition(event)
      const elementIdx = this.findElementAt(x)
      this.indicator.x = x
      if (elementIdx !== -1) {
        this.showBinLabel(y, this.mainTimeSeries.data[elementIdx])
      } else {
        this.hideBinLabel()
      }
      this.render(this.mainTimeSeries)
    },

    startDragging(event) {
      this.dragging.active = true
      this.dragging.startPos = event.clientX
      this.dragging.lastPos = event.clientX
    },

    stopDragging() {
      this.dragging.active = false
      this.dragging.startPos = 0
      this.dragging.lastPos = 0
    },

    checkLoadPreviousOrNext() {
      const maxRange = this.getMaxViewableBins() * this.bins.width
      this.dragPrevious = false
      if (this.mainTimeSeries.hasPrevious) {
        let firstElem = this.mainTimeSeries.firstElement
        let x = this.bins.squeeze ? firstElem.squeezedPosition : firstElem.unsqueezedPosition
        let prevX = x + maxRange
        if (this.bins.timelinePos < prevX) {
          this.dragPrevious = true
          this.getMainTimeSeriesData(undefined, true, false)
        }
      }

      this.dragNext = false
      if (this.mainTimeSeries.hasNext) {
        let lastElem = this.mainTimeSeries.lastElement
        let x = this.bins.squeeze ? lastElem.squeezedPosition : lastElem.unsqueezedPosition
        let nextX = x - 2 * maxRange
        // this.nextX = (nextX - this.bins.timelinePos - 0.5 * this.bins.width) * this.mainTimeSeries.zoom.x
        if (this.bins.timelinePos > nextX) {
          // if (this.bins.timelinePos > x - 2 * maxRange) {
          this.dragNext = true
          this.getMainTimeSeriesData(undefined, false, true)
        }
      }
    },

    updateDragging(event) {
      if (this.dragging.active) {
        this.bins.timelinePos +=
          (this.dragging.lastPos - event.clientX) / this.mainTimeSeries.zoom.x
        this.dragging.lastPos = event.clientX

        this.checkLoadPreviousOrNext()

        this.$nextTick(() => {
          this.render(this.mainTimeSeries)
        })
      }
    },

    isGroupingAvailable() {
      return (
        (this.dataset.object_detection && this.timelineQuantity === 'object') ||
        (this.dataset.classification && this.timelineQuantity === 'item')
      )
    },

    switchTimelineQuantity(quantity) {
      this.timelineQuantity = quantity
      if (!this.isGroupingAvailable()) {
        this.groupByClass = false
      }
      this.getMainTimeSeriesData()
    },

    mouseEnterMainSeries() {
      this.indicator.show = true
    },

    mouseLeaveMainSeries(event) {
      this.indicator.show = false
      this.stopDragging(event)
    },
    mouseMoveMainSeries(event) {
      this.updateDragging(event)
      this.updateIndicator(event)
    },

    mouseDownMainSeries(event) {
      if (event.which !== 1) return

      // eslint-disable-next-line no-unused-vars
      let [x, y] = this.getCanvasPosition(event)
      if (y < this.mainTimeSeries.paddingTop + this.mainTimeSeries.height) {
        this.startDragging(event)
      }
    },
    mouseUpMainSeries(event) {
      this.stopDragging(event)
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../custom';

.main-time-series-wrapper {
  position: relative;
  border: 1px solid $gray-400;
  border-radius: 0.2rem;
}

.main-canvas {
}

.main-time-series-svg {
  pointer-events: all;
}

.dragging {
  cursor: grabbing;
}

.no-data-overlay {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
</style>
