<template>
  <div class="pl-2 pr-2 pt-0 pb-2">
    <model-entry :model="source" v-on="$listeners" />
  </div>
</template>

<script>
import ModelEntry from './ModelEntry'

export default {
  name: 'ModelListEntry',
  components: { ModelEntry },
  props: {
    source: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {},
  methods: {},
}
</script>

<style lang="scss" scoped></style>
