<template>
  <div class="full-height-wrapper">
    <div class="h-100 mh-100 scroll-box-always">
      <div class="container h-100 mh-100 d-flex flex-column">
        <loading-overlay v-if="classLabelsLoading" />
        <div class="mt-3 p-3">
          <h2>Class Labels</h2>
          <div class="col-12 pl-3 pr-3 pt-3 pl-md-5 pr-md-5 text-center">
            <input
              v-model="searchString"
              type="text"
              class="form-control"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search"
              @keydown="onSearchKeyDown"
            />
          </div>
        </div>
        <div class="p-3">
          <div v-if="createClassLabelAvailable" class="pl-3 pr-3 pb-4 text-dark text-center">
            <div class="pl-5 pr-5 pb-3">
              You can use <b>CTRL + ENTER</b> to create a new class label:
            </div>
            <class-label-entry
              :class-label="{ id: 0, name: searchString }"
              @click="onClickCreateClassLabel"
            />
          </div>
          <div class="label-grid border-radius-md w-100">
            <div
              v-for="classLabel in filteredClassLabels"
              :key="`class-label-${classLabel.id}`"
              class="label-grid-item"
            >
              <class-label-entry
                :class-label="classLabel"
                :no-hover="true"
                :dropdown="true"
                @click-delete="onClickDeleteClassLabel(classLabel)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-delete-class-label"
      title="Delete Class Label"
      ok-variant="danger"
      ok-title="Delete"
      @ok="onOkDeleteClassLabel"
      size="lg"
    >
      <b-alert show variant="danger">
        <fai icon="exclamation-circle" size="lg" class="text-danger mr-3" />
        <span class="font-weight-700">CAUTION</span>
        <hr />
        You should only delete class labels that are <b>not in use</b> in any dataset or AI model.
        Existing annotations and inference results cannot be used anymore and
        <b>cannot be be recovered</b>!
      </b-alert>
      <span v-if="classLabelForDeletion" class="p-3">
        Are you sure you want to delete the class label "{{ classLabelForDeletion.name }}"?
      </span>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ClassLabelEntry from '@/components/class-labels/ClassLabelEntry'
import LoadingOverlay from '@/components/LoadingOverlay'
import { compareTwoStrings } from 'string-similarity'
import _ from 'lodash'
import { ctrlOrCmdPressed } from '@/keyboardShortcuts'

export default {
  name: 'ClassLabelsView',
  components: { LoadingOverlay, ClassLabelEntry },
  data() {
    return {
      centerLoading: false,
      searchString: '',
      classLabelForDeletion: undefined,
    }
  },

  computed: {
    ...mapState(['classLabels', 'classLabelsLoading']),
    filteredClassLabels() {
      const s = this.searchString
      let result = []
      if (s.length === 0) {
        return this.classLabels
      } else if (s.length === 1) {
        result = this.classLabels.filter((d) => {
          return d.name.toLowerCase().startsWith(s.toLowerCase())
        })
      } else {
        result = this.classLabels.filter((d) => {
          return d.name.toLowerCase().includes(s.toLowerCase())
        })
      }

      if (s.length > 1) {
        result.sort((a, b) => {
          return compareTwoStrings(s, b.name) - compareTwoStrings(s, a.name)
        })
      }
      return result
    },
    createClassLabelAvailable() {
      const found = _.find(this.classLabels, ['name', this.searchString]) !== undefined
      return this.searchString.length > 0 && !found
    },
  },

  watch: {},

  created() {},

  mounted() {},

  methods: {
    ...mapActions(['addClassLabel', 'deleteClassLabel']),
    onClickCreateClassLabel() {
      if (this.createClassLabelAvailable) {
        this.createClassLabel()
      }
    },
    onOkDeleteClassLabel(evt) {
      evt.preventDefault()
      this.deleteClassLabel({ classLabelId: this.classLabelForDeletion.id })
        .then(() => {
          this.$bvModal.hide('modal-delete-class-label')
          this.classLabelForDeletion = undefined
        })
        .catch(() => {
          this.$bvToast.toast('Could not delete class label.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
        })
    },
    onClickDeleteClassLabel(classLabel) {
      this.classLabelForDeletion = classLabel
      this.$bvModal.show('modal-delete-class-label')
    },
    onSearchKeyDown(evt) {
      if (evt.keyCode === 13) {
        // enter
        evt.preventDefault()
        if (ctrlOrCmdPressed(evt)) {
          if (this.createClassLabelAvailable) {
            this.createClassLabel()
          }
        }
      }
    },
    async createClassLabel() {
      if (this.searchString.length === 0) {
        this.$bvToast.toast('Class labels must be at least one characters long.', {
          title: 'Class Label',
          variant: 'warning',
          autoHideDelay: 3000,
          solid: true,
        })
      } else {
        try {
          // dispatch action to create the new class label
          await this.addClassLabel({ name: this.searchString })
        } catch (e) {
          console.error(e)
          this.$bvToast.toast('Error during class label creation.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '../custom';

.label-grid {
  display: grid;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  align-items: start;
}

.label-grid-item-group {
  grid-column: 1/-1;
  padding-top: 0.25em;
}

.label-grid-item {
  margin-bottom: 0.25em;
}
</style>
