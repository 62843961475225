<template>
  <div class="container">
    <div class="row mt-2">
      <div class="col-12 pt-3">
        <h3>Jobs</h3>
        <div v-if="anyJob" class="table-responsive-sm">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Job ID</th>
                <th scope="col">Model ID</th>
                <th scope="col">Worker ID</th>
                <th scope="col">Max. iterations</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              <template v-for="worker in workers">
                <template v-if="worker.jobs">
                  <tr v-for="job in worker.jobs" :key="`worker-${worker.id}-job-${job.id}`">
                    <td>{{ job.id }}</td>
                    <td>{{ job.model }}</td>
                    <td>{{ worker.id }}</td>
                    <td>{{ job.max_iterations }}</td>
                    <td class="text-right">
                      <!--                  <b-button variant="info" size="sm" class="mr-1">model upload-->
                      <!--                    <fai icon="upload"/>-->
                      <!--                  </b-button>-->
                      <b-button
                        :to="{ path: 'model-explorer', query: { model: job.model } }"
                        variant="outline-primary"
                        size="sm"
                        class="mr-1"
                      >
                        open model
                        <!--                    <fai icon="info-circle"/>-->
                      </b-button>
                      <b-button
                        variant="outline-danger"
                        size="sm"
                        class="mr-1"
                        @click="onDeleteJob(worker, job.id)"
                      >
                        <fai icon="trash-alt" />
                      </b-button>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
        <div v-else>No jobs scheduled.</div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import _ from 'lodash'

export default {
  name: 'JobExplorer',
  components: {},
  data() {
    return {
      centerLoading: false,
      workers: [],
    }
  },

  computed: {
    anyJob: function () {
      for (let i = 0; i < this.workers.length; i++) {
        if (this.workers[i].jobs.length > 0) {
          return true
        }
      }

      return false
    },
  },

  created: function () {},

  mounted() {
    this.loadWorkers()
  },

  methods: {
    loadWorkers() {
      let workers = []
      this.$axios
        .get('/api/workers')
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            let worker = response.data[i]
            worker.jobs = []
            workers.push(worker)

            this.$axios
              .get('/api/workers/' + worker.id + '/jobs')
              .then((response) => {
                // this.$set(worker, 'jobs', response.data);
                worker.jobs = response.data
              })
              .catch((error) => {
                console.log(error)
              })
          }
        })
        .catch(() => {
          this.$bvToast.toast('Could not load list of workers.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
        })
        .finally(() => {
          console.log(JSON.stringify(workers))
          this.workers = workers
        })
    },

    onDeleteJob(worker, jobId) {
      this.$bvModal
        .msgBoxConfirm('Do you want to stop and remove this training job?', {
          title: 'Stop training?',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'remove',
          cancelTitle: 'cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            // confirmation
            this.$axios({
              method: 'delete',
              url: '/api/jobs/' + jobId,
              withCredentials: true,
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': Cookie.get('csrftoken'),
              },
            })
              .then(() => {
                worker.jobs = _.filter(worker.jobs, function (job) {
                  return job.id != jobId
                })

                this.$bvToast.toast('Training job removed.', {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 4000,
                  solid: true,
                })
              })
              .catch(() => {
                this.$bvToast.toast('Could not remove job for model training.', {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 4000,
                  solid: true,
                })
              })
          } else {
            // cancel
          }
        })
        .catch(() => {
          // An error occurred
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
  min-height: calc(100vh - 70px);
}
</style>
