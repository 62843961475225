<template>
  <div
    ref="item"
    :style="{
      width: `${(itemSize * 100).toFixed(4)}%`,
      padding: `${(itemSize * 0.5 * paddingFactor).toFixed(4)}em`,
    }"
    class="item"
  >
    <div
      :class="{ selected: datasetItem.selected, highlighted: highlighted, 'hover-area': hoverable }"
      :style="{ padding: `${(padding + itemSize * 0.5).toFixed(4)}em` }"
      @click="onClickItem(datasetItem, $event)"
      @click.middle="onClickMiddleItem(datasetItem, $event)"
    >
      <div style="display: none">
        -->
        <!--            <img v-if="datasetItem.thumbnail" :ref="`image-${datasetItem.id}`" :src="datasetItem.thumbnail"-->
        <div style="display: none">
          <img ref="imagePlaceholder" src="@/assets/dataset-explorer/image-placeholder.png" />
          <img
            ref="imagePlaceholderError"
            src="@/assets/dataset-explorer/image-placeholder-error.png"
          />
        </div>

        <img
          ref="itemImage"
          :src="datasetItem.thumbnail"
          @error="drawPlaceholder(true)"
          @load="drawThumbnail"
        />
        <!--            <img v-else src="@/assets/dataset-explorer/image-placeholder.png" :ref="'image'+datasetItem.id">-->
      </div>
      <div class="timestamp-wrapper">
        <span v-if="showTimestamp" class="timestamp text-monospace">{{
          formatCreatedDate(datasetItem.created_date)
        }}</span>
      </div>
      <div class="image-overlay">
        <div
          class="d-flex flex-column justify-content-start align-items-start pl-1 pt-1 pr-1 pb-1 h-100"
        >
          <div
            v-if="isClassificationCorrect !== undefined"
            :class="{ 'bg-white': isClassificationCorrect, 'bg-danger': !isClassificationCorrect }"
            class="d-flex align-items-center p-1 border-radius-xl shadow-md mb-2 d-flex"
          >
            <fai
              v-if="isClassificationCorrect"
              class="text-success font-size-x-large"
              icon="check-circle"
            />
            <fai
              v-else-if="isClassificationCorrect === false"
              class="text-white font-size-x-large"
              icon="times-circle"
            />
            <fai v-else class="text-secondary font-size-x-large" icon="minus-circle" />

            <!--              <template-->
            <!--                v-if="showTrueLabels && datasetItem.annotations && datasetItem.annotations.hasOwnProperty('classes')"-->
            <!--              >-->
            <!--                <span-->
            <!--                  v-for="classId in datasetItem.annotations.classes"-->
            <!--                  :id="`true-class-badge-${classId}`"-->
            <!--                  :style="getClassStyle(classId)"-->
            <!--                  class="item-badge-x-small image-class-badge mt-1 mr-1"-->
            <!--                >-->
            <!--                  {{ classLabelMap[classId] !== undefined ? classLabelMap[classId].name : '?' }}-->
            <!--                </span>-->
            <!--              </template>-->
            <span
              v-if="showLabelsInOverlay && showPredictedLabels"
              :class="{ 'text-white': !isClassificationCorrect }"
              :style="isClassificationCorrect ? getClassStyle(predictedClass.classId) : ''"
              class="item-badge-x-small image-class-badge align-self-center ml-2"
            >
              {{
                classLabelMap[predictedClass.classId] !== undefined
                  ? classLabelMap[predictedClass.classId].name
                  : '?'
              }}
              {{ predictedClass.confidence.toFixed(4) }}
            </span>
          </div>
          <div
            v-if="
              showLabelsInOverlay &&
              ((showSubsets && datasetItem.data_subsets) ||
                (!isClassificationCorrect &&
                  showTrueLabels &&
                  datasetItem.annotations &&
                  datasetItem.annotations.hasOwnProperty('classes')))
            "
          >
            <div
              class="d-flex flex-wrap align-items-center mr-auto bg-white shadow-md border-radius-xl p-1"
            >
              <!--              <template v-if="showSubsets && datasetItem.data_subsets">-->
              <!--                <subset-badge v-for="subsetId in datasetItem.data_subsets"-->
              <!--                              :key="`subset-badge-${subsetId}`"-->
              <!--                              :data-subsets="dataSubsets" :subset-id="subsetId"-->
              <!--                              class=""></subset-badge>-->
              <!--              </template>-->
              <template
                v-if="
                  !isClassificationCorrect &&
                  showTrueLabels &&
                  datasetItem.annotations &&
                  datasetItem.annotations.hasOwnProperty('classes')
                "
              >
                <fai class="text-secondary font-size-x-large mr-1" icon="check-circle" />
                <span
                  v-for="classId in datasetItem.annotations.classes"
                  :key="`overlay-item-class-${classId}`"
                  :style="getClassStyle(classId)"
                  class="item-badge-x-small image-class-badge"
                >
                  {{ classLabelMap[classId] !== undefined ? classLabelMap[classId].name : '?' }}
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
      <canvas ref="itemCanvas" class="item-canvas border-radius-sm" height="256" width="256" />
      <confidence-histogram
        v-if="showConfidenceHistogram"
        :predictions="predictions"
      ></confidence-histogram>
      <!--      <canvas width="960" height="360" :ref="`canvas-${datasetItem.id}`" class="item-canvas w-100"></canvas>-->
      <div class="d-flex">
        <template v-if="showStatus">
          <span v-if="datasetItem.status === 'uploaded'" class="text-info"
            ><fai class="status-icon mb-1" icon="file-import"
          /></span>
          <span v-if="datasetItem.status === 'annotated'" class="text-info"
            ><fai class="status-icon mb-1" icon="object-group"
          /></span>
          <span v-if="datasetItem.status === 'reviewed'" class="text-info"
            ><fai class="status-icon mb-1" icon="check"
          /></span>
          <span v-if="datasetItem.status === 'ignored'" class="text-info"
            ><fai class="status-icon mb-1" icon="ban"
          /></span>
        </template>
        <span v-if="showFilename" class="filename ml-2">
          {{ getItemName(datasetItem) }}
        </span>
      </div>
      <div class="d-flex flex-wrap">
        <template v-if="showSubsets && datasetItem.data_subsets">
          <subset-badge
            v-for="subsetId in datasetItem.data_subsets"
            :key="`subset-badge-${subsetId}`"
            :data-subsets="dataSubsets"
            :subset-id="subsetId"
          />
        </template>
        <template
          v-if="
            !showLabelsInOverlay &&
            showTrueLabels &&
            datasetItem.annotations &&
            datasetItem.annotations.hasOwnProperty('classes')
          "
        >
          <span
            v-for="classId in datasetItem.annotations.classes"
            :key="`item-class-${classId}`"
            :style="getClassStyle(classId)"
            class="item-badge-x-small image-class-badge mt-1 mr-1"
          >
            {{ classLabelMap[classId] !== undefined ? classLabelMap[classId].name : '?' }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  classColors,
  getClassStyle,
  getColorForCSS,
  getColorRGB,
  getTextColorForCSS,
} from '@/colors'
import { drawItem } from '@/draw'
import { formatTimestamp } from '@/datetime'
import SubsetBadge from '@/components/item-viewer/SubsetBadge'
import { argMax } from '@/utils'
import ConfidenceHistogram from '@/components/item-viewer/ConfidenceHistogram'

export default {
  name: 'ItemTile',
  components: { ConfidenceHistogram, SubsetBadge },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    dataSubsets: {
      type: Object,
      default: () => {},
    },
    timezone: {
      type: String,
      default: 'UTC',
    },
    itemSize: {
      type: Number,
      default: 0.25,
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
    showFilename: {
      type: Boolean,
      default: true,
    },
    showTimestamp: {
      type: Boolean,
      default: true,
    },
    showTrueLabels: {
      type: Boolean,
      default: true,
    },
    showPredictedLabels: {
      type: Boolean,
      default: true,
    },
    showSubsets: {
      type: Boolean,
      default: true,
    },
    showObjects: {
      type: Boolean,
      default: true,
    },
    showLabelsInOverlay: {
      type: Boolean,
      default: false,
    },
    drawGroundTruthDashed: {
      type: Boolean,
      default: false,
    },
    showPredictedObjects: {
      type: Boolean,
      default: true,
    },
    predictedObjectClassIds: {
      type: Array,
      default: () => [],
    },
    showConfidence: {
      type: Boolean,
      default: true,
    },
    showConfidenceHistogram: {
      type: Boolean,
      default: false,
    },
    objectSelectivity: {
      type: Number,
      default: 0.3,
    },
    boxLineWidth: {
      type: Number,
      default: 2,
    },
    padding: {
      type: Number,
      default: 0.375,
    },
    paddingFactor: {
      type: Number,
      default: 1.0,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    hoverable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      classColors: classColors,
    }
  },

  computed: {
    ...mapState({
      classLabelMap: (state) => state.classLabelMap,
    }),
    datasetItem() {
      if ('dataset_item' in this.item) {
        return this.item['dataset_item']
      } else {
        return this.item
      }
    },
    predictions() {
      if (!this.item) {
        return undefined
      }
      return this.item.predictions
    },
    predictedClass() {
      if (this.item.predictions) {
        let classification = this.item.predictions.classification
        if (classification) {
          let confidences = classification.class_confidences
          let classes = classification.classes
          if (confidences && classes) {
            let idx = argMax(confidences)
            return { classId: parseInt(classes[idx]), confidence: confidences[idx] }
          }
        }
      }
      return undefined
    },
    isClassificationCorrect() {
      if (this.item.metrics && this.item.metrics.classification) {
        return this.item.metrics.classification.wrong_labels === 0
      } else if (
        this.predictedClass &&
        this.datasetItem &&
        this.datasetItem.annotations &&
        this.datasetItem.annotations.classes
      ) {
        const trueClasses = this.datasetItem.annotations.classes
        return trueClasses.includes(this.predictedClass.classId)
      }
      return undefined
    },
  },

  watch: {
    showObjects() {
      this.drawThumbnail()
    },
    showConfidence() {
      this.drawThumbnail()
    },
    objectSelectivity() {
      this.drawThumbnail()
    },
    showPredictedObjects() {
      this.drawThumbnail()
    },
    item: {
      deep: true,
      handler: function () {
        this.drawThumbnail()
      },
    },
  },

  created() {},

  mounted() {
    this.$nextTick(() => this.drawThumbnail())
  },

  beforeDestroy() {},

  methods: {
    formatCreatedDate(timestamp) {
      return formatTimestamp(timestamp, this.timezone)
    },
    getSubsetColorForCSS(idx) {
      let color = getColorRGB(idx)
      return `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.25)`
    },

    getClassStyle(idx) {
      return getClassStyle(idx, { alpha: 1.0 })
    },

    getColorForCSS(idx) {
      return getColorForCSS(idx)
    },
    getTextColorForCSS(idx) {
      return getTextColorForCSS(idx)
    },
    onClickItem(item, evt) {
      this.$emit('click', { item: item, evt: evt })
    },
    onClickMiddleItem(item, evt) {
      this.$emit('click-middle', { item: item, evt: evt })
    },
    getItemName: function (item) {
      let itemName = item.name.split('/')
      itemName = itemName[itemName.length - 1]
      return itemName
    },

    drawThumbnail() {
      const item = this.datasetItem
      const predictions = this.item.predictions
      let canvas = this.$refs.itemCanvas
      if (typeof canvas === 'undefined') {
        console.warn(`canvas undefined for ${item.id}`)
        return
      }

      let image = this.$refs.itemImage
      if (image === undefined || image.width === 0 || image.height === 0) {
        this.drawPlaceholder()
      } else {
        let predictedObjectClassIds = []
        if (this.showPredictedObjects) {
          predictedObjectClassIds = this.predictedObjectClassIds
        }

        const confidenceThreshold = this.objectSelectivity
        drawItem(canvas, item, predictions, image, {
          drawGroundTruthObjects: this.showObjects,
          drawGroundTruthDashed: this.drawGroundTruthDashed,
          predictedObjectClassIds: predictedObjectClassIds,
          confidenceThreshold: confidenceThreshold,
          showConfidence: this.showConfidence,
          boxLineWidth: this.boxLineWidth,
        })
      }
    },

    drawPlaceholder(error) {
      const item = this.datasetItem
      let canvas = this.$refs.itemCanvas
      if (typeof canvas === 'undefined') {
        console.warn(`canvas undefined for ${item.id} (draw placeholder)`)
        return
      }

      let image = this.$refs.imagePlaceholder
      if (error) {
        image = this.$refs.imagePlaceholderError
      }

      if (typeof canvas === 'undefined') {
        console.warn(`placeholder is undefined`)
        return
      }
      drawItem(canvas, {}, {}, image, {
        drawGroundTruthObjects: this.showObjects,
        predictedObjectClassIds: [],
        boxLineWidth: this.boxLineWidth,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';

.selection-rectangle-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

#selection-rectangle {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(200, 200, 200, 0.5);
  border: 1px solid #cccccc;
  opacity: 0;
  pointer-events: none;
}

.item-viewer-container {
  flex: 1 1 auto;
  position: relative;
  //position: relative;
  //min-height: 300px;
  //height: 100%;
}

.item {
  position: relative;

  //padding: 0.25em;
  max-width: 100%;

  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  img {
    pointer-events: none;
  }

  .item-canvas {
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .timestamp-wrapper {
    margin: 0;
    padding-left: 0.3em;
    padding-right: 0.3em;
    width: 100%;
    background-color: $gray-300;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .timestamp {
    display: inline-block;
    font-size: smaller;
    font-weight: 500;
    max-width: 100%;
    color: $gray-800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -0.25em;
  }

  .filename {
    display: inline-block;
    font-size: smaller;
    font-weight: 500;
    max-width: 100%;
    color: $gray-800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .status-icon {
    font-size: smaller;
  }

  .selected {
    .filename {
      color: $white;
    }

    .subset-badge {
      color: $white;
    }

    background-color: $primary;
  }

  .selected:hover {
    background-color: $blue-variation !important;
  }

  .highlighted {
    background-color: $blue-variation !important;
  }

  .hover-area {
    //padding: 1em;
    //border: 1px solid $gray-400;
    //background-color: $gray-300;
    border-radius: 6px;
  }

  .hover-area:hover {
    background-color: $gray-300;
    cursor: pointer;
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }
}
</style>
