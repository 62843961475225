<template>
  <div>
    <canvas ref="confHistCanvas" class="w-100 h-100" height="96" width="256" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { classColors } from '@/colors'
import { argMax } from '@/utils'

export default {
  name: 'ConfidenceHistogram',
  components: {},
  props: {
    predictions: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      classColors: classColors,
    }
  },

  computed: {
    ...mapState({
      classLabelMap: (state) => state.classLabelMap,
    }),
    predictedClass() {
      if (this.predictions) {
        let classification = this.predictions.classification
        if (classification) {
          let confidences = classification.class_confidences
          let classes = classification.classes
          if (confidences && classes) {
            let idx = argMax(confidences)
            return { classId: parseInt(classes[idx]), confidence: confidences[idx] }
          }
        }
      }
      return undefined
    },
    // isClassificationCorrect() {
    //   if (this.item.metrics && this.item.metrics.classification) {
    //     return this.item.metrics.classification.wrong_labels === 0
    //   } else if (
    //     this.predictedClass &&
    //     this.datasetItem &&
    //     this.datasetItem.annotations &&
    //     this.datasetItem.annotations.classes
    //   ) {
    //     const trueClasses = this.datasetItem.annotations.classes
    //     return trueClasses.includes(this.predictedClass.classId)
    //   }
    //   return undefined
    // },
  },

  watch: {
    item: {
      deep: true,
      handler: function () {
        this.drawHistogram()
      },
    },
  },

  created() {},

  mounted() {
    this.$nextTick(() => this.drawHistogram())
  },

  beforeDestroy() {},

  methods: {
    drawHistogram() {
      let canvas = this.$refs.confHistCanvas
      if (typeof canvas === 'undefined') {
        console.warn(`canvas undefined (confidence histogram)`)
        return
      }

      if (!canvas.getContext) {
        console.warn(`canvas has no context (confidence histogram)`)
        return
      }

      const predictions = this.predictions
      if (!predictions) return

      const classification = this.predictions.classification
      if (!classification) return

      const confidences = classification.class_confidences
      if (!confidences) return

      const classes = classification.classes
      if (!classes) return

      const ctx = canvas.getContext('2d')
      ctx.fillStyle = '#003153'

      // we pick at most the top 20 classes
      const nClasses = Math.min(confidences.length, 20)
      if (nClasses === 0) return

      const paddingX = 1
      const barWidth = canvas.width / nClasses
      const height = canvas.height

      for (let i = 0; i < nClasses; i++) {
        ctx.fillStyle = `rgba(0, 49, 83, ${1 - (2 * i) / (2 * nClasses)})`
        const conf = confidences[i]
        const x = i * barWidth
        const h = Math.max(1, conf * height)
        const y = height - h
        ctx.fillRect(x + paddingX, y, barWidth - 2 * paddingX, h)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';
</style>
