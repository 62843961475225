<template>
  <b-form-group :label="field.display_name">
    <b-input-group v-for="(v, i) in values" :key="i" class="mb-2">
      <b-form-input :id="$id(field.name)" type="text" plaintext v-model="values[i]"> </b-form-input>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: 'StringArrayParameter',
  props: ['value', 'field'],
  data() {
    return {
      values: [],
    }
  },

  mounted() {
    this.values = this.value
  },

  methods: {},
}
</script>
