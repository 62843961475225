<template>
  <div
    class="class-label d-flex justify-content-between align-items-center no-select"
    :class="{ highlight: highlight, 'no-hover': noHover }"
    :style="getClassStyle(classLabel.id)"
    @click="onClick"
    @mouseenter="showDropdown = true"
    @mouseleave="showDropdown = false"
  >
    <div class="d-flex align-items-center">
      <div :style="getSquareStyle(classLabel.id)" class="class-label-square" />
      <span>{{ classLabel.name }}</span>
    </div>
    <b-dropdown
      v-if="dropdown && showDropdown"
      no-caret
      right
      size="sm"
      variant="link"
      class="class-label-dropdown"
    >
      <template v-slot:button-content>
        <fai class="text-primary" icon="ellipsis-v"></fai>
      </template>
      <b-dropdown-item-button @click.stop="onClickDelete" variant="danger">
        delete
      </b-dropdown-item-button>
    </b-dropdown>
  </div>
</template>

<script>
import { getClassStyle, getColorForCSS } from '@/colors'

export default {
  name: 'ClassLabelEntry',
  props: ['classLabel', 'highlight', 'noHover', 'dropdown'],
  data() {
    return {
      showDropdown: false,
    }
  },
  methods: {
    getClassStyle(idx) {
      return getClassStyle(idx, { alpha: 0.35 })
    },
    getSquareStyle(idx) {
      return {
        'background-color': getColorForCSS(idx, 1.0),
      }
    },
    onClick(evt) {
      this.$emit('click', evt)
    },
    onClickDelete() {
      this.$emit('click-delete')
      // this.$bvModal
      //   .msgBoxConfirm(`Do you want to delete the class label "${this.classLabel.name}"?`, {
      //     title: 'Delete Class Label',
      //     size: 'md',
      //     buttonSize: 'md',
      //     okVariant: 'danger',
      //     okTitle: 'delete',
      //     cancelTitle: 'cancel',
      //     footerClass: 'p-2',
      //     hideHeaderClose: false,
      //     centered: true,
      //   })
      //   .then((value) => {
      //     if (value) {
      //       this.deleteClassLabel({ classLabelId: this.classLabel.id })
      //         .then(() => {})
      //         .catch(() => {
      //           this.$bvToast.toast('Could not delete class label.', {
      //             title: 'Error',
      //             variant: 'danger',
      //             autoHideDelay: 4000,
      //             solid: true,
      //           })
      //         })
      //     } else {
      //       // cancel
      //     }
      //   })
      //   .catch(() => {
      //     // An error occurred
      //   })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../custom';

.class-label {
  padding: 0.5rem;
  border-radius: 5px;
  font-weight: 600;
  min-height: 3rem;

  cursor: pointer;

  &:hover {
    box-shadow: inset 0 0 0 10em rgba(0, 0, 0, 0.1);
    opacity: 1 !important;
  }
}

.no-hover {
  cursor: default !important;
  box-shadow: none !important;
}

.class-label-dropdown {
  @extend .no-btn-outline;
}

.class-label-square {
  display: inline-block;
  width: 12px;
  height: 24px;
  border-radius: 2px;
  margin-right: 0.5rem;
}

.highlight {
  outline: $primary solid 3px;
}
</style>
