import Vue from 'vue'
import store from './store'
import _ from 'lodash'
import posthog from 'posthog-js'

import Router from 'vue-router'
import { analyticsInitialized } from '@/analytics'
import ModelExplorer from './views/ModelExplorer.vue'
import DatasetExplorer from './views/DatasetExplorer.vue'
import JobExplorer from './views/JobExplorer.vue'
import UserProfile from './views/UserProfile.vue'
import OrganizationSettings from './views/OrganizationSettings.vue'
import NotFound from './views/NotFound.vue'
import OrganizationLicense from '@/views/OrganizationLicense'
import WorkersView from '@/views/WorkersView'
import ClassLabelsView from '@/views/ClassLabelsView'

Vue.use(Router)

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`)
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: { name: 'dataset-explorer' },
    },
    {
      path: '/class-labels',
      name: 'class-labels',
      component: ClassLabelsView,
    },
    {
      path: '/dataset-explorer',
      name: 'dataset-explorer',
      component: DatasetExplorer,
    },
    {
      path: '/model-explorer',
      name: 'model-explorer',
      component: ModelExplorer,
    },
    // {
    //   path: '/device-explorer',
    //   name: 'device-explorer',
    //   component: DeviceExplorer,
    // },
    // {
    //   path: '/deployment-explorer',
    //   name: 'deployment-explorer',
    //   component: DeploymentExplorer,
    // },
    // {
    //   path: '/deployment-details',
    //   name: 'deployment-details',
    //   component: DeploymentDetails,
    // },
    // {
    //   path: '/deployment-details/:id',
    //   component: DeploymentDetails,
    // },
    {
      path: '/job-explorer',
      name: 'job-explorer',
      component: JobExplorer,
    },
    {
      path: '/workers',
      name: 'workers',
      component: WorkersView,
    },
    {
      path: '/profile',
      name: 'profile',
      component: UserProfile,
    },
    {
      path: '/organization',
      name: 'organization',
      component: OrganizationSettings,
    },
    {
      path: '/organization-license',
      name: 'organization-license',
      component: OrganizationLicense,
    },
    {
      path: '/register',
      name: 'register',
      component: lazyLoad('RegisterView'),
    },
    {
      path: '/activate-user',
      name: 'activate-user',
      component: lazyLoad('ActivateUserView'),
    },
    {
      path: '/confirm-password-reset',
      name: 'confirm-password-reset',
      component: lazyLoad('ConfirmPasswordResetView'),
    },
    {
      path: '*',
      component: NotFound,
    },
    // {
    //   path: '/login',
    //   name: 'login',
    //   component: Login,
    // },
  ],
})

router.beforeEach((to, from, next) => {
  const forwardRule = function (user) {
    if (
      user !== undefined &&
      user.username !== '' &&
      (_.isEmpty(user.license) || !user.license.valid || !user.organization.license_valid)
    ) {
      // user is logged in but the license is invalid
      if (['profile', 'organization', 'organization-license', 'workers'].includes(to.name)) {
        // the routing targets 'profile', 'organization', and 'organization-license' are ok
        next()
      } else if (user.organization.is_admin) {
        if (!user.organization.license_valid && !user.organization.deactivated) {
          // redirect to the invalid-organization-license page
          next({ name: 'organization-license' })
        } else {
          // redirect to the organization settings page
          next({ name: 'organization' })
        }
      } else {
        // redirect to the profile page
        next({ name: 'profile' })
      }
    } else {
      next()
    }
  }

  let user = store.getters.user
  if (
    user === undefined ||
    (user.username === '' &&
      !['register', 'activate-user', 'confirm-password-reset'].includes(to.name))
  ) {
    store
      .dispatch('getUser')
      .catch(() => {})
      .finally(() => {
        store.dispatch('setLoading', false)
        user = store.getters.user
        forwardRule(user)
      })
  } else {
    forwardRule(user)
  }
})

router.afterEach(() => {
  if (Vue.prototype.$analyticsEnabled && analyticsInitialized) {
    posthog.capture('$pageview')
  }
})

export default router
