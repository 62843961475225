export default {
  loading: true,
  server: {
    enableRegistration: false,
    enableSupport: false,
    enableCloudDeployments: false,
    enableAutomaticAnnotations: false,
    timezones: ['UTC'],
    cloud: false,
    version: '',
  },

  user: {
    id: undefined,
    username: '',
    permissions: [],
    organization: {},
  },
  classLabels: [],
  classLabelsLoading: false,
  classLabelMap: {},
  datasets: [],

  models: [],
  modelsLoading: false,
  modelDetailsLoading: false,
  modelSelection: [],

  modelConfigurationOptions: [],

  jobs: undefined,
  jobsLoading: false,

  workers: [],
  workersLoading: false,

  hideNavbar: false,
  modals: {
    aiModelDetails: {
      show: false,
      model: undefined,
    },
  },
}
