<template>
  <b-modal
    id="modal-start-training"
    ref="modalStartTraining"
    v-model="showModal"
    size="lg"
    title="Start Training"
    @ok="onStartTraining"
  >
    <div>
      <transition name="default-fade" mode="out-in">
        <b-alert variant="danger" :show="trainingAlert !== ''">
          {{ trainingAlert }}
        </b-alert>
      </transition>

      <b-form-group
        label="Iterations"
        label-for="trainingIterations"
        description="Enter the number of iterations to train the model."
      >
        <b-form-input
          id="trainingIterations"
          v-model="iterations"
          type="number"
          :min="minIterations"
          :max="maxIterations"
          required
          placeholder="Iterations"
        />
      </b-form-group>

      <worker-selection v-model="worker" />
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button variant="secondary" @click="cancel()">Cancel</b-button>
      <b-button variant="primary" @click="ok()">
        <b-spinner v-if="okLoading" small></b-spinner>
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import WorkerSelection from '@/components/worker/WorkerSelection'

export default {
  name: 'StartTrainingModal',
  components: { WorkerSelection },
  props: {
    show: Boolean,
    model: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      okLoading: false,
      showModal: false,
      trainingAlert: '',
      iterations: 10000,
      worker: undefined,
    }
  },

  computed: {
    ...mapState(['user', 'workers', 'workersLoading']),
    ...mapGetters(['deleteModelPermission']),
    minIterations() {
      const minIt = 1000
      if (
        this.model &&
        this.model.parameters &&
        this.model.parameters.evaluation &&
        this.model.parameters.evaluation.interval
      ) {
        return Math.max(minIt, this.model.parameters.evaluation.interval)
      } else {
        return minIt
      }
    },
    maxIterations() {
      if (this.user.organization.max_iterations_per_job === -1) {
        return 10000000
      }
      return this.user.organization.max_iterations_per_job
    },
  },

  watch: {
    show(newValue) {
      this.showModal = newValue
    },

    showModal(newValue) {
      this.$emit('show', newValue)
    },
  },

  mounted() {
    this.showModal = this.show
    this.getWorkers({ forceReload: false })
  },

  methods: {
    ...mapActions(['getWorkers', 'createJob']),
    onStartTraining(evt) {
      evt.preventDefault()
      if (this.iterations < this.minIterations) {
        this.trainingAlert = `You need to train at least for ${this.minIterations} iterations.`
      } else if (this.iterations > this.maxIterations) {
        this.trainingAlert = `Your organization is limited to at most ${this.maxIterations} iterations per session.`
      } else if (!this.worker) {
        this.trainingAlert = `You need to select a worker for training.`
      } else {
        this.okLoading = true
        this.trainingAlert = ''
        let job = {
          iterations: this.iterations,
          model_id: this.model.id,
          worker_id: this.worker,
        }
        this.createJob(job)
          .then(() => {
            this.$bvToast.toast('Training job created.', {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 4000,
              solid: true,
            })
            this.showModal = false
            this.okLoading = false
          })
          .catch((error) => {
            let message = 'Could not schedule job for model training: unknown error.'
            if (
              error.response.status === 400 &&
              error.response.data &&
              error.response.data !== '' &&
              error.response.data.error
            ) {
              if (error.response.data.error.includes('maximum number of parallel jobs reached')) {
                message = `Your organization is limited to ${this.user.organization.max_parallel_jobs} parallel training sessions.`
              } else {
                message = `Could not schedule job for model training: ${error.response.data.error}`
              }
            } else if (error.response.status === 403) {
              message = `Could not schedule job for model training: not authorized.`
            }
            this.trainingAlert = message
            this.okLoading = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
