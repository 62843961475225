<template>
  <div>
    <b-modal
      id="modal-ai-model"
      ref="modalAiModel"
      v-model="showModal"
      body-class="fixed-modal-body"
      scrollable
      size="xl"
      title="Create AI Model"
    >
      <div class="container-fluid h-100">
        <overlay-component v-if="error">
          <div class="d-flex flex-column">
            <div class="mb-3">
              {{ error }}
            </div>
            <div class="d-flex justify-content-center">
              <b-button variant="primary" @click="onRetry"> retry</b-button>
            </div>
          </div>
        </overlay-component>
        <loading-overlay v-if="loading" />

        <div class="row h-100">
          <div class="col-3 bg-gray-200 p-3 h-100 scroll-box">
            <b-nav pills vertical>
              <!--              <div class="text-secondary ml-2 mb-2 font-weight-600 no-select">NEURAL NETWORK</div>-->
              <b-nav-item :active="tabIndex === 0" class="wizard-nav-item" @click="tabIndex = 0">
                <fai class="nav-item-icon" icon="adjust" />
                <div class="nav-item-text">Type</div>
                <exclamation-mark v-if="modelTypeValid === false" class="ml-2" />
              </b-nav-item>
              <b-nav-item :active="tabIndex === 1" class="wizard-nav-item" @click="tabIndex = 1">
                <fai class="nav-item-icon" icon="share-alt" />
                <div class="nav-item-text">Network Architecture</div>
                <exclamation-mark v-if="modelArchitectureValid === false" class="ml-2" />
              </b-nav-item>

              <!--              <div class="text-secondary ml-2 mt-3 mb-2 font-weight-600 no-select">DATA</div>-->
              <!--              <b-nav-item class="wizard-nav-item" :active="tabIndex === 2" @click="tabIndex = 2">-->
              <!--                Training Data-->
              <!--                &lt;!&ndash;              <exclamation-mark class="mr-2"></exclamation-mark>&ndash;&gt;-->
              <!--              </b-nav-item>-->
              <b-nav-item :active="tabIndex === 2" class="wizard-nav-item" @click="tabIndex = 2">
                <fai class="nav-item-icon" icon="database" />
                <div class="nav-item-text">Data</div>
              </b-nav-item>
              <b-nav-item :active="tabIndex === 3" class="wizard-nav-item" @click="tabIndex = 3">
                <fai class="nav-item-icon" icon="tags" />
                <div class="nav-item-text">Class Labels</div>
              </b-nav-item>

              <!--              <div class="text-secondary ml-2 mt-3 mb-2 font-weight-600 no-select">ADVANCED</div>-->
              <b-nav-item :active="tabIndex === 4" class="wizard-nav-item" @click="tabIndex = 4">
                <fai class="nav-item-icon" icon="crop-alt" />
                <div class="nav-item-text">Preprocessing</div>
              </b-nav-item>

              <b-nav-item :active="tabIndex === 5" class="wizard-nav-item" @click="tabIndex = 5">
                <fai class="nav-item-icon" icon="clone" />
                <div class="nav-item-text">Augmentations</div>
              </b-nav-item>

              <b-nav-item :active="tabIndex === 6" class="wizard-nav-item" @click="tabIndex = 6">
                <fai class="nav-item-icon" icon="cogs" />
                <div class="nav-item-text">Parameters</div>
              </b-nav-item>

              <div class="horizontal-line mt-3 mb-3" />
              <b-nav-item :active="tabIndex === 7" class="wizard-nav-item" @click="tabIndex = 7">
                <fai class="nav-item-icon" icon="clipboard-list" />
                <div class="nav-item-text">Summary</div>
              </b-nav-item>
            </b-nav>
          </div>

          <div v-show="tabIndex === 0" class="col-9 p-3 h-100 scroll-box">
            <div class="mb-0 font-weight-700">AI Model Type</div>
            <div class="text-dark mb-3">Select the type of AI model you want to create.</div>
            <div class="row pl-2 pr-2">
              <div class="col-12 col-md-6 pl-2 pr-2">
                <selectable-card :value="classificationSelected" @input="selectClassification">
                  <img class="w-100" src="@/assets/dataset-explorer/categories.png" />
                  <div class="pt-2 pl-1 pr-1">
                    <div class="font-weight-600">Image Classification</div>
                    <small>Add class labels to whole images for image classification.</small>
                  </div>
                </selectable-card>
              </div>
              <div class="col-12 col-md-6 pl-2 pr-2">
                <selectable-card :value="detectionSelected" @input="selectDetection">
                  <img class="w-100" src="@/assets/dataset-explorer/bounding_boxes.png" />
                  <div class="pt-2 pl-1 pr-1">
                    <div class="font-weight-600">Object Detection</div>
                    <small
                      >Detection of objects with bounding boxes (with or without rotation).</small
                    >
                  </div>
                </selectable-card>
              </div>
            </div>
          </div>

          <div v-show="tabIndex === 1" class="col-9 p-3 h-100 scroll-box">
            <div class="pb-3">
              <span class="font-weight-700">Deep Neural Network Architecture</span>
              <div class="text-dark d-none d-md-block">
                Select the deep neural network architecture for your model. There is a large choice
                of state-of-the-art networks with individual advantages and disadvantages. Choose
                one of the recommended architectures in case you have no preference.
              </div>
            </div>

            <div v-if="!aiModelType" class="text-dark">
              Network architectures can be selected after the
              <b-link @click="tabIndex = 0"> model type</b-link>
              is selected.
            </div>
            <template v-else>
              <card-collapse
                v-for="(group, i) in architectureGroups"
                :key="`architecture-group-${group.title}`"
                :collapse-id="`collapse-architecture-group-${group.title}`"
                :initially-open="i === 0 || selectedArchitectureInGroup(group)"
                accordion="architecture-groups"
                class="bg-gray-200 mb-2"
                medium
              >
                <template v-slot:header>
                  <div class="font-weight-700">
                    <div v-if="selectedArchitectureInGroup(group)" class="selection-square" />
                    {{ group.title }}
                  </div>
                  <div class="text-dark mt-1">
                    {{ group.description }}
                  </div>
                </template>
                <div class="p-3 d-flex flex-wrap justify-content-start">
                  <div
                    v-for="architecture in group.architectures"
                    :key="`architecture-${group.title}-${architecture.name}`"
                    class="p-2 w-50"
                  >
                    <div
                      :class="{
                        'selected-card':
                          selectedArchitecture && selectedArchitecture.name === architecture.name,
                      }"
                      class="border-radius-sm selection-card cursor-pointer shadow-md d-flex p-3 justify-content-between align-items-center bg-white"
                      @click="onSelectArchitecture(architecture)"
                    >
                      <div>
                        <span class="mr-2">{{ architecture.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </card-collapse>
            </template>
          </div>

          <!--          <div class="col-9 h-100 p-0" v-show="tabIndex === 2">-->
          <!--            <div class="d-flex flex-column h-100">-->
          <!--              <div class="pl-3 pt-3 pr-3">-->
          <!--                <span class="font-weight-700">Training Data Selection</span>-->
          <!--                <div class="text-dark">-->
          <!--                  Deep neural networks are <b>trained</b> on the selected data subsets. A data-->
          <!--                  subset is a part of your dataset. It is recommended, to use one part for training-->
          <!--                  and another independent part for testing.-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="flex-grow-1 position-relative overflow-hidden">-->
          <!--                <div class="h-100">-->
          <!--                  <columnar-data-selection :datasets="datasets"-->
          <!--                                           @selection-changed="selectedTrainData = $event"-->
          <!--                                           @click-create-subset="onClickCreateSubset">-->
          <!--                  </columnar-data-selection>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <div v-show="tabIndex === 2" class="col-9 h-100 p-0">
            <loading-overlay v-if="datasetsLoading" />
            <div class="d-flex flex-column h-100">
              <div class="pl-3 pt-3 pr-3">
                <span class="font-weight-700">Data Selection</span>
                <div class="text-dark d-none d-md-block">
                  Deep neural networks are <b>trained</b> and <b>tested</b> on the selected data
                  subsets. A data subset is a part of your dataset. It is recommended, to use one
                  part for training and another independent part for testing.
                </div>
              </div>
              <div v-if="!aiModelType" class="text-dark p-3">
                You can select the data for training as soon as the
                <b-link @click="tabIndex = 0"> model type</b-link>
                is chosen.
              </div>
              <div v-else class="flex-grow-1 position-relative overflow-hidden p-3">
                <div class="h-50">
                  <columnar-data-selection
                    :datasets="availableDatasets"
                    selected-title="Selected Training Data"
                    @selection-changed="selectedTrainData = $event"
                    @click-create-subset="onClickCreateSubset"
                  />
                </div>
                <div class="h-50 pt-2">
                  <columnar-data-selection
                    :datasets="availableDatasets"
                    selected-title="Selected Test Data"
                    @selection-changed="selectedTestData = $event"
                    @click-create-subset="onClickCreateSubset"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-show="tabIndex === 3" class="col-9 h-100 p-0">
            <div class="d-flex flex-column h-100">
              <div class="pl-3 pt-3 pr-3">
                <span class="font-weight-700">Class Label Selection</span>
                <div class="text-dark d-none d-md-block">
                  Select the classes that the AI should recognize. You can only choose classes that
                  appear in at least one of the selected datasets.
                </div>
              </div>

              <div v-if="!aiModelType && selectedTrainData.length === 0" class="text-dark p-3">
                You can select the class labels for training once the
                <b-link @click="tabIndex = 0"> model type</b-link>
                and the
                <b-link @click="tabIndex = 2"> data</b-link>
                is selected.
              </div>
              <div v-else-if="!aiModelType" class="text-dark p-3">
                You can select the class labels for training once the
                <b-link @click="tabIndex = 0"> model type</b-link>
                is chosen.
              </div>
              <div v-else-if="selectedTrainData.length === 0" class="text-dark p-3">
                You can select the class labels for training as soon as the
                <b-link @click="tabIndex = 2"> data</b-link>
                is selected.
              </div>
              <div v-else class="flex-grow-1 position-relative overflow-hidden p-3">
                <div class="h-100">
                  <columnar-class-label-selection
                    :class-labels="availableClassLabels"
                    select-all-enabled
                    @selection-changed="selectedClassLabels = $event"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-show="tabIndex === 4" class="col-9 p-3 h-100 scroll-box">
            <div class="pb-3">
              <span class="font-weight-700">Preprocessing</span>
              <div class="text-dark d-none d-md-block">
                Select the input dimensions and image channels. For many purposes, very good results
                can be achieved even at low resolutions. A large input resolution increases the
                training and inference time.
              </div>
            </div>

            <div v-if="!aiModelType || !selectedArchitecture" class="text-dark">
              The preprocessing can be specified once the
              <b-link @click="tabIndex = 0"> model type</b-link>
              and the
              <b-link @click="tabIndex = 1"> network architecture</b-link>
              is chosen.
            </div>

            <template v-else>
              <div class="d-flex">
                <div class="w-50 pr-2">
                  <b-form-group label="input width" label-size="sm">
                    <b-form-select
                      id="input-width-field"
                      v-model="dimensions.width"
                      :options="[
                        32, 64, 96, 128, 160, 192, 224, 256, 288, 320, 352, 384, 416, 448, 480, 512,
                        544, 576, 608, 640, 672, 704, 736, 768, 800, 832, 864, 896, 928, 960, 992,
                        1024,
                      ]"
                      size="sm"
                    />
                  </b-form-group>
                </div>
                <div class="w-50 pl-2">
                  <b-form-group label="input height" label-size="sm">
                    <b-form-select
                      id="input-height-field"
                      v-model="dimensions.height"
                      :options="[
                        32, 64, 96, 128, 160, 192, 224, 256, 288, 320, 352, 384, 416, 448, 480, 512,
                        544, 576, 608, 640, 672, 704, 736, 768, 800, 832, 864, 896, 928, 960, 992,
                        1024,
                      ]"
                      size="sm"
                    />
                  </b-form-group>
                </div>
              </div>

              <form-generator
                v-if="selectedArchitecture"
                :key="`${selectedArchitecture.id}-preprocessing`"
                v-model="preprocessing"
                :fields="preprocessingFields"
              />
            </template>
          </div>

          <div v-show="tabIndex === 5" class="col-9 p-3 h-100 scroll-box">
            <div class="pb-3">
              <span class="font-weight-700">Augmentations</span>
              <div class="text-dark d-none d-md-block">
                To enhance the model with respect to perturbations of the input data (i.e., change
                of lighting conditions, varying environment and objects), the training data can be
                augmented with a variety of methods (e.g., change of brightness, scaling and
                cropping the image).
              </div>
            </div>

            <div v-if="!aiModelType || !selectedArchitecture" class="text-dark">
              Augmentations can be selected after the
              <b-link @click="tabIndex = 0"> model type</b-link>
              and the
              <b-link @click="tabIndex = 1"> network architecture</b-link>
              is chosen.
            </div>

            <form-generator
              v-if="selectedArchitecture"
              :key="`${selectedArchitecture.id}-augmentations`"
              v-model="augmentations"
              :fields="augmentationFields"
            />
          </div>

          <div v-show="tabIndex === 6" class="col-9 p-3 h-100 scroll-box">
            <div class="pb-3">
              <span class="font-weight-700">Parameters</span>
              <div class="text-dark d-none d-md-block">
                Change the following parameters with care. The default values suit to most use
                cases.
              </div>
            </div>

            <div v-if="!aiModelType || !selectedArchitecture" class="text-dark">
              Parameters can be changed once the
              <b-link @click="tabIndex = 0"> model type</b-link>
              and the
              <b-link @click="tabIndex = 1"> network architecture</b-link>
              is selected.
            </div>

            <form-generator
              v-if="selectedArchitecture"
              :key="`${selectedArchitecture.id}-parameters`"
              v-model="parameters"
              :fields="parameterFields"
            />
          </div>

          <div v-show="tabIndex === 7" class="col-9 p-3 h-100 scroll-box">
            <div class="pb-3">
              <span class="font-weight-700">Summary</span>
            </div>

            <b-form-group
              description="Enter a descriptive name for the new model."
              label-align-lg="left"
              label-cols-lg="3"
            >
              <template v-slot:label>
                <b-tooltip
                  target="model-name-warn-icon"
                  title="A name for the new model is needed"
                />
                Name
                <fai
                  v-if="!aiModelNameValid"
                  id="model-name-warn-icon"
                  class="text-warning mb-1 mr-1 ml-1"
                  icon="exclamation-triangle"
                />
              </template>
              <b-form-input
                v-model="aiModelName"
                placeholder="Name for the model"
                required
                type="text"
              />
            </b-form-group>

            <b-form-group label-align-lg="left" label-cols-lg="3">
              <template v-slot:label>
                <b-tooltip target="model-type-warn-icon" title="Model type is not selected" />
                AI Model Type
                <b-link
                  v-if="!aiModelTypeValid"
                  class="text-warning mr-1 ml-1"
                  @click="tabIndex = 0"
                >
                  <fai id="model-type-warn-icon" class="mb-1" icon="exclamation-triangle" />
                </b-link>
              </template>
              <b-form-input
                :value="aiModelType"
                placeholder="not selected"
                plaintext
                required
                type="text"
              />
            </b-form-group>

            <b-form-group label-align-lg="left" label-cols-lg="3">
              <template v-slot:label>
                <b-tooltip
                  target="model-architecture-warn-icon"
                  title="There's no network architecture selected"
                />
                Network Architecture

                <b-link
                  v-if="!architectureValid"
                  class="text-warning mr-1 ml-1"
                  @click="tabIndex = 1"
                >
                  <fai id="model-architecture-warn-icon" class="mb-1" icon="exclamation-triangle" />
                </b-link>
              </template>
              <b-form-input
                :value="selectedArchitecture ? selectedArchitecture.name : ''"
                placeholder="not selected"
                plaintext
                type="text"
              />
            </b-form-group>

            <b-form-group label-align-lg="left" label-cols-lg="3">
              <template v-slot:label>
                <b-tooltip
                  target="training-data-warn-icon"
                  title="There's no data selected for training"
                />
                Training Data

                <b-link v-if="!trainDataValid" class="text-warning mr-1 ml-1" @click="tabIndex = 2">
                  <fai id="training-data-warn-icon" class="mb-1" icon="exclamation-triangle" />
                </b-link>
              </template>
              <b-form-input
                :value="
                  `${selectedTrainData.length} ` +
                  (selectedTrainData.length === 1 ? 'dataset' : 'datasets')
                "
                plaintext
                type="text"
              />
            </b-form-group>

            <b-form-group label-align-lg="left" label-cols-lg="3">
              <template v-slot:label>
                <b-tooltip
                  target="test-data-warn-icon"
                  title="There's no data selected for testing"
                />
                Test Data

                <b-link v-if="!testDataValid" class="text-warning mr-1 ml-1" @click="tabIndex = 2">
                  <fai id="test-data-warn-icon" class="mb-1" icon="exclamation-triangle" />
                </b-link>
              </template>
              <b-form-input
                :value="
                  `${selectedTestData.length} ` +
                  (selectedTestData.length === 1 ? 'dataset' : 'datasets')
                "
                plaintext
                type="text"
              />
            </b-form-group>

            <b-form-group label="Classes" label-align-lg="left" label-cols-lg="3">
              <template v-slot:label>
                <b-tooltip target="classes-warn-icon" title="At least one class must be selected" />
                Classes

                <b-link v-if="!classesValid" class="text-warning mr-1 ml-1" @click="tabIndex = 3">
                  <fai id="classes-warn-icon" class="mb-1" icon="exclamation-triangle" />
                </b-link>
              </template>
              <b-form-input
                :value="
                  `${selectedClassLabels.length} ` +
                  (selectedClassLabels.length === 1 ? 'class' : 'classes')
                "
                plaintext
                type="text"
              />
            </b-form-group>

            <b-form-group label-align-lg="left" label-cols-lg="3">
              <template v-slot:label>
                <b-tooltip
                  target="input-dimensions-warn-icon"
                  title="The input dimension must be specified (preprocessing)"
                />
                Input Dimensions

                <b-link
                  v-if="!inputDimensionsValid"
                  class="text-warning mr-1 ml-1"
                  @click="tabIndex = 4"
                >
                  <fai id="input-dimensions-warn-icon" class="mb-1" icon="exclamation-triangle" />
                </b-link>
              </template>
              <b-form-input
                :value="inputDimensions"
                placeholder="not specified"
                plaintext
                type="text"
              />
            </b-form-group>

            <div class="pt-3">
              <span class="font-weight-700">Training</span>
            </div>

            <b-form-group label-align-lg="left" label-cols-lg="3">
              <b-form-checkbox
                id="checkbox-start-training-later"
                v-model="startTrainingLater"
                name="checkbox-start-training-later"
              >
                start training later
              </b-form-checkbox>
            </b-form-group>

            <b-form-group
              description="Enter the number of iterations to train the model."
              label="Iterations"
              label-align-lg="left"
              label-cols-lg="3"
              label-for="training-iterations"
            >
              <template v-slot:label>
                <b-tooltip
                  :title="`More than ${minIterations} iterations must be entered`"
                  target="iterations-warn-icon"
                />
                Iterations
                <fai
                  v-if="!iterationsValid"
                  id="iterations-warn-icon"
                  class="text-warning mb-1 mr-1 ml-1"
                  icon="exclamation-triangle"
                />
              </template>
              <b-form-input
                id="training-iterations"
                v-model="iterations"
                :disabled="startTrainingLater"
                :min="minIterations"
                placeholder="Iterations"
                required
                type="number"
              />
            </b-form-group>

            <worker-selection
              v-model="selectedWorker"
              :valid="workerValid"
              :disabled="startTrainingLater"
              :horizontal="true"
            />
            <!--            <b-form-group-->
            <!--              description="Choose the worker for training the model."-->
            <!--              label-align-lg="left"-->
            <!--              label-cols-lg="3"-->
            <!--            >-->
            <!--              <template v-slot:label>-->
            <!--                <b-tooltip target="worker-warn-icon" title="No worker for training selected" />-->
            <!--                Worker-->
            <!--                <fai-->
            <!--                  v-if="!workerValid"-->
            <!--                  id="worker-warn-icon"-->
            <!--                  class="text-warning mb-1 mr-1 ml-1"-->
            <!--                  icon="exclamation-triangle"-->
            <!--                />-->
            <!--              </template>-->

            <!--              <b-form-select-->
            <!--                id="training-worker"-->
            <!--                v-model="selectedWorker"-->
            <!--                :disabled="startTrainingLater"-->
            <!--                :options="workerOptions"-->
            <!--                required-->
            <!--              />-->
            <!--            </b-form-group>-->
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button class="ml-2" variant="secondary" @click="showModal = false"> Close</b-button>
          <b-button v-if="tabIndex > 0" class="ml-2" variant="primary" @click="tabIndex--">
            Previous
          </b-button>
          <b-button
            v-if="tabIndex === 0 || tabIndex < nTabs - 1"
            :disabled="tabIndex === 0 && nTabs === 1"
            class="ml-2"
            variant="primary"
            @click="tabIndex++"
          >
            Next
          </b-button>
          <b-button
            v-if="saveAvailable"
            :disabled="!saveEnabled"
            class="ml-2"
            variant="primary"
            @click="onClickSave"
          >
            Save
          </b-button>
        </div>
      </template>
    </b-modal>
    <create-subset-modal
      :dataset="createSubsetDataset"
      :show="showCreateSubsetModal"
      prefill
      type="random_split"
      @show="showCreateSubsetModal = $event"
    />
  </div>
</template>

<script>
import CardCollapse from '@/components/CardCollapse'
import ColumnarClassLabelSelection from '@/components/class-labels/ColumnarClassLabelSelection'
import ColumnarDataSelection from '@/components/data/ColumnarDataSelection'
import CreateSubsetModal from '@/components/modals/CreateSubsetModal'
import ExclamationMark from '@/components/ExclamationMark'
import FormGenerator from '@/components/form-generator/FormGenerator'
import LoadingOverlay from '@/components/LoadingOverlay'
import OverlayComponent from '@/components/OverlayComponent'
import SelectableCard from '@/components/selectable-card/SelectableCard'
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'
import WorkerSelection from '@/components/worker/WorkerSelection'

export default {
  name: 'AiModelModal',
  components: {
    WorkerSelection,
    CardCollapse,
    ColumnarClassLabelSelection,
    ColumnarDataSelection,
    CreateSubsetModal,
    ExclamationMark,
    FormGenerator,
    LoadingOverlay,
    OverlayComponent,
    SelectableCard,
  },
  props: {
    show: Boolean,
    workers: Array,
  },
  data() {
    return {
      loading: false,
      datasetsLoading: false,
      error: undefined,

      tabIndex: 0,
      showModal: false,

      showCreateSubsetModal: false,
      createSubsetDataset: undefined,

      classificationSelected: false,
      detectionSelected: false,
      selectedArchitecture: undefined,
      selectedTrainData: [],
      selectedTestData: [],
      selectedClassLabels: [],
      dimensions: {
        width: 320,
        height: 320,
      },
      preprocessing: {},
      augmentations: {},
      parameters: {},
      aiModelName: '',

      startTrainingLater: false,
      iterations: 10000,
      selectedWorker: undefined,
    }
  },

  computed: {
    ...mapState({
      modelConfigurationOptions: (state) => state.modelConfigurationOptions,
      classLabelMap: (state) => state.classLabelMap,
      datasets: (state) => state.datasets,
    }),
    workerOptions() {
      const options = []
      for (const worker of this.workers) {
        options.push({ text: worker.name, value: worker.id })
      }
      return options
    },
    nTabs() {
      let n = 8
      return n
    },
    aiModelType() {
      if (this.classificationSelected) return 'classification'
      if (this.detectionSelected) return 'detection'
      return undefined
    },
    availableNetworks() {
      let options = []
      for (let i = 0; i < this.modelConfigurationOptions.length; i++) {
        const option = this.modelConfigurationOptions[i]
        if (option['type'] === this.aiModelType && option['active']) {
          options.push(option)
        }
      }
      // options.sort((o) => o.name)
      options = _.sortBy(options, ['name'])
      return options
    },
    architectureGroups() {
      let groups = []

      if (this.aiModelType === 'classification') {
        groups.push({
          title: 'Recommended Network Architectures',
          keys: [
            'Classification Fbnetc ',
            'Classification Resnet18',
            'Classification Resnest26d',
            'Classification Resnest50d',
          ],
          exactMatching: true,
          description:
            'For image classification, choose Fbnetc for the fastest inference times for low-power hardware. Deeper Resne(s)t based architectures are best suited for larger datasets and more complex tasks.',
          architectures: [],
        })
      } else if (this.aiModelType === 'detection') {
        groups.push({
          title: 'Recommended Network Architectures',
          keys: ['Detection Dla46 c', 'Detection Dla34', 'Detection Dla60x'],
          exactMatching: true,
          description:
            'DLA (deep layer aggregation) architectures are state-of-the-art for object detection and allow quick training and real-time inference at different input sizes. Choose Dla46 c for the shortest execution duration and Dla60x for complex tasks that require a very high accuracy.',
          architectures: [],
        })
      }

      groups.push(
        ...[
          {
            title: 'Small Network Architectures',
            keys: ['Mobile', 'Fbnetc'],
            description: 'Small network architectures are optimized for fastest execution.',
            architectures: [],
          },
          {
            title: 'DLA',
            keys: ['Dla'],
            description:
              'Deep layer aggregation (DLA) architectures fuse small and high resolution features in aggregation layers.',
            architectures: [],
          },
          {
            title: 'Densenet',
            keys: ['Densenet'],
            description:
              'Densenet architectures make use of a large number of connections from early layers to later ones.',
            architectures: [],
          },
          {
            title: 'Resnet',
            keys: ['Resnet', 'Resnest', 'Resnext'],
            description:
              'Resnet architectures incorporate so-called residual layers to encounter the vanishing gradient problem during training. Resnest and Resnext incorporate enhancements to the original Resnets.',
            architectures: [],
          },
          {
            title: 'Efficientnet',
            keys: ['Efficient'],
            description:
              'Efficientnet architectures were constructed using neural architecture search. They are available at different sizes (B1 - small to B8 - large).',
            architectures: [],
          },
          {
            title: 'Vovnet',
            keys: ['vovnet'],
            description:
              'Vovnets are based on Densenet with the goal of faster excution times by increasing the computational effiency.',
            architectures: [],
          },
        ]
      )

      const miscGroup = {
        title: 'Other',
        keys: [],
        description: '',
        architectures: [],
      }

      for (const network of this.availableNetworks) {
        let groupFound = false
        for (const group of groups) {
          for (const key of group.keys) {
            let matches = false
            if (group.exactMatching) {
              matches = network.name === key
            } else {
              matches = network.name.includes(key)
            }

            if (matches) {
              group.architectures.push(network)
              groupFound = true
            }
          }
        }
        if (!groupFound) {
          miscGroup.architectures.push(network)
        }
      }

      groups.push(miscGroup)
      groups = groups.filter(({ architectures }) => architectures.length > 0)
      return groups
    },
    availableDatasets() {
      const availableDatasets = []
      for (let i = 0; i < this.datasets.length; i++) {
        const dataset = this.datasets[i]
        if (
          (this.aiModelType === 'classification' && dataset.classification) ||
          (this.aiModelType === 'detection' && dataset.object_detection)
        ) {
          availableDatasets.push(dataset)
        }
      }
      return availableDatasets
    },
    availableClassLabels() {
      let availableClassLabels = []

      // all class labels of the selected datasets are available for training
      const labelSet = new Set()
      for (let i = 0; i < this.selectedTrainData.length; i++) {
        let d = this.selectedTrainData[i]
        let datasetClassLabels = []
        if (this.aiModelType === 'classification') {
          datasetClassLabels = d['classification_class_labels']
        } else if (this.aiModelType === 'detection') {
          datasetClassLabels = d['object_detection_class_labels']
        }

        for (let j = 0; j < datasetClassLabels.length; j++) {
          labelSet.add(datasetClassLabels[j])
        }

        // second variant: intersection of the sets of class labels
        // if (i === 0) {
        //   // add all labels
        //   for (let j = 0; j < datasetClassLabels.length; j++) {
        //     let c = datasetClassLabels[j]
        //     availableClassLabels.push({value: c.id, text: c.name})
        //   }
        // } else {
        //   // remove label if it is not in the dataset's class labels
        //   for (let j = availableClassLabels.length - 1; j >= 0; j--) {
        //     let c = availableClassLabels[j]
        //
        //     let classLabelValid = false
        //     for (let k = 0; k < datasetClassLabels.length; k++) {
        //       if (datasetClassLabels[k].id === c.value) {
        //         classLabelValid = true
        //         break
        //       }
        //     }
        //     if (!classLabelValid) {
        //       availableClassLabels.splice(j, 1);
        //     }
        //   }
        // }
      }

      for (let classLabelId of labelSet) {
        const c = this.classLabelMap[classLabelId]
        if (c) {
          availableClassLabels.push(c)
        }
      }

      return availableClassLabels
    },
    minIterations: function () {
      if (this.parameters && this.parameters.evaluation && this.parameters.evaluation.interval) {
        return this.parameters.evaluation.interval
      } else {
        return 1000
      }
    },
    modelTypeValid() {
      return this.datasetNameValid && this.annotationSelectionValid
    },
    modelArchitectureValid() {
      return true
    },
    dataTabValid() {
      return true
    },
    classLabelTabValid() {
      return true
    },

    aiModelNameValid() {
      return this.aiModelName
    },
    aiModelTypeValid() {
      return this.aiModelType
    },
    architectureValid() {
      return this.selectedArchitecture
    },
    trainDataValid() {
      return this.selectedTrainData.length > 0
    },
    testDataValid() {
      return this.selectedTestData.length > 0
    },
    classesValid() {
      return this.selectedClassLabels.length > 0
    },
    inputDimensionsValid() {
      return this.inputDimensions
    },
    inputDimensions() {
      if (this.dimensions.width && this.dimensions.height) {
        return `${this.dimensions.width}x${this.dimensions.height}`
      }
      return ''
    },
    iterationsValid() {
      return this.iterations > this.minIterations || this.startTrainingLater
    },
    workerValid() {
      return this.selectedWorker || this.startTrainingLater
    },

    saveAvailable() {
      return this.tabIndex !== 0 && this.tabIndex === this.nTabs - 1
    },
    saveEnabled() {
      return (
        this.aiModelNameValid &&
        this.aiModelTypeValid &&
        this.architectureValid &&
        this.trainDataValid &&
        this.testDataValid &&
        this.classesValid &&
        this.inputDimensionsValid &&
        this.iterationsValid &&
        this.workerValid
      )
    },

    preprocessingFields() {
      if (this.selectedArchitecture && this.selectedArchitecture.options) {
        let datasetFields = this.selectedArchitecture.options.fields.filter((f) => {
          return f.name === 'dataset'
        })
        if (datasetFields.length > 0) {
          let preprocessingFields = datasetFields[0].fields
          return preprocessingFields.filter((f) => {
            return !['input_width', 'input_height'].includes(f.name)
          })
        }
      }
      return []
    },

    augmentationFields() {
      if (this.selectedArchitecture && this.selectedArchitecture.options) {
        let augmentationFields = this.selectedArchitecture.options.fields.filter((f) => {
          return f.name === 'augmentation'
        })
        if (augmentationFields.length > 0) {
          return augmentationFields[0].fields
        }
      }
      return []
    },

    parameterFields() {
      if (this.selectedArchitecture && this.selectedArchitecture.options) {
        return this.selectedArchitecture.options.fields.filter((f) => {
          return !['augmentation', 'dataset'].includes(f.name)
        })
      }
      return []
    },
  },

  watch: {
    show(newValue) {
      if (newValue) this.fullReset()
      this.showModal = newValue
    },

    showModal(newValue) {
      if (this.showModal) {
        this.loadDatasets()
      }
      this.$emit('show', newValue)
    },

    selectedArchitecture(newArchitecture, oldArchitecture) {
      this.resetParameters()
      if (
        this.aiModelName === this.generateAiModelName(oldArchitecture) ||
        this.aiModelName === ''
      ) {
        this.aiModelName = this.generateAiModelName(newArchitecture)
      }
    },
  },

  created() {},

  mounted() {
    this.showModal = this.show
    this.loadModelConfigurationOptions()
    if (this.showModal) {
      this.loadDatasets()
    }
    this.fullReset()
  },

  methods: {
    ...mapActions(['getDatasets', 'getModelConfigurationOptions', 'createJob', 'createModel']),
    loadModelConfigurationOptions() {
      this.loading = true
      const self = this
      this.getModelConfigurationOptions()
        .then(() => {
          self.loading = false
        })
        .catch(() => {
          self.loading = false
          self.error = 'Could not load model architectures.'
        })
    },
    loadDatasets() {
      this.datasetsLoading = true
      const self = this
      this.getDatasets()
        .then(() => {
          self.datasetsLoading = false
        })
        .catch(() => {
          self.datasetsLoading = false
          self.error = 'Could not load datasets.'
        })
    },
    fullReset() {
      this.reset()
      this.aiModelName = ''
      this.selectedTrainData = []
      this.selectedTestData = []
      this.selectedClassLabels = []
      this.iterations = 10000
      this.selectedWorker = undefined
    },
    reset() {
      this.tabIndex = 0

      this.classificationSelected = false
      this.detectionSelected = false
      this.selectedArchitecture = undefined

      this.resetParameters()
    },
    resetParameters() {
      this.preprocessing = {}
      this.augmentations = {}
      this.parameters = {}
    },
    selectedArchitectureInGroup(group) {
      if (!this.selectedArchitecture) return false
      for (const architecture of group.architectures) {
        if (this.selectedArchitecture.name === architecture.name) return true
      }
      return false
    },
    selectClassification(value) {
      this.reset()
      this.detectionSelected = false
      this.classificationSelected = value
    },
    selectDetection(value) {
      this.reset()
      this.classificationSelected = false
      this.detectionSelected = value
    },
    validate() {
      // this.datasetNameValid = this.datasetName !== ''
      // this.annotationSelectionValid = this.categoriesSelected ||
      //   this.boundingBoxesSelected ||
      //   this.rotatedBoundingBoxesSelected
      // this.selectedClassLabelsValid = !this.categoriesSelected || this.selectedClassLabels.length > 0

      // return this.datasetNameValid && this.annotationSelectionValid && this.selectedClassLabelsValid && this.selectedDetectionLabelsValid
      return false
    },
    generateAiModelName(architecture) {
      if (architecture) {
        return `${architecture.name}`
      }
      return ''
    },
    onRetry() {
      this.error = undefined
      this.loadModelConfigurationOptions()
      this.loadDatasets()
    },
    onSelectArchitecture(architecture) {
      this.selectedArchitecture = architecture
    },
    onClickCreateSubset(dataset) {
      this.showCreateSubsetModal = true
      this.createSubsetDataset = dataset
    },
    onClickSave() {
      // TODO validate

      this.loading = true

      // create new model
      let parameters = _.cloneDeep(this.parameters)
      parameters.augmentation = _.cloneDeep(this.augmentations)
      parameters.dataset = _.cloneDeep(this.preprocessing)

      // we add input_width and input_height manually because they are displayed
      // and handled separately in the UI
      parameters.dataset.input_width = this.dimensions.width
      parameters.dataset.input_height = this.dimensions.height

      let requestData = {
        name: this.aiModelName,
        class_labels: [],
        data_subsets: [],
        network_config_option: this.selectedArchitecture.id,
        network_type: this.aiModelType,
        parameters: parameters,
      }

      for (const selectedClassLabel of this.selectedClassLabels) {
        requestData.class_labels.push({
          class_label: selectedClassLabel.id,
          target_class_label: selectedClassLabel.id,
        })
      }

      for (const dataset of this.selectedTrainData) {
        for (const dataSubset of dataset.data_subsets) {
          requestData.data_subsets.push({
            category: `${this.aiModelType}_train`,
            data_subset: dataSubset.id,
          })
        }
      }

      for (const dataset of this.selectedTestData) {
        for (const dataSubset of dataset.data_subsets) {
          requestData.data_subsets.push({
            category: `${this.aiModelType}_test`,
            data_subset: dataSubset.id,
          })
        }
      }

      const self = this
      this.createModel({ model: requestData })
        .then((model) => {
          self.$bvToast.toast('New model created.', {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 4000,
            solid: true,
          })

          if (self.startTrainingLater) {
            self.loading = false
            self.showModal = false
            const job = undefined
            self.$emit('model-created', { model, job })
          } else {
            // create training job
            const job = {
              iterations: self.iterations,
              model_id: model.id,
              worker_id: self.selectedWorker,
            }
            this.createJob(job)
              .then((newJob) => {
                self.$emit('model-created', { model, newJob })
                this.$bvToast.toast('Training started.', {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 4000,
                  solid: true,
                })
              })
              .catch((error) => {
                let message = 'Could not schedule job for model training.'
                if (
                  error.response.status === 400 &&
                  error.response.data &&
                  error.response.data !== ''
                ) {
                  message = `Could not schedule job for model training: ${error.response.data}`
                } else if (error.response.status === 403) {
                  message = `Could not schedule job for model training: not authorized`
                }

                this.$bvToast.toast(message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 8000,
                  solid: true,
                })
              })
              .finally(() => {
                self.loading = false
                self.showModal = false
              })
          }
        })
        .catch((err) => {
          console.error(err)
          self.$bvToast.toast('Could not create model.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';

.selection-square {
  border-radius: 2px;
  display: inline-block;
  margin-right: 0.25rem;
  margin-bottom: -0.125rem;
  background-color: $primary;
  width: 6px;
  height: 1rem;
}

.selection-card {
  &:hover {
    background: $blue-light !important;
  }
}
</style>
