<template>
  <b-form-group v-b-popover.hover.left="field.description">
    <b-form-checkbox :id="$id(field.name)" disabled v-model="checkboxValue">
      {{ field.display_name }}
    </b-form-checkbox>
  </b-form-group>
</template>

<script>
export default {
  name: 'BoolParameter',
  props: ['value', 'field'],
  data() {
    return {
      checkboxValue: false,
    }
  },

  mounted() {
    this.checkboxValue = this.value
  },

  methods: {},
}
</script>
