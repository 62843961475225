<template>
  <div v-show="!field.hidden">
    <div class="d-flex justify-content-between">
      <div>
        <div>
          <strong>{{ field.display_name }}</strong>
        </div>
        <span class="text-secondary"
          ><small>{{ field.description }}</small></span
        >
      </div>
      <b-button @click="addElement()" size="sm" variant="primary">add</b-button>
    </div>

    <hr />

    <div v-for="(v, i) in values" :key="i" class="list-fields pt-2 pl-2 pr-2 mb-2">
      <form-generator
        :all-plaintext="plaintext || field.editable === false"
        :key="`list_fields_${field.name}_${i}`"
        :fields="field.fields"
        :value="values[i]"
        @input="onInput(i, $event)"
        :size="size"
        :form-group-attributes="formGroupAttributes"
      ></form-generator>
      <div class="d-flex justify-content-end mb-3">
        <b-button @click="removeElement(i)" :size="size" variant="danger">remove</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListInput',
  props: ['value', 'field', 'plaintext', 'size', 'formGroupAttributes'],
  components: {
    FormGenerator: () => import('../FormGenerator'),
  },
  data() {
    return {
      values: [],
    }
  },

  mounted() {
    if (this.value !== undefined) {
      this.values = this.value
    } else {
      this.values = []
    }
    this.onInput()
  },

  methods: {
    addElement() {
      const value = {}
      this.values.push(value)
      // this.onInput()
    },
    removeElement(index) {
      this.values.splice(index, 1)
      this.onInput()
    },
    onInput(index, event) {
      if (index !== undefined && event !== undefined) {
        this.values[index] = event
      }

      this.$emit('input', this.values)
    },
  },
}
</script>

<style lang="scss" scoped>
.list-fields {
  border-bottom: 1px solid #dddddd;
}
</style>
