<template>
  <div class="d-flex h-100">
    <loading-overlay v-if="loading" />
    <div class="w-50 d-flex flex-column pr-3">
      <div class="pb-2 font-weight-600">Datasets</div>
      <div class="flex-grow-1 position-relative overflow-hidden">
        <div class="h-100 selection-column d-flex flex-column">
          <div class="p-3 search-wrapper">
            <b-form-input
              v-model="leftSearchString"
              placeholder="search"
              size="sm"
              @focus="leftSearchFocus = true"
              @blur="leftSearchFocus = false"
            />
          </div>
          <div
            v-if="
              filteredDatasets.length === 0 && availableDatasets && availableDatasets.length > 0
            "
            class="text-dark text-center mt-5 mb-3 pl-5 pr-5"
          >
            <p>no results</p>
          </div>
          <div
            v-if="!availableDatasets || availableDatasets.length === 0"
            class="text-dark text-center mt-5 pt-3 mb-5"
          >
            {{ availableEmptyText }}
          </div>

          <div v-if="filteredDatasets.length > 0" class="scroll-box flex-grow-1 pt-3 pb-2">
            <div
              v-for="dataset in filteredDatasets"
              :key="`dataset-${dataset.id}`"
              :ref="`dataset-${dataset.id}`"
              class="pl-3 pr-3 pb-2 mt-0"
            >
              <div class="border-radius-md bg-white shadow-sm border-gray-100">
                <card-collapse no-animation small :title="dataset.name">
                  <template #header>
                    <div class="d-flex justify-content-start">
                      <img
                        v-if="dataset.thumbnail !== undefined"
                        :src="dataset.thumbnail"
                        @error="dataset.thumbnail = undefined"
                        class="dataset-image border-radius-md ml-n3 mt-n2 mb-n2 mr-2"
                      />
                      <div class="w-100 text-primary font-weight-600 no-select">
                        {{ dataset.name }}
                      </div>
                    </div>
                  </template>

                  <div class="p-2 position-relative">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div
                        v-for="subset in dataset.data_subsets"
                        :key="`dataset-${dataset.id}-subset-${subset.id}`"
                        class="p-1 w-50"
                      >
                        <subset-entry :subset="subset" @click="onClickSubset(dataset, subset)" />
                      </div>
                    </div>

                    <div class="pt-2 pl-2 pr-2 pb-0">
                      <b-link class="text-dark font-weight-500 no-select">
                        <fai icon="plus" />
                        <span class="ml-2" @click="onClickCreateSubset(dataset)"
                          >create subset</span
                        >
                      </b-link>
                    </div>
                  </div>
                </card-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-50 d-flex flex-column">
      <div class="pb-2 font-weight-600">
        <exclamation-mark v-if="warning" class="mr-2" />
        {{ selectedTitle }}
      </div>
      <div class="flex-grow-1 position-relative overflow-hidden">
        <div class="h-100 selection-column d-flex flex-column">
          <div
            v-if="!selectedDatasets || selectedDatasets.length === 0"
            class="text-dark text-center mt-5 pt-3 mb-5"
          >
            {{ selectionEmptyText }}
          </div>
          <div v-else class="scroll-box flex-grow-1 pt-3 pb-2">
            <div
              v-for="dataset in selectedDatasets"
              :key="`selected-dataset-${dataset.id}`"
              class="pl-3 pr-3 pb-2"
            >
              <div class="shadow-sm border-radius-md bg-white p-2">
                <div class="w-100 text-primary font-weight-600 mb-1 pl-1">
                  {{ dataset.name }}
                </div>
                <subset-entry
                  v-for="subset in dataset.data_subsets"
                  :key="`selected-dataset-${dataset.id}-subset-${subset.id}`"
                  :subset="subset"
                  class="mt-1"
                  @click="onClickSelectedSubset(dataset, subset)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExclamationMark from '@/components/ExclamationMark'
import LoadingOverlay from '@/components/LoadingOverlay'
import SubsetEntry from '@/components/data/SubsetEntry'

import _ from 'lodash'
import { compareTwoStrings } from 'string-similarity'
import CardCollapse from '@/components/CardCollapse'

export default {
  name: 'ColumnarDataSelection',
  components: { CardCollapse, SubsetEntry, LoadingOverlay, ExclamationMark },
  props: {
    warning: {
      type: Boolean,
      default: false,
    },
    datasets: Array,
    selectedTitle: {
      type: String,
      default: 'Selected Data Subsets',
    },
    availableEmptyText: {
      type: String,
      default: 'no available datasets',
    },
    selectionEmptyText: {
      type: String,
      default: 'selected data appears here',
    },
  },

  data() {
    return {
      loading: false,
      leftSearchString: '',
      availableDatasets: [],
      selectedDatasets: [],
      leftSearchFocus: false,
    }
  },

  computed: {
    filteredDatasets() {
      const s = this.leftSearchString

      let result = []

      if (s.length === 0) {
        return this.availableDatasets
      } else if (s.length === 1) {
        result = this.availableDatasets.filter((d) => {
          return d.name.toLowerCase().startsWith(s.toLowerCase())
        })
      } else {
        result = this.availableDatasets.filter((d) => {
          return d.name.toLowerCase().includes(s.toLowerCase())
        })
      }

      if (s.length > 1) {
        result.sort((a, b) => {
          return compareTwoStrings(s, b.name) - compareTwoStrings(s, a.name)
        })
      }
      return result
    },
  },

  watch: {
    datasets(datasets) {
      this.loadDatasets(datasets)
    },
  },

  mounted() {
    this.loadDatasets(this.datasets)
  },

  methods: {
    loadDatasets(datasets) {
      if (!datasets) this.availableDatasets = []
      const newDatasets = _.cloneDeep(datasets)

      // go through all selected datasets and remove the selected subsets from the available ones
      // for (const selectedDatasets of this.selectedDatasets) {
      for (let i = this.selectedDatasets.length - 1; i >= 0; i--) {
        const selectedDataset = this.selectedDatasets[i]
        let selectedFound = false
        for (let j = newDatasets.length - 1; j >= 0; j--) {
          const newDataset = newDatasets[j]
          if (newDataset.id === selectedDataset.id) {
            selectedFound = true
            for (let k = newDataset.data_subsets.length - 1; k >= 0; k--) {
              const s = newDataset.data_subsets[k]
              for (const subset of selectedDataset.data_subsets) {
                if (s.id === subset.id) {
                  newDataset.data_subsets.splice(k, 1)
                }
              }
            }
            break
          }
        }

        // this dataset is not part of the new ones, hence it must also be
        // removed from the selected datasets
        if (!selectedFound) {
          this.selectedDatasets.splice(i, 1)
        }
      }

      this.availableDatasets = newDatasets
      this.$emit('selection-changed', _.cloneDeep(this.selectedDatasets))
    },
    onClickCreateSubset(dataset) {
      this.$emit('click-create-subset', dataset)
    },
    onClickSubset(dataset, subset) {
      for (let i = 0; i < this.availableDatasets.length; i++) {
        const d = this.availableDatasets[i]
        if (d.id === dataset.id) {
          let s
          for (let j = 0; j < d.data_subsets.length; j++) {
            s = d.data_subsets[j]
            if (s.id === subset.id) {
              d.data_subsets.splice(j, 1)
              break
            }
          }
          break
        }
      }

      let foundDataset = undefined
      for (const selectedDataset of this.selectedDatasets) {
        if (selectedDataset.id === dataset.id) {
          foundDataset = selectedDataset
          break
        }
      }

      if (!foundDataset) {
        foundDataset = _.cloneDeep(dataset)
        foundDataset.data_subsets = []
        this.selectedDatasets.push(foundDataset)
      }
      foundDataset.data_subsets.push(subset)

      this.$emit('selection-changed', _.cloneDeep(this.selectedDatasets))
    },

    onClickSelectedSubset(dataset, subset) {
      for (let i = 0; i < this.selectedDatasets.length; i++) {
        const d = this.selectedDatasets[i]
        if (d.id === dataset.id) {
          let s
          for (let j = 0; j < d.data_subsets.length; j++) {
            s = d.data_subsets[j]
            if (s.id === subset.id) {
              d.data_subsets.splice(j, 1)
              break
            }
          }

          if (d.data_subsets.length === 0) {
            this.selectedDatasets.splice(i, 1)
          }
          break
        }
      }

      for (const selectedDataset of this.availableDatasets) {
        if (selectedDataset.id === dataset.id) {
          selectedDataset.data_subsets.push(subset)
          break
        }
      }

      this.$emit('selection-changed', _.cloneDeep(this.selectedDatasets))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';

.dataset-header {
  @extend .border-radius-md;
  min-height: 6rem;
  background-size: cover;
  background-position: center;
  //padding: 1rem 30% 1rem 1rem;
  padding: 0;
  width: 100%;

  .dataset-name {
    background: $primary;
    color: $white;
    font-weight: 700;
    font-size: larger;
  }
}

.selection-column {
  background: $gray-200;
  border-radius: 5px;
}

.search-wrapper {
  border-bottom: $gray-400 solid 1px;
}

.dataset-image {
  //width: 100%;
  height: 2.5em;
  width: 4em;
  object-fit: cover;
}
</style>
