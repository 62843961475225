<template>
  <div class="full-height-wrapper">
    <div class="h-100 mh-100">
      <div class="h-100 mh-100 d-flex">
        <div class="mh-100 mw-60px flex-shrink-0 d-flex flex-column align-content-center">
          <div class="pl-1 pr-1 pb-1 pt-3 d-flex flex-column align-content-center">
            <b-button
              id="button-overview"
              v-b-tooltip.right.nofade.hover.d0.o0
              :class="{ 'icon-button-active': tabIndex === 0 }"
              class="icon-button mh-50px font-size-large"
              title="overview"
              variant="link"
              @click="onClickOverview"
            >
              <fai icon="th" />
            </b-button>

            <b-button
              id="button-training-progress"
              v-b-tooltip.right.nofade.hover.d0.o0
              :class="{ 'icon-button-active': tabIndex === 1 }"
              class="icon-button mh-50px font-size-large"
              title="training progress"
              variant="link"
              @click="onClickTrainingProgress"
            >
              <fai icon="chart-line" />
            </b-button>

            <!--            <b-button-->
            <!--              id="button-evaluation"-->
            <!--              v-b-tooltip.right.nofade.hover.d0.o0-->
            <!--              :class="{'icon-button-active': tabIndex === 2}"-->
            <!--              class="icon-button mh-50px font-size-large"-->
            <!--              title="evaluation"-->
            <!--              variant="link"-->
            <!--              @click="tabIndex = 2"-->
            <!--            >-->
            <!--              <fai-->
            <!--                icon="tachometer-alt"-->
            <!--              />-->
            <!--            </b-button>-->

            <b-button
              id="button-inference-results"
              v-b-tooltip.right.nofade.hover.d0.o0
              :class="{ 'icon-button-active': tabIndex === 3 }"
              class="icon-button mh-50px font-size-large"
              title="test items"
              variant="link"
              @click="onClickInferenceResults"
            >
              <fai icon="images" />
            </b-button>

            <!--            <b-button-->
            <!--              id="button-web-inference"-->
            <!--              v-b-tooltip.right.nofade.hover.d0.o0-->
            <!--              :class="{'icon-button-active': tabIndex === 4}"-->
            <!--              class="icon-button mh-50px font-size-large"-->
            <!--              title="web inference"-->
            <!--              variant="link"-->
            <!--              @click="tabIndex = 4"-->
            <!--            >-->
            <!--              <fai-->
            <!--                icon="play-circle"-->
            <!--              />-->
            <!--            </b-button>-->
          </div>
        </div>

        <div
          v-if="[1, 2, 3].includes(tabIndex)"
          class="mh-100 model-selection flex-shrink-0 d-flex pt-3 pl-1"
        >
          <div
            class="flex-grow-1 position-relative overflow-hidden h-100 mh-100 w-100 model-selection-wrapper"
          >
            <model-selection
              :models="models"
              :model-filters="modelFilters"
              :show-model-selection="[1].includes(tabIndex)"
              multi-selection
              view-mode="list"
              @click-model="onClickModelSelection"
              @click-deselect-model="onClickDeselectModel"
              @click-model-details="onClickModelDetails"
            />
          </div>
        </div>

        <div
          v-if="tabIndex === 0"
          class="h-100 mh-100 flex-grow-1 position-relative overflow-hidden d-flex flex-column w-100"
        >
          <div class="h-100 pl-1 pt-3 pr-0 d-flex flex-column">
            <div class="h-100 mh-100">
              <!--              <div class="mh-100 h-100 w-100">-->
              <!--                <loading-overlay v-if="modelsLoading" />-->
              <!--                <div class="d-flex flex-column mh-100 h-100 w-100">-->
              <!--                  <div class="row m-0 mh-100 h-100">-->
              <!--                    <div-->
              <!--                      class="col-lg-9 col-xl-10 p-0 mh-100 flex-grow-1 position-relative overflow-hidden model-selection-wrapper"-->
              <!--                    >-->
              <div class="row m-0 mh-100 h-100">
                <div
                  class="col-lg-9 col-xl-10 p-0 mh-100 flex-grow-1 position-relative overflow-hidden model-selection-wrapper"
                >
                  <model-selection
                    :models="models"
                    :model-filters="modelFilters"
                    show-create-model
                    view-mode="grid"
                    @click-model="onClickModelDetails"
                    @click-create-model="onClickCreateAiModel"
                  />
                </div>
                <div
                  class="col-lg-3 col-xl-2 p-0 mh-100 flex-grow-1 position-relative overflow-hidden"
                >
                  <model-filters :models="models" v-model="modelFilters"></model-filters>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="tabIndex === 1"
          class="h-100 mh-100 flex-grow-1 position-relative overflow-hidden d-flex flex-column w-100"
        >
          <div class="scroll-box-always h-100 mh-100">
            <model-statistics :selected-models="modelSelection" />
          </div>
        </div>

        <div
          v-if="tabIndex === 3"
          class="h-100 mh-100 flex-grow-1 justify-content-start position-relative overflow-hidden d-flex flex-column w-100"
        >
          <inference-result-viewer
            v-if="modelSelection.length > 0"
            :key="`inference-result-viewer-model-${modelSelection[0].id}`"
            :model="modelSelection.length > 0 ? modelSelection[0] : undefined"
            class="ml-2"
          />
          <div v-else class="pt-4 pb-3">
            <div class="p-5 text-secondary font-weight-500">
              <fai class="mr-2" icon="arrow-left" />
              select a model to view test items
            </div>
          </div>
        </div>
      </div>
    </div>

    <ai-model-modal
      :show="modals.aiModelModal.show"
      :workers="workers"
      @show="modals.aiModelModal.show = $event"
      @model-created="onModelCreated"
    />

    <!--    <ai-model-details-modal-->
    <!--      :model="modals.aiModelDetailsModal.model"-->
    <!--      :show="modals.aiModelDetailsModal.show"-->
    <!--      @show="modals.aiModelDetailsModal.show = $event"-->
    <!--    />-->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AiModelModal from '../components/modals/AiModelModal'
import ModelSelection from '../components/models/ModelSelection'
import ModelStatistics from '../components/ModelStatistics'
import InferenceResultViewer from '../components/item-viewer/InferenceResultViewer'
import _ from 'lodash'
import ModelFilters from '@/components/models/ModelFilters'

export default {
  name: 'ModelExplorer',
  components: {
    ModelFilters,
    InferenceResultViewer,
    AiModelModal,
    ModelSelection,
    ModelStatistics,
  },
  data() {
    return {
      tabIndex: 0,
      modals: {
        aiModelModal: {
          show: false,
        },
      },
      modelFilters: {},
    }
  },
  computed: {
    ...mapState(['models', 'modelsLoading', 'modelSelection', 'workers']),
  },
  watch: {
    '$route.query'() {
      this.processQueryParams()
    },
    modelSelection() {
      this.updateQueryParams()
    },
    models() {
      // if (this.modals.aiModelDetailsModal.model) {
      //   for (const model of this.models) {
      //     if (this.modals.aiModelDetailsModal.model.id === model.id) {
      //       this.modals.aiModelDetailsModal.model = model
      //       break
      //     }
      //   }
      // }
    },
  },

  async mounted() {
    await this.getModels({ forceReload: true })
    await this.getModelConfigurationOptions()
    this.processQueryParams()
  },

  methods: {
    ...mapActions([
      'getModels',
      'getModelDetails',
      'getModelConfigurationOptions',
      'getJobs',
      'createJob',
      'deleteJob',
      'openAiModal',
      'updateModel',
      'selectModel',
      'deselectModel',
      'toggleSelectModel',
    ]),

    onModelCreated({ model }) {
      this.openAiModal(model)
    },

    onClickOverview() {
      this.tabIndex = 0
      this.updateQueryParams()
    },
    onClickTrainingProgress() {
      this.tabIndex = 1
      this.updateQueryParams()
    },
    onClickInferenceResults() {
      if (this.modelSelection.length > 0) {
        // deselect all but the first model
        while (this.modelSelection.length > 1) {
          this.deselectModel({ model: this.modelSelection[1] })
        }
      }
      this.tabIndex = 3
      this.updateQueryParams()
    },

    onClickDeselectModel(model) {
      this.deselectModel({ model })
    },

    onClickModelSelection(model) {
      if (this.tabIndex === 1) {
        this.toggleSelectModel({ model: model })
      } else {
        for (const m of this.models) {
          if (m.id !== model.id) {
            this.deselectModel({ model: m })
          }
        }
        this.selectModel({ model: model })
      }

      // this.updateModel({ model })
      this.updateQueryParams()
    },

    onClickModelDetails(model) {
      this.openAiModal(model)
      // this.modals.aiModelDetailsModal.show = true
      // this.modals.aiModelDetailsModal.model = model
    },

    updateQueryParams() {
      // only "view" and "models" are updated by the model explorer, the
      // remaining query params will be kept
      const q = { ...this.$route.query }
      delete q.view
      delete q.models

      if (this.tabIndex === 1) {
        q.view = 'training-progress'
      } else if (this.tabIndex === 2) {
        q.view = 'evaluation'
      } else if (this.tabIndex === 3) {
        q.view = 'test-items'
      } else if (this.tabIndex === 4) {
        q.view = 'web-inference'
      }

      let selectedModelIds = []
      for (const m of this.models) {
        if (m.selected) {
          selectedModelIds.push(m.id)
        }
      }
      if (selectedModelIds.length > 0) {
        q.models = selectedModelIds.join(',')
      }

      if (!_.isEqual(q, this.$route.query)) {
        this.$router.replace({
          query: q,
        })
      }
    },

    processQueryParams() {
      let q = this.$route.query
      let modelIds = q['models']
      if (modelIds !== undefined) {
        try {
          modelIds = modelIds.split(',').map((m) => parseInt(m))
        } catch (e) {
          console.log(e)
          modelIds = []
        }
      } else {
        modelIds = []
      }

      const view = q['view']
      if (view === 'training-progress') {
        this.tabIndex = 1
        this.selectModels(modelIds)
      } else if (view === 'evaluation') {
        // this.tabIndex = 2
        this.selectModels(modelIds)
      } else if (view === 'test-items') {
        this.tabIndex = 3
        if (modelIds.length > 0) {
          this.selectModels([modelIds[0]])
        }
      } else if (view === 'web-inference') {
        // this.tabIndex = 4
      } else {
        let modelId = q['model']
        if (modelId !== undefined) {
          modelId = parseInt(modelId)
          for (const model of this.models) {
            if (modelId === model.id) {
              this.openAiModal(model)
            }
          }
        }
      }
    },

    onClickCreateAiModel() {
      this.modals.aiModelModal.show = true
    },

    selectModels(modelIds) {
      for (const m of this.models) {
        if (modelIds.includes(m.id)) {
          // this.$set(m, 'selected', true)
          this.selectModel({ model: m })
        }
      }

      this.updateQueryParams()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../custom';

.model-selection {
  width: calc(min(50%, 400px)) !important;
  //max-width: calc(min(20%, 250px)) !important;
}

.model-selection-wrapper {
  @extend .bg-gray-200;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
</style>
