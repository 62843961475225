<template>
  <div class="stat-wrapper" @mouseover="hover = true" @mouseleave="hover = false">
    <div :style="style" class="stat no-select" :class="{ 'w-100': hover }">
      {{ caption }}
    </div>
    <div class="stat-background-caption">
      {{ caption }}
    </div>
  </div>
</template>

<script>
import { getColorForCSS, getTextColorForCSS } from '@/colors'
import { subsetNameHash } from '@/utils'

export default {
  name: 'BarChartEntry',
  props: {
    stat: {
      type: Object,
    },
    elementMap: {
      type: Object,
    },
    colorFromName: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    notFoundName: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      hover: false,
    }
  },

  computed: {
    name() {
      const elem = this.elementMap[this.stat.id]
      if (elem) {
        return elem.name
      }

      return this.notFoundName
    },
    caption() {
      let caption = `${this.name} ${(100 * this.stat.percentage).toFixed(2)}%`
      if (this.hover) {
        caption += ` (${this.stat.count})`
      }
      return caption
    },
    style() {
      let borderColor
      let backgroundColor
      let color

      if (this.colorFromName) {
        let hash = 0
        if (this.name !== this.notFoundName) {
          hash = subsetNameHash(this.name)
        }
        if (this.hover) {
          borderColor = getColorForCSS(hash, 1.0)
          backgroundColor = getColorForCSS(hash, 0.35)
          color = getTextColorForCSS(hash)
        } else {
          borderColor = getColorForCSS(hash, 1.0)
          backgroundColor = getColorForCSS(hash, 0.35)
          color = getTextColorForCSS(hash)
        }
      } else {
        if (this.hover) {
          borderColor = getColorForCSS(this.stat.id, 1.0)
          backgroundColor = getColorForCSS(this.stat.id, 0.7)
          color = getTextColorForCSS(this.stat.id, true)
        } else {
          borderColor = getColorForCSS(this.stat.id, 1.0)
          backgroundColor = getColorForCSS(this.stat.id, 0.9)
          color = getTextColorForCSS(this.stat.id, true)
        }
      }

      const style = {
        width: `${100 * this.stat.displayWidth}%`,
        'background-color': backgroundColor,
        color: color,
      }

      if (this.border) {
        style['outline'] = `2px solid ${borderColor}`
      } else {
        style['outline'] = `2px solid ${backgroundColor}`
      }
      return style
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../custom';

.stat-font {
  font-size: 12px;
  font-weight: 500;
  padding-left: 4px;
}

.stat {
  @extend .stat-font;

  padding-right: 4px;

  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;

  transition: all 0.3s ease-out;
  position: relative;

  //word-break: unset;
  //word-wrap: unset;
  //overflow-wrap: unset;
  //overflow: hidden;

  //height: 16px;
  border-radius: 6px;

  z-index: 20;
}

.stat-background-caption {
  @extend .stat-font;

  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;

  color: $gray-700;
  position: absolute;
  top: 4px;

  z-index: 10;
}

.stat-wrapper {
  border-radius: 6px;

  padding-top: 4px;
  padding-bottom: 4px;

  position: relative;

  &:hover {
    //background: $gray-200;
  }
}
</style>
