<template>
  <div class="pl-4 pr-4 pb-4">
    <div class="d-flex w-100">
      <template v-for="(milestone, i) in milestones">
        <div
          v-if="i > 0"
          :key="`milestone-${i}-line`"
          :style="{ width: `${(100 / (milestones.length - 1)).toFixed(5)}%` }"
          :class="`bg-${milestone.variant}`"
          class="line"
        />
        <div
          :key="`milestone-${i}-circle`"
          :class="circleClass(milestone)"
          class="circle flex-shrink-0"
        >
          <div
            v-if="milestone.animate"
            :class="circleClass(milestone)"
            class="animated-circle grow-animation"
          ></div>
          <div
            v-b-tooltip.d250.o0
            :class="{ 'text-dark': !milestone.active }"
            :title="milestone.tooltip"
            class="circle-text"
          >
            <div class="d-flex align-items-center">
              <fai
                v-if="milestone.icon"
                :icon="milestone.icon"
                class="font-size-small mr-1 text-dark"
              />
              <div>{{ milestone.text }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MilestoneLine',
  props: {
    milestones: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  methods: {
    onClickMileStone(milestone, event) {
      this.$emit('click-milestone', { milestone, event })
    },
    circleClass(milestone) {
      let cls = {}
      cls[`bg-${milestone.variant}`] = true
      return cls
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../custom';

.circle {
  position: relative;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

.animated-circle {
  position: absolute;
  top: 0;

  height: 8px;
  width: 8px;

  border-radius: 50%;
  z-index: 10;
}

.circle-text {
  @extend .no-select;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 150%;
  transform: translate(-50%, 0);
}

.line {
  margin-top: 2px;
  margin-bottom: 2px;
  height: 4px;
  opacity: 0.5;
}

.grow-animation {
  animation: growAnimation 5s ease 0s infinite normal forwards;

  @keyframes growAnimation {
    0% {
      animation-timing-function: ease-out;
      transform: scale(1);
      opacity: 1;
      transform-origin: center center;
    }

    25% {
      animation-timing-function: ease-out;
      transform: scale(1);
      opacity: 0.5;
    }

    50% {
      animation-timing-function: ease-out;
      transform: scale(3);
      opacity: 0;
    }

    85% {
      animation-timing-function: ease-out;
      transform: scale(1);
      opacity: 1;
    }
  }
}
</style>
