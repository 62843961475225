<template>
  <div>
    <loading-overlay-fullscreen v-if="loadingCounter > 0" />

    <b-modal
      id="modal-create-data-subset"
      ref="modalCreateDataSubset"
      v-model="showModal"
      size="md"
      :title="`Create Data Subset for ${dataset ? dataset.name : '?'}`"
      @ok="createSubset"
    >
      <div>
        <b-form v-if="type === 'random_split' || type === 'range_split'">
          <p v-if="type === 'random_split'">
            Create two new subsets from the dataset by random splitting. Each item of the dataset is
            randomly placed into one of the newly created subsets.
          </p>
          <p v-if="type === 'range_split'">
            Create two new subsets by selecting a range of items from the dataset for each split.
            Each item of the dataset is placed into one of the newly created subsets.
          </p>

          <h5><b>Subset 1</b></h5>
          <b-form-group label="Name" description="Enter a descriptive name for the new subset.">
            <b-form-input
              id="subsetName1"
              v-model="subset1.name"
              type="text"
              required
              maxlength="48"
              placeholder="Name"
            />
          </b-form-group>

          <!--          <b-form-group label="Description">-->
          <!--            <b-form-textarea-->
          <!--              id="subsetDesctiption1"-->
          <!--              v-model="subset1.description"-->
          <!--              placeholder="Description"-->
          <!--              rows="3"></b-form-textarea>-->
          <!--          </b-form-group>-->

          <h5><b>Subset 2</b></h5>
          <b-form-group label="Name" description="Enter a descriptive name for the new subset.">
            <b-form-input
              id="subsetName2"
              v-model="subset2.name"
              type="text"
              required
              maxlength="48"
              placeholder="Name"
            />
          </b-form-group>

          <!--          <b-form-group label="Description">-->
          <!--            <b-form-textarea-->
          <!--              id="subsetDesctiption2"-->
          <!--              v-model="subset2.description"-->
          <!--              placeholder="Description"-->
          <!--              rows="3"></b-form-textarea>-->
          <!--          </b-form-group>-->

          <h5><b>Split Settings</b></h5>
          <b-form-group
            label="Ratio"
            description="The ratio for splitting (e.g., associate 0.8 of the dataset to subset 1 and 0.2 to subset 2)."
          >
            <b-form-input
              id="ratio"
              v-model="ratio"
              type="number"
              min="0"
              max="1"
              step="0.01"
              required
              placeholder="Ratio"
            />
          </b-form-group>

          <!--          <b-form-group-->
          <!--            v-if="type === 'random_split'"-->
          <!--            label="Random Seed"-->
          <!--            description="The seed for random splitting. Given the same seed and the same dataset with the same items, splitting will result in the same subsets.">-->
          <!--            <b-form-input id="randomSeed"-->
          <!--                          type="number"-->
          <!--                          v-model="randomSeed"-->
          <!--                          min="0"-->
          <!--                          step="1"-->
          <!--                          required-->
          <!--                          placeholder="Random Seed">-->
          <!--            </b-form-input>-->
          <!--          </b-form-group>-->
        </b-form>

        <b-form v-else>
          <p>Create a new subset. You can add items to the subset later.</p>
          <b-form-group label="Name" description="Enter a descriptive name for the new subset.">
            <b-form-input
              id="subsetName"
              v-model="subset1.name"
              type="text"
              required
              maxlength="48"
              placeholder="Name"
            />
          </b-form-group>

          <!--          <b-form-group label="Description">-->
          <!--            <b-form-textarea-->
          <!--              id="subsetDesctiption"-->
          <!--              v-model="subset1.description"-->
          <!--              placeholder="Description"-->
          <!--              rows="3"></b-form-textarea>-->
          <!--          </b-form-group>-->
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import LoadingOverlayFullscreen from '@/components/LoadingOverlayFullscreen'
import Cookie from 'js-cookie'

export default {
  name: 'CreateSubsetModal',
  components: { LoadingOverlayFullscreen },
  props: {
    show: Boolean,
    type: String,
    dataset: Object,
    prefill: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      loadingCounter: 0,
      createSubset1: false,
      createSubset2: false,
      showModal: false,
      subset1: {
        name: '',
        description: '',
      },
      subset2: {
        name: '',
        description: '',
      },
      ratio: 0.8,
      // randomSeed: 42,
    }
  },

  computed: {},

  watch: {
    show: function (newValue) {
      this.showModal = newValue
    },

    showModal: function (newValue) {
      this.$emit('show', newValue)
    },
  },

  created: function () {},

  mounted() {
    this.showModal = this.show
    if (this.prefill) {
      this.subset1.name = 'Train'
      this.subset2.name = 'Test'
    }
  },

  methods: {
    createSubset() {
      let self = this
      if (this.type === 'random_split' || this.type === 'range_split') {
        const subset1 = this.subset1
        const subset2 = this.subset2

        this.loadingCounter++

        this.postSubset(subset1)
          .then((newSubset1) => {
            this.postSubset(subset2).then((newSubset2) => {
              let subsetIds = [newSubset1.id, newSubset2.id]
              self.$emit('created', newSubset1)
              self.$emit('created', newSubset2)

              const ratio1 = parseFloat(this.ratio).toPrecision(7)
              const ratio2 = (1 - parseFloat(this.ratio)).toPrecision(7)

              return self.$axios({
                method: 'post',
                url: '/api/dataset-items/edit_subsets/',
                withCredentials: true,
                timeout: 5 * 60 * 1000,
                headers: {
                  'X-Requested-With': 'XMLHttpRequest',
                  'X-CSRFToken': Cookie.get('csrftoken'),
                },
                data: {
                  subsets: subsetIds,
                  mode: this.type,
                  splits: [ratio1 - ratio2],
                },
              })
            })
          })
          .catch((e) => {
            self.$bvToast.toast(`Error during subset creation.`, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 4000,
              solid: true,
            })
            console.error(e)
          })
          .finally(() => {
            this.loadingCounter--
          })
      } else {
        const subset = this.subset1
        this.loadingCounter++
        this.postSubset(subset)
          .then((response) => {
            self.$emit('created', response.data)
            self.$bvToast.toast(`Empty data subset "${subset.name}" created.`, {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 4000,
              solid: true,
            })
          })
          .catch(() => {
            self.$bvToast.toast(`Could not create subset "${subset.name}".`, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 4000,
              solid: true,
            })
          })
          .finally(() => self.loadingCounter--)
      }

      // let app = this;
      // if (this.type === 'random_split' || this.type === 'range_split') {
      //   // load item list
      //   this.$axios.get(`/api/datasets/${this.datasetId}/items`)
      //     .then(response => {
      //       let items = response.data;
      //
      //       if (this.type === 'random_split') {
      //         items = app.$_.shuffle(items);
      //       }
      //
      //       // split items into 2 groups
      //       let splitIdx = Math.min(Math.ceil(app.ratio * items.length), items.length - 1);
      //
      //       let itemIds1 = [];
      //       let itemIds2 = [];
      //
      //       for (let i = 0; i < items.length; i++) {
      //         if (i < splitIdx) {
      //           itemIds1.push(items[i].id);
      //         } else {
      //           itemIds2.push(items[i].id);
      //         }
      //       }
      //
      //       // create both subsets and add the items
      //       app.postSubset(app.subset1, itemIds1);
      //       app.postSubset(app.subset2, itemIds2);
      //     })
      //     .catch(error => {
      //       console.log(error);
      //       // this.$bvToast.toast('Could not load dataset items.', {
      //       //   title: 'Error',
      //       //   variant: 'danger',
      //       //   autoHideDelay: 4000,
      //       //   solid: true
      //       // });
      //
      //       this.loading = false;
      //     });
      // } else {
      //   app.postSubset(app.subset1, []);
      // }
    },
    postSubset(subset) {
      return this.$store.dispatch('addSubset', {
        name: subset.name,
        description: subset.description,
        datasetId: this.dataset.id,
      })

      //
      // let requestData = {
      //   name: subset.name,
      //   description: subset.description,
      //   dataset: this.datasetId
      // }
      //
      // return this.$axios({
      //   method: 'post',
      //   url: '/api/data-subsets/',
      //   data: requestData,
      //   withCredentials: true,
      //   headers: {
      //     'X-Requested-With': 'XMLHttpRequest',
      //     'X-CSRFToken': Cookie.get('csrftoken'),
      //   }
      // })
    },
  },
}
</script>

<style scoped></style>
