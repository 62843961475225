import { formatDatetimeURI } from '@/datetime'

export function getFilterQuery(filterSettings, excludeTimeRange, timezone, forInferenceResults) {
  let datasetItemPrefix = ''
  if (forInferenceResults) {
    datasetItemPrefix = 'dataset_item__'
  }

  let filterQuery = ''
  if (filterSettings.status.length > 0) {
    let statusQueries = []
    for (let i = 0; i < filterSettings.status.length; i++) {
      statusQueries.push(`${datasetItemPrefix}status=${filterSettings.status[i]}`)
    }

    if (statusQueries.length === 1) {
      filterQuery += `&${statusQueries[0]}`
    } else if (statusQueries.length > 1) {
      filterQuery += `&or(${statusQueries.join(',')})`
    }
  }

  if (filterSettings.imageClass !== null) {
    filterQuery += `&${datasetItemPrefix}class=${filterSettings.imageClass}`
  }

  if (filterSettings.objectClass !== null) {
    filterQuery += `&${datasetItemPrefix}object_class=${filterSettings.objectClass}`
  }

  if (filterSettings.dataSubset !== null) {
    if (filterSettings.dataSubset === -1) {
      filterQuery += `&${datasetItemPrefix}data_subsets__id=null()`
    } else {
      filterQuery += `&${datasetItemPrefix}data_subsets__id=${filterSettings.dataSubset}`
    }
  }

  if (!excludeTimeRange) {
    if (filterSettings.dateTimeFrom !== null) {
      filterQuery += `&gt(${datasetItemPrefix}created_date,${formatDatetimeURI(
        filterSettings.dateTimeFrom,
        timezone
      )})`
    }

    if (filterSettings.dateTimeTo !== null) {
      filterQuery += `&lt(${datasetItemPrefix}created_date,${formatDatetimeURI(
        filterSettings.dateTimeTo,
        timezone
      )})`
    }
  }

  if (filterSettings.attributes.length > 0) {
    let attrQueries = []
    for (const attrFilter of filterSettings.attributes) {
      if (attrFilter.operator && attrFilter.attribute) {
        attrQueries.push(
          `${attrFilter.operator}(${datasetItemPrefix}attributes,${attrFilter.attribute.name}:${attrFilter.value})`
        )
      }
    }

    if (attrQueries.length === 1) {
      filterQuery += `&${attrQueries[0]}`
    } else if (attrQueries.length > 1) {
      filterQuery += `&and(${attrQueries.join(',')})`
    }
  }

  if (forInferenceResults) {
    if (filterSettings.correctClassificationClass === true) {
      // filter for correct classification results
      filterQuery += '&classification_correct=true'
    } else if (filterSettings.correctClassificationClass === false) {
      // filter for incorrect classification results
      filterQuery += '&classification_correct=false'
    }
  }

  return filterQuery
}
