import { baseColor, getColorRGB } from '@/colors'

export function drawItem(
  canvas,
  item,
  predictions,
  image,
  {
    drawGroundTruthObjects,
    drawGroundTruthDashed,
    predictedObjectClassIds,
    confidenceThreshold,
    showConfidence,
    boxLineWidth,
    applyPadding = true,
    textSize = 12,
  }
) {
  let ctx = canvas.getContext('2d')

  let groundTruthObjects = undefined
  if (Object.prototype.hasOwnProperty.call(item, 'annotations') && item.annotations !== null) {
    groundTruthObjects = item.annotations.objects
  }

  let predictedObjects = undefined
  if (
    predictions &&
    Object.prototype.hasOwnProperty.call(predictions, 'detection') &&
    predictions.detection !== null
  ) {
    predictedObjects = []
    try {
      let predictedBoxes = predictions.detection.boxes
      let predictedClassConfidences = predictions.detection.class_confidences
      let predictedClasses = predictions.detection.classes

      for (let i = 0; i < predictedBoxes.length; i++) {
        const box = predictedBoxes[i]
        const classConfs = predictedClassConfidences[i]

        let maxConf = 0
        let label = -1
        for (let j = 0; j < classConfs.length; j++) {
          const conf = classConfs[j]
          if (conf >= maxConf) {
            maxConf = conf
            label = predictedClasses[j]
          }
        }

        if (
          predictedObjectClassIds.includes(label) &&
          label !== -1 &&
          maxConf >= confidenceThreshold
        ) {
          predictedObjects.push({
            label: label,
            confidence: maxConf,
            x: box[0],
            y: box[1],
            width: box[2],
            height: box[3],
            orientation: box[4],
          })
        }
      }
    } catch (e) {
      console.error('error while parsing predicted objects', e)
      predictedObjects = undefined
    }
  }

  let aspectRatio = image.width / image.height

  let paddingX = 0
  let paddingY = 0
  if (applyPadding) {
    canvas.height = canvas.width
    if (aspectRatio >= 1) {
      paddingY = 0.5 * (canvas.width - canvas.width / aspectRatio)
    } else {
      paddingX = 0.5 * (canvas.width - canvas.width * aspectRatio)
    }
  } else {
    canvas.height = canvas.width / aspectRatio
  }

  ctx.fillStyle = baseColor
  ctx.fillRect(0, 0, canvas.width, canvas.height)

  const drawnImageWidth = canvas.width - 2 * paddingX
  const drawnImageHeight = canvas.height - 2 * paddingY
  ctx.drawImage(image, paddingX, paddingY, drawnImageWidth, drawnImageHeight)

  let scaleX = 1.0
  let scaleY = 1.0
  if (aspectRatio > 1.0) {
    scaleY = aspectRatio
  } else {
    scaleX = 1.0 / aspectRatio
  }

  ctx.save()
  ctx.translate(paddingX, paddingY)
  if (drawGroundTruthObjects && groundTruthObjects !== undefined) {
    if (drawGroundTruthDashed) {
      ctx.setLineDash([7, 3])
    }
    for (let i = 0; i < groundTruthObjects.length; i++) {
      let gt = groundTruthObjects[i]
      let classId = gt['label']
      let color = getColorRGB(classId)

      drawObject(
        color,
        ctx,
        canvas,
        gt,
        false,
        scaleX,
        scaleY,
        boxLineWidth,
        drawnImageWidth,
        drawnImageHeight,
        textSize
      )
    }
    ctx.setLineDash([0, 0])
  }

  if (
    predictedObjectClassIds !== undefined &&
    predictedObjectClassIds.length > 0 &&
    predictedObjects !== undefined
  ) {
    for (let i = 0; i < predictedObjects.length; i++) {
      let obj = predictedObjects[i]
      let classId = obj['label']
      let color = getColorRGB(classId)
      drawObject(
        color,
        ctx,
        canvas,
        obj,
        showConfidence,
        scaleX,
        scaleY,
        boxLineWidth,
        drawnImageWidth,
        drawnImageHeight,
        textSize
      )
    }
  }
  ctx.restore()
}

export function drawObject(
  color,
  ctx,
  canvas,
  obj,
  showConfidence,
  scaleX,
  scaleY,
  boxLineWidth,
  imageWidth,
  imageHeight,
  textSize
) {
  let r = color[0]
  let g = color[1]
  let b = color[2]

  ctx.lineWidth = boxLineWidth
  ctx.strokeStyle = `rgb(${r}, ${g}, ${b})`

  let x = obj.x * imageWidth * scaleX
  let y = obj.y * imageHeight * scaleY
  let w = obj.width * imageWidth * scaleX
  let h = obj.height * imageHeight * scaleY
  let rot = -obj.orientation + 2 * Math.PI
  let confidence = obj.confidence

  ctx.translate(x, y)
  ctx.rotate(rot)
  ctx.strokeRect(-0.5 * w, -0.5 * h, w, h)

  if (showConfidence) {
    let padding = 4
    ctx.font = `${textSize}px Arial`
    let displayText = confidence.toFixed(4)
    let textW = ctx.measureText(displayText).width
    let textH = textSize
    let textX = -0.5 * w
    let textY = -0.5 * h

    // draw background rectangle
    ctx.fillStyle = `rgb(${r}, ${g}, ${b})`
    ctx.fillRect(
      textX - boxLineWidth / 2,
      textY - textH - boxLineWidth / 2 - padding / 2,
      textW + padding + boxLineWidth,
      textH + padding
    )

    // draw text
    ctx.fillStyle = 'white'
    ctx.fillText(displayText, textX + padding / 2, textY - boxLineWidth / 2 - padding / 2)
  }

  ctx.rotate(-rot)
  ctx.translate(-x, -y)
}
