import { parseISO } from 'date-fns'

import { format, utcToZonedTime } from 'date-fns-tz'

export function formatTimestamp(timestamp, timezone, formatString) {
  if (formatString === undefined) {
    formatString = 'yyyy-MM-dd HH:mm:ss.SSS'
  }

  try {
    if (timestamp) {
      if (typeof timestamp === 'string') {
        timestamp = new Date(timestamp)
      }
      const t = utcToZonedTime(timestamp, timezone)
      return format(t, formatString, { timeZone: timezone })
    }
  } catch (e) {
    console.error('Invalid timestamp', timestamp)
    return ''
  }
}

export function formatDatetimeURI(datetime, timezone) {
  if (timezone === undefined) {
    timezone = 'UTC'
  }

  try {
    return encodeURIComponent(
      format(datetime, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", { timeZone: timezone })
    )
  } catch (e) {
    console.error('Invalid timestamp', datetime)
    return ''
  }
}

export function formatDatetimeCursorPosition(datetime) {
  try {
    const timezone = 'UTC'
    datetime = parseISO(datetime)
    const t = utcToZonedTime(datetime, timezone)
    return format(t, "yyyy-MM-dd+HH:mm:ss.SSS'Z'", { timeZone: timezone })
  } catch (e) {
    console.error('Invalid timestamp', datetime)
    return ''
  }
}
