<template>
  <b-container class="container-fluid-fill">
    <template v-if="loading">
      <loading-overlay-fullscreen />
    </template>

    <div class="row pt-4 pb-3">
      <div class="col-12 p-3 pl-md-5 pr-md-5 text-center">
        <input
          v-model="datasetSearchString"
          type="text"
          class="form-control"
          placeholder="Search"
          aria-label="Search"
          aria-describedby="search"
        />
      </div>
    </div>
    <div class="row pb-5 pt-0">
      <!--      <div class="col-12 p-0">-->
      <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 p-3">
        <div class="position-relative text-center p-5">
          <div style="height: 1.5em" />
          <div v-b-tooltip.bottom.nofade.hover.d0.o0.right :title="createDatasetTooltip">
            <div
              @click="createDataset"
              :class="{ disabled: createDatasetDisabled }"
              class="add-tile"
            >
              <fai icon="plus" class="font-size-xxx-large" />
              <div class="text-dark mt-3 no-select">create dataset</div>
            </div>
          </div>
          <div
            v-if="!createDatasetDisabled && datasets.length === 0 && !loading"
            class="d-none d-sm-block create-first-dataset-message text-secondary no-select"
          >
            <div class="d-flex justify-content-start">
              <fai icon="arrow-left" class="mt-1 mr-2" />
              <div class="text-left">Create your first dataset to get started</div>
            </div>
          </div>
        </div>
      </div>

      <!--            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 p-3" :key="dataset.id"-->
      <!--                 v-for="dataset in filteredDatasets">-->
      <!--              <div class="dataset-header cursor-pointer"-->
      <!--                   @click="openDataset(dataset)"-->
      <!--                   :style="{'background-image': `url(${dataset.thumbnail})`}">-->
      <!--                <span class="dataset-name">{{ dataset.name }}</span>-->
      <!--              </div>-->
      <!--            </div>-->

      <div
        v-for="dataset in filteredDatasets"
        :key="dataset.id"
        class="col-sm-6 col-md-6 col-lg-4 col-xl-3 p-3"
      >
        <div
          class="dataset-header cursor-pointer d-flex flex-column justify-content-end p-0"
          :style="{ 'background-image': `url(${dataset.thumbnail})` }"
          @click="openDataset(dataset)"
        >
          <div class="dataset-name">
            <span>{{ dataset.name }}</span>
          </div>
        </div>
      </div>

      <!--      <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 p-3" :key="dataset.id"-->
      <!--           v-for="dataset in filteredDatasets">-->
      <!--        <div>-->
      <!--          <img v-if="thumbnails[dataset.id] !== undefined" :src="thumbnails[dataset.id]"-->
      <!--               class="dataset-image">-->
      <!--          <img v-else src="@/assets/dataset-explorer/image-placeholder.png"-->
      <!--               class="dataset-image">-->
      <!--        </div>-->
      <!--        <div class="p-3 dataset-tile-caption">-->
      <!--          <h5><b>{{ dataset.name }}</b><br></h5>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <!--    </div>-->
  </b-container>
</template>

<script>
import LoadingOverlayFullscreen from './LoadingOverlayFullscreen'
import { mapState } from 'vuex'

export default {
  name: 'DatasetOverview',
  components: { LoadingOverlayFullscreen },
  props: {
    datasets: Array,
    loading: Boolean,
  },

  data() {
    return {
      datasetSearchString: '',
      thumbnails: {},
    }
  },

  computed: {
    ...mapState(['user', 'server']),
    createDatasetDisabled() {
      return (
        this.user.organization.max_datasets !== -1 &&
        this.datasets.length >= this.user.organization.max_datasets
      )
    },
    createDatasetTooltip() {
      if (this.createDatasetDisabled) {
        if (this.user.organization.max_datasets === 1) {
          return `Your organization is limited to ${this.user.organization.max_datasets} dataset.`
        }
        return `Your organization is limited to ${this.user.organization.max_datasets} datasets.`
      }
      return ''
    },
    filteredDatasets() {
      return this.datasets.filter((d) => {
        return d.name.toLowerCase().includes(this.datasetSearchString.toLowerCase())
      })
    },
  },

  watch: {
    datasets: function () {
      this.updateThumbnails()
    },
  },

  created() {},

  mounted() {
    this.updateThumbnails()
  },

  methods: {
    createDataset() {
      if (!this.createDatasetDisabled) {
        this.$emit('create-dataset')
      }
    },

    openDataset(dataset) {
      this.$emit('open-dataset', dataset.id)
    },

    updateThumbnails() {
      for (let i = 0; i < this.datasets.length; i++) {
        let dataset = this.datasets[i]
        this.getThumbnail(dataset)
      }
    },

    getThumbnail(dataset) {
      if (!Object.prototype.hasOwnProperty.call(this.thumbnails, dataset.id)) {
        if (dataset.thumbnail === undefined) {
          let app = this
          this.$axios
            .get(`/api/datasets/${dataset.id}/detailed_items?limit=1`)
            .then((response) => {
              if (response.data.results.length > 0) {
                app.$set(app.thumbnails, dataset.id, response.data.results[0].thumbnail)
              }
            })
            .catch(() => {})
        } else {
          this.$set(this.thumbnails, dataset.id, dataset.thumbnail)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../custom';

.dataset-header {
  @extend .border-radius-md;
  @extend .shadow-md;

  min-height: 13rem;
  background-color: $gray-200;
  background-size: cover;
  background-position: center;

  outline: 1px solid $gray-200;
  overflow: hidden;

  .dataset-name {
    //@extend .border-radius-sm;

    border-top: 1px solid $gray-200;
    backdrop-filter: blur(2px);
    width: 100%;
    //color: rgba(0, 0, 0, 0.5);
    background: rgba(255, 255, 255, 0.75);
    font-weight: bold;
    //font-size: 1.125rem;
    padding: 0.5rem 1rem;
    word-wrap: break-word;
  }

  &:hover {
    background-color: $blue-light;
    outline: 1px solid $blue;

    .dataset-name {
      //background-color: $blue-light;
      background-color: rgba(222, 235, 247, 0.85);
    }
  }
}

//.dataset-image {
//  width: 100%;
//  height: 10em;
//  object-fit: cover;
//}

.container-fluid-fill {
  min-height: calc(100vh - 121px);
  /*    height: 100%;*/
}

.add-tile {
  //font-size: 3.5em;
  color: $primary;

  &:not(.disabled):hover {
    color: #00cafe;
    cursor: pointer;
  }
}

.dataset-tile-caption {
  min-height: 7.2em;
}

.disabled {
  cursor: default !important;
  opacity: 0.6;
}

.create-first-dataset-message {
  position: absolute;
  top: 83px;
  left: 220px;
  width: 180px;
}
</style>
