<template>
  <div>
    <b-modal
      id="modal-progress"
      ref="modal-progress"
      v-model="showModal"
      size="md"
      centered
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      :title="title"
    >
      <div>
        <div class="mb-2">
          <span class="mr-2">{{ (100 * (progress / maxProgress)).toFixed(2) }}%</span>
          <span v-if="showAbsolute">({{ progress }} / {{ maxProgress }})</span>
        </div>
        <b-progress :max="maxProgress" height="1.5rem">
          <b-progress-bar :value="progress" animated variant="info">
            <!--            <span><strong>{{ (100 * (progress / maxProgress)).toFixed(2) }}%  ({{-->
            <!--                progress.toFixed(2)-->
            <!--              }} / {{ maxProgress }})</strong></span>-->
          </b-progress-bar>
        </b-progress>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ProgressModal',
  props: {
    title: {
      type: String,
      default: 'Progress',
    },
    show: Boolean,
    showAbsolute: {
      type: Boolean,
      default: true,
    },
    progress: Number,
    maxProgress: {
      type: Number,
      default: 1.0,
    },
  },

  data() {
    return {
      showModal: false,
    }
  },

  computed: {},

  watch: {
    show: function (newValue) {
      this.showModal = newValue
    },

    showModal: function (newValue) {
      this.$emit('show', newValue)
    },
  },

  mounted() {
    this.showModal = this.show
  },

  methods: {},
}
</script>

<style scoped></style>
