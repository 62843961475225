<template>
  <div
    v-if="model"
    :class="{ 'd-flex': !card, highlight: highlightSelection }"
    class="model justify-content-between align-items-center overflow-hidden no-select shadow-sm"
    @click="onClick"
  >
    <div :class="{ 'd-flex': !card }" class="flex-shrink-1 overflow-hidden">
      <div class="mr-2 text-secondary font-size-small">#{{ model.id }}</div>
      <div class="mr-2 text-ellipsis">
        {{ model.name }}
      </div>
      <div v-if="showTrainingState" class="mb-2">
        <template v-if="!jobs">
          <b-badge variant="secondary" class="mr-2">-</b-badge>
          <span class="text-dark font-size-small"></span>
        </template>
        <template v-else-if="trainingState === trainingStates.notTrained">
          <b-badge variant="secondary" class="mr-2">not trained</b-badge>
          <span class="text-dark font-size-small"></span>
        </template>
        <template v-else-if="trainingState === trainingStates.requested">
          <b-badge variant="info" class="mr-2">requested</b-badge>
        </template>
        <template v-else-if="trainingState === trainingStates.enqueued">
          <b-badge variant="info" class="mr-2"> enqueued</b-badge>
        </template>
        <template v-else-if="trainingState === trainingStates.running">
          <b-badge variant="info" class="mr-2">running</b-badge>
        </template>
        <template v-else-if="trainingState === trainingStates.stalling">
          <b-badge variant="warning" class="mr-2">stalling</b-badge>
        </template>
        <template v-else-if="trainingState === trainingStates.finished">
          <b-badge variant="success" class="mr-2">finished</b-badge>
        </template>
        <template v-else-if="trainingState === trainingStates.error">
          <b-badge variant="danger" class="mr-2">error</b-badge>
        </template>
        <template v-else>
          <b-badge variant="secondary" class="mr-2">{{ trainingState }}</b-badge>
        </template>
        <transition name="slow-fade">
          <fai
            v-if="jobsLoading || !jobs"
            icon="spinner"
            class="font-size-small mr-2 rotating"
          ></fai>
        </transition>
      </div>
      <div v-if="showUpdatedDate" class="text-secondary font-size-small">
        <fai icon="clock" class="mr-1" />
        updated {{ model.updated_date | formatDateDuration }}
      </div>
    </div>
    <div class="flex-shrink-0">
      <b-button
        v-if="showDetailsButton"
        class="text-primary"
        variant="link"
        size="sm"
        @click.stop="onClickOpenDetails"
      >
        <fai icon="info-circle" />
      </b-button>
      <!--      <b-dropdown-->
      <!--        boundary="window"-->
      <!--        no-caret-->
      <!--        right-->
      <!--        size="sm"-->
      <!--        variant="link"-->
      <!--      >-->
      <!--        <template slot="button-content">-->
      <!--          <fai :class="{'text-primary': !highlightSelection , 'text-light': highlightSelection}"-->
      <!--               icon="ellipsis-v"></fai>-->
      <!--        </template>-->
      <!--        <b-dropdown-item href="#" @click.stop="onClickOpenDetails">-->
      <!--          details-->
      <!--        </b-dropdown-item>-->
      <!--      </b-dropdown>-->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getTrainingState, TrainingState } from '@/utils'

export default {
  name: 'ModelEntry',
  props: {
    model: {
      type: Object,
      default() {
        return {}
      },
    },
    showTrainingState: {
      type: Boolean,
      default: false,
    },
    showUpdatedDate: {
      type: Boolean,
      default: false,
    },
    showDetailsButton: {
      type: Boolean,
      default: false,
    },
    card: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      trainingStates: TrainingState,
    }
  },
  computed: {
    ...mapState(['jobs', 'jobsLoading']),
    highlightSelection() {
      return this.highlight && this.model.selected
    },
    trainingState() {
      if (this.model) {
        return getTrainingState(this.model, this.jobs)
      }
      return undefined
    },
  },
  methods: {
    onClick(evt) {
      this.$emit('click', evt)
    },
    onClickOpenDetails(evt) {
      this.$emit('click-details', evt)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';

.model {
  @extend .border-radius-md;
  background: $white;

  border-left: 5px solid $white;

  padding: 0.5rem;
  cursor: pointer;

  &:hover {
    background: $blue-light;
    border-left-color: $blue-light;
  }
}

.no-hover {
  cursor: default !important;
  opacity: 1 !important;
}

.highlight {
  border-left-color: $primary;
  //background: $primary;
  //color: $white !important;

  &:hover {
    //background: $blue-variation;
    border-left-color: $blue-variation;
  }
}
</style>
