<template>
  <b-form-group
    v-show="!field.hidden"
    v-bind="formGroupAttributes"
    :label="field.display_name"
    :label-size="size"
    :description="field.description"
  >
    <b-form-input
      :id="$id(field.name)"
      type="text"
      required
      :plaintext="plaintext || field.editable === false"
      :value="value"
      @input="onInput"
      :placeholder="field.display_name"
      :size="size"
    >
    </b-form-input>
  </b-form-group>
</template>

<script>
export default {
  name: 'StringInput',
  props: ['value', 'field', 'plaintext', 'size', 'formGroupAttributes'],
  data() {
    return {}
  },

  mounted() {
    if (this.value === undefined) {
      this.onInput('', true)
    }
  },

  methods: {
    onInput(event, initial) {
      this.$emit('input', event, initial)
    },
  },
}
</script>
