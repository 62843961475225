<template>
  <b-modal
    id="modal-cancel-training"
    ref="modalCancelTraining"
    v-model="showModal"
    :ok-title="okTitle"
    ok-variant="danger"
    cancel-title="Close"
    :title="title"
    size="md"
    @ok="onCancelTraining"
  >
    <div v-if="error">
      <div class="mb-2">The training was terminated with the following message:</div>
      <div class="m-1 p-3 bg-gray-100 text-danger border-radius-sm">{{ error }}</div>
    </div>
    <div v-else class="mb-3">
      When you cancel the training, the worker tries to create and upload a new checkpoint. You will
      be able to resume the training from the new checkpoint.
    </div>
    <template v-if="latestCheckpoint">
      <div>Your current last checkpoint is:</div>
      <div class="m-1 p-3 border-radius-sm bg-gray-100 d-flex">
        <fai icon="file" class="text-dark ml-1 mr-3 mt-1" />
        <div>
          <div>
            <span>{{ latestCheckpoint.iteration }}</span> iterations trained
          </div>
          <div class="font-size-small">
            saved
            {{ latestCheckpoint.updated_date | formatDateDuration }}
          </div>
        </div>
      </div>
    </template>

    <!--    <div class="mt-1 d-flex justify-content-between align-items-stretch">-->
    <!--      <div class="p-2 w-50">-->
    <!--      </div>-->
    <!--      <div class="p-2 w-50">-->
    <!--      </div>-->
    <!--    </div>-->

    <div v-if="lostIterations" class="mt-3">
      You could loose some training progress in case the worker cannot create and upload a new
      checkpoint in time:
      <div class="m-1 p-3 border-radius-sm bg-gray-100 text-center">
        <div class="text-danger font-weight-700">
          {{ lostIterations }}
        </div>
        <div>iterations</div>
      </div>
    </div>

    <div v-if="!error" class="mt-3 text-dark font-size-small">
      It may take up to a few minutes for the training to be entirely terminated and to get the
      worker ready for the next task.
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getModelJobs, getTrainingState, TrainingState } from '@/utils'

export default {
  name: 'CancelTrainingModal',
  props: {
    show: Boolean,
    model: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      showModal: false,
    }
  },

  computed: {
    ...mapState(['jobs']),
    error() {
      const trainingState = getTrainingState(this.model, this.modelJobs)
      if (trainingState === TrainingState.error) {
        if (this.modelJob.details && this.modelJob.details.error) {
          return this.modelJob.details.error
        }
        return 'An error occurred during training.'
      }
      return undefined
    },
    okTitle() {
      if (this.error) {
        return 'Acknowledge Error'
      }
      return 'Cancel Training'
    },
    title() {
      if (this.error) {
        return `Acknowledge error for training of "${this.model.name}"`
      }
      if (this.model) {
        return `Cancel the training for "${this.model.name}"?`
      }
      return `Cancel the training?`
    },
    lostIterations() {
      if (this.modelJob && this.modelJob.current_iteration) {
        if (this.latestCheckpoint) {
          return this.modelJob.current_iteration - this.latestCheckpoint.iteration
        }
        return this.modelJob.current_iteration
      } else {
        return 0
      }
    },
    latestCheckpoint() {
      let latestCheckpoint = undefined
      if (this.model && this.model.checkpoints) {
        for (const checkpoint of this.model.checkpoints) {
          if (!latestCheckpoint) {
            latestCheckpoint = checkpoint
            continue
          }
          if (checkpoint.iteration > latestCheckpoint.iteration) {
            latestCheckpoint = checkpoint
          }
        }
      }

      return latestCheckpoint
    },
    modelJobs() {
      return getModelJobs(this.model, this.jobs)
    },
    modelJob() {
      if (this.modelJobs.length > 0) {
        return this.modelJobs[0]
      }
      return undefined
    },
  },

  watch: {
    show(newValue) {
      this.showModal = newValue
    },

    showModal(newValue) {
      this.$emit('show', newValue)
    },
  },

  mounted() {
    this.showModal = this.show
  },

  methods: {
    ...mapActions(['deleteJob']),
    onCancelTraining(evt) {
      evt.preventDefault()
      const modelJobs = getModelJobs(this.model, this.jobs)
      for (const modelJob of modelJobs) {
        const jobId = modelJob.id
        this.deleteJob({ jobId })
          .then(() => {
            this.$bvToast.toast('Training job successfully removed.', {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 4000,
              solid: true,
            })
            this.showModal = false
          })
          .catch((error) => {
            this.$bvToast.toast('Could not remove job.', {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 4000,
              solid: true,
            })
            console.error(error)
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
