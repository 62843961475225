<template>
  <div v-if="invoice">
    <!--    <div class="font-weight-700">Invoice number: {{ invoice.number }}</div>-->
    <div class="mb-3">
      Billing Address: <br />
      <div v-if="invoice.customer_address">
        {{ invoice.customer_name }} <br />
        {{ invoice.customer_address.line1 }} <br />
        {{ invoice.customer_address.line2 }} <br v-if="invoice.customer_address.line2" />
        {{ invoice.customer_address.postal_code }}
        {{ invoice.customer_address.city }} <br />
        {{ invoice.customer_address.state }}
        {{ invoice.customer_address.country }}
      </div>
      <b-alert v-else show variant="danger">
        No billing address specified. Please update your organization's address.
      </b-alert>
    </div>

    <template v-if="invoice.lines">
      <div
        v-for="(line, i) in invoice.lines"
        :key="`invoice-line-${i}`"
        class="d-flex justify-content-between border-top p-2"
      >
        <div>
          <div class="mr-2">
            {{ line.description }}
          </div>
          <div
            v-if="invoice.mode !== 'change_at_period_end'"
            class="mt-1 font-size-small text-dark"
          >
            period:
            {{ (line.period.start * 1000) | formatTimestampAsDate }}
            until
            {{ (line.period.end * 1000) | formatTimestampAsDate }}
          </div>
        </div>
        <div class="">
          {{ line.amount | formatPrice }}
        </div>
      </div>
    </template>
    <div class="border-top text-right p-2">
      <div class="mt-1">Subtotal: {{ invoice.subtotal | formatPrice }}</div>
      <div class="mt-1">Tax: {{ invoice.tax | formatPrice }}</div>
      <div class="mt-1 font-weight-700">Total: {{ invoice.total | formatPrice }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvoicePreview',
  components: {},
  props: {
    invoice: Object,
  },
}
</script>

<style lang="scss" scoped></style>
