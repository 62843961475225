<template>
  <vue-tags-input
    ref="imageClassLabelsInput"
    v-model="tag"
    placeholder="image class labels"
    class="tags-input"
    :tags="selectedClassLabelTags"
    :autocomplete-items="autoCompleteClassLabels"
    :add-only-from-autocomplete="true"
    :class="{ 'block-input': block }"
    @tags-changed="onClassesChanged"
  />
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input'
import { getColorForCSS, getTextColorForCSS } from '@/colors'

export default {
  name: 'ClassInput',
  components: {
    VueTagsInput,
  },
  props: {
    availableClassLabels: Array,
    selectedClassLabels: Array,
    block: {
      default: false,
    },
  },

  data() {
    return {
      tag: '',
      selectedClassLabelTags: [],
    }
  },

  computed: {
    autoCompleteClassLabels: function () {
      let result = this.availableClassLabels.filter((c) => {
        return c.name.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
      })
      for (const c of result) {
        c.text = c.name
      }
      return result
    },
  },

  watch: {
    selectedClassLabels() {
      this.getSelectedClassLabels()
    },
  },

  mounted() {
    this.getSelectedClassLabels()
  },

  methods: {
    getSelectedClassLabels() {
      let selectedClassLabelTags = []
      if (this.selectedClassLabels) {
        for (const selectedClassLabel of this.selectedClassLabels) {
          selectedClassLabelTags.push({
            id: selectedClassLabel.id,
            text: selectedClassLabel.name,
            style: this.getTagStyle(selectedClassLabel.id),
          })
        }
      }
      this.selectedClassLabelTags = selectedClassLabelTags
    },
    getTagStyle(classId) {
      return `color: ${getTextColorForCSS(classId)}; background-color: ${getColorForCSS(classId)};`
    },
    onClassesChanged(newTags) {
      let selectedClassLabels = []
      for (const newTag of newTags) {
        selectedClassLabels.push({
          id: newTag.id,
          name: newTag.text,
        })
        newTag.style = this.getTagStyle(newTag.id)
      }
      this.selectedClassLabelTags = newTags
      this.$emit('change', selectedClassLabels)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../custom';

.block-input.vue-tags-input {
  max-width: none;
  width: 100%;
}
</style>
