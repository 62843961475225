<template>
  <div>
    <canvas id="canvas" ref="canvas" class="canvas" />
  </div>
</template>

<script>
import _ from 'lodash'
import * as THREE from 'three'
import { PCDLoader } from '@/components/annotation/PCDLoader'
import CustomOrbitControls from '@/components/CustomOrbitControls'

export default {
  name: 'PointCloudCanvas',
  props: ['datasetItem'],
  data() {
    return {
      item: {},
    }
  },

  watch: {
    datasetItem: {
      deep: true,
      handler: function (newItem) {
        if (newItem) {
          this.item = _.cloneDeep(newItem)
          this.loadItem(this.item)
        }
      },
    },
  },

  beforeDestroy() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame)
    }

    if (this.renderer) {
      let loseContextExtension = this.renderer.context.getExtension('WEBGL_lose_context')
      if (loseContextExtension) {
        loseContextExtension.loseContext()
      }
    }
  },

  mounted() {
    this.baseURL = this.$axios.defaults.baseURL

    this.initializeCanvas()

    this.item = _.cloneDeep(this.datasetItem)
    this.loadItem(this.item)

    this.animate()

    this.$nextTick(() => {
      this.$refs.canvas.focus()
    })
  },

  methods: {
    initializeCanvas() {
      try {
        // initialize scene and container
        this.scene = new THREE.Scene()

        this.canvas = this.$refs.canvas
        this.width = this.canvas.clientWidth
        this.height = this.canvas.clientHeight

        if (this.width === 0) this.width = 200
        if (this.height === 0) this.height = 200

        // initialize camera
        this.camera = new THREE.PerspectiveCamera(60, this.width / this.height, 0.1, 100000)
        this.camera.position.set(0.0, 1500.0, 0.0)
        this.camera.up.set(0, 0, -1)
        // this.camera.lookAt(0.0, 0.0, 0.0);
        this.camera.updateProjectionMatrix()
        this.scene.add(this.camera)

        // initialize mouse controls
        this.controls = new CustomOrbitControls(this.camera, this.canvas)
        this.controls.enableRotate = true
        this.controls.minDistance = 0.0
        this.controls.screenSpacePanning = true
        this.controls.zoomSpeed = 2.0

        // initialize renderer
        // Not used at the moment: we need to use the WEbGL1Renderer instead of WebGLRenderer due to an issue with the text shader
        // see https://github.com/Jam3/three-bmfont-text/issues/38 for details
        // this.renderer = new THREE.WebGL1Renderer({canvas: this.canvas, alpha: true, antialias: true})
        this.renderer = new THREE.WebGLRenderer({
          canvas: this.canvas,
          alpha: true,
          antialias: true,
        })
        this.renderer.setClearColor(0xffffff)
      } catch (e) {
        this.errorMessage = 'Could not create WebGL canvas.'
      }
    },

    loadItem(item) {
      const self = this

      if (item) {
        const pcdLoader = new PCDLoader()
        pcdLoader.crossOrigin = true
        pcdLoader.load(item.data, ({ xyz }) => {
          if (self.points) {
            self.scene.remove(self.points)
          }

          self.xyz = xyz
          self.pointsGeometry = new THREE.BufferGeometry()
          self.pointsGeometry.setAttribute(
            'position',
            new THREE.Float32BufferAttribute(self.xyz, 3)
          )
          self.pointsMaterial = new THREE.PointsMaterial({ color: 0x000000, size: 2.0 })
          self.pointsMaterial.vertexColors = THREE.VertexColors
          self.points = new THREE.Points(self.pointsGeometry, self.pointsMaterial)
          self.points.frustumCulled = false
          self.scene.add(self.points)
          // canvas.points.geometry.attributes.color.needsUpdate = true;
        })
      }
    },

    animate() {
      this.animationFrame = requestAnimationFrame(this.animate)
      this.render()
    },

    render() {
      if (!this.renderer) {
        console.warn('renderer is undefined')
        return
      }

      this.width = this.canvas.clientWidth
      this.height = this.canvas.clientHeight
      if (
        (this.canvas.width !== this.width || this.canvas.height !== this.height) &&
        this.width !== 0 &&
        this.height !== 0
      ) {
        this.renderer.setSize(this.width, this.height, false)
        this.camera.aspect = this.width / this.height
        this.camera.updateProjectionMatrix()
      }

      this.controls.update()
      this.renderer.render(this.scene, this.camera)
      this.updateAllObjects = false
      this.updateRenderer = false
    },
  },
}
</script>

<style scoped lang="scss">
.canvas {
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 50px);
}
</style>
