<template>
  <div class="pl-2 pr-2 pt-2 pb-1 mb-1">
    <strong>{{ field.display_name }}</strong>
    <hr />
    <parameter-viewer
      :key="'group' + field.name"
      :fields="field.fields"
      v-bind:value="value"
    ></parameter-viewer>
  </div>
</template>

<script>
export default {
  props: ['value', 'field'],
  name: 'ParamElemGroup',
  components: {
    ParameterViewer: () => import('../ParameterViewer'),
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>

<style scoped>
/*.group {*/
/*  !*border: #dfdfdf 1px solid;*!*/
/*  !*border-radius: 0.25rem;*!*/

/*  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.075);*/
/*  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.075);*/
/*  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.075);*/
/*}*/
</style>
