<template>
  <div class="container">
    <div class="row mt-2">
      <div class="col-12 pt-3">
        <h3>404 - Not Found</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  components: {},
  data() {
    return {}
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.full-height {
  /*min-height: 300px;*/
  min-height: calc(100vh - 70px);
}
</style>
