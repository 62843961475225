<template>
  <div class="container">
    <div class="row mt-3 p-3"><h2>Workers</h2></div>
    <div class="row mt-2 p-3">
      <div class="worker-grid border-radius-md w-100">
        <div
          v-for="worker in workers"
          :key="`worker-${worker.id}`"
          class="worker-grid-item shadow-md border-gray-100 border-radius-md bg-white p-3"
        >
          <worker-entry :worker="worker" @click-delete="onClickDeleteWorker" />
        </div>
        <div class="worker-grid-item text-center">
          <b-button v-b-modal.modal-add-worker variant="link" class="p-4">
            <fai icon="plus" />
            <span class="ml-2">add worker</span>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import WorkerEntry from '@/components/worker/WorkerEntry'

export default {
  name: 'WorkersView',
  components: { WorkerEntry },
  data() {
    return {
      centerLoading: false,
    }
  },

  computed: {
    ...mapState(['workersLoading', 'workers']),
  },

  watch: {},

  created() {},

  mounted() {},

  methods: {
    ...mapActions(['deleteWorker']),
    onClickDeleteWorker(worker) {
      this.$bvModal
        .msgBoxConfirm('Do you want to delete this worker?', {
          title: 'Remove Worker?',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'remove',
          cancelTitle: 'cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            // confirmation
            this.deleteWorker({ workerId: worker.id })
          } else {
            // cancel
          }
        })
        .catch(() => {
          // An error occurred
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../custom';

.worker-grid {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  align-items: start;
}

.worker-grid-item-group {
  grid-column: 1/-1;
  padding-top: 0.25em;
}

.worker-grid-item {
  margin-bottom: 0.25em;
}
</style>
