<template>
  <div>
    <b-dropdown boundary="window" no-caret right :size="size" variant="link">
      <template #button-content>
        <fai
          :class="{ 'text-primary': !model.selected, 'text-light': model.selected }"
          icon="ellipsis-v"
        />
      </template>
      <b-dropdown-item-button v-if="refresh" @click.stop="onClickRefresh">
        refresh
      </b-dropdown-item-button>
      <b-dropdown-item-button v-if="details" @click.stop="onClickDetails">
        details
      </b-dropdown-item-button>
      <b-dropdown-item-button v-if="rename" @click.stop="onClickRename">
        rename
      </b-dropdown-item-button>
      <b-dropdown-item-button v-if="duplicate" @click.stop="onClickDuplicate">
        duplicate
      </b-dropdown-item-button>
      <b-dropdown-item-button
        v-if="startOrStopTraining && !trainingActive"
        @click.stop="onClickStartTraining"
      >
        start training
      </b-dropdown-item-button>
      <b-dropdown-item-button
        v-if="startOrStopTraining && trainingActive"
        variant="danger"
        @click.stop="onClickStopTraining"
      >
        stop training
      </b-dropdown-item-button>
      <b-dropdown-item-button
        v-if="deleteModel && deleteModelPermission"
        :disabled="trainingActive"
        variant="danger"
        @click.stop="onClickDelete"
      >
        delete
      </b-dropdown-item-button>
    </b-dropdown>
    <delete-model-modal
      v-if="deleteModel"
      :model="model"
      :show="modals.deleteModel.show"
      @show="modals.deleteModel.show = $event"
    />
    <start-training-modal
      v-if="startOrStopTraining"
      :model="model"
      :show="modals.startTraining.show"
      @show="modals.startTraining.show = $event"
    />
    <cancel-training-modal
      v-if="startOrStopTraining"
      :model="model"
      :show="modals.stopTraining.show"
      @show="modals.stopTraining.show = $event"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DeleteModelModal from '@/components/modals/DeleteModelModal'
import StartTrainingModal from '@/components/modals/StartTrainingModal'
import CancelTrainingModal from '@/components/modals/CancelTrainingModal'

export default {
  name: 'ModelDropdown',
  components: { CancelTrainingModal, StartTrainingModal, DeleteModelModal },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      },
    },
    size: {
      type: String,
      default: 'sm',
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Boolean,
      default: true,
    },
    rename: {
      type: Boolean,
      default: false,
    },
    duplicate: {
      type: Boolean,
      default: false,
    },
    deleteModel: {
      type: Boolean,
      default: true,
    },
    startOrStopTraining: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modals: {
        deleteModel: {
          show: false,
        },
        startTraining: {
          show: false,
        },
        stopTraining: {
          show: false,
        },
      },
    }
  },
  computed: {
    ...mapState(['jobs']),
    ...mapGetters(['deleteModelPermission']),
    trainingActive() {
      if (this.model && this.jobs) {
        for (const job of this.jobs) {
          if (job.model.id === this.model.id) {
            return true
          }
        }
      }
      return false
    },
  },
  methods: {
    ...mapActions(['openAiModal']),
    onClickRefresh() {
      this.$emit('click-refresh')
    },
    onClickDetails() {
      this.openAiModal(this.model)
    },
    onClickRename() {},
    onClickDuplicate() {},
    onClickStartTraining() {
      this.modals.startTraining.show = true
    },
    onClickStopTraining() {
      this.modals.stopTraining.show = true
    },
    onClickDelete() {
      this.modals.deleteModel.show = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../custom';
</style>
