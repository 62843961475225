<template>
  <div>
    <div class="row m-0 pt-4 pl-4 pr-4 pb-2">
      <div class="w-100 d-flex justify-content-between">
        <div class="pl-2">
          <h5 class="m-0">
            <fai icon="user" class="mr-2" />
            <b>Users</b>
          </h5>
        </div>
        <b-button v-b-modal.modal-create-user variant="success"> create user</b-button>
      </div>
    </div>
    <div class="row m-0 p-3">
      <div class="col-12">
        <user-table
          :users="users"
          :organization-license-valid="organization && organization.license_valid"
          @edit-user="onEditUser"
          @delete-user="onDeleteUser"
          @assign-license="onAssignLicense"
          @unassign-license="onUnassignLicense"
          @change-role="onChangeRole"
          @change-password="onChangePassword"
        />
      </div>
    </div>

    <b-modal
      id="modal-create-user"
      ref="modalCreateUser"
      title="Create User"
      size="lg"
      @ok="handleCreateUserOk"
    >
      <div>
        <b-form @submit.stop.prevent="createUser">
          <b-form-group
            id="group-registration-username"
            label-cols-md="4"
            label-for="input-registration-username"
            label="Username"
            invalid-feedback="This is a required field."
          >
            <b-form-input
              id="input-registration-username"
              v-model="$v.newUserForm.username.$model"
              type="text"
              :state="validateNewUserForm('username')"
              required
              placeholder="Username"
            />
          </b-form-group>

          <b-form-group
            id="group-registration-email"
            label-cols-md="4"
            label-for="input-registration-email"
            label="Email"
            invalid-feedback="Please insert a valid email address."
          >
            <b-form-input
              id="input-registration-email"
              v-model="$v.newUserForm.email.$model"
              type="text"
              :state="validateNewUserForm('email')"
              required
              placeholder="Email"
            />
          </b-form-group>

          <b-form-group
            id="group-registration-first-name"
            label-cols-md="4"
            label-for="input-registration-first-name"
            label="First Name"
            invalid-feedback="This is a required field."
          >
            <b-form-input
              id="input-registration-first-name"
              v-model="$v.newUserForm.firstName.$model"
              type="text"
              :state="validateNewUserForm('firstName')"
              required
              placeholder="First Name"
            />
          </b-form-group>

          <b-form-group
            id="group-registration-last-name"
            label-cols-md="4"
            label-for="input-registration-last-name"
            label="Last Name"
            invalid-feedback="This is a required field."
          >
            <b-form-input
              id="input-registration-last-name"
              v-model="$v.newUserForm.lastName.$model"
              type="text"
              :state="validateNewUserForm('lastName')"
              required
              placeholder="Last Name"
            />
          </b-form-group>

          <b-form-group
            id="group-registration-password"
            label-cols-md="4"
            label-for="input-registration-password"
            label="Password"
            invalid-feedback="The password must contain at least eight characters."
          >
            <b-form-input
              id="input-registration-password"
              v-model="$v.newUserForm.password.$model"
              type="password"
              placeholder="Password"
              :state="validateNewUserForm('password')"
              required
            />
          </b-form-group>

          <b-form-group
            id="group-registration-repeat-password"
            label-cols-md="4"
            label-for="input-registration-repeat-password"
            label="Repeat Password"
            invalid-feedback="The repeated password must match."
          >
            <b-form-input
              id="input-registration-repeat-password"
              v-model="$v.newUserForm.repeatPassword.$model"
              type="password"
              placeholder="Repeated Password"
              :state="validateNewUserForm('repeatPassword')"
              required
            />
          </b-form-group>
        </b-form>
      </div>
    </b-modal>

    <b-modal
      id="modal-edit-user"
      ref="modalEditUser"
      title="Edit User"
      size="lg"
      @ok="handleEditUserOk"
    >
      <div>
        <b-form @submit.stop.prevent="editUser">
          <b-form-group
            id="group-edit-user-username"
            label-cols-md="4"
            label-for="input-edit-user-username"
            label="Username"
            invalid-feedback="This is a required field."
          >
            <b-form-input
              id="input-edit-user-username"
              v-model="$v.changeUserForm.username.$model"
              type="text"
              :state="validateChangeUserForm('username')"
              required
              placeholder="Username"
            />
          </b-form-group>

          <b-form-group
            id="group-edit-user-email"
            label-cols-md="4"
            label-for="input-edit-user-email"
            label="Email"
            invalid-feedback="Please insert a valid email address."
          >
            <b-form-input
              id="input-edit-user-email"
              v-model="$v.changeUserForm.email.$model"
              type="text"
              :state="validateChangeUserForm('email')"
              required
              placeholder="Email"
            />
          </b-form-group>

          <b-form-group
            id="group-edit-user-first-name"
            label-cols-md="4"
            label-for="input-edit-user-first-name"
            label="First Name"
            invalid-feedback="This is a required field."
          >
            <b-form-input
              id="input-edit-user-first-name"
              v-model="$v.changeUserForm.firstName.$model"
              type="text"
              :state="validateChangeUserForm('firstName')"
              required
              placeholder="First Name"
            />
          </b-form-group>

          <b-form-group
            id="group-edit-user-last-name"
            label-cols-md="4"
            label-for="input-edit-user-last-name"
            label="Last Name"
            invalid-feedback="This is a required field."
          >
            <b-form-input
              id="input-edit-user-last-name"
              v-model="$v.changeUserForm.lastName.$model"
              type="text"
              :state="validateChangeUserForm('lastName')"
              required
              placeholder="Last Name"
            />
          </b-form-group>
        </b-form>
      </div>
    </b-modal>

    <b-modal
      id="modal-change-password"
      ref="modalChangePassword"
      title="Change Password"
      size="lg"
      @ok="handleChangePasswordOk"
    >
      <div>
        <p v-if="passwordChangeUser">
          Change the password of
          {{ getUserDisplayName(passwordChangeUser) }}.
        </p>
        <b-form @submit.stop.prevent="changePassword">
          <b-form-group
            id="group-change-password-password"
            label-cols-md="4"
            label-for="input-change-password-password"
            label="Password"
            invalid-feedback="The password must contain at least eight characters."
          >
            <b-form-input
              id="input-registration-password"
              v-model="$v.passwordForm.password.$model"
              type="password"
              placeholder="Password"
              :state="validatePasswordForm('password')"
              required
            />
          </b-form-group>
          <b-form-group
            id="group-change-password-repeat-password"
            label-cols-md="4"
            label-for="input-registration-repeat-password"
            label="Repeat Password"
            invalid-feedback="The repeated password must match."
          >
            <b-form-input
              id="input-change-password-repeat-password"
              v-model="$v.passwordForm.repeatPassword.$model"
              type="password"
              placeholder="Repeated Password"
              :state="validatePasswordForm('repeatPassword')"
              required
            />
          </b-form-group>
        </b-form>
      </div>
    </b-modal>

    <b-modal
      id="modal-change-role"
      ref="modalChangeRole"
      title="Change Role"
      size="lg"
      @ok="handleChangeRoleOk"
    >
      <div>
        <p v-if="changeRoleUser">Change the role of {{ getUserDisplayName(changeRoleUser) }}.</p>
        <b-form @submit.stop.prevent="changeRole">
          <b-form-group
            id="group-change-role"
            label-cols-md="4"
            label-for="select-change-role"
            label="Password"
            invalid-feedback="The password must contain at least eight characters."
          >
            <b-form-select id="select-change-role" v-model="newUserRole" :options="userRoles" />
          </b-form-group>
        </b-form>
      </div>
    </b-modal>

    <b-modal
      id="modal-assign-license"
      ref="modalAssignLicense"
      title="Assign License"
      size="lg"
      @ok="handleAssignLicenseOk"
    >
      <div>
        <b-alert v-if="licenseAssignementError !== ''" variant="danger">
          {{ licenseAssignementError }}
        </b-alert>
        <p v-if="assignLicenseUser">
          Assign a license to {{ getUserDisplayName(assignLicenseUser) }}.
        </p>
        <b-form @submit.stop.prevent="assignLicense">
          <h5 class="mb-2">
            <b>Annotation</b>
          </h5>
          <compact-license-table
            :licenses="licenses"
            :selected-license-id="selectedLicenseId"
            hoverable
            license-type="Annotation"
            @select-license="selectedLicenseId = $event"
          />

          <h5 class="mb-2">
            <b>Engineering</b>
          </h5>
          <compact-license-table
            :licenses="licenses"
            :selected-license-id="selectedLicenseId"
            hoverable
            license-type="Engineering"
            @select-license="selectedLicenseId = $event"
          />
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import UserTable from './UserTable'
import { email, minLength, required, sameAs } from 'vuelidate/lib/validators'
import CompactLicenseTable from '@/components/organization-settings/CompactLicenseTable'

export default {
  name: 'UsersSettings',
  components: { CompactLicenseTable, UserTable },
  props: {
    users: Array,
    licenses: Array,
    organization: Object,
  },

  data() {
    return {
      loading: true,
      newUserForm: {
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        repeatPassword: '',
      },

      passwordChangeUser: undefined,
      passwordForm: {
        password: '',
        repeatPassword: '',
      },

      changeUser: undefined,
      changeUserForm: {
        username: '',
        email: '',
        firstName: '',
        lastName: '',
      },

      changeRoleUser: undefined,
      newUserRole: 'member',
      userRoles: [
        { text: 'Admin', value: 'admin' },
        { text: 'Member', value: 'member' },
      ],

      assignLicenseUser: undefined,
      selectedLicenseId: undefined,
      licenseAssignementError: '',
    }
  },
  validations: {
    newUserForm: {
      username: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      firstName: { required },
      lastName: { required },
      password: {
        required,
        minLength: minLength(8),
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },

    passwordForm: {
      password: {
        required,
        minLength: minLength(8),
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },

    changeUserForm: {
      username: {
        required,
        minLength: minLength(3),
      },
      email: {
        // required,
        email,
      },
      firstName: {},
      lastName: {},
    },
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
  },

  created() {},

  mounted() {},

  methods: {
    validateNewUserForm(name) {
      const { $dirty, $error } = this.$v.newUserForm[name]
      return $dirty ? !$error : null
    },
    validatePasswordForm(name) {
      const { $dirty, $error } = this.$v.passwordForm[name]
      return $dirty ? !$error : null
    },
    validateChangeUserForm(name) {
      const { $dirty, $error } = this.$v.changeUserForm[name]
      return $dirty ? !$error : null
    },
    handleCreateUserOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.createUser()
    },
    getUserDisplayName(user) {
      let displayName = user.username
      if (
        !(user.first_name === '' || user.first_name === undefined) ||
        !(user.last_name === '' || user.last_name === undefined)
      ) {
        displayName = `${user.first_name} ${user.last_name} (${user.username})`
      }
      return displayName
    },
    createUser() {
      this.$v.newUserForm.$touch()
      if (!this.$v.newUserForm.$invalid) {
        let data = {
          username: this.newUserForm.username,
          first_name: this.newUserForm.firstName,
          last_name: this.newUserForm.lastName,
          email: this.newUserForm.email,
          password: this.newUserForm.password,
          organization: this.organization.id,
        }

        this.$axios({
          method: 'post',
          url: `/api/organizations/${this.organization.id}/create_user/`,
          data: data,
          withCredentials: true,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': Cookie.get('csrftoken'),
          },
        })
          .then((response) => {
            this.$emit('user-created', response.data)
            this.$nextTick(() => {
              this.$bvModal.hide('modal-create-user')
              this.$bvToast.toast('New user created.', {
                title: 'Success',
                variant: 'success',
                autoHideDelay: 4000,
                solid: true,
              })
            })
          })
          .catch(() => {
            this.$nextTick(() => {
              this.$bvModal.hide('modal-create-user')
              this.$bvToast.toast('Could not create user.', {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 4000,
                solid: true,
              })
            })
          })
      }
    },

    onEditUser(user) {
      this.changeUserForm.username = user.username
      this.changeUserForm.firstName = user.first_name
      this.changeUserForm.lastName = user.last_name
      this.changeUserForm.email = user.email
      this.$refs.modalEditUser.show()
      this.changeUser = user
    },
    handleEditUserOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.editUser()
    },
    editUser() {
      this.$v.changeUserForm.$touch()
      if (!this.$v.changeUserForm.$invalid) {
        let data = {
          username: this.changeUserForm.username,
          first_name: this.changeUserForm.firstName,
          last_name: this.changeUserForm.lastName,
          email: this.changeUserForm.email,
        }
        this.$axios({
          method: 'patch',
          url: `/api/organizations/${this.organization.id}/change_user/`,
          params: {
            username: this.changeUser.username,
          },
          data: data,
          withCredentials: true,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': Cookie.get('csrftoken'),
          },
        })
          .then((response) => {
            this.$nextTick(() => {
              this.$bvModal.hide('modal-edit-user')
              this.$bvToast.toast('User properties saved.', {
                title: 'Success',
                variant: 'success',
                autoHideDelay: 4000,
                solid: true,
              })
              let user = response.data
              this.$emit('user-changed', user)
            })
          })
          .catch(() => {
            this.$bvToast.toast('Could not save the new user properties.', {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 4000,
              solid: true,
            })
          })
      }
    },

    onChangeRole(user) {
      if (user.username === this.user.username) {
        this.$bvModal
          .msgBoxOk('You cannot change your own role.', {
            title: 'Confirmation',
          })
          .then(() => {})
          .catch(() => {})
        return
      }
      this.changeRoleUser = user
      this.newUserRole = user.organization_role
      this.$refs.modalChangeRole.show()
    },
    handleChangeRoleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.changeRole()
    },
    changeRole() {
      let data = {
        organization_role: this.newUserRole,
      }
      this.$axios({
        method: 'patch',
        url: `/api/organizations/${this.organization.id}/change_user/`,
        params: {
          username: this.changeRoleUser.username,
        },
        data: data,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
        .then((response) => {
          this.$nextTick(() => {
            this.$bvModal.hide('modal-change-role')
            this.$bvToast.toast('User role saved.', {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 4000,
              solid: true,
            })
            let user = response.data
            this.$emit('user-changed', user)
          })
        })
        .catch(() => {
          this.$bvToast.toast('Could change the role of the user.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
        })
    },

    onChangePassword(user) {
      this.passwordForm.password = ''
      this.passwordForm.repeatPassword = ''
      this.passwordChangeUser = user
      this.$refs.modalChangePassword.show()
    },
    handleChangePasswordOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.changePassword()
    },
    changePassword() {
      this.$v.passwordForm.$touch()
      if (!this.$v.passwordForm.$invalid) {
        this.$axios({
          method: 'patch',
          url: `/api/organizations/${this.organization.id}/change_user/`,
          params: {
            username: this.passwordChangeUser.username,
          },
          withCredentials: true,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': Cookie.get('csrftoken'),
          },
          data: {
            password: this.passwordForm.password,
          },
        })
          .then((response) => {
            this.$nextTick(() => {
              this.$bvModal.hide('modal-change-password')
              this.$bvToast.toast('Password changed.', {
                title: 'Success',
                variant: 'success',
                autoHideDelay: 4000,
                solid: true,
              })
              this.$emit('password-changed', response.data)
              this.passwordForm.password = ''
              this.passwordForm.repeatPassword = ''
            })
          })
          .catch(() => {
            this.$bvToast.toast('Could not change password.', {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 4000,
              solid: true,
            })
          })
      }
    },

    onDeleteUser(user) {
      if (user.username === this.user.username) {
        this.$bvModal
          .msgBoxOk('You cannot delete yourself.', {
            title: 'Confirmation',
          })
          .then(() => {})
          .catch(() => {})
        return
      }
      let displayName = this.getUserDisplayName(user)
      this.$bvModal
        .msgBoxConfirm(`Are you sure that you want to delete the user ${displayName}?`, {
          title: `Delete User`,
          okVariant: 'danger',
          okTitle: 'Delete',
        })
        .then((value) => {
          if (value) {
            this.$axios({
              method: 'delete',
              url: `/api/organizations/${this.organization.id}/delete_user/`,
              params: {
                username: user.username,
              },
              withCredentials: true,
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': Cookie.get('csrftoken'),
              },
            })
              .then(() => {
                this.$emit('user-deleted', user)
                this.$bvToast.toast('User deleted.', {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 4000,
                  solid: true,
                })
              })
              .catch(() => {
                this.$bvToast.toast('Could not delete user.', {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 4000,
                  solid: true,
                })
              })
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err)
        })
    },

    onAssignLicense(user) {
      this.assignLicenseUser = user
      this.selectedLicenseId = undefined
      this.licenseAssignementError = ''
      this.$refs.modalAssignLicense.show()
    },
    handleAssignLicenseOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.assignLicense()
    },
    assignLicense() {
      if (this.selectedLicenseId === undefined) {
        this.licenseAssignementError = 'You need to select a valid license.'
      }
      let data = {
        license: this.selectedLicenseId,
      }
      this.$axios({
        method: 'patch',
        url: `/api/organizations/${this.organization.id}/change_user/`,
        params: {
          username: this.assignLicenseUser.username,
        },
        data: data,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      })
        .then(() => {
          this.$emit('license-changed', {
            previousUser: null,
            user: this.assignLicenseUser.id,
            license: this.selectedLicenseId,
          })
          this.assignLicenseUser = undefined
          this.$bvModal.hide('modal-assign-license')
          this.$bvToast.toast('Assigned license to user.', {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 4000,
            solid: true,
          })
        })
        .catch(() => {
          this.$bvToast.toast('Could not assign license to user.', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 4000,
            solid: true,
          })
        })
    },

    onUnassignLicense(user) {
      let displayName = this.getUserDisplayName(user)
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure that you want to unassign the license from the user ${displayName}?`,
          {
            title: `Unassign License`,
            okVariant: 'danger',
            okTitle: 'Unassign',
          }
        )
        .then((value) => {
          if (value) {
            let data = {
              license: null,
            }
            this.$axios({
              method: 'patch',
              url: `/api/organizations/${this.organization.id}/change_user/`,
              params: {
                username: user.username,
              },
              data: data,
              withCredentials: true,
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': Cookie.get('csrftoken'),
              },
            })
              .then(() => {
                this.$emit('license-changed', {
                  previousUser: user.id,
                  user: null,
                  license: user.license,
                })
                this.$bvToast.toast('Unassigned user from license.', {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 4000,
                  solid: true,
                })
              })
              .catch(() => {
                this.$bvToast.toast('Could not unassign license from user.', {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 4000,
                  solid: true,
                })
              })
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err)
        })
    },
  },
}
</script>

<style scoped></style>
