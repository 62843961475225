<template>
  <b-modal
    id="modal-delete-model"
    ref="modalDeleteModel"
    v-model="showModal"
    size="lg"
    title="Delete model"
    @ok="deleteSelectedModel"
  >
    <div>
      <template v-if="deleteModelPermission">
        <b-alert v-if="model" variant="danger" show>
          Are you absolutely sure you wish to delete this model ({{ model.name }}) and all
          associated statistics, inference results, checkpoints, and model files?
        </b-alert>
        <b-form-group
          id="deleteModelNameGroup"
          label="Model Name"
          label-for="deleteModelName"
          description="Confirm to delete the correct model by entering its name."
        >
          <b-form-input
            id="deleteModelName"
            v-model="deleteModelName"
            type="text"
            required
            placeholder="Name"
          />
        </b-form-group>
      </template>
      <template v-else>
        <b-alert variant="danger" show>
          You don't have the permission to delete this model.
        </b-alert>
      </template>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DeleteModelModal',
  props: {
    show: Boolean,
    model: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      showModal: false,
      deleteModelName: '',
    }
  },

  computed: {
    ...mapGetters(['deleteModelPermission']),
  },

  watch: {
    show(newValue) {
      this.showModal = newValue
    },

    showModal(newValue) {
      this.$emit('show', newValue)
    },
  },

  mounted() {
    this.showModal = this.show
  },

  methods: {
    ...mapActions(['deleteModel']),
    deleteSelectedModel(evt) {
      evt.preventDefault()
      let modelId = this.model.id

      if (this.deleteModelName === this.model.name) {
        this.deleteModel({ modelId })
          .then(() => {
            this.$bvToast.toast('Model deleted.', {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 4000,
              solid: true,
            })

            this.deleteModelName = ''
            this.$nextTick(() => {
              this.showModal = false
            })
          })
          .catch((error) => {
            console.error(error)
            this.$bvToast.toast('Could not delete model.', {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 4000,
              solid: true,
            })
          })
      } else {
        this.$bvToast.toast('Please enter the correct name of the model that should be removed.', {
          title: 'Could not delete model.',
          variant: 'danger',
          autoHideDelay: 4000,
          solid: true,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
