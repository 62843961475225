<template>
  <div class="d-flex flex-column justify-content-start h-100 mh-100 position-relative">
    <div class="pl-0 pt-3 pr-3 pb-2 flex-wrap d-flex justify-content-start align-items-center">
      <loading-overlay v-if="items.length === 0 && itemsLoading > 0" />

      <!--      <div class="ml-2">-->
      <!--        <b-input-group size="sm" class="flex-nowrap">-->
      <!--          <b-button-group>-->
      <!--            <b-button-->
      <!--              @click="openFilterSettings"-->
      <!--              variant="outline-primary"-->
      <!--              size="sm">-->
      <!--              <fai icon="filter" class="mb-1 mr-1"></fai>-->
      <!--              filters-->
      <!--            </b-button>-->
      <!--          </b-button-group>-->
      <!--        </b-input-group>-->
      <!--      </div>-->

      <!--      <div class="ml-2" v-if="selectedDataset.classification">-->
      <!--        <b-input-group-->
      <!--          prepend="Image Labels"-->
      <!--          size="sm"-->
      <!--          class="flex-nowrap">-->
      <!--          <b-input-group-append is-text>-->
      <!--            <input type="checkbox" v-model="showImageLabels"/>-->
      <!--          </b-input-group-append>-->
      <!--        </b-input-group>-->
      <!--      </div>-->
      <!--      <div class="ml-2" v-if="selectedDataset.object_detection">-->
      <!--        <b-input-group-->
      <!--          prepend="Objects"-->
      <!--          size="sm"-->
      <!--          class="flex-nowrap">-->
      <!--          <b-input-group-append is-text>-->
      <!--            <input type="checkbox" v-model="showObjects"/>-->
      <!--          </b-input-group-append>-->
      <!--        </b-input-group>-->
      <!--      </div>-->
      <div class="ml-2">
        <b-input-group class="flex-nowrap" size="sm">
          <b-button-group>
            <b-button size="sm" variant="outline-primary" @click="openFilterSettings">
              <fai class="mr-1" icon="filter" />
              filters
            </b-button>
          </b-button-group>
        </b-input-group>
      </div>

      <div class="ml-2">
        <b-input-group class="flex-nowrap" size="sm">
          <b-input-group-prepend is-text> Size</b-input-group-prepend>
          <b-form-input
            v-model="itemsPerRowReversed"
            :max="maxItemsPerRow - 2"
            min="0"
            size="sm"
            step="1"
            type="range"
          />
        </b-input-group>
      </div>

      <template v-if="model.network_type === 'classification'">
        <div class="ml-2">
          <b-input-group class="flex-nowrap" prepend="Class Labels" size="sm">
            <b-input-group-append is-text>
              <input v-model="showPredictedLabels" type="checkbox" />
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="ml-2">
          <b-input-group class="flex-nowrap" prepend="Confidence Histogram" size="sm">
            <b-input-group-append is-text>
              <input v-model="showConfidenceHistogram" type="checkbox" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </template>

      <template v-if="model.network_type === 'detection'">
        <div class="ml-2">
          <b-input-group class="flex-nowrap" size="sm">
            <b-input-group-prepend is-text> Selectivity</b-input-group-prepend>
            <b-form-input
              v-model="selectivity"
              max="1"
              min="0"
              size="sm"
              step="0.001"
              type="range"
            />
            <b-form-input
              v-model="selectivity"
              max="1"
              min="0"
              size="sm"
              step="0.0001"
              style="max-width: 6rem"
              type="number"
            />
          </b-input-group>
        </div>
        <div class="ml-2">
          <b-input-group class="flex-nowrap" prepend="Objects" size="sm">
            <b-input-group-append is-text>
              <input v-model="showPredictedObjects" type="checkbox" />
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="ml-2">
          <b-input-group class="flex-nowrap" prepend="Show Confidence" size="sm">
            <b-input-group-append is-text>
              <input v-model="showConfidence" type="checkbox" />
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="ml-2">
          <b-input-group class="flex-nowrap" prepend="Show Ground Truth" size="sm">
            <b-input-group-append is-text>
              <input v-model="showTrueObjects" type="checkbox" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </template>
    </div>
    <div
      ref="itemViewerWrapper"
      class="scroll-box-always flex-grow-1 bg-gray-200 border-radius-lg ml-2"
      @scroll="onScrollItemViewer"
    >
      <item-viewer
        v-if="items.length > 0"
        :dataset-items="items"
        :draw-ground-truth-dashed="true"
        :item-size="itemSize"
        :loading="itemsLoading > 0"
        :object-selectivity="objectSelectivity"
        :predicted-object-class-ids="predictedObjectClassIds"
        :selection-rectangle-enabled="false"
        :show-confidence="showConfidence"
        :show-confidence-histogram="isClassificationModel && showConfidenceHistogram"
        :show-filename="false"
        :show-labels-in-overlay="true"
        :show-objects="isDetectionModel && showTrueObjects"
        :show-predicted-labels="isClassificationModel && showPredictedLabels"
        :show-predicted-objects="isDetectionModel && showPredictedObjects"
        :show-status="false"
        :show-subsets="false"
        :show-timestamp="false"
        :show-true-labels="isClassificationModel && showPredictedLabels"
        container-class="p-2"
        timezone="UTC"
        @click="onClickItem"
        @scroll-adjustment-required="onScrollAdjustmentRequired"
      />
      <div v-else-if="itemsLoading === 0" class="text-secondary p-5">no items to show</div>
    </div>
    <item-preview-modal
      v-if="items.length > 0 && modals.itemPreview.item"
      :draw-ground-truth-dashed="true"
      :is-first-item="modals.itemPreview.item.id === items[0].id"
      :is-last-item="modals.itemPreview.item.id === items[items.length - 1].id"
      :item="modals.itemPreview.item"
      :object-selectivity="objectSelectivity"
      :predicted-object-class-ids="predictedObjectClassIds"
      :selection-rectangle-enabled="false"
      :show="modals.itemPreview.show"
      :show-confidence="showConfidence"
      :show-image-labels="isClassificationModel && showTrueLabels"
      :show-objects="isDetectionModel && showTrueObjects"
      :show-predicted-labels="isClassificationModel && showPredictedLabels"
      :show-predicted-objects="isDetectionModel && showPredictedObjects"
      @show="modals.itemPreview.show = $event"
      @open-previous="openPreviousItem"
      @open-next="openNextItem"
    />
    <filter-settings-modal
      :attributes="[]"
      :filter-settings="filterSettings"
      :image-class-labels="classificationClassLabels"
      :object-class-labels="objectClassLabels"
      :show="modals.filterSettings.show"
      :show-classification-filters="isClassificationModel"
      :show-detection-filters="isDetectionModel"
      :show-not-part-of-any-subset-option="false"
      :show-status-filter="false"
      :show-time-range-filter="false"
      :subsets="subsets"
      @show="modals.filterSettings.show = $event"
      @new-settings="applyFilterSettings"
    />
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'

import ItemViewer from './ItemViewer'
import { getFilterQuery } from '../../filter'
import ItemPreviewModal from '../modals/ItemPreviewModal'
import LoadingOverlay from '../LoadingOverlay'
import FilterSettingsModal from '../modals/FilterSettingsModal'

export default {
  name: 'InferenceResultViewer',
  components: { FilterSettingsModal, LoadingOverlay, ItemPreviewModal, ItemViewer },
  props: {
    model: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      itemsPerRowReversed: 6,
      maxItemsPerRow: 12,
      showConfidence: true,
      showConfidenceHistogram: false,
      showTrueLabels: true,
      showPredictedLabels: true,
      showTrueObjects: false,
      showPredictedObjects: true,
      selectivity: 0.3,
      items: [],
      itemsLoading: 0,
      modals: {
        itemPreview: {
          show: false,
          item: undefined,
        },
        filterSettings: {
          show: false,
        },
      },
      earliestPreviousLink: undefined,
      latestNextLink: undefined,
      itemCursor: {
        position: undefined,
        reversed: false,
      },
      filterSettings: {
        status: [],
        imageClass: null,
        objectClass: null,
        dataSubset: null,
        attributes: [],
        dateTimeFrom: null,
        dateTimeTo: null,
        correctClassificationClass: null,
      },
    }
  },

  computed: {
    ...mapState(['classLabelMap', 'classLabels']),
    itemsPerRow() {
      return this.maxItemsPerRow - this.itemsPerRowReversed
    },
    itemSize() {
      return 1 / this.itemsPerRow - 1e-4
    },
    predictedObjectClassIds() {
      if (this.model && this.model.class_labels) {
        const classLabelIds = []
        for (const c of this.model.class_labels) {
          let classLabelId = c.target_class_label ? c.target_class_label : c.class_label
          classLabelIds.push(classLabelId)
        }
        return classLabelIds
      }
      return []
    },
    objectSelectivity() {
      return parseFloat(this.selectivity)
    },
    objectClassLabels() {
      let result = []
      if (this.model && this.model.network_type === 'detection') {
        for (const classLabelId of this.model.class_labels) {
          const cls = this.classLabelMap[classLabelId]
          if (cls) {
            result.push(cls)
          }
        }
      }
      return result
    },
    classificationClassLabels() {
      let result = []
      if (this.model && this.model.network_type === 'classification') {
        for (const classLabelId of this.model.class_labels) {
          const cls = this.classLabelMap[classLabelId]
          if (cls) {
            result.push(cls)
          }
        }
      }
      return result
    },
    subsets() {
      let subsets = {}
      if (this.model.data_subsets) {
        for (let i = 0; i < this.model.data_subsets.length; i++) {
          let modelSubset = this.model.data_subsets[i]
          if (['classification_test', 'detection_test'].includes(modelSubset.category)) {
            if (modelSubset.data_subset.id) {
              subsets[modelSubset.data_subset.id] = {
                id: modelSubset.data_subset.id,
                name: `${modelSubset.data_subset.name} (${modelSubset.data_subset.dataset_name})`,
              }
            }
          }
        }
      }
      return subsets
    },
    isClassificationModel() {
      return this.model.network_type === 'classification'
    },
    isDetectionModel() {
      return this.model.network_type === 'detection'
    },
  },

  watch: {
    model() {
      if (this.$refs.itemViewerWrapper) {
        this.$refs.itemViewerWrapper.scrollTo(0, 0)
      }

      if (this.model) {
        this.getModelDetails({ model: this.model })
        this.loadItems(this.model.id)
      } else {
        this.reset()
      }
    },
  },

  created() {
    this.loadThrottled = _.throttle(
      (modelId, callback, loadNext, loadPrevious) => {
        if (this.model && this.model.id === modelId) {
          this.loadItems(modelId, callback, loadNext, loadPrevious)
        }
      },
      200,
      { leading: true }
    )
  },

  mounted() {
    if (this.model) {
      this.getModelDetails(this.model)
      this.loadItems(this.model.id)
    }
  },

  beforeDestroy() {
    if (this.loadItemCancelTokenSource) {
      // cancel pending request
      this.loadItemCancelTokenSource.cancel()
      this.loadItemCanceled = true
    }
  },

  methods: {
    ...mapActions(['getModelDetails']),
    onClickItem({ item: item }) {
      this.modals.itemPreview.item = item
      this.modals.itemPreview.show = true
    },
    reset() {
      this.itemCursor.position = undefined
      this.itemCursor.reversed = false
      this.earliestPreviousLink = undefined
      this.latestNextLink = undefined
      if (this.$refs.itemViewerWrapper) {
        // scroll to top
        this.$refs.itemViewerWrapper.scrollTo(0, 0)
      }
      this.items = []
    },
    applyFilterSettings(event) {
      this.filterSettings = event
      this.reset()
      this.loadItems(this.model.id)
    },
    openFilterSettings() {
      this.modals.filterSettings.show = true
    },
    openPreviousItem() {
      let previousItem = undefined
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i]
        if (item.id === this.modals.itemPreview.item.id) {
          if (previousItem) {
            this.modals.itemPreview.item = previousItem
          }
          break
        }
        previousItem = item
      }
    },

    openNextItem() {
      let nextItem = undefined
      for (let i = this.items.length - 1; i >= 0; i--) {
        let item = this.items[i]
        if (item.id === this.modals.itemPreview.item.id) {
          if (nextItem) {
            this.modals.itemPreview.item = nextItem
          }
          break
        }
        nextItem = item
      }
    },
    onScrollItemViewer(event) {
      let scrollTopMax = event.target.scrollTopMax
      if (scrollTopMax === undefined) {
        scrollTopMax = event.target.scrollHeight - event.target.clientHeight
      }
      let scrollTop = Math.ceil(event.target.scrollTop)

      if (
        this.itemsLoading === 0 &&
        scrollTop >= Math.max(scrollTopMax * 0.7, scrollTopMax - 600)
      ) {
        // load next items
        this.loadThrottled(this.model.id, undefined, true, false)
      }

      if (this.itemsLoading === 0 && scrollTop <= 600) {
        // load previous items
        this.loadThrottled(this.model.id, undefined, false, true)
      }
    },
    onScrollAdjustmentRequired({ scrollDifference }) {
      this.$refs.itemViewerWrapper.scrollBy(0, scrollDifference)
    },
    checkLoadItems() {
      const itemsViewerWrapper = this.$refs.itemViewerWrapper
      if (
        this.model &&
        itemsViewerWrapper &&
        itemsViewerWrapper.scrollHeight <= itemsViewerWrapper.clientHeight
      ) {
        this.loadItems(this.model.id, undefined, true, false)
      }
    },
    loadItems(modelId, callback, loadNext, loadPrevious) {
      if (loadPrevious && this.earliestPreviousLink === null) {
        return
      }

      if (loadNext && this.latestNextLink === null) {
        return
      }

      if (modelId === undefined) {
        return
      }

      if (this.loadItemCancelTokenSource) {
        // cancel previous request
        this.loadItemCancelTokenSource.cancel()
        this.loadItemCanceled = true
      }
      this.loadItemCancelTokenSource = axios.CancelToken.source()

      this.itemsLoading++

      let url
      if (loadPrevious && this.earliestPreviousLink) {
        url = this.earliestPreviousLink
      } else if (loadNext && this.latestNextLink) {
        url = this.latestNextLink
      } else {
        let cursor = ''
        if (!loadPrevious && !loadNext && this.itemCursor.position) {
          cursor = `&cursor_position=${this.itemCursor.position}`
        }

        const itemPageSize = 12 * this.itemsPerRow
        let filterQuery = getFilterQuery(this.filterSettings, false, this.timezone, true)
        url = `/api/inference-results/?page_size=${itemPageSize}&network_model__id=${modelId}${filterQuery}${cursor}`
      }

      // convert absolute url to relative url
      if (this.$axios.defaults.baseURL !== '') {
        const urlObj = new URL(url, this.$axios.defaults.baseURL)
        url = urlObj.toString().substring(urlObj.origin.length)
      }

      let self = this
      this.$axios({
        method: 'get',
        url: url,
        cancelToken: this.loadItemCancelTokenSource.token,
      })
        .then((response) => {
          if (self.earliestPreviousLink === undefined || loadPrevious) {
            self.earliestPreviousLink = response.data.previous
          }

          if (self.latestNextLink === undefined || loadNext) {
            self.latestNextLink = response.data.next
          }

          let items = response.data.results

          let updatedItems
          if (loadPrevious) {
            updatedItems = items.concat(self.items)
          } else if (loadNext) {
            updatedItems = self.items.concat(items)
          } else {
            updatedItems = items
          }

          self.items = updatedItems

          self.itemsLoading--
          if (!loadPrevious && !loadNext) {
            // on the initial loading, load one previous chunk
            self.loadItems(modelId, undefined, false, true)
          } else {
            self.$nextTick(() => {
              self.checkLoadItems()
            })
          }
        })
        .catch((error) => {
          self.itemsLoading--
          if (!self.loadItemCanceled) {
            self.$bvToast.toast('Could not load items.', {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 4000,
              solid: true,
            })

            console.error(error)
          }
        })
        .finally(() => {
          if (callback) {
            callback()
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
