<template>
  <div
    class="group"
    :class="{ 'parent-level': level === 0, 'child-level': level > 0 }"
    v-show="!field.hidden"
  >
    <span class="font-weight-700" :class="{ 'text-capitalize': level === 0 }">{{
      field.display_name
    }}</span>
    <div class="mt-2">
      <form-generator
        :all-plaintext="plaintext || field.editable === false"
        :key="'group' + field.name"
        :fields="field.fields"
        :value="value"
        @input="onInput"
        :size="size"
        :form-group-attributes="formGroupAttributes"
        :level="this.level + 1"
      ></form-generator>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'field', 'plaintext', 'size', 'formGroupAttributes', 'level'],
  name: 'ParamGroup',
  components: {
    FormGenerator: () => import('../FormGenerator'),
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {
    onInput(event) {
      this.$emit('input', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.parent-level {
  margin-bottom: 1rem;
}

.child-level {
  margin-bottom: 0;
}
</style>
