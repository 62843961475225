<template>
  <div class="sk-cube-grid">
    <div class="sk-cube sk-cube1" />
    <div class="sk-cube sk-cube2" />
    <div class="sk-cube sk-cube3" />
    <div class="sk-cube sk-cube4" />
    <div class="sk-cube sk-cube5" />
    <div class="sk-cube sk-cube6" />
    <div class="sk-cube sk-cube7" />
    <div class="sk-cube sk-cube8" />
    <div class="sk-cube sk-cube9" />
    <div class="text-secondary loading-text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
}
</script>

<style scoped>
.loading-text {
  font-size: small;
  text-align: center;
  -webkit-animation: fade-in 3.9s infinite ease-in-out;
  animation: fade-in 3.9s infinite ease-in-out;
  margin-top: 106px;
  margin-left: -40px;
  margin-right: -40px;
}

.sk-cube-grid {
  width: 96px;
  height: 96px;
  margin: 0;
  position: absolute;
  top: calc(50% - 48px);
  left: calc(50% - 48px);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 100;
}

.sk-cube-grid .sk-cube {
  width: 30px;
  height: 30px;
  margin: 1px;
  border-radius: 2px;
  background-color: rgba(0, 50, 83, 0.9);
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  z-index: 100;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@-webkit-keyframes fade-in {
  0%,
  10% {
    opacity: 0;
  }
  20%,
  80% {
    opacity: 1;
  }
  90%,
  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0%,
  5% {
    opacity: 0;
  }
  20%,
  80% {
    opacity: 1;
  }
  95%,
  100% {
    opacity: 0;
  }
}
</style>
