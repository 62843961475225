<template>
  <div class="wrapper bg-gray-200 d-flex justify-content-start">
    <div
      v-b-tooltip.bottom.nofade.hover.d0="{ customClass: 'no-tooltip-arrow' }"
      class="bar reviewed no-tooltip-arrow"
      :title="`reviewed: ${((100 * reviewed) / total).toFixed(2)}% (${reviewed})`"
      :style="style(reviewed)"
    />

    <div
      v-b-tooltip.bottom.nofade.hover.d0="{ customClass: 'no-tooltip-arrow' }"
      class="bar annotated no-tooltip-arrow"
      :title="`annotated: ${((100 * annotated) / total).toFixed(2)}% (${annotated})`"
      :style="style(annotated)"
    />

    <div
      v-b-tooltip.bottom.nofade.hover.d0="{ customClass: 'no-tooltip-arrow' }"
      class="bar ignored no-tooltip-arrow"
      :title="`ignored: ${((100 * ignored) / total).toFixed(2)}% (${ignored})`"
      :style="style(ignored)"
    />

    <div
      v-b-tooltip.bottom.nofade.hover.d0="{ customClass: 'no-tooltip-arrow' }"
      class="bar uploaded no-tooltip-arrow"
      :title="`uploaded: ${((100 * uploaded) / total).toFixed(2)}% (${uploaded})`"
      :style="style(uploaded)"
    />
  </div>
</template>

<script>
export default {
  name: 'StatusBar',
  props: {
    total: {
      default: 0,
    },
    reviewed: {
      default: 0,
    },
    annotated: {
      default: 0,
    },
    uploaded: {
      default: 0,
    },
    ignored: {
      default: 0,
    },
  },
  methods: {
    style(stat) {
      return {
        width: `${100 * (stat / this.total)}%`,
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../custom';

.wrapper {
  height: 20px;
  border-radius: 6px;
  overflow: hidden;
}

.bar {
  height: 100%;
  transition: all 0.3s ease-out;
}

.reviewed {
  background-color: $green;
}

.annotated {
  background-color: $blue;
}

.uploaded {
  background-color: $light;
}

.ignored {
  background-color: $dark;
}
</style>
