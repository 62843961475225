<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 statistics-content pt-4 pb-3">
        <loading-spinner v-if="loading" class="loading-spinner" />
        <div v-if="loading" class="center-loading-background" />

        <div v-if="anyChart" class="pl-3 pr-3 d-flex justify-content-between">
          <div>
            <b-button
              :variant="activeTab === 'training' ? 'primary' : 'light'"
              class="mr-2 no-focus-ring"
              @click="activeTab = 'training'"
            >
              Train Metrics
            </b-button>
            <b-button
              :variant="activeTab === 'evaluation' ? 'primary' : 'light'"
              class="no-focus-ring"
              @click="activeTab = 'evaluation'"
            >
              Test Metrics
            </b-button>
          </div>
          <b-button
            :class="{ rotating: loading }"
            :disabled="loading"
            class="text-primary no-btn-outline"
            size="sm"
            variant="link"
            @click="reload"
          >
            <fai icon="sync" />
          </b-button>
        </div>

        <div v-if="anyChart" class="p-0">
          <div v-show="activeTab === 'training'" class="p-0" title="Train Metrics">
            <div class="row m-0 pt-3">
              <template v-for="(chart, i) in scalarCharts['train']">
                <div
                  v-if="chart.series.length > 0"
                  :key="`scalar-train-chart-${i}`"
                  class="col-lg-12 col-xl-6 col-xxl-4"
                >
                  <apexchart :options="chart.options" :series="chart.series" :type="chart.type" />
                </div>
              </template>
            </div>
          </div>

          <div v-show="activeTab === 'evaluation'" class="p-0" title="Test Metrics">
            <div class="row m-0 pl-0 pr-0 pt-0 pb-0">
              <div class="col-12 pl-3 pr-0 pt-3">
                <b-tabs content-class="p-0" pills small vertical>
                  <b-tab
                    v-if="
                      averagePrecisionChart.series.length > 0 ||
                      precisionChart.series.length > 0 ||
                      mAPChart.series.length > 0 ||
                      accuracyChart.series.length > 0
                    "
                    active
                    class="p-0"
                    title="Overview"
                  >
                    <div class="row m-0 pl-3 pr-3 pt-3 pb-0" />
                    <div class="row m-0 pt-3">
                      <div
                        v-if="averagePrecisionChart.series.length > 0"
                        class="col-lg-12 col-xl-6 col-xxl-4"
                      >
                        <apexchart
                          :options="averagePrecisionChart.options"
                          :series="averagePrecisionChart.series"
                          type="bar"
                        />
                      </div>

                      <div
                        v-if="precisionChart.series.length > 0"
                        class="col-lg-12 col-xl-6 col-xxl-4"
                      >
                        <apexchart
                          :options="precisionChart.options"
                          :series="precisionChart.series"
                          type="bar"
                        />
                      </div>

                      <div v-if="mAPChart.series.length > 0" class="col-lg-12 col-xl-6 col-xxl-4">
                        <apexchart
                          :options="mAPChart.options"
                          :series="mAPChart.series"
                          type="area"
                        />
                      </div>

                      <div
                        v-if="accuracyChart.series.length > 0"
                        class="col-lg-12 col-xl-6 col-xxl-4"
                      >
                        <apexchart
                          :options="accuracyChart.options"
                          :series="accuracyChart.series"
                          type="area"
                        />
                      </div>

                      <div
                        v-if="recallChart.series.length > 0"
                        class="col-lg-12 col-xl-6 col-xxl-4"
                      >
                        <apexchart
                          :options="recallChart.options"
                          :series="recallChart.series"
                          type="bar"
                        />
                      </div>

                      <div
                        v-if="confusionMatrixChart.series.length > 0"
                        class="col-lg-12 col-xl-6 col-xxl-4"
                      >
                        <apexchart
                          :options="confusionMatrixChart.options"
                          :series="confusionMatrixChart.series"
                          type="heatmap"
                        />
                      </div>
                    </div>
                  </b-tab>

                  <b-tab v-if="showAveragePrecisionCharts" class="p-0" title="Average Precisions">
                    <div class="row m-0 pt-3">
                      <template v-for="(chart, i) in averagePrecisionCharts">
                        <div
                          v-if="chart.series.length > 0"
                          :key="`ap-chart-${i}`"
                          class="col-lg-12 col-xl-6 col-xxl-4"
                        >
                          <apexchart
                            :options="chart.options"
                            :series="chart.series"
                            :type="chart.type"
                          />
                        </div>
                      </template>
                    </div>
                  </b-tab>

                  <b-tab v-if="showPrCurveCharts" class="p-0" title="PR Curves">
                    <div class="row m-0 pt-3">
                      <template v-for="(chart, i) in prCurveCharts">
                        <div
                          v-if="chart.series.length > 0"
                          :key="`pr-curve-chart-${i}`"
                          class="col-lg-12 col-xl-6 col-xxl-4"
                        >
                          <apexchart
                            :options="chart.options"
                            :series="chart.series"
                            :type="chart.type"
                          />
                        </div>
                      </template>
                    </div>
                  </b-tab>

                  <b-tab v-if="showEvalScalarCharts" class="p-0" title="Scalars">
                    <div class="row m-0 pt-3">
                      <template v-for="(chart, i) in scalarCharts['eval']">
                        <div
                          v-if="chart.series.length > 0"
                          :key="`scalar-eval-chart-${i}`"
                          class="col-lg-12 col-xl-6 col-xxl-4"
                        >
                          <apexchart
                            :options="chart.options"
                            :series="chart.series"
                            :type="chart.type"
                          />
                        </div>
                      </template>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="row">
          <div class="col-12 p-5">
            <span class="text-secondary font-weight-500">
              <fai class="mr-2" icon="arrow-left" />
              select models to view metrics
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import LoadingSpinner from '../components/LoadingSpinner'

let defaultTooltipFormatter = function (value) {
  if (value === undefined) {
    return value
  } else if (value === 0.0) {
    return '0.0'
  } else if (value < 0.0001) {
    return value.toExponential(3)
  } else {
    return value.toFixed(4)
  }
}

let defaultYAxisFormatter = function (value) {
  if (value === undefined) {
    return value
  } else if (value === 0.0) {
    return '0.0'
  } else if (value < 0.1) {
    return value.toExponential(1)
  } else {
    return value.toFixed(1)
  }
}

export default {
  name: 'ModelStatistics',
  components: { LoadingSpinner },
  props: {
    selectedModels: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      activeTab: 'training',
      models: this.selectedModels,
      classLabels: {},
      statistics: {},
      anyChart: false,

      defaultScalarChartOptions: {
        theme: {
          palette: 'palette6',
        },
        chart: {
          fontFamily: 'IBM Plex Sans, Helvetica, Arial, sans-serif',
          stacked: false,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: false,
          },
          toolbar: {
            show: true,
            autoSelected: 'zoom',
            tools: {
              download: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
            },
          },
          animations: {
            enabled: false,
            dynamicAnimation: {
              enabled: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
          width: 1.5,
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        title: {
          text: '',
          align: 'center',
        },
        xaxis: {
          type: 'category',
          tickAmount: 5,
          title: {
            text: 'iteration',
          },
          labels: {
            formatter: function (value) {
              return Math.round(value)
            },
          },
        },
        yaxis: {
          title: {
            text: 'Loss',
          },
          labels: {
            formatter: defaultYAxisFormatter,
          },
          min: function (min) {
            if (min >= 0.0) {
              return 0.0
            }
            return min
          },
          max: function (max) {
            if (0.1 <= max && max <= 1.0) {
              return 1.0
            }
            return max
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: defaultTooltipFormatter,
          },
        },
      },
      scalarCharts: {},
      scalarChartsCount: {},

      averagePrecisionChart: {
        options: {
          theme: {
            palette: 'palette6',
          },
          chart: {
            fontFamily: 'IBM Plex Sans, Helvetica, Arial, sans-serif',
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            size: 0,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
            formatter: defaultTooltipFormatter,
          },
          title: {
            text: 'average precision',
            align: 'center',
          },
          xaxis: {
            type: 'category',
            title: {
              text: 'class',
            },
            tickPlacement: 'between',
            labels: {
              rotate: -90,
            },
            categories: [],
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                if (value !== undefined) {
                  return value.toFixed(1)
                }
                return value
              },
            },
            min: 0.0,
            max: 1.0,
            title: {
              text: 'precision',
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            onItemClick: {
              toggleDataSeries: false,
            },
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: defaultTooltipFormatter,
            },
          },
        },
        data: {},
        categories: [],
        series: [],
      },

      precisionChart: {
        options: {
          theme: {
            palette: 'palette6',
          },
          chart: {
            fontFamily: 'IBM Plex Sans, Helvetica, Arial, sans-serif',
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            size: 0,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
            formatter: defaultTooltipFormatter,
          },
          title: {
            text: 'precision',
            align: 'center',
          },
          xaxis: {
            type: 'category',
            title: {
              text: 'class',
            },
            tickPlacement: 'between',
            labels: {
              rotate: -90,
            },
            categories: [],
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                if (value !== undefined) {
                  return value.toFixed(2)
                }
                return value
              },
            },
            min: 0.0,
            max: 1.0,
            title: {
              text: 'precision',
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            onItemClick: {
              toggleDataSeries: false,
            },
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: defaultTooltipFormatter,
            },
          },
        },
        data: {},
        categories: [],
        series: [],
      },

      recallChart: {
        options: {
          theme: {
            palette: 'palette6',
          },
          chart: {
            fontFamily: 'IBM Plex Sans, Helvetica, Arial, sans-serif',
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            size: 0,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
            formatter: defaultTooltipFormatter,
          },
          title: {
            text: 'recall',
            align: 'center',
          },
          xaxis: {
            type: 'category',
            title: {
              text: 'class',
            },
            tickPlacement: 'between',
            labels: {
              rotate: -90,
            },
            categories: [],
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                if (value !== undefined) {
                  return value.toFixed(2)
                }
                return value
              },
            },
            min: 0.0,
            max: 1.0,
            title: {
              text: 'recall',
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            onItemClick: {
              toggleDataSeries: false,
            },
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: defaultTooltipFormatter,
            },
          },
        },
        data: {},
        categories: [],
        series: [],
      },

      confusionMatrixChart: {
        options: {
          chart: {
            fontFamily: 'IBM Plex Sans, Helvetica, Arial, sans-serif',
          },
          colors: ['#008FFB'],
          title: {
            text: 'confusion matrix',
            align: 'center',
          },
          dataLabels: {
            // style: {
            //     colors: ["#333333"]
            // },
            formatter: function (value) {
              return value.toFixed(2)
            },
          },
          xaxis: {
            title: {
              text: 'predicted class',
            },
          },
          yaxis: {
            title: {
              text: 'true class',
            },
          },
        },
        data: {},
        series: [],
      },

      mAPChart: {
        options: {
          theme: {
            palette: 'palette6',
          },
          chart: {
            fontFamily: 'IBM Plex Sans, Helvetica, Arial, sans-serif',
            stacked: false,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: false,
            },
            toolbar: {
              show: true,
              autoSelected: 'zoom',
              tools: {
                download: false,
                zoom: true,
                zoomin: false,
                zoomout: false,
              },
            },
            animations: {
              enabled: false,
              dynamicAnimation: {
                enabled: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
            width: 1.5,
          },
          grid: {
            show: true,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
          title: {
            text: 'mean average precision (mAP)',
            align: 'center',
          },
          xaxis: {
            type: 'category',
            tickAmount: 5,
            title: {
              text: 'iteration',
            },
            labels: {
              formatter: function (value) {
                return Math.round(value)
              },
            },
          },
          yaxis: {
            title: {
              text: 'mean average precision (mAP)',
            },
            labels: {
              formatter: function (value) {
                if (value >= 0.1) {
                  return value.toFixed(1)
                }

                if (value === 0.0) {
                  return value.toFixed(1)
                }

                return value.toExponential(1)
              },
            },
            min: 0.0,
            max: 1.0,
          },
          legend: {
            show: true,
            showForSingleSeries: true,
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: defaultTooltipFormatter,
            },
          },
        },
        data: {},
        series: [],
      },

      accuracyChart: {
        options: {
          theme: {
            palette: 'palette6',
          },
          chart: {
            fontFamily: 'IBM Plex Sans, Helvetica, Arial, sans-serif',
            stacked: false,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: false,
            },
            toolbar: {
              show: true,
              autoSelected: 'zoom',
              tools: {
                download: false,
                zoom: true,
                zoomin: false,
                zoomout: false,
              },
            },
            animations: {
              enabled: false,
              dynamicAnimation: {
                enabled: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
            width: 1.5,
          },
          grid: {
            show: true,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
          title: {
            text: 'accuracy',
            align: 'center',
          },
          xaxis: {
            type: 'category',
            tickAmount: 5,
            title: {
              text: 'iteration',
            },
            labels: {
              formatter: function (value) {
                return Math.round(value)
              },
            },
          },
          yaxis: {
            title: {
              text: 'accuracy',
            },
            labels: {
              formatter: function (value) {
                if (value >= 0.1) {
                  return value.toFixed(1)
                }

                if (value === 0.0) {
                  return value.toFixed(1)
                }

                return value.toExponential(1)
              },
            },
            min: 0.0,
            max: 1.0,
          },
          legend: {
            show: true,
            showForSingleSeries: true,
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: defaultTooltipFormatter,
            },
          },
        },
        data: {},
        series: [],
      },

      defaultAveragePrecisionChartOptions: {
        theme: {
          palette: 'palette6',
        },
        chart: {
          fontFamily: 'IBM Plex Sans, Helvetica, Arial, sans-serif',
          stacked: false,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: false,
          },
          toolbar: {
            show: true,
            autoSelected: 'zoom',
            tools: {
              download: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
            },
          },
          animations: {
            enabled: false,
            dynamicAnimation: {
              enabled: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
          width: 1.5,
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        title: {
          text: 'average precision',
          align: 'center',
        },
        xaxis: {
          type: 'category',
          tickAmount: 5,
          title: {
            text: 'iteration',
          },
          labels: {
            formatter: function (value) {
              return Math.round(value)
            },
          },
        },
        yaxis: {
          title: {
            text: 'Loss',
          },
          labels: {
            formatter: function (value) {
              return value.toFixed(1)
            },
          },
          min: 0.0,
          max: 1.0,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: defaultTooltipFormatter,
          },
        },
      },
      averagePrecisionCharts: {},

      defaultPrCurveChartOptions: {
        theme: {
          palette: 'palette6',
        },
        chart: {
          fontFamily: 'IBM Plex Sans, Helvetica, Arial, sans-serif',
          stacked: false,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: false,
          },
          toolbar: {
            show: true,
            autoSelected: 'zoom',
            tools: {
              download: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
            },
          },
          animations: {
            enabled: false,
            dynamicAnimation: {
              enabled: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
          width: 1.5,
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        title: {
          text: 'precision-recall curve',
          align: 'center',
        },
        xaxis: {
          type: 'numeric',
          // tickAmount: 15,
          title: {
            text: 'recall',
          },
          labels: {
            formatter: function (value) {
              return value.toFixed(2)
            },
          },
          min: 0.0,
          max: 1.0,
        },
        yaxis: {
          title: {
            text: 'precision',
          },
          tickAmount: 10,
          labels: {
            formatter: function (value) {
              return value.toFixed(2)
            },
          },
          min: 0.0,
          max: 1.0,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: defaultTooltipFormatter,
          },
        },
      },
      prCurveCharts: {},
    }
  },

  computed: {
    showAveragePrecisionCharts: function () {
      return this.emptySeries(this.averagePrecisionCharts)
    },

    showPrCurveCharts: function () {
      return this.emptySeries(this.prCurveCharts)
    },

    showEvalScalarCharts: function () {
      if (_.isEmpty(this.scalarCharts)) {
        return false
      }

      if (Object.prototype.hasOwnProperty.call(this.scalarCharts, 'eval')) {
        return false
      }

      return this.emptySeries(this.scalarCharts['eval'])
    },
  },

  watch: {
    selectedModels: function (newSelectedModels) {
      this.modelChange(newSelectedModels)
    },
  },

  mounted() {
    this.modelChange(this.selectedModels)
  },

  beforeDestroy() {
    if (this.loadStatisticsCancelTokenSource) {
      // cancel pending request
      this.loadStatisticsCancelTokenSource.cancel()
      this.loadStatisticsCanceled = true
    }
  },

  methods: {
    resetChart(chart) {
      if (Object.prototype.hasOwnProperty.call(chart, 'data')) {
        chart.data = {}
      }
      if (Object.prototype.hasOwnProperty.call(chart, 'series')) {
        chart.series = []
      }
      if (Object.prototype.hasOwnProperty.call(chart, 'categories')) {
        chart.categories = []
      }
    },

    reload() {
      this.statistics = []
      this.scalarCharts = {}
      this.scalarChartsCount = {}
      this.averagePrecisionCharts = {}
      this.prCurveCharts = {}

      this.resetChart(this.averagePrecisionChart)
      this.resetChart(this.precisionChart)
      this.resetChart(this.recallChart)
      this.resetChart(this.confusionMatrixChart)
      this.resetChart(this.mAPChart)
      this.resetChart(this.accuracyChart)

      this.modelChange(this.selectedModels)
    },

    modelChange(newSelectedModels) {
      const self = this
      this.loading = true
      this.models = newSelectedModels

      // determine added models
      let newModels = _.differenceWith(
        newSelectedModels,
        Object.keys(this.statistics),
        (newModel, oldModelId) => {
          return newModel.id === parseInt(oldModelId)
        }
      )

      if (this.loadStatisticsCancelTokenSource) {
        // cancel previous request
        this.loadStatisticsCancelTokenSource.cancel()
        this.loadStatisticsCanceled = true
      }
      this.loadStatisticsCancelTokenSource = axios.CancelToken.source()

      // create http requests to load statistics for new models
      let modelStatisticHttpCalls = []
      let modelStatisticHttpCallsIds = []
      newModels.forEach((newModel) => {
        this.statistics[newModel.id] = []
        modelStatisticHttpCalls.push(
          this.$axios({
            method: 'get',
            url: `/api/models/${newModel.id}/statistics`,
            cancelToken: this.loadStatisticsCancelTokenSource.token,
          })
        )
        modelStatisticHttpCallsIds.push(newModel.id)
      })

      // load statistics for new models
      if (modelStatisticHttpCalls.length > 0) {
        axios
          .all(modelStatisticHttpCalls)
          .then((results) => {
            results.forEach((response, index) => {
              let modelId = modelStatisticHttpCallsIds[index]
              this.statistics[modelId] = response.data
              this.createCharts(modelId)
            })
          })
          .catch((error) => {
            if (!self.loadStatisticsCanceled) {
              console.log(error)
              this.$bvToast.toast('Could not load model statistics.', {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 4000,
                solid: true,
              })
            }
          })
          .finally(() => {
            // draw selected charts
            this.drawCharts()
          })
      } else {
        // draw selected charts
        this.drawCharts()
      }
    },

    createClassificationCharts(modelId, iteration, isLastEntry, classificationStatistics) {
      const MULTI_CLASS = 'multi_class'
      const PER_CLASS = 'per_class'
      const PRECISION = 'precision'
      const RECALL = 'recall'
      const ACCURACY = 'accuracy'
      const CONFUSION_MATRIX = 'confusion_matrix'

      let classLabelMap = this.$store.getters.classLabelMap

      if (ACCURACY in classificationStatistics) {
        let dataExists = modelId in this.accuracyChart.data
        if (!dataExists) {
          this.accuracyChart.data[modelId] = []
        }

        // append value to data
        let e = [iteration, classificationStatistics[ACCURACY]]
        this.accuracyChart.data[modelId].push(e)
      }

      if (CONFUSION_MATRIX in classificationStatistics) {
        if (isLastEntry) {
          this.confusionMatrixChart.data[modelId] = classificationStatistics[CONFUSION_MATRIX]
        }
      }

      if (MULTI_CLASS in classificationStatistics) {
        if (PER_CLASS in classificationStatistics[MULTI_CLASS]) {
          if (PRECISION in classificationStatistics[MULTI_CLASS][PER_CLASS]) {
            if (isLastEntry) {
              // create precision bar chart

              let data = []
              let categories = []
              for (let classId in classificationStatistics[MULTI_CLASS][PER_CLASS][PRECISION]) {
                let label = 'Class ' + classId
                let classLabel = classLabelMap[classId]
                if (classLabel !== undefined) {
                  label = classLabel.name
                }
                data.push(classificationStatistics[MULTI_CLASS][PER_CLASS][PRECISION][classId])
                categories.push(label)
              }

              this.$set(this.precisionChart.data, modelId, data)
              this.$set(this.precisionChart.categories, modelId, categories)
            }
          }

          if (RECALL in classificationStatistics[MULTI_CLASS][PER_CLASS]) {
            if (isLastEntry) {
              // create precision bar chart

              let data = []
              let categories = []
              for (let classId in classificationStatistics[MULTI_CLASS][PER_CLASS][RECALL]) {
                let label = 'Class ' + classId
                let classLabel = classLabelMap[classId]
                if (classLabel !== undefined) {
                  label = classLabel.name
                }
                data.push(classificationStatistics[MULTI_CLASS][PER_CLASS][RECALL][classId])
                categories.push(label)
              }

              this.$set(this.recallChart.data, modelId, data)
              this.$set(this.recallChart.categories, modelId, categories)
            }
          }
        }

        // for (let classId in detectionStatistics[AVERAGE_PRECISIONS]) {
        //     let chartExists = classId in this.averagePrecisionCharts;
        //
        //     if (!chartExists) {
        //         this.$set(this.averagePrecisionCharts, classId, {
        //             classId: classId,
        //             type: 'area',
        //             options: _.cloneDeep(this.defaultAveragePrecisionChartOptions),
        //             data: {},
        //             series: []
        //         });
        //
        //         let label = 'average precision - class ' + classId;
        //         let classLabel = classLabelMap[classId];
        //         if (classLabel !== undefined) {
        //             label = 'average precision - ' + classLabel;
        //         }
        //
        //         this.averagePrecisionCharts[classId].options.title.text = label;
        //         this.averagePrecisionCharts[classId].options.yaxis.title.text = 'average precision';
        //     }
        //
        //     let dataExists = modelId in this.averagePrecisionCharts[classId].data;
        //     if (!dataExists) {
        //         this.averagePrecisionCharts[classId].data[modelId] = [];
        //     }
        //
        //     // append value to data
        //     let e = [iteration, detectionStatistics[AVERAGE_PRECISIONS][classId]];
        //     this.averagePrecisionCharts[classId].data[modelId].push(e);
        // }
      }
    },

    createDetectionCharts(modelId, iteration, isLastEntry, detectionStatistics) {
      const AVERAGE_PRECISIONS = 'average_precisions'
      const PR_CURVES = 'pr_curves'

      let classLabelMap = this.$store.getters.classLabelMap

      if (AVERAGE_PRECISIONS in detectionStatistics) {
        if (isLastEntry) {
          // create average precision bar chart
          let data = []
          let categories = []
          for (let classId in detectionStatistics[AVERAGE_PRECISIONS]) {
            let label = 'Class ' + classId
            let classLabel = classLabelMap[classId]
            if (classLabel !== undefined) {
              label = classLabel.name
            }
            data.push(detectionStatistics[AVERAGE_PRECISIONS][classId])
            categories.push(label)
          }

          this.$set(this.averagePrecisionChart.data, modelId, data)
          this.$set(this.averagePrecisionChart.categories, modelId, categories)
        }

        {
          // mAP chart
          let meanAveragePrecision = 0.0
          let count = 0
          for (let classId in detectionStatistics[AVERAGE_PRECISIONS]) {
            meanAveragePrecision += detectionStatistics[AVERAGE_PRECISIONS][classId]
            count += 1
          }
          meanAveragePrecision /= count

          let dataExists = modelId in this.mAPChart.data
          if (!dataExists) {
            this.mAPChart.data[modelId] = []
          }

          // append value to data
          let e = [iteration, meanAveragePrecision]
          this.mAPChart.data[modelId].push(e)
        }

        for (let classId in detectionStatistics[AVERAGE_PRECISIONS]) {
          let chartExists = classId in this.averagePrecisionCharts

          if (!chartExists) {
            this.$set(this.averagePrecisionCharts, classId, {
              classId: classId,
              type: 'area',
              options: _.cloneDeep(this.defaultAveragePrecisionChartOptions),
              data: {},
              series: [],
            })

            let label = 'average precision - class ' + classId
            let classLabel = classLabelMap[classId]
            if (classLabel !== undefined) {
              label = 'average precision - ' + classLabel.name
            }

            this.averagePrecisionCharts[classId].options.title.text = label
            this.averagePrecisionCharts[classId].options.yaxis.title.text = 'average precision'
          }

          let dataExists = modelId in this.averagePrecisionCharts[classId].data
          if (!dataExists) {
            this.averagePrecisionCharts[classId].data[modelId] = []
          }

          // append value to data
          let e = [iteration, detectionStatistics[AVERAGE_PRECISIONS][classId]]
          this.averagePrecisionCharts[classId].data[modelId].push(e)
        }
      }

      if (PR_CURVES in detectionStatistics) {
        if (isLastEntry) {
          // create pr-curves

          for (let classId in detectionStatistics[PR_CURVES]) {
            let chartExists = classId in this.prCurveCharts

            if (!chartExists) {
              this.$set(this.prCurveCharts, classId, {
                classId: classId,
                type: 'area',
                options: _.cloneDeep(this.defaultPrCurveChartOptions),
                data: {},
                series: [],
              })

              let label = 'class ' + classId
              let classLabel = classLabelMap[classId]
              if (classLabel !== undefined) {
                label = classLabel.name
              }

              this.prCurveCharts[classId].options.title.text = label
            }

            this.$set(
              this.prCurveCharts[classId].data,
              modelId,
              detectionStatistics[PR_CURVES][classId]
            )
          }
        }
      }
    },

    createScalarCharts(modelId, iteration, scalars, category) {
      if (!(category in this.scalarCharts)) {
        this.$set(this.scalarCharts, category, {})
      }

      for (let key in scalars) {
        let value = scalars

        let chartExists = key in this.scalarCharts[category]
        if (!chartExists) {
          this.$set(this.scalarCharts[category], key, {
            key: key,
            type: 'area',
            options: _.cloneDeep(this.defaultScalarChartOptions),
            data: {},
            series: [],
          })
          this.scalarCharts[category][key].options.title.text = key
          this.scalarCharts[category][key].options.yaxis.title.text = key
        }

        let dataExists = modelId in this.scalarCharts[category][key].data
        if (!dataExists) {
          this.scalarCharts[category][key].data[modelId] = []
        }

        // append value to data
        let e = [iteration, value[key]]
        this.scalarCharts[category][key].data[modelId].push(e)
      }
    },

    createCharts(modelId) {
      const ITERATION = 'iteration'
      const STATISTICS = 'statistics'
      const TRAIN = 'train'
      const EVAL = 'eval'
      const DETECTION = 'detection'
      const CLASSIFICATION = 'classification'
      const SCALARS = 'scalars'

      let modelStatistics = this.statistics[modelId]
      if (modelStatistics === undefined) {
        return
      }

      for (let i = 0; i < modelStatistics.length; i++) {
        let entry = modelStatistics[i]
        let iteration = entry[ITERATION]
        let statistics = entry[STATISTICS]

        if (EVAL in statistics) {
          if (SCALARS in statistics[EVAL]) {
            this.createScalarCharts(modelId, iteration, statistics[EVAL][SCALARS], EVAL)
          }

          if (CLASSIFICATION in statistics[EVAL]) {
            this.createClassificationCharts(
              modelId,
              iteration,
              i === modelStatistics.length - 1,
              statistics[EVAL][CLASSIFICATION]
            )
          }

          if (DETECTION in statistics[EVAL]) {
            this.createDetectionCharts(
              modelId,
              iteration,
              i === modelStatistics.length - 1,
              statistics[EVAL][DETECTION]
            )
          }
        }

        if (TRAIN in statistics) {
          if (SCALARS in statistics[TRAIN]) {
            this.createScalarCharts(modelId, iteration, statistics[TRAIN][SCALARS], TRAIN)
          }
        }
      }
    },

    drawCharts() {
      let classLabelMap = this.$store.getters.classLabelMap

      let anyChart = false

      this.loading = true
      for (let category in this.scalarCharts) {
        this.scalarChartsCount[category] = 0
        for (let key in this.scalarCharts[category]) {
          let series = []
          for (let i = 0; i < this.models.length; i++) {
            let model = this.models[i]

            if (model.id in this.scalarCharts[category][key].data) {
              let scalarSeries = {
                name: model.id,
                data: this.scalarCharts[category][key].data[model.id],
              }
              series.push(scalarSeries)
            }
          }
          this.$set(this.scalarCharts[category][key], 'series', series)
          if (series.length > 0) {
            //   this.scalarCharts[category].active = true;
            this.scalarChartsCount[category] += 1
            anyChart = true
          }
        }
      }

      {
        // average precision chart
        let series = []
        let categories = new Set()
        for (let i = 0; i < this.models.length; i++) {
          let model = this.models[i]
          if (model.id in this.averagePrecisionChart.data) {
            let scalarSeries = {
              name: model.id,
              type: 'column',
              data: this.averagePrecisionChart.data[model.id],
            }
            series.push(scalarSeries)
            anyChart = true

            for (let j = 0; j < this.averagePrecisionChart.categories[model.id].length; j++) {
              let category = this.averagePrecisionChart.categories[model.id][j]
              categories.add(category)
            }
          }
        }

        let averagePrecisionChart = this.averagePrecisionChart
        averagePrecisionChart.options.xaxis.categories = Array.from(categories)
        averagePrecisionChart.series = series
        this.averagePrecisionChart = _.cloneDeep(averagePrecisionChart)
      }

      {
        // precision chart
        let series = []
        let categories = new Set()
        for (let i = 0; i < this.models.length; i++) {
          let model = this.models[i]
          if (model.id in this.precisionChart.data) {
            let scalarSeries = {
              name: model.id,
              type: 'column',
              data: this.precisionChart.data[model.id],
            }
            series.push(scalarSeries)
            anyChart = true

            for (let j = 0; j < this.precisionChart.categories[model.id].length; j++) {
              let category = this.precisionChart.categories[model.id][j]
              categories.add(category)
            }
          }
        }

        let precisionChart = this.precisionChart
        precisionChart.options.xaxis.categories = Array.from(categories)
        precisionChart.series = series
        this.precisionChart = _.cloneDeep(precisionChart)
      }

      {
        // recall chart
        let series = []
        let categories = new Set()
        for (let i = 0; i < this.models.length; i++) {
          let model = this.models[i]
          if (model.id in this.recallChart.data) {
            let scalarSeries = {
              name: model.id,
              type: 'column',
              data: this.recallChart.data[model.id],
            }
            series.push(scalarSeries)
            anyChart = true

            for (let j = 0; j < this.recallChart.categories[model.id].length; j++) {
              let category = this.recallChart.categories[model.id][j]
              categories.add(category)
            }
          }
        }

        let recallChart = this.recallChart
        recallChart.options.xaxis.categories = Array.from(categories)
        recallChart.series = series
        this.recallChart = _.cloneDeep(recallChart)
      }

      {
        // confusion matrix
        let series = []
        for (let i = 0; i < this.models.length; i++) {
          let model = this.models[i]
          if (model.id) {
            if (model.id in this.confusionMatrixChart.data) {
              anyChart = true

              let classIds = this.confusionMatrixChart.data[model.id]['class_ids']
              let matrix = this.confusionMatrixChart.data[model.id]['matrix']
              let nClasses = classIds.length

              let classNames = []
              for (let j = 0; j < nClasses; j++) {
                let classId = classIds[j]
                let label = 'class ' + classId
                let classLabel = classLabelMap[classId]
                if (classLabel !== undefined) {
                  label = classLabel.name
                }
                classNames.push(label)
              }

              let confusionMatrixOk = false
              if (matrix && matrix.length === nClasses) {
                confusionMatrixOk = true
                for (let j = nClasses - 1; j >= 0; j--) {
                  if (matrix[j].length !== nClasses) {
                    confusionMatrixOk = false
                    break
                  }
                }
              }

              if (confusionMatrixOk) {
                for (let j = nClasses - 1; j >= 0; j--) {
                  let data = []

                  let sum = 0.0
                  for (let k = 0; k < nClasses; k++) {
                    sum += matrix[j][k]
                  }

                  for (let k = 0; k < nClasses; k++) {
                    data.push({
                      x: classNames[k],
                      y: (matrix[j][k] / sum).toFixed(4),
                    })
                  }

                  let scalarSeries = {
                    name: classNames[j],
                    data: data,
                  }
                  series.push(scalarSeries)
                }
              }
            }
          }
        }

        let confusionMatrixChart = this.confusionMatrixChart
        confusionMatrixChart.series = series
        this.confusionMatrixChart = _.cloneDeep(confusionMatrixChart)
      }

      {
        let series = []
        for (let i = 0; i < this.models.length; i++) {
          let model = this.models[i]
          if (model.id in this.mAPChart.data) {
            let scalarSeries = {
              name: model.id,
              data: this.mAPChart.data[model.id],
            }
            series.push(scalarSeries)
            anyChart = true
          }
        }
        this.$set(this.mAPChart, 'series', series)
      }

      {
        // accuracy chart
        let series = []
        for (let i = 0; i < this.models.length; i++) {
          let model = this.models[i]
          if (model.id in this.accuracyChart.data) {
            let scalarSeries = {
              name: model.id,
              data: this.accuracyChart.data[model.id],
            }
            series.push(scalarSeries)
            anyChart = true
          }
        }
        this.$set(this.accuracyChart, 'series', series)
      }

      {
        for (let classId in this.averagePrecisionCharts) {
          let series = []
          for (let i = 0; i < this.models.length; i++) {
            let model = this.models[i]

            if (model.id in this.averagePrecisionCharts[classId].data) {
              series.push({
                name: model.id,
                data: this.averagePrecisionCharts[classId].data[model.id],
              })
              anyChart = true
            }
          }
          this.$set(this.averagePrecisionCharts[classId], 'series', series)
        }
      }

      {
        for (let classId in this.prCurveCharts) {
          let series = []
          for (let i = 0; i < this.models.length; i++) {
            let model = this.models[i]

            if (model.id in this.prCurveCharts[classId].data) {
              series.push({
                name: model.id,
                data: this.prCurveCharts[classId].data[model.id],
              })
              anyChart = true
            }
          }
          this.$set(this.prCurveCharts[classId], 'series', series)
        }
      }

      this.anyChart = anyChart
      setTimeout(() => {
        this.loading = false
      }, 100)
    },

    emptySeries(charts) {
      if (_.isEmpty(charts)) {
        return false
      }
      for (let key in charts) {
        let chart = charts[key]
        if (chart.series.length > 0) {
          return true
        }
      }
      return false
    },
  },
}
</script>

<style scoped>
.statistics-content {
  min-height: 200px;
}

.loading-spinner {
  z-index: 10;
}

.center-loading-background {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 9;
}
</style>
