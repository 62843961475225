<template>
  <b-form-group
    v-show="!field.hidden"
    v-bind="formGroupAttributes"
    :label="field.display_name"
    :description="field.description"
    :label-size="size"
  >
    <b-form-input
      :id="$id(field.name)"
      required
      :plaintext="plaintext || field.editable === false"
      :state="valid ? null : valid"
      :value="value"
      @input="onInput"
      :placeholder="field.display_name"
      :size="size"
    >
    </b-form-input>
  </b-form-group>
</template>

<script>
export default {
  name: 'DoubleInput',
  props: ['value', 'field', 'plaintext', 'size', 'formGroupAttributes'],
  data() {
    return {
      valid: null,
    }
  },

  mounted() {
    if (this.value === undefined) {
      this.onInput(0.0, true)
    }
  },

  methods: {
    onInput(event, initial) {
      let v = event
      if (v === undefined || v === '') {
        v = undefined
        this.valid = null
      } else {
        v = parseFloat(event)
        this.valid = !isNaN(v)
      }

      if (this.valid) {
        this.$emit('input', v, initial)
      }
    },
  },
}
</script>
